/** @format */

import { UserAccountStatusData, UserAuthType } from "@roadflex/types";
import { SVGProps } from "react";
import MerchantCategoryBreakdownGraph from "../graphs/merchant-category-breakdown-graph.ui";
import WeeklySpendGraph from "../graphs/weekly-spend-graph.ui";
import { AccountAttentionUI } from "./panels/account-attention-panel.ui";
import { AccountDetailsUI } from "./panels/account-details.panel.ui";
import { FraudActivityDetails } from "./panels/fraud-panel.ui";
import { CreditUsersUsage } from "./statistics/credit-statistics/credit-statistics";

type CreditDashboardUIProps = {
  businessName?: string;
  bonusAndCreditStats: {
    id: number;
    name: string;
    stat: number;
  }[];
  currentData: {
    creditBalance: number;
    bonusAmount: number;
    totalFuelQuantity: number;
    bonusType: string;
    referralBalance: number;
    signOnBalance: number;
    targetFuelQuantity: number;
    amountCorrection: number;
  };
  overviewStats: {
    id: number;
    name: string;
    stat: string | number;
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    subText?: () => JSX.Element;
  }[];
  currentCycleData: {
    startDt: Date;
    endDt: Date;
  } | null;
  weeklySpendsData: Array<[number, number]>;
  categoryBreakdown: { [key: string]: string };
  totalFraudDeclinedTransaction: number;
  totalFraudSavings: number;
  totalLifeTimeSavings: number;
  userAccountStatusData?: UserAccountStatusData;
  readCurrentUserAuth: UserAuthType;
};

export const CreditDashboardUI = ({
  businessName,
  bonusAndCreditStats,
  currentData,
  overviewStats,
  currentCycleData,
  weeklySpendsData,
  categoryBreakdown,
  userAccountStatusData,
  totalFraudDeclinedTransaction,
  totalFraudSavings,
  totalLifeTimeSavings,
  readCurrentUserAuth,
}: CreditDashboardUIProps) => {
  return (
    <div className="mt-5 text-sm md:mt-0 md:text-base">
      <FraudActivityDetails
        {...{
          totalFraudDeclinedTransaction,
          totalFraudSavings,
          totalLifeTimeSavings,
        }}
      ></FraudActivityDetails>
      <div className="flex flex-col gap-4 mt-4 2xl:flex-row">
        <div className="2xl:w-9/12">
          <div className="flex flex-col">
            <CreditUsersUsage
              currentCycleData={currentCycleData}
              creditLimit={bonusAndCreditStats[0].stat}
              spend={bonusAndCreditStats[1].stat}
              savings={bonusAndCreditStats[2].stat}
              creditRemaining={bonusAndCreditStats[3].stat}
              readCurrentUserAuth={readCurrentUserAuth}
            ></CreditUsersUsage>
            <AccountDetailsUI
              {...{ overviewStats, businessName }}
            ></AccountDetailsUI>
          </div>
        </div>
        <div className="w-full">
          <AccountAttentionUI
            {...{ userAccountStatusData }}
          ></AccountAttentionUI>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 xl:grid-cols-2 ">
        <div className="">
          <WeeklySpendGraph
            weeklySpendsData={weeklySpendsData}
            dashboardLoading={false}
          ></WeeklySpendGraph>
        </div>
        <div className="">
          <MerchantCategoryBreakdownGraph
            categoryBreakdown={categoryBreakdown}
            dashboardLoading={false}
          ></MerchantCategoryBreakdownGraph>
        </div>
      </div>
    </div>
  );
};
