/** @format */

import { useLazyQuery } from "@apollo/client";
import { FRAUD_ANALYSIS_DATA } from "@roadflex/graphql";
import { UserAuthType } from "@roadflex/types";
import {
  getEndOfWeek,
  getMinDate,
  getStartOfWeek,
  getTimeZoneOffset,
} from "@roadflex/utils";
import { showErrorMessage } from "@roadflex/web-lib";
import { addMilliseconds } from "date-fns";
import { CalendarChangeParams } from "primereact/calendar";
import { DropdownChangeParams } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FraudAnalysisUI } from "./fraud-analysis.ui";

type FraudAnalysisState = {
  dailyFraudDeclinedTransactions: [number, number][];
  weeklyFraudDeclinedTransactions: [number, number][];
  categoryBreakdown: { [key: string]: string };
  code: string;
  message: string;
};

export const FraudAnalysis = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  const fraudDeclinedTransactionReportDefaultValue = {
    dailyFraudDeclinedTransactions: [],
    weeklyFraudDeclinedTransactions: [],
    categoryBreakdown: {},
    code: "",
    message: "",
  };
  const [
    readFraudDeclinedTransactionReport,
    setReadFraudDeclinedTransactionReport,
  ] = useState<FraudAnalysisState>({
    ...fraudDeclinedTransactionReportDefaultValue,
  });
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }
  const [dates, setDates] = useState<Date[]>([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const displayModes = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
  ];
  const [selectedDisplayMode, setSelectedDisplayMode] = useState(
    displayModes[0].value,
  );

  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);
  const [startDate, setStartDate] = useState<Date>(minDate);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [fraudAnalysisDataFn, { loading: fraudAnalysisDataLoading }] =
    useLazyQuery(FRAUD_ANALYSIS_DATA, {
      fetchPolicy: "no-cache",
      onCompleted: (data: {
        readFraudDeclinedTransactionReport: {
          dailyFraudDeclinedTransactions: Array<[number, number]>;
          weeklyFraudDeclinedTransactions: Array<[number, number]>;
          categoryBreakdown: { [key: string]: string };
          code: string;
          message: string;
        };
      }) => {
        if (data?.readFraudDeclinedTransactionReport?.code === "200") {
          setReadFraudDeclinedTransactionReport(
            data?.readFraudDeclinedTransactionReport,
          );
        } else {
          showErrorMessage(
            data?.readFraudDeclinedTransactionReport?.message,
            data?.readFraudDeclinedTransactionReport?.code,
          );
          setReadFraudDeclinedTransactionReport({
            ...fraudDeclinedTransactionReportDefaultValue,
          });
        }
      },
      onError: (error: Error) => {
        toast.error(`Error: ${error.message}`);
      },
      notifyOnNetworkStatusChange: true,
    });

  const fetchFraudAnalysisData = (
    startDt: Date,
    endDt: Date,
    displayMode: string,
  ) => {
    if (startDt && endDt) {
      let finalStartDate = startDt;
      let finalEndDate = endDt;

      if (displayMode === "week") {
        finalStartDate = getStartOfWeek(startDt);
        finalEndDate = getEndOfWeek(endDt);
      }

      setDates([finalStartDate, finalEndDate]);
      fraudAnalysisDataFn({
        variables: {
          data: {
            dateFilter: {
              startDate: addMilliseconds(
                finalStartDate,
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
              endDate: addMilliseconds(
                finalEndDate,
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
            },
          },
        },
      });
    }
  };

  const onDisplayModeChange = (e: DropdownChangeParams) => {
    const displayMode = e.value;
    setSelectedDisplayMode(displayMode);
    if (displayMode === "week") {
      fetchFraudAnalysisData(dates[0], dates[1], displayMode);
    }
  };

  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    if (dateArray[0] && !dateArray[1]) {
      const startDateTemp = new Date(dateArray[0]);
      const endDateTemp = new Date(dateArray[0]);
      startDateTemp.setDate(startDateTemp.getDate() - 30);
      endDateTemp.setDate(endDateTemp.getDate() + 30);
      const calculatedEndDate = getMinDate(endDateTemp, new Date());
      setStartDate(startDateTemp);
      setEndDate(calculatedEndDate);
    }
    if (dateArray[1]) {
      setStartDate(minDate);
      setEndDate(new Date());
    }
    setDates(dateArray);
    fetchFraudAnalysisData(dateArray[0], dateArray[1], selectedDisplayMode);
  };

  useEffect(() => {
    fetchFraudAnalysisData(dates[0], dates[1], selectedDisplayMode);
  }, []);

  return (
    <FraudAnalysisUI
      {...{
        fraudAnalysisDataLoading,
        dates,
        onDateRangeChange,
        startDate,
        endDate,
        displayModes,
        selectedDisplayMode,
        onDisplayModeChange,
        readCurrentUserAuth,
        readFraudDeclinedTransactionReport,
      }}
    />
  );
};
