/** @format */

import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { useCreateAccountTag } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useState } from "react";
import { toast } from "react-toastify";
import { AccountTagsUI } from "./account-tag.ui";

interface AccountTagsNewProps {
  readCurrentUserAuth: UserAuthType;
}
export const AccountTagsNew = ({
  readCurrentUserAuth,
}: AccountTagsNewProps) => {
  const tabs = [{ id: 1, name: "Account Tag", current: true }];
  const [update, setUpdate] = useState(false);
  const router = useRouter();

  const { resetForm: createAccountTagResetForm, ...accountTagFormikValues } =
    useCreateAccountTag({
      initialValues: {
        title: "",
        description: "",
        tagType: ENUM_ACCOUNT_TAG_TYPE.CARD,
      },
      onSubmit(res, err) {
        if (err) {
          toast.error(err.message);
          return;
        }
        if (res?.code === "200") {
          toast.success(res.message);
        }
        createAccountTagResetForm();
        router.push("/account-tags");
      },
    });
  return (
    <AccountTagsUI
      {...{
        customerType: readCurrentUserAuth.fuelCard.customerType,
        tabs,
        resetForm: createAccountTagResetForm,
        update,
        setUpdate,
        ...accountTagFormikValues,
      }}
    />
  );
};
