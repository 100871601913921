/* eslint-disable dot-notation */
/** @format */

import { ENUM_AUTHORIZATION_DECLINE_REASON } from "@prisma/client";
import { AuthorizationType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { dollarFormatter } from "@roadflex/web-lib";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import ModalLoader from "../../loader/modal-loader";

type ExportDeclinedTransactionsModalProps = {
  open: boolean;
  onCloseExportModal: () => void;
  authorizations: AuthorizationType[];
  authorizationLoading: boolean;
};

export const ExportDeclinedTransactionsModal = ({
  open,
  onCloseExportModal,
  authorizations,
  authorizationLoading,
}: ExportDeclinedTransactionsModalProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);

  const csvLinkRef1 = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const onExportCSV = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
      setShowComplete(true);
      csvLinkRef1?.current?.link.click();
    }, 2000);
  };

  const [exportTransactionList, setExportTransactionList] = useState<
    {
      authorizationDate: string;
      timezone: string;
      vehicleName: string;
      driverName: string;
      vin: string;
      location: string;
      lastFour: string;
      merchantName: string;
      amount: string;
      status: string;
      declineReason: ENUM_AUTHORIZATION_DECLINE_REASON | null;
    }[]
  >([]);

  useEffect(() => {
    const transactions = authorizations.map((transaction) => {
      return {
        authorizationDate: convertISOStringToDate(
          transaction?.authorizationDate?.toString(),
          false,
          false,
          true,
        ),
        timezone: new Date().toTimeString().slice(9),
        driverName: transaction.driverName ? transaction.driverName : "",
        vehicleName: transaction.vehicleName ? transaction.vehicleName : "",
        vin: transaction.vehicleVIN ? transaction.vehicleVIN : "",
        location: `${
          transaction?.stripeResponse?.merchant_data?.city
            ? `${transaction?.stripeResponse?.merchant_data?.city},`
            : ""
        } ${transaction?.merchantState ? transaction.merchantState : ""} ${
          transaction?.merchantPostCd ? transaction.merchantPostCd : ""
        } `,
        lastFour: `${transaction.card.lastFour}`,
        merchantName: transaction.merchantName,
        amount: dollarFormatter(transaction.authorizationAmount / 100),
        status: "Declined",
        declineReason: transaction?.declineReason,
      };
    });
    setExportTransactionList(transactions);
  }, [authorizations, authorizations.length]);

  const transactionHeader = [
    { label: "Date", key: "authorizationDate" },
    { label: "Timezone Reported In", key: "timezone" },
    { label: "Driver Name", key: "driverName" },
    { label: "Vehicle Name", key: "vehicleName" },
    { label: "VIN", key: "vin" },
    { label: "Location", key: "location" },
    { label: "Card Last Four", key: "lastFour" },
    { label: "Merchant", key: "merchantName" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "status" },
    { label: "Decline Reason", key: "declineReason" },
  ];

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.Normal}
        onClick={() => {
          onCloseExportModal();
        }}
      >
        Close
      </Button>
      <CSVLink
        filename="transaction-declined.csv"
        ref={csvLinkRef1}
        data={exportTransactionList}
        headers={transactionHeader}
        target="_blank"
      ></CSVLink>
      {!showLoading && !showComplete && (
        <Button
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          loading={authorizationLoading}
          onClick={() => onExportCSV()}
        >
          Export Declined Transactions
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={
        <span className="text-base md:text-lg">
          Export Declined Transactions
        </span>
      }
      visible={open}
      style={{ fontFamily: "inherit" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={onCloseExportModal}
    >
      <div className="space-y-3 text-sm md:text-base">
        {showLoading || showComplete ? (
          <ModalLoader showLoading={showLoading} showComplete={showComplete} />
        ) : (
          <div></div>
        )}
      </div>
    </Dialog>
  );
};
