import { CreditCardIcon } from "@heroicons/react/outline";
import { CustomerPaymentMethods } from "@roadflex/types";
import { capitalizeFirstLetter } from "@roadflex/utils";
import classNames from "classnames";
import React, { useEffect } from "react";
import { StripeAddNewCardButton } from "./StripeAddNewCardButton";

interface StripePaymentMethodsUIAltProps {
  customerPaymentMethods: CustomerPaymentMethods[];
  customerPaymentMethodsLoading: boolean;
  selectedPaymentMethod: string;
  setSelectedPaymentMethod: (value: string) => void;
}
export const StripePaymentMethodsUIAlt = (
  props: StripePaymentMethodsUIAltProps,
) => {
  const {
    customerPaymentMethods,
    customerPaymentMethodsLoading,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
  } = props;

  useEffect(() => {
    setSelectedPaymentMethod(
      customerPaymentMethods.length > 0
        ? customerPaymentMethods.find((paymentMethod) => paymentMethod.default)
            ?.paymentMethodId || ""
        : "",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex-grow-1">
      <div className="flex flex-col justify-between w-full">
        <div className="mb-2 text-sm">Select Source Account</div>
        {customerPaymentMethodsLoading && (
          <div className="w-full mb-2 ">Updating...</div>
        )}
        <div className="flex flex-col w-full gap-2 mb-2">
          {customerPaymentMethods.length > 0 &&
            customerPaymentMethods.map(
              (card: CustomerPaymentMethods, idx: number) => {
                return (
                  <div className="flex flex-col" key={idx}>
                    <div className="flex flex-row items-center">
                      <input
                        onChange={(e) => {
                          setSelectedPaymentMethod(card.paymentMethodId);
                        }}
                        className="w-4 h-4 mr-2 cursor-pointer text-scarpa-flow-500 focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
                        type="radio"
                        checked={selectedPaymentMethod === card.paymentMethodId}
                      />
                      <div className={classNames("text-sm mr-3 bg-white")}>
                        {capitalizeFirstLetter(card.brand)}
                        {"  "}
                        <span className="font-bold text-gray-900">
                          &#xB7;&#xB7;&#xB7;&#xB7; {card?.last4}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
        </div>
        {customerPaymentMethods.length === 0 && !customerPaymentMethodsLoading && (
          <div className="h-[125px] flex justify-center items-center flex-col">
            <div>
              <CreditCardIcon className="w-20 h-12"></CreditCardIcon>
            </div>
            <div className="max-w-[200px] text-center md:text-xl">
              No cards connected
            </div>
          </div>
        )}
        <div className="flex flex-col mb-2">
          <StripeAddNewCardButton
            buttonName="Add a debit card"
            disabled={customerPaymentMethods.length === 5}
          ></StripeAddNewCardButton>
        </div>
      </div>
    </div>
  );
};
