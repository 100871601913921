/** @format */

import { MailIcon, MailOpenIcon } from "@heroicons/react/outline";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  AccountSettingDisplayType,
  AlertsValues,
  AlertType,
  ChildFeatureType,
  TabsType,
} from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { dateTemplate } from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { ConfirmModal, TooltipModal } from "../../modals";
import { NoAccessFeatureUI } from "../no-access";
import PersonalNotifications from "./notifications-tabs/personal-notifications";

type AlertsUIProps = FormikProps<AlertsValues> & {
  alertsList: AlertType[];
  alertsListFirstThree: AlertType[];
  loadingAlerts: boolean;
  accountDisplaySetting: AccountSettingDisplayType;
  archiveTemplate: (rowData: AlertType) => void;
  readTemplate: (rowData: AlertType) => void;
  typeTemplate: (rowData: AlertType) => void;
  message: string;
  setMessage: (value: string) => void;
  showMessage: boolean;
  setShowMessage: (value: boolean) => void;
  confirmModal: boolean;
  setConfirmModal: (value: boolean) => void;
  tabs: TabsType[];
  markMessageAsRead: (id: string) => void;
  tabOpen: number;
  setTabOpen: (value: number) => void;
  alertsLazyParams: DataTablePFSEvent;
  onPageAndSortAlerts: (value: DataTablePFSEvent) => void;
  deletedAlertsLazyParams: DataTablePFSEvent;
  onPageAndSortDeletedAlerts: (value: DataTablePFSEvent) => void;
  totalCount: number;
  customerType: ENUM_CUSTOMER_TYPE;
  childFeatures: ChildFeatureType[];
};

export const AlertsUI = ({
  alertsList,
  alertsListFirstThree,
  loadingAlerts,
  accountDisplaySetting,
  archiveTemplate,
  readTemplate,
  typeTemplate,
  message,
  setMessage,
  showMessage,
  setShowMessage,
  confirmModal,
  setConfirmModal,
  handleSubmit,
  isSubmitting,
  values,
  tabs,
  markMessageAsRead,
  tabOpen,
  setTabOpen,
  alertsLazyParams,
  onPageAndSortAlerts,
  deletedAlertsLazyParams,
  onPageAndSortDeletedAlerts,
  totalCount,
  customerType,
  childFeatures,
  ...rest
}: AlertsUIProps) => {
  const { isMobile } = usePlatformDetect();

  const access = {
    PersonalNotificationAccess:
      childFeatures.find(
        (item) =>
          item.name === "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING",
      ) !== undefined,
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Notifications`.toUpperCase()}
      </div>
      <div className="mt-5 md:mt-0">
        {alertsListFirstThree.length > 0 && (
          <div className="mb-8 overflow-hidden">
            {alertsListFirstThree?.slice(0, 3)?.map((alert) => (
              <div className="p-4 mb-4 bg-white rounded-md" key={alert.id}>
                <div className="flex">
                  <div className="flex-shrink-0">
                    {alert?.read ? (
                      <span>
                        <MailOpenIcon
                          className="w-5 h-5 text-brown-500"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <MailIcon
                        className="w-5 h-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="w-full ml-3">
                    <h3 className=""></h3>
                    <div className="text-sm">
                      <p>{alert.message} (Recent unread alerts)</p>
                    </div>
                    <div className="mt-2 text-sm italic">
                      <p>
                        {convertISOStringToDate(alert.createdAt.toString())}
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <Button
                        variant={ButtonVariant.Transparent}
                        size={ButtonSize.AppSize}
                        className="!py-0"
                        onClick={() => {
                          markMessageAsRead(alert?.id);
                        }}
                      >
                        Dismiss
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="overflow-hidden">
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
            <div className="flex flex-col w-full p-8 mt-4 mr-4 bg-white rounded-md shadow">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={alertsList}
                loading={loadingAlerts}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "inherit",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                lazy
                rows={alertsLazyParams.rows}
                filters={alertsLazyParams.filters}
                sortField={alertsLazyParams.sortField}
                sortOrder={alertsLazyParams.sortOrder}
                sortMode={"single"}
                first={alertsLazyParams.first}
                totalRecords={totalCount}
                onPage={onPageAndSortAlerts}
                onSort={onPageAndSortAlerts}
              >
                <Column
                  sortable
                  field="createdAt"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  header={`Date (${accountDisplaySetting?.timeZone})`}
                  body={(r) =>
                    dateTemplate(r.createdAt, accountDisplaySetting?.timeZone)
                  }
                />

                <Column
                  sortable
                  field="type"
                  header="Notification Type"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={typeTemplate}
                />
                <Column
                  sortable
                  field="read"
                  header="View Message"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={readTemplate}
                />
                <Column
                  field="Archive"
                  header="Archive Message"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={archiveTemplate}
                />
              </DataTable>
            </div>
          </div>
          <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-6`}>
            <div className="flex flex-col w-full p-8 mt-4 mr-4 bg-white rounded-md shadow">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={alertsList}
                loading={loadingAlerts}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "inherit",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                rows={deletedAlertsLazyParams.rows}
                filters={deletedAlertsLazyParams.filters}
                sortField={deletedAlertsLazyParams.sortField}
                sortOrder={deletedAlertsLazyParams.sortOrder}
                sortMode={"single"}
                first={deletedAlertsLazyParams.first}
                totalRecords={totalCount}
                onPage={onPageAndSortDeletedAlerts}
                onSort={onPageAndSortDeletedAlerts}
              >
                <Column
                  sortable
                  field="createdAt"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  header={`Date (${accountDisplaySetting?.timeZone})`}
                  body={(r) =>
                    dateTemplate(r.createdAt, accountDisplaySetting?.timeZone)
                  }
                />

                <Column
                  sortable
                  field="type"
                  header="Notification Type"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={typeTemplate}
                />
                <Column
                  sortable
                  field="read"
                  header="View Message"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={readTemplate}
                />
              </DataTable>
            </div>
          </div>

          <div className={`${tabOpen === 3 ? `` : `hidden`} mt-6 #3`}>
            {access.PersonalNotificationAccess && (
              <div>
                <PersonalNotifications
                  customerType={customerType}
                  childFeatures={childFeatures}
                />
              </div>
            )}
            {!access.PersonalNotificationAccess && <NoAccessFeatureUI />}
          </div>

          {showMessage && (
            <TooltipModal
              title={"Message Content"}
              body={<div>{message}</div>}
              setShow={setShowMessage}
              show={showMessage}
              footer={<div></div>}
            ></TooltipModal>
          )}

          {confirmModal && (
            <ConfirmModal
              title={"Archive Message"}
              body={`Confirm to proceed.`}
              setShow={setConfirmModal}
              submitting={isSubmitting}
              confirm={() => {
                handleSubmit();
                setConfirmModal(false);
              }}
              show={confirmModal}
            ></ConfirmModal>
          )}
        </div>
      </div>
    </div>
  );
};
