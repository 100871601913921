import { useUpdateAccountDisplaySetting } from "@roadflex/react-hooks";
import { AccountSettingDisplayType } from "@roadflex/types";
import { toast } from "react-toastify";
import DisplaySettingsUI from "./displaySettings.ui";

interface DisplaySettingsProps {
  readAccountDisplaySetting: AccountSettingDisplayType;
}

const DisplaySettings = ({
  readAccountDisplaySetting,
}: DisplaySettingsProps) => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    handleReset,
    setFieldValue,
    ...rest
  } = useUpdateAccountDisplaySetting({
    initialValues: {
      colorScheme: readAccountDisplaySetting?.colorScheme,
      timeZone: readAccountDisplaySetting?.timeZone,
      showCompanyName: readAccountDisplaySetting?.showCompanyName,
    },
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      toast.success(res?.message);
    },
  });
  return (
    <DisplaySettingsUI
      {...{
        values,
        readAccountDisplaySetting,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        ...rest,
      }}
    />
  );
};
export default DisplaySettings;
