/* eslint-disable no-console */

import { CogIcon } from "@heroicons/react/outline";
import { PersonalInformationValues } from "@roadflex/types";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_API_URL, PERSONA_ENVIRONMENT } = publicRuntimeConfig;

type PersonaVerificationProps = FormikProps<PersonalInformationValues>;

export default function PersonaVerificationByAdmin({
  handleBlur,
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  ...rest
}: PersonaVerificationProps) {
  const [client, setClient] = useState<null | { open: () => void }>(null);
  const [loading, setLoading] = useState<boolean>(true);

  async function downloadDocument(
    fileName: string,
    fileId: string,
    fieldValue: string,
  ): Promise<boolean> {
    const data = {
      fileName,
      fileId,
    };

    const fileDownloadResponse = await fetch(
      `${NEXT_PUBLIC_API_URL}/api/getPersonaDocument`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then(async (response) => {
        await response
          .clone()
          .blob()
          .then((res) => {
            const file = new File([res], fileName);
            setFieldValue(fieldValue, file ? file : "");
          });
        await response.text();
        return true;
        // .then((a) => console.log("downloadDocument ", a.length));
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again.");
        console.log("error", error);
        setLoading(false);
        return false;
      });
    return fileDownloadResponse;
  }

  async function downloadInquiry(inquiryId: string) {
    setLoading(true);
    const data = {
      inquiryId,
    };

    await fetch(`${NEXT_PUBLIC_API_URL}/api/getPersonaInquiry`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        let isSubmit = true;
        let licenseFrontDownloadSuccess = false;
        let licenseBackDownloadSuccess = false;
        // eslint-disable-next-line no-unused-expressions
        await response.json().then(async (inquiryResponse) => {
          let licenseFrontUrl =
            inquiryResponse?.included[0].attributes?.frontPhotoUrl;
          if (!licenseFrontUrl) {
            licenseFrontUrl =
              inquiryResponse?.included[0].attributes["front-photo-url"];
          }
          if (licenseFrontUrl) {
            const licenseFrontSplitUrl = licenseFrontUrl.split("/");
            const licenseFrontFileName = licenseFrontSplitUrl.pop();
            const licenseFrontFileId = licenseFrontSplitUrl.pop();
            licenseFrontDownloadSuccess = await downloadDocument(
              licenseFrontFileName,
              licenseFrontFileId,
              "fleetLicense",
            );
          } else {
            isSubmit = false;
            toast.error("No front license photo found");
          }

          let licenseBackUrl =
            inquiryResponse?.included[0].attributes?.backPhotoUrl;
          if (!licenseBackUrl) {
            licenseBackUrl =
              inquiryResponse?.included[0].attributes["back-photo-url"];
          }
          if (licenseBackUrl) {
            const licenseBackSplitUrl = licenseBackUrl.split("/");
            const licenseBackFileName = licenseBackSplitUrl.pop();
            const licenseBackFileId = licenseBackSplitUrl.pop();
            licenseBackDownloadSuccess = await downloadDocument(
              licenseBackFileName,
              licenseBackFileId,
              "fleetLicenseBack",
            );
          } else {
            isSubmit = false;
            toast.error("No back license photo found");
          }
        });
        if (
          isSubmit &&
          licenseFrontDownloadSuccess &&
          licenseBackDownloadSuccess
        ) {
          handleSubmit();
        } else {
          toast.error(
            "Failed to fetch the license due to an error, please try verifying the license again.",
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Something went wrong. Please try again.");
        setLoading(false);
      });
  }

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    const Persona = require("persona");
    const clientInit = new Persona.Client({
      templateId: "itmpl_uVMZsa47AjP3BLvTsKYVJJ6h", // Govt ID
      // templateId: "itmpl_yzTYVhCRmxEvZzH9UEU2zerU", // Govt ID + Selfie
      environment: PERSONA_ENVIRONMENT,
      onReady: () => {
        // eslint-disable-next-line no-console
        console.log("ready");
        setLoading(false);
      },
      onComplete: ({
        inquiryId,
        status,
        fields,
      }: {
        inquiryId: string;
        status: string;
        fields: unknown;
      }) => {
        // eslint-disable-next-line no-console
        // console.log("onComplete", inquiryId, status, fields);
        setFieldValue("personaInquiryId", inquiryId);
        setFieldValue("personaFields", fields);
        downloadInquiry(inquiryId);
      },
      onCancel: ({
        inquiryId,
        sessionToken,
      }: {
        inquiryId: string;
        sessionToken: string;
      }) => {
        // eslint-disable-next-line no-console
        // console.log("onCancel", inquiryId, sessionToken);
      },
      onError: (error: unknown) => {
        // eslint-disable-next-line no-console
        console.log(error);
        toast.error("Something went wrong. Please try again.");
      },
    });
    setClient(clientInit);
  }, []);

  return (
    <button
      onClick={() => {
        client?.open();
      }}
      onBlur={handleBlur}
      disabled={loading || isSubmitting}
      type="button"
      className="inline-flex justify-center w-full px-4 py-2 mt-6 text-sm font-medium text-white rounded-md bg-scarpa-flow-100 hover:bg-scarpa-flow-200 focus:ring-2 focus:ring-scarpa-flow-100 sm:ml-3 sm:w-auto sm:text-sm focus:ring-opacity-50 disabled:opacity-75 disabled:transform-none disabled:transition-none disabled:cursor-not-allowed"
    >
      {loading || isSubmitting ? (
        <span className="inline-flex items-center">
          <CogIcon
            className="w-6 h-6 mr-2 -ml-1 animate-spin"
            aria-hidden="true"
          />
          {"Loading..."}
        </span>
      ) : (
        <span>{"Verify Identity and Create Onboarding Request"}</span>
      )}
    </button>
  );
}

export const PersonaAdmin = memo(PersonaVerificationByAdmin);
