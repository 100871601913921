import { ChevronRightIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import React, { useCallback } from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
interface PlaidPendingConnectionProps {
  linkToken: string;
  institution: string;
  plaidId: string;
  handleSubmit: (data: {
    isUpdateMode?: boolean;
    plaidId?: string;
    publicToken: string;
    institution: string;
    businessId?: string;
    pendingConnection?: boolean;
  }) => void;
  onDeletePlaidPendingConnection: (plaidId: string) => void;
}
const PlaidPendingConnection = ({
  linkToken,
  institution,
  plaidId,
  handleSubmit,
  onDeletePlaidPendingConnection,
}: PlaidPendingConnectionProps) => {
  const onSuccess = useCallback<PlaidLinkOnSuccess>((token, metadata) => {
    const data = {
      institution: metadata?.institution?.name || "",
      publicToken: token || "",
      pendingConnection:
        metadata?.accounts[0]?.verification_status ===
        "pending_manual_verification"
          ? true
          : false,
    };
    handleSubmit(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const config: PlaidLinkOptions = {
    token: linkToken || "",
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <div
      onClick={() => {
        if (ready) {
          open();
        }
      }}
      className={classNames(
        "bg-white border-brown-500",
        "p-3 flex-grow min-h-[95px] sm:min-h-[125px] text-brown-500 w-full sm:min-w-[220px] sm:max-w-[250px] border rounded-md shadow flex flex-col justify-between hover:shadow-lg hover:cursor-pointer",
      )}
    >
      <div className="flex flex-row items-center justify-between w-full">
        <div className={classNames("text-sm mr-3")}>Bank Connection</div>
        <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>
      </div>

      <div className="flex items-center justify-between w-full">
        <div className="mr-3 text-sm font-semibold text-gray-900">
          {institution || "Not available"}{" "}
        </div>
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.Small}
          onClick={(e) => {
            e.stopPropagation();
            onDeletePlaidPendingConnection(plaidId);
          }}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export default PlaidPendingConnection;
