/** @format */

import { LockClosedIcon } from "@heroicons/react/outline";
import { ENUM_ADDRESS_TYPE } from "@prisma/client";
import { dollarFormatter } from "@roadflex/constants";
import {
  AdditionalBusinessInfoType,
  AddressType,
  BusinessType,
  UserOnboardType,
} from "@roadflex/types";
import { Button, ButtonVariant } from "../buttons";

type Props = {
  business: BusinessType;
  addresses: AddressType[];
  additionalBusinessInfo: AdditionalBusinessInfoType;
  onJumpToStep: (step: number) => void;
  onConfirm: () => void;
  confirming: boolean;
  showRegistrationConfirmationModal: boolean;
  setShowRegistrationConfirmationModal: (
    showRegistrationConfirmationModal: boolean,
  ) => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: () => void;
  isSubmittingOnboardStatus: boolean;
  isSameAddress: boolean;
};

const ReviewUI = ({
  business,
  addresses,
  additionalBusinessInfo,
  onJumpToStep,
  onConfirm,
  confirming,
  showRegistrationConfirmationModal,
  setShowRegistrationConfirmationModal,
  readCurrentUserOnboard,
  nextStep,
  isSubmittingOnboardStatus,
  isSameAddress,
}: Props) => {
  const businessAddress = addresses.find(
    (value) => value.type === ENUM_ADDRESS_TYPE.PERMANENT,
  );
  const deliveryAddress = addresses.find(
    (value) => value.type === ENUM_ADDRESS_TYPE.DELIVERY,
  );
  // const homeAddress = addresses.find(
  //   (value) => value.type === ENUM_ADDRESS_TYPE.HOME,
  // );
  return (
    <div className="">
      <div className="mb-4 text-xl font-semibold text-center text-white md:mb-8 md:text-4xl">
        Review Your Application
      </div>
      <div className="w-full space-y-2">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="text-white">Business</div>
            <Button
              variant={ButtonVariant.Transparent}
              className="underline-offset-2 underline text-white !px-0"
              onClick={() => {
                onJumpToStep(2);
              }}
            >
              Edit
            </Button>
          </div>
          <div className="w-full p-4 text-sm bg-white rounded-md ">
            <span className=""> Name:</span>{" "}
            <span className="capitalize">{business.title}</span> <br />
            <span className=""> Business Type:</span> {business.type} <br />
            {business.companyType && (
              <>
                <span className=""> Company Type:</span> {business.companyType}{" "}
                <br />
              </>
            )}
            <span className=""> Name on Cards:</span> {business.cardName} <br />
            <span className=""> Years of Operation:</span>{" "}
            {business.yearsOfOperation} <br />
            <span className=""> EIN:</span> {business.ein} <br />
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="text-white">Additional Business Information</div>
            <Button
              variant={ButtonVariant.Transparent}
              className="underline-offset-2 underline text-white !px-0"
              onClick={() => {
                onJumpToStep(3);
              }}
            >
              Edit
            </Button>
          </div>
          <div className="w-full p-4 text-sm bg-white rounded-md ">
            <span className=""> Fuel and maintenance spend per week:</span>{" "}
            {dollarFormatter(
              (additionalBusinessInfo?.weeklyfuelSpends as number) / 100,
            )}
            <br />
            <span className="">Annual Business Revenue:</span>{" "}
            {dollarFormatter(
              (additionalBusinessInfo?.annualBusinessRevenue as number) / 100,
            )}{" "}
            <br />
            <span className="">Number of Vehicles:</span>{" "}
            {additionalBusinessInfo?.fleetNum} <br />
            {business.mc && (
              <>
                <span className="">MC Number</span>: {business.mc} <br />
              </>
            )}
            {business.dot && (
              <>
                <span className="">DOT Number</span>: {business.dot} <br />
              </>
            )}
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="text-white">Business Address</div>
            <Button
              variant={ButtonVariant.Transparent}
              className="underline-offset-2 underline text-white !px-0"
              onClick={() => {
                onJumpToStep(2);
              }}
            >
              Edit
            </Button>
          </div>
          <div className="w-full p-4 text-sm bg-white rounded-md ">
            {businessAddress?.streetAddress}, <br />
            {businessAddress?.streetDetails && (
              <div>{businessAddress?.streetDetails},</div>
            )}
            {businessAddress?.city}, {businessAddress?.state}, <br />
            {businessAddress?.zip}
          </div>
        </div>
        {!isSameAddress && (
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="text-white">Shipping Address</div>
              <Button
                variant={ButtonVariant.Transparent}
                className="underline-offset-2 underline text-white !px-0"
                onClick={() => {
                  onJumpToStep(4);
                }}
              >
                Edit
              </Button>
            </div>
            <div className="w-full p-4 text-sm bg-white rounded-md ">
              {deliveryAddress?.streetAddress}, <br />
              {deliveryAddress?.streetDetails && (
                <div>{deliveryAddress?.streetDetails},</div>
              )}
              {deliveryAddress?.city}, {deliveryAddress?.state}, <br />
              {deliveryAddress?.zip}
            </div>
          </div>
        )}
      </div>
      <div className="mt-8">
        <Button
          variant={ButtonVariant.Orange}
          onClick={() => {
            nextStep();
          }}
          className="w-full"
          disabled={isSubmittingOnboardStatus}
          loading={isSubmittingOnboardStatus}
        >
          <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
          Next
        </Button>
      </div>
    </div>
  );
};

export default ReviewUI;
