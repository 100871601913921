import { CogIcon } from "@heroicons/react/outline";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MapType } from "@roadflex/types";
import getConfig from "next/config";
import { useCallback, useEffect, useState } from "react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

const containerStyle = {
  height: "20vh",
  width: "100%",
};

export function SinglePointLocationMap({ mapValues }: { mapValues: MapType }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
  });

  const [map, setMap] = useState<null>(null);
  const [position, setPosition] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 0, lng: 0 });
  const [showLiveLocationInfo, setShowLiveLocationInfo] =
    useState<boolean>(true);

  const onLoad = useCallback((mapObj) => {
    const location = {
      lat: Number(mapValues.latitude),
      lng: Number(mapValues.longitude),
    };
    setPosition(location);
    setMap(map);
  }, []);

  const onUnmount = useCallback((mapObj) => {
    setMap(null);
  }, []);

  useEffect(() => {
    const location = {
      lat: Number(mapValues.latitude),
      lng: Number(mapValues.longitude),
    };
    setPosition(location);
  }, [mapValues]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={position}
        title={"Current location"}
        // icon={{
        //   url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        // }}
        onClick={() => {
          setShowLiveLocationInfo(true);
        }}
        key={"currentLocationKey"}
      >
        {showLiveLocationInfo && (
          <InfoWindow
            onCloseClick={() => {
              setShowLiveLocationInfo(false);
            }}
          >
            <div>
              <div>
                {/* <b>Device Name: </b> */}
                {mapValues?.message}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  ) : (
    <div className="flex justify-center h-full align-middle">
      <span className="inline-flex items-center">
        <CogIcon
          className="w-6 h-6 mr-2 -ml-1 animate-spin"
          aria-hidden="true"
        />
        {"Loading Map..."}
      </span>
    </div>
  );
}
