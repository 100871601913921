/** @format */

import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  AccountSettingDisplayType,
  UserActivityLogType,
} from "@roadflex/types";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { dateTemplate } from "../../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";

// const activityTypeTemplate = (rowData: UserActivityLogType) => {
//   return (
//     <span className="">
//       {rowData.activityType
//         ? rowData.activityType.replace(/_/g, " ")[0] +
//           rowData.activityType.replace(/_/g, " ").slice(1).toLowerCase()
//         : "-"}
//     </span>
//   );
// };

interface ActivityLogsUIProp {
  userActivityLogsData: UserActivityLogType[];
  userActivityLogsLoading: boolean;
  readAccountDisplaySetting: AccountSettingDisplayType;
}

const ActivityLogsUI = (props: ActivityLogsUIProp) => {
  const { userActivityLogsData, userActivityLogsLoading } = props;
  const { isMobile } = usePlatformDetect();
  return (
    <div className="flex flex-col w-full p-4 mr-4 bg-white rounded-md shadow md:p-8">
      <div className="flex flex-col h-full">
        <DataTable
          size="small"
          value={userActivityLogsData}
          loading={userActivityLogsLoading}
          paginator
          rows={10}
          dataKey="id"
          sortField="createdAt"
          sortOrder={-1}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rowsPerPageOptions={[10, 20, 50, 100]}
          responsiveLayout={isMobile() ? "scroll" : "scroll"}
          emptyMessage="No available records"
          style={{ fontFamily: "inherit" }}
          totalRecords={userActivityLogsData.length}
          paginatorTemplate={newPaginationTemplate}
          className="w-auto min-w-full custom-table"
          pageLinkSize={isMobile() ? 2 : 3}
        >
          {!isMobile() && (
            <Column
              dataType="date"
              field="createdAt"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              header={`Date (${props?.readAccountDisplaySetting?.timeZone})`}
              body={(r) =>
                dateTemplate(
                  r.createdAt,
                  props?.readAccountDisplaySetting?.timeZone,
                )
              }
            />
          )}
          {!isMobile() && (
            <Column
              field="email"
              header="Account Email"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
          )}
          <Column
            field="fullName"
            header="Account Name"
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
            body={(r) => `${r.firstName} ${r.lastName}`}
          />
          <Column
            field="activity"
            header="Activity"
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
          />
          {/* <Column
            field="activityType"
            header="Activity Type"
            className="text-sm 5xl:text-base"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
            body={activityTypeTemplate}
          /> */}
        </DataTable>
      </div>
    </div>
  );
};
export default ActivityLogsUI;
