/** @format */
import { useQuery } from "@apollo/client";
import { GET_DOCUMENTS } from "@roadflex/graphql";

interface UseDocumentsProps {
  businessId: string;
}

export const useDocuments = ({ businessId }: UseDocumentsProps) => {
  const { data, refetch, ...rest } = useQuery(GET_DOCUMENTS, {
    variables: {
      where: {
        businessId,
      },
    },
    fetchPolicy: "no-cache",
  });
  const documents = data?.getDocuments;
  return { documents, refetch, ...rest };
};
