/** @format */

import { useReadAutoReload } from "@roadflex/react-hooks";
import { Loader } from "../../../loader";
import { AutoReloadUI } from "./auto-reload.ui";

export function AutoReload() {
  const { autoReloadDetails, autoReloadDetailsLoading } = useReadAutoReload();
  if (autoReloadDetailsLoading) {
    return <Loader />;
  }
  return (
    <AutoReloadUI
      autoReloadDetails={autoReloadDetails?.getAutoReloadDetails}
    ></AutoReloadUI>
  );
}
