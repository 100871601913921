/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { appUpdateCardPinSchema } from "@roadflex/constants";
import { READ_APP_CARD_DATA, UPDATE_CARD_PIN } from "@roadflex/graphql";
import { useFormik } from "formik";

type useUpdateCardPinProps = {
  initialValues: {
    cardId: string;
    pin: string;
  };
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useReadAppCardData = (
  returnPhysical: boolean,
  returnDeliveredOnly: boolean,
  returnVirtual: boolean,
  mode: string,
  cardId?: string,
) => {
  const { data, ...rest } = useQuery(READ_APP_CARD_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        returnPhysical,
        returnDeliveredOnly,
        returnVirtual,
        mode,
        cardId,
      },
    },
  });
  const { readAppCardData = null }: { readAppCardData: any | null } =
    data || {};

  return { readAppCardData, ...rest };
};

export const useUpdateCardPin = ({
  onSubmit,
  initialValues,
}: useUpdateCardPinProps) => {
  const [updateCardPinFn] = useMutation<{
    updateCardPin: {
      code: string;
      message: string;
    };
  }>(UPDATE_CARD_PIN, {});

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appUpdateCardPinSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateCardPinFn({
          variables: {
            data: {
              cardId: value.cardId,
              pin: value.pin.toString(),
            },
          },
        });
        if (res?.data?.updateCardPin) {
          onSubmit(res.data.updateCardPin, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
