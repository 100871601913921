/** @format */

import { CheckCircleIcon } from "@heroicons/react/solid";
import {
  AddressType,
  BusinessType,
  TermsOfServiceValues,
  UserOnboardType,
} from "@roadflex/types";
import { FormikProps } from "formik";
import { Button, ButtonVariant } from "../buttons";
import { RegistrationConfirmationModal } from "../modals";

interface Props extends FormikProps<TermsOfServiceValues> {
  onJumpToStep: (step: number) => void;
  onConfirm: () => void;
  confirming: boolean;
  showRegistrationConfirmationModal: boolean;
  setShowRegistrationConfirmationModal: (
    showRegistrationConfirmationModal: boolean,
  ) => void;
  readCurrentUserOnboard: UserOnboardType;
  business: BusinessType;
  addresses: AddressType[];
  isSubmittingApplication: boolean;
}

const TermsOfServiceUI = ({
  onJumpToStep,
  onConfirm,
  confirming,
  showRegistrationConfirmationModal,
  setShowRegistrationConfirmationModal,
  readCurrentUserOnboard,
  business,
  addresses,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  isSubmittingApplication,
  ...rest
}: Props) => {
  return (
    <div className="">
      <div className="mb-4 text-xl font-semibold text-center text-white md:mb-8 md:text-4xl">
        Terms of Service
      </div>
      <form className="space-y-6 ">
        <div className="flex justify-between" role="group">
          <div className="mr-3 text-white max-w-[600px]">
            <label htmlFor="ownsMoreThan25Percent" className="">
              I own more than 25% of the company
            </label>
            <br />
            <label className="text-xs">
              (To prevent funding of terrorism and money laundering activities,
              Federal law requires financial institutions to obtain and verify
              such information about each business that opens an account.)
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="ownsMoreThan25Percent"
              name="ownsMoreThan25Percent"
              type="checkbox"
              disabled={false}
              checked={values.ownsMoreThan25Percent}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
          </div>
        </div>
        <div className="flex justify-between" role="group">
          <div className="mr-3 text-white max-w-[600px]">
            <label htmlFor="significantManagementResponsibility" className="">
              I have significant management responsbility
            </label>
            <br />
            <label className="">(President, CEO, CFO, VP, etc.)</label>
          </div>
          <div className="flex items-center">
            <input
              id="significantManagementResponsibility"
              name="significantManagementResponsibility"
              type="checkbox"
              disabled={false}
              checked={values.significantManagementResponsibility}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
          </div>
        </div>
        <div className="flex justify-between" role="group">
          <label
            htmlFor="authorizedRepresentative"
            className="mr-3 flex text-white max-w-[600px] relative"
          >
            <span className="absolute text-red-500 left-[-15px]">*</span>I am an
            authorized representative with authority to apply on behalf of the
            business and that the information provided is accurate.
          </label>
          <div className="flex items-center">
            <input
              id="authorizedRepresentative"
              name="authorizedRepresentative"
              type="checkbox"
              disabled={false}
              checked={values.authorizedRepresentative}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
          </div>
        </div>
        <div className="flex justify-between" role="group">
          <label
            htmlFor="agreeToRoadFlexTerms"
            className="mr-3 text-white max-w-[600px] relative "
          >
            <span className="absolute text-red-500 left-[-15px]">*</span>I agree
            to the{" "}
            <span
              className="underline hover:cursor-pointer"
              onClick={() => {
                setShowRegistrationConfirmationModal(true);
              }}
            >
              RoadFlex Card Program Terms
            </span>
          </label>
          <div className="flex items-center">
            <input
              id="agreeToRoadFlexTerms"
              name="agreeToRoadFlexTerms"
              type="checkbox"
              disabled={false}
              checked={values.agreeToRoadFlexTerms}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
          </div>
        </div>
      </form>
      <div className="flex items-center justify-center px-4 py-1 my-16 text-sm text-center mx-auto max-w-[500px] w-full self-center bg-green-100 rounded-full">
        {" "}
        <CheckCircleIcon className="w-4 h-4 mr-1 text-green-900"></CheckCircleIcon>
        Applying won't affect your personal credit score
      </div>
      <div className="text-white">
        <div>Questions?</div>
        <div>
          Contact our support team at RoadFlex at phone (877) 870-7440 or email
          support@roadflex.com
        </div>
      </div>
      <div className="mt-6">
        <Button
          variant={ButtonVariant.Orange}
          onClick={() => handleSubmit()}
          className="w-full"
          disabled={Object.keys(errors).length > 0 || isSubmittingApplication}
          loading={isSubmittingApplication}
        >
          Submit Application
        </Button>
      </div>

      <RegistrationConfirmationModal
        confirm={() => {
          setFieldValue("agreeToRoadFlexTerms", true);
          setShowRegistrationConfirmationModal(false);
        }}
        submitting={confirming}
        showRegistrationConfirmationModal={showRegistrationConfirmationModal}
        setShowRegistrationConfirmationModal={
          setShowRegistrationConfirmationModal
        }
        business={business}
        addresses={addresses}
        readCurrentUserOnboard={readCurrentUserOnboard}
      />
    </div>
  );
};

export default TermsOfServiceUI;
