/** @format */

import { useUpdateFleetInformation } from "@roadflex/react-hooks";
import { UserDetailsType } from "@roadflex/types";
import { toast } from "react-toastify";
import UpdateFleetInfoUI from "./updateFleetInfo.ui";

type UpdateFleetProps = {
  readUserDetailsData: UserDetailsType;
};

const UpdateFleet = ({ readUserDetailsData }: UpdateFleetProps) => {
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useUpdateFleetInformation({
    initialValues: {
      businessId: readUserDetailsData?.businessDetails?.id || "",
      fleetType: readUserDetailsData?.fleet?.fleetType || "",
    },
    onSubmit(res, error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res.message);
      }
    },
  });

  return (
    <UpdateFleetInfoUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        ...rest,
      }}
    />
  );
};

export default UpdateFleet;
