/** @format */

import { ENUM_FLEET_DRIVER_STATUS } from "@prisma/client";
import {
  MAX_LIMIT_ENTERPRISE,
  MAX_LIMIT_NON_ENTERPRISE,
} from "@roadflex/constants";
import { useDeleteDrivers, useReadDrivers } from "@roadflex/react-hooks";
import {
  ChildFeatureType,
  FleetDriverExtended,
  UserAuthType,
} from "@roadflex/types";
import { phoneFormatter } from "@roadflex/web-lib";
import { useState } from "react";
import { Step } from "react-joyride";
import { toast } from "react-toastify";
import { Loader } from "../../loader";
import { DriversUI } from "./drivers.ui";

interface DriversProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  childFeatures: ChildFeatureType[];
}

export const Drivers = ({
  readCurrentUserAuth,
  loading,
  childFeatures,
}: DriversProps) => {
  const [tabOpen, setTabOpen] = useState(1);
  const tabs = [
    { id: 1, name: "Drivers", href: "", current: true },
    { id: 2, name: "Archived Drivers", href: "", current: false },
  ];

  const maxDrivers = readCurrentUserAuth?.fuelCard?.customerType?.includes(
    "ENTERPRISE",
  )
    ? MAX_LIMIT_ENTERPRISE
    : MAX_LIMIT_NON_ENTERPRISE;

  const { getDriversData, getDriversRefetch, getDriversLoading } =
    useReadDrivers([]);

  const {
    resetForm,
    deleteDriverHandleSubmit,
    deleteDriverSetValues,
    ...rest
  } = useDeleteDrivers({
    initialValues: {
      idList: [],
    },
    onSubmit(res, err) {
      resetForm();
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
    },
  });

  const steps: Step[] = [
    {
      title: "Welcome",
      content:
        "Welcome to the Drivers page. Once a driver is added, you can link a driver to a card.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      placement: "center",
      target: "body",
    },
    {
      content:
        "To start, you can add a driver by clicking here. RoadFlex offers a driver app for premium accounts. If you are a premium user, you can send an invitation to the driver to download the driver app.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-2",
    },
    {
      content:
        "To edit or delete a driver, click here. Each non-enterprise account can add up to 40 drivers. Enterprise accounts can add up to 400 drivers.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-3",
    },
    {
      target: ".step-4",
      title: "Help Center",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      content:
        'If you need help or would like to go through the navigation again, click "Help Center".',
    },
  ];

  const [joyride, setJoyride] = useState(false);

  if (loading) {
    return <Loader />;
  }

  const driversList = getDriversData?.readDrivers?.drivers || [];

  const activeDriversList: FleetDriverExtended[] = [];
  const archivedDriversList: FleetDriverExtended[] = [];

  driversList?.forEach((driver: FleetDriverExtended) => {
    const formattedPhoneNumber = phoneFormatter(driver.phoneNumber);
    const createdAt = new Date(driver.createdAt);
    const newDriverObj = {
      ...driver,
      formattedPhoneNumber,
      createdAt: createdAt,
    };
    if (newDriverObj?.status === ENUM_FLEET_DRIVER_STATUS.DELETED) {
      archivedDriversList.push(newDriverObj);
    } else {
      activeDriversList.push(newDriverObj);
    }
  });

  return (
    <DriversUI
      {...{
        driversList: activeDriversList,
        archivedDriversList,
        getDriversLoading,
        getDriversRefetch,
        deleteDriverHandleSubmit,
        deleteDriverSetValues,
        steps,
        joyride,
        setJoyride,
        tabs,
        tabOpen,
        setTabOpen,
        maxDrivers,
        childFeatures,
      }}
    />
  );
};
