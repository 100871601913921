/** @format */

import { useLazyQuery } from "@apollo/client";
import { CATEGORY_LABELS } from "@roadflex/constants";
import { TRANSACTION_LIST } from "@roadflex/graphql";
import { useReadDrivers, useReadVehicles } from "@roadflex/react-hooks";
import {
  DriverSpendData,
  FleetDriverExtended,
  FleetExtended,
  TransactionType,
  UserAuthType,
  VehicleSpendData,
} from "@roadflex/types";
import {
  appChartsGraphSorter,
  getTimeZoneOffset,
  humanize,
} from "@roadflex/utils";
import { dollarFormatter, showErrorMessage } from "@roadflex/web-lib";
import { addMilliseconds, endOfDay, startOfDay, subDays } from "date-fns";
import { CalendarChangeParams } from "primereact/calendar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AccountAnalysisUI from "./account-analysis.ui";

export const AccountAnalysis = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }
  const [dates, setDates] = useState<Date[]>([
    subDays(startOfDay(Date.now()), 6),
    endOfDay(Date.now()),
  ]);
  const { getVehiclesData, getVehiclesLoading } = useReadVehicles([]);
  const { getDriversData, getDriversLoading } = useReadDrivers([]);
  const vehiclesList: FleetExtended[] =
    getVehiclesData?.readVehicles?.vehicles || [];
  const driversList: FleetDriverExtended[] =
    getDriversData?.readDrivers?.drivers || [];
  const [transactionList, setTransactionList] = useState<TransactionType[]>([]);
  const [transactionListFn, { loading: transactionListLoading }] = useLazyQuery(
    TRANSACTION_LIST,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: {
        readTransactions: {
          code: string;
          transactions: TransactionType[];
          message: string;
        };
      }) => {
        if (data?.readTransactions?.code === "200") {
          setTransactionList(data?.readTransactions?.transactions || []);
        } else {
          showErrorMessage(
            data?.readTransactions?.message,
            data?.readTransactions?.code,
          );
          setTransactionList([]);
        }
      },
      onError: (error: Error) => {
        toast.error(`Settled Transaction: ${error.message}`);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    transactionListFn({
      variables: {
        data: {
          dateFilter: {
            startDate: addMilliseconds(
              dates[0],
              (-getTimeZoneOffset(
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              ) +
                getTimeZoneOffset(tz)) *
                60000,
            ),
            endDate: addMilliseconds(
              dates[1],
              (-getTimeZoneOffset(
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              ) +
                getTimeZoneOffset(tz)) *
                60000,
            ),
          },
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    if (dateArray.length === 2) {
      const [startDate1, endDate1] = dateArray;
      const oneMonthAfterStart = new Date(startDate1);
      oneMonthAfterStart.setMonth(oneMonthAfterStart.getMonth() + 1);
      if (endDate1 >= oneMonthAfterStart) {
        toast.error("Max Range: 1 month");
        return;
      }
    }
    setDates(dateArray);
    if (dateArray[0] && dateArray[1]) {
      transactionListFn({
        variables: {
          data: {
            dateFilter: {
              startDate: addMilliseconds(
                dateArray[0],
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
              endDate: addMilliseconds(
                dateArray[1],
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
            },
          },
        },
      });
    }
    //  if (dateArray[0] !== null && dateArray[1] !== null) {
    //    dateFilterApplied({
    //      field: "date",
    //      constraints: {
    //        operator: "and",
    //        constraints: [
    //          {
    //            value: new Date(dateArray[0]),
    //            matchMode: "dateAfter",
    //          },
    //          {
    //            value: new Date(dateArray[1]),
    //            matchMode: "dateBefore",
    //          },
    //        ],
    //      },
    //    });
    //  }
  };

  const transactionVolumeData: [number, number][] = [];
  const merchantAnalysisData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[] = [];

  const merchantCategoryBreakdownData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[] = [];

  const transactionDict: Record<string, number> = {};
  const merchantDict: Record<string, number> = {};
  const merchantCategoryDict: Record<string, number> = {};

  let totalTransactionAmount = 0;
  transactionList?.forEach((item) => {
    if (
      item.transactionStatus === "CAPTURE" &&
      item.merchantName !== "RoadFlex" &&
      new Date(item.authorizationDate) >= (dates as Date[])[0] &&
      new Date(item.authorizationDate) <= (dates as Date[])[1]
    ) {
      let category = item?.stripeResponse?.merchant_data?.category || "Others";
      category =
        (CATEGORY_LABELS[category] && CATEGORY_LABELS[category]?.label) ||
        humanize(category);
      const transAmount = item.transactionAmount;
      const authDate = `${
        new Date(item.authorizationDate).toISOString().split("T")[0]
      }T00:00:00.000Z`;
      if (authDate in transactionDict) {
        transactionDict[authDate] += transAmount;
      } else {
        transactionDict[authDate] = transAmount;
      }
      const merchant = item.merchantName;
      totalTransactionAmount += transAmount;
      if (merchant in merchantDict) {
        merchantDict[merchant] += transAmount;
      } else {
        merchantDict[merchant] = transAmount;
      }
      if (category in merchantCategoryDict) {
        merchantCategoryDict[category] += transAmount;
      } else {
        merchantCategoryDict[category] = transAmount;
      }
    }
  });
  for (const [key, value] of Object.entries(transactionDict)) {
    const date = new Date(key);
    transactionVolumeData.push([date.getTime(), value / 100]);
  }
  transactionVolumeData.sort(appChartsGraphSorter);
  const dailyTransactionVolumeData = [
    { name: "Daily Transaction Volume", data: transactionVolumeData },
  ];
  let otherPercentage = 0;
  for (const [key, value] of Object.entries(merchantDict)) {
    if (totalTransactionAmount !== 0) {
      const percentage =
        Math.round((10000 * value) / totalTransactionAmount) / 100;
      if (percentage > 10) {
        merchantAnalysisData.push({
          name: key,
          y: percentage,
          sliced: true,
          selected: true,
        });
      } else {
        otherPercentage += percentage;
      }
    }
  }
  merchantAnalysisData.push({
    name: "others",
    y: Math.round(otherPercentage * 100) / 100,
    sliced: true,
    selected: true,
  });
  for (const [key, value] of Object.entries(merchantCategoryDict)) {
    if (totalTransactionAmount !== 0) {
      const percentage =
        Math.round((10000 * value) / totalTransactionAmount) / 100;
      merchantCategoryBreakdownData.push({
        name: key,
        y: percentage,
        sliced: true,
        selected: true,
      });
    }
  }

  const driverSpendData = [];
  const driverSpendCategories = [];
  const vehicleSpendCategories = [];
  const vehicleSpendData = [];
  const driverSpendOverTimeData = [];
  const vehicleSpendOverTimeData = [];
  const allDriverSpendData: DriverSpendData[] = [];
  const allVehicleSpendData: VehicleSpendData[] = [];

  for (let i = 0; i < driversList.length || 0; i++) {
    driverSpendCategories.push(driversList[i].name);
    const driverArray: [number, number][] = [];
    const transactionsList = driversList[i].transaction;
    if (transactionsList) {
      let maxAmount = 0;
      for (let j = 0; j < transactionsList.length || 0; j++) {
        const transAmount = transactionsList[j].transactionAmount;
        const authDate = new Date(transactionsList[j].authorizationDate);
        if (
          dates &&
          authDate >= (dates as Date[])[0] &&
          authDate <= (dates as Date[])[1]
        ) {
          if (transAmount > maxAmount) {
            maxAmount = transAmount;
          }
          const city = driversList[i]?.transaction[j]?.stripeResponse
            ?.merchant_data?.city
            ? driversList[i]?.transaction[j]?.stripeResponse?.merchant_data
                ?.city
            : "";
          const state = driversList[i].transaction[j]?.merchantState
            ? driversList[i].transaction[j]?.merchantState
            : "-";
          const postCd = driversList[i].transaction[j]?.merchantPostCd
            ? driversList[i].transaction[j]?.merchantPostCd
            : "-";
          allDriverSpendData.push({
            driverName: driversList[i].name,
            totalSpends: dollarFormatter(transAmount / 100),
            fuelSpends:
              driversList[i]?.transaction[j]?.fuelQuantity > 0
                ? dollarFormatter(transAmount / 100)
                : "-",
            otherSpends:
              driversList[i]?.transaction[j]?.fuelQuantity <= 0
                ? dollarFormatter(transAmount / 100)
                : "-",
            location: `${city} ${state} ${postCd}`,
          });
          driverArray.push([authDate.getTime(), transAmount / 100]);
        }
      }
      driverSpendData.push(maxAmount / 100);
      driverArray.sort(appChartsGraphSorter);
    }
    driverSpendOverTimeData.push({
      name: driversList[i].name || "-",
      data: driverArray,
    });
  }

  for (let i = 0; i < vehiclesList.length || 0; i++) {
    vehicleSpendCategories.push(vehiclesList[i].fleetName);
    const vehicleArray: [number, number][] = [];
    const transactionsList = vehiclesList[i].transaction;
    if (transactionsList) {
      let maxAmount = 0;
      for (let j = 0; j < transactionsList.length || 0; j++) {
        const transAmount = transactionsList[j].transactionAmount;
        const authDate = new Date(transactionsList[j].authorizationDate);
        if (
          dates &&
          authDate >= (dates as Date[])[0] &&
          authDate <= (dates as Date[])[1]
        ) {
          if (transAmount > maxAmount) {
            maxAmount = transAmount;
          }
          vehicleArray.push([authDate.getTime(), transAmount / 100]);
          const city = vehiclesList[i]?.transaction[j]?.stripeResponse
            ?.merchant_data?.city
            ? vehiclesList[i]?.transaction[j]?.stripeResponse?.merchant_data
                ?.city
            : "";
          const state = vehiclesList[i]?.transaction[j]?.merchantState
            ? vehiclesList[i]?.transaction[j]?.merchantState
            : "-";
          const postCd = vehiclesList[i]?.transaction[j]?.merchantPostCd
            ? vehiclesList[i]?.transaction[j]?.merchantPostCd
            : "-";
          allVehicleSpendData.push({
            vehicleName: vehiclesList[i].fleetName,
            licensePlate: vehiclesList[i].fleetLicensePlate,
            totalSpends: dollarFormatter(transAmount / 100),
            fuelSpends:
              vehiclesList[i]?.transaction[j]?.fuelQuantity > 0
                ? dollarFormatter(transAmount / 100)
                : "-",
            otherSpends:
              vehiclesList[i]?.transaction[j]?.fuelQuantity <= 0
                ? dollarFormatter(transAmount / 100)
                : "-",
            location: `${city} ${state} ${postCd}`,
          });
        }
      }
      vehicleSpendData.push(maxAmount / 100);
      vehicleArray.sort(appChartsGraphSorter);
    }
    vehicleSpendOverTimeData.push({
      name: vehiclesList[i].fleetName || "-",
      data: vehicleArray,
    });
  }

  return (
    <AccountAnalysisUI
      {...{
        vehicleSpendData,
        driverSpendData,
        vehicleSpendCategories,
        driverSpendCategories,
        driverSpendOverTimeData,
        vehicleSpendOverTimeData,
        dailyTransactionVolumeData,
        allDriverSpendData,
        allVehicleSpendData,
        merchantAnalysisData,
        merchantCategoryBreakdownData,
        dates,
        setDates,
        onDateRangeChange,
        transactionListLoading,
        readCurrentUserAuth,
      }}
    />
  );
};
