import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import {
  useAssignAccountTag,
  usePlatformDetect,
  useReadAccountTag,
} from "@roadflex/react-hooks";
import { AccountTagType } from "@roadflex/types";
import { capitalizeFirstLetter } from "@roadflex/utils";
import classNames from "classnames";
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { Loader } from "../../loader";

const ACCOUNT_TAGS_COLOR = [
  "bg-slate-200",
  "bg-red-200",
  "bg-amber-200",
  "bg-green-200",
  "bg-teal-200",
  "bg-gray-200",
  "bg-purple-200",
  "bg-blue-200",
  "bg-lime-200",
  "bg-orange-200",
  "bg-sky-200",
  "bg-pink-200",
];

const accountTagItemTemplate = (accountTagItem: AccountTagType) => {
  const index = Number(accountTagItem?.colorCode) || 0;
  const length = ACCOUNT_TAGS_COLOR.length;
  const colorCode = ACCOUNT_TAGS_COLOR[index % length];
  return (
    <div>
      <span
        className={classNames(colorCode, "rounded-full text-sm px-2 mr-3")}
      ></span>
      <span>
        {accountTagItem.title}{" "}
        {accountTagItem.tagId ? <span>({accountTagItem.tagId})</span> : null}
      </span>
    </div>
  );
};

interface AssignAccountTagModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  accountTagType: ENUM_ACCOUNT_TAG_TYPE;
  accountTag?: AccountTagType;
  itemName: string;
  itemId: string;
  refetchData: () => void;
}

export const AssignAccountTagModal = ({
  open,
  setOpen,
  accountTagType,
  accountTag,
  itemName,
  itemId,
  refetchData,
}: AssignAccountTagModalProps) => {
  const { data, loading } = useReadAccountTag({
    tagType: accountTagType,
  });

  const { resetForm, setFieldValue, handleSubmit, isSubmitting } =
    useAssignAccountTag({
      initialValues: {
        id: "",
        accountTagId: "",
        tagType: accountTagType,
        isUnassigned: false,
      },
      onSubmit(res, err) {
        if (err) {
          toast.error(err.message);
          return;
        }
        if (res?.code === "200") {
          toast.success(res?.message || "Account tag assigned succesfully");
          resetForm();
          setOpen(false);
          refetchData();
        }
      },
    });

  const cancelButtonRef = useRef(null);
  const [accountTags, setAccountTags] = useState<AccountTagType[]>([]);
  const [selectedAccountTag, setSelectedAccountTag] =
    useState<AccountTagType | null>(null);
  const [filteredAccountTags, setFilteredAccountTags] = useState<
    AccountTagType[]
  >([]);
  const { isMobile } = usePlatformDetect();

  const searchAccountTag = (event: { query: string }) => {
    setTimeout(() => {
      let filteredTags;
      if (!event.query.trim().length) {
        filteredTags = [...accountTags];
      } else {
        filteredTags = accountTags.filter((tag) => {
          return (
            tag?.tagId?.toLowerCase().includes(event.query.toLowerCase()) ||
            tag?.title?.toLowerCase().includes(event.query.toLowerCase())
          );
        });
      }
      setFilteredAccountTags(filteredTags);
    }, 250);
  };

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.Red}
        size={ButtonSize.AppSize}
        disabled={isSubmitting}
        className="w-full"
        onClick={() => setOpen(false)}
        ref={cancelButtonRef}
      >
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.AppSize}
        disabled={isSubmitting || !selectedAccountTag}
        loading={isSubmitting}
        className="w-full ml-3"
        onClick={() => {
          if (selectedAccountTag) {
            setFieldValue("id", itemId);
            setFieldValue("accountTagId", selectedAccountTag?.id || "");
            if (selectedAccountTag.description === "unassign") {
              setFieldValue("isUnassigned", true);
            }
            handleSubmit();
          } else {
            toast.error("Please select a tag");
          }
        }}
      >
        Save
      </Button>
    </div>
  );

  const renderHeader = () => {
    return (
      <div className="text-base">{`${
        accountTag ? "Edit" : "Add"
      } tag for ${capitalizeFirstLetter(accountTagType)} - ${itemName}`}</div>
    );
  };

  const populateAccountTagData = (value: ENUM_ACCOUNT_TAG_TYPE) => {
    const accountTagList = data?.readAccountTags?.accountTagList || {};
    let activeTags: AccountTagType[];
    switch (value) {
      case ENUM_ACCOUNT_TAG_TYPE.CARD:
        activeTags = accountTagList?.CARD?.active || [];
        break;
      case ENUM_ACCOUNT_TAG_TYPE.DRIVER:
        activeTags = accountTagList?.DRIVER?.active || [];
        break;
      case ENUM_ACCOUNT_TAG_TYPE.VEHICLE:
        activeTags = accountTagList?.VEHICLE?.active || [];
        break;
      default:
        activeTags = [];
        break;
    }
    const emptyAccountTagObj = {
      title: "-",
      description: "unassign",
      tagType: value,
      tagId: "",
    };
    activeTags.unshift(emptyAccountTagObj);
    setAccountTags(activeTags);
  };

  useEffect(() => {
    if (data && data.readAccountTags && data.readAccountTags.accountTagList) {
      populateAccountTagData(accountTagType);
    }
    if (accountTag) {
      setSelectedAccountTag(accountTag);
    }
  }, [data, accountTag]);

  return (
    <Dialog
      header={renderHeader}
      visible={open}
      style={isMobile() ? { width: "80%" } : { width: "30%" }}
      className="min-h-[220px]"
      footer={footer}
      onHide={() => setOpen(false)}
      closable={false}
    >
      <div className="w-full">
        {loading ? (
          <Loader />
        ) : (
          <AutoComplete
            value={selectedAccountTag}
            suggestions={filteredAccountTags}
            completeMethod={searchAccountTag}
            field="title"
            dropdown
            itemTemplate={accountTagItemTemplate}
            className="w-full custom-autocomplete"
            onChange={(e) => {
              setSelectedAccountTag(e.value);
            }}
          />
        )}
      </div>
    </Dialog>
  );
};
