import { useMutation, useQuery } from "@apollo/client";
import { ENUM_WORKFLOW_TYPE, SendGridTemplates } from "@prisma/client";
import {
  DELETE_NOTIFICATION_WORKFLOW,
  READ_NOTIFICATION_WORKFLOW,
} from "@roadflex/graphql";
import { useReadAdminOutboundEmails } from "@roadflex/react-hooks";
import { NotificationWorkflow } from "@roadflex/types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import NotificationWorkflowUI from "./notification-workflow.ui";

export const EmailWorkflow = ({
  selectedWorkflowType,
}: {
  selectedWorkflowType: ENUM_WORKFLOW_TYPE;
}) => {
  const [workFlowModal, setWorkFlowModal] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<
    NotificationWorkflow[]
  >([]);
  const { data } = useReadAdminOutboundEmails();
  const [deleteNotificationWorkflow, { loading: deletingWorkflow }] =
    useMutation(DELETE_NOTIFICATION_WORKFLOW, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      refetchQueries: [READ_NOTIFICATION_WORKFLOW],
    });

  const {
    data: notificationWorkflowData,
    loading: notificationWorkflowLoading,
  } = useQuery<{
    readNotificationWorkflow: {
      notificationWorkflow: NotificationWorkflow[];
      sendGridTemplates: SendGridTemplates[];
    };
  }>(READ_NOTIFICATION_WORKFLOW, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        workflowType: selectedWorkflowType,
      },
    },
  });

  const sendGridTemplates =
    notificationWorkflowData?.readNotificationWorkflow?.sendGridTemplates?.map(
      (value) => {
        return {
          id: value.id,
          sendgridTemplateType: value.sendgridTemplateType,
          variableNames: value.variableNames,
        };
      },
    ) || [];

  const handleDeleteWorkflow = async () => {
    const selectedWorkflowList = selectedWorkflow.map((workflow) => {
      return workflow.id;
    });
    try {
      await deleteNotificationWorkflow({
        variables: {
          data: {
            notificationWorkflowIds: selectedWorkflowList,
          },
        },
      });
      setSelectedWorkflow([]);
      toast.success("Workflow deleted successfully");
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <NotificationWorkflowUI
      addedEmailAccounts={data?.readAdminOutboundEmails || []}
      notificationWorkflowData={
        notificationWorkflowData?.readNotificationWorkflow
          ?.notificationWorkflow || []
      }
      notificationWorkflowDataLoading={notificationWorkflowLoading}
      setWorkFlowModal={setWorkFlowModal}
      workFlowModal={workFlowModal}
      selectedWorkflow={selectedWorkflow}
      setSelectedWorkflow={setSelectedWorkflow}
      handleDeleteWorkflow={handleDeleteWorkflow}
      deletingWorkflow={deletingWorkflow}
      sendGridTemplates={sendGridTemplates}
      selectedWorkflowType={selectedWorkflowType}
    ></NotificationWorkflowUI>
  );
};
