/** @format */

import {
  ChevronRightIcon,
  DownloadIcon,
  PhotographIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import { AccountTagType, AuthorizationDetailsType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import classNames from "classnames";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { TextInput } from "../../../inputs";

const { publicRuntimeConfig } = getConfig();
interface Upload {
  file: File | null;
  progress: number;
  isLoading: boolean;
  hasError: boolean;
  url?: string;
  fromServer: boolean;
  createdAt: Date;
  fileId: string;
}
interface AuthorizationDetailsPageUIProps {
  authorizationDetailsObj: AuthorizationDetailsType;
  updateTransactionMemoSubmitting: boolean;
  updateTransactionMemoValues: {
    memo: string;
    transactionId: string;
    type: string;
    transactionTagId?: string;
    cardTagId?: string;
    driverTagId?: string;
    vehicleTagId?: string;
  };
  handleUpdateTransactionMemoSubmit: () => void;
  handleUpdateTransactionMemoChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  editMemo: boolean;
  setEditMemo: (val: boolean) => void;
  setFieldValue: (field: string, value: string) => void;
  transactionTags: AccountTagType[];
  cardTags: AccountTagType[];
  driverTags: AccountTagType[];
  vehicleTags: AccountTagType[];
  accountTagsDataLoading: boolean;
  setAuthInitialMemoValues: () => void;
  handleRemoveReceiptFile: (value: string) => void;
  handleReceiptFiles: (value: FileList) => void;
  uploads: Upload[];
}

export const AuthorizationDetailsPageUI = ({
  authorizationDetailsObj,
  updateTransactionMemoValues,
  handleUpdateTransactionMemoSubmit,
  updateTransactionMemoSubmitting,
  handleUpdateTransactionMemoChange,
  editMemo,
  setEditMemo,
  setFieldValue,
  transactionTags,
  cardTags,
  driverTags,
  vehicleTags,
  accountTagsDataLoading,
  setAuthInitialMemoValues,
  handleReceiptFiles,
  handleRemoveReceiptFile,
  uploads,
}: AuthorizationDetailsPageUIProps) => {
  const [loading, setLoading] = useState(false);

  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const accountTagItemTemplate = (accountTagItem: AccountTagType) => {
    const index = Number(accountTagItem?.colorCode) || 0;
    const length = ACCOUNT_TAGS_COLOR.length;
    const colorCode = ACCOUNT_TAGS_COLOR[index % length];
    return (
      <div>
        <span
          className={classNames(colorCode, "rounded-full text-sm px-2 mr-3")}
        ></span>
        <span>
          {accountTagItem.title}{" "}
          {accountTagItem.tagId ? <span>({accountTagItem.tagId})</span> : null}
        </span>
      </div>
    );
  };

  const router = useRouter();
  const backToTransactionsPanel = () => {
    router.push("/transactions");
  };

  const discardChanges = () => {
    setEditMemo(false);
    setAuthInitialMemoValues();
  };

  const toggle = () => {
    if (editMemo) {
      setEditMemo(false);
    } else {
      setEditMemo(true);
    }
  };

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.GrayOutline}
                onClick={backToTransactionsPanel}
                className="pl-0 ml-[-6px] border-0"
              >
                <span className="flex items-center h-5">
                  <ChevronRightIcon
                    className={
                      "-rotate-180 h-5 w-5 transform  ease-in duration-300"
                    }
                    aria-hidden="true"
                  />
                </span>
                Back
              </Button>
              <div className="ml-4 text-lg font-semibold">
                Pending Transaction
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-5 lg:flex-row gap-y-8 md:mt-0">
          <div className="flex flex-col w-full p-4 bg-white rounded-md shadow md:p-8 gap-y-2 md:mt-0">
            <div className="flex flex-row items-center justify-between w-full gap-2 md:gap-4">
              <div className="text-sm md:text-base  min-w-[80px] md:min-w-[125px]">
                Memo
              </div>
              <div className="w-full max-w-[400px] text-sm md:text-base">
                <TextInput
                  value={updateTransactionMemoValues?.memo || ""}
                  onChange={handleUpdateTransactionMemoChange}
                  disabled={!editMemo}
                  type="text"
                  maxLength={250}
                  name="memo"
                ></TextInput>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-2 md:gap-4">
              <div className="text-sm md:text-base  min-w-[80px] md:min-w-[125px]">
                Transaction Tag
              </div>
              <div className="w-full max-w-[400px] text-sm md:text-base">
                <Select
                  id="transaction-tag"
                  value={updateTransactionMemoValues.transactionTagId}
                  options={transactionTags}
                  onChange={(e) => {
                    setFieldValue("transactionTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-2 md:gap-4">
              <div className="text-sm md:text-base  min-w-[80px] md:min-w-[125px]">
                Card Tag
              </div>
              <div className="w-full max-w-[400px] text-sm md:text-base">
                <Select
                  id="card-tag"
                  value={updateTransactionMemoValues.cardTagId}
                  options={cardTags}
                  onChange={(e) => {
                    setFieldValue("cardTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-2 md:gap-4">
              <div className="text-sm md:text-base  min-w-[80px] md:min-w-[125px]">
                Driver Tag
              </div>
              <div className="w-full max-w-[400px] text-sm md:text-base">
                <Select
                  id="driver-tag"
                  value={updateTransactionMemoValues.driverTagId}
                  options={driverTags}
                  onChange={(e) => {
                    setFieldValue("driverTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-2 md:gap-4">
              <div className="text-sm md:text-base  min-w-[80px] md:min-w-[125px]">
                Vehicle Tag
              </div>
              <div className="w-full max-w-[400px] text-sm md:text-base">
                <Select
                  id="vehicle-tag"
                  value={updateTransactionMemoValues.vehicleTagId}
                  options={vehicleTags}
                  onChange={(e) => {
                    setFieldValue("vehicleTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex justify-end">
              {!editMemo && (
                <Button
                  onClick={toggle}
                  variant={ButtonVariant.AppOrange}
                  size={ButtonSize.AppSize}
                  disabled={updateTransactionMemoSubmitting || loading}
                >
                  Add/Update
                </Button>
              )}
              {editMemo && (
                <div className="flex gap-x-2">
                  <Button
                    onClick={discardChanges}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={updateTransactionMemoSubmitting || loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    loading={updateTransactionMemoSubmitting}
                    disabled={updateTransactionMemoSubmitting || loading}
                    onClick={handleUpdateTransactionMemoSubmit}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-between w-full space-y-4">
              <div className="text-sm md:text-base">
                Transaction Receipt (Max: 2)
              </div>

              <div>
                <div className="text-xs">
                  Click the area below to upload files from your device
                </div>
                <label
                  htmlFor="receipts"
                  className="relative flex items-center justify-center h-32 text-black bg-white border-2 border-gray-200 border-dashed rounded-lg hover:cursor-pointer "
                >
                  <input
                    className="hidden"
                    type="file"
                    id="receipts"
                    name="receipts"
                    onChange={(event) => {
                      handleReceiptFiles(event?.target?.files as FileList);
                      // setStatus({ error: null });
                    }}
                    // onBlur={handleBlur}
                    multiple
                    accept=".png, .jpeg, .jpg"
                  />
                  <div className="flex flex-row">
                    <UploadIcon
                      className="w-10 h-5 text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm tracking-wider group-hover:text-gray-600">
                      Upload Receipt
                    </span>
                  </div>
                </label>
              </div>
              {uploads.length > 0 && (
                <div className="">
                  <h3 className="font-medium text-gray-900 ">Uploaded Files</h3>
                  <ul className="mt-4 space-y-4">
                    {uploads.map((upload, index) => (
                      <li
                        key={index}
                        className="relative flex items-center gap-2 p-4 bg-white border rounded-lg shadow-sm md:gap-4"
                      >
                        <PhotographIcon className="w-8 h-8"></PhotographIcon>
                        <div className="flex flex-wrap items-center justify-between w-full gap-2 md:gap-4">
                          {" "}
                          <div className="flex-1">
                            <div className="flex flex-row gap-3">
                              <p className="text-sm font-medium text-gray-900">
                                {/* {upload.file?.name} */}
                                receipt_image_{index + 1}
                              </p>
                              {upload.fromServer && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveReceiptFile(upload.fileId)
                                  }
                                >
                                  <TrashIcon className="w-4 h-4"></TrashIcon>
                                </button>
                              )}
                            </div>
                            <p className="text-sm text-gray-500">
                              upload Date:{" "}
                              {
                                convertISOStringToDate(
                                  upload.createdAt.toString(),
                                  false,
                                  false,
                                  true,
                                ).split(",")[0]
                              }
                            </p>
                          </div>
                          <div className="flex items-center space-x-2">
                            {upload.progress !== 100 && (
                              <div className="relative w-48">
                                <div className="w-full h-2 bg-gray-200 rounded-full">
                                  <div
                                    className={`h-full rounded-full ${
                                      upload.hasError
                                        ? "bg-red-500"
                                        : "bg-brown-500"
                                    }`}
                                    style={{ width: `${upload.progress}%` }}
                                  ></div>
                                </div>
                                <div className="inset-y-0 right-0 flex items-center pr-2 text-sm text-gray-500 ">
                                  {upload.hasError
                                    ? "Error uploading"
                                    : `${upload.progress}%`}
                                </div>
                              </div>
                            )}
                            {upload.isLoading ? (
                              <></>
                            ) : upload.hasError ? (
                              <svg
                                className="w-5 h-5 text-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            ) : (
                              <a
                                href={upload.url}
                                rel="noreferrer"
                                target="_blank"
                                download={upload.file?.name}
                                className=""
                              >
                                <DownloadIcon className="w-4 h-4"></DownloadIcon>
                              </a>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
