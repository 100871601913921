/** @format */

import { FormikErrors } from "formik";

type Props = {
  error:
    | string
    | string[]
    | FormikErrors<unknown>
    | FormikErrors<unknown>[]
    | undefined;
};

const ErrorMessage = ({ error }: Props) => {
  if (!error) {
    return null;
  }
  return (
    <span className="flex items-center mt-1 ml-1 text-xs font-medium tracking-wide text-red-500">
      {error}
    </span>
  );
};

export default ErrorMessage;
