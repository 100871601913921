/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { GEOTAB_GET_TRIP_DETAILS } from "@roadflex/graphql";
import { EldDeviceTripDetails } from "@roadflex/types";
import { useFormik } from "formik";

type Values = {
  database: string;
  orgName: string;
  startDate: string;
  endDate: string;
  fromVersion: string;
  resultsLimit: string;
  deviceId: string;
};

type UseGeoTabGetTripDetails = {
  initialValues: Values;
  onSubmit: (
    val: {
      eldDeviceTripDetails: {
        fromVersion: string;
        eldDeviceTripDetails: EldDeviceTripDetails[];
      };
    } | null,
    err: Error | null,
  ) => void;
};

export const useGeoTabGetTripDetails = ({
  onSubmit,
  initialValues,
}: UseGeoTabGetTripDetails) => {
  const [geoTabGetTripDetails] = useMutation<{
    eldDeviceTripDetails: {
      fromVersion: string;
      eldDeviceTripDetails: EldDeviceTripDetails[];
    };
  }>(GEOTAB_GET_TRIP_DETAILS);

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        // console.log("data => ", data);
        const res = await geoTabGetTripDetails({
          variables: { data },
        });
        onSubmit(res?.data || null, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    geoTabGetTripDetailsValues: values,
    geoTabGetTripDetailsHandleChange: handleChange,
    geoTabGetTripDetailsHandleSubmit: handleSubmit,
    geoTabGetTripDetailsIsSubmitting: isSubmitting,
    geoTabGetTripDetailsSetFieldValue: setFieldValue,
    ...rest,
  };
};
