/* eslint-disable @typescript-eslint/no-explicit-any */
/** @format */

import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ExclamationCircleIcon, MenuAlt2Icon } from "@heroicons/react/outline";
import { ENUM_CARDHOLDER_STATUS } from "@prisma/client";
import { READ_ACCOUNT_STATUS } from "@roadflex/graphql";
import { UserAuthType } from "@roadflex/types";
import {
  checkIfUserIsInImpersonatorMode,
  deleteCookie,
  getCookie,
  setCookie,
} from "@roadflex/web-lib";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InfoModal } from "../../../modals";
import SidebarOverlayed from "../../sidebars/sidebar-overlayed";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

interface HeaderProps {
  toggle: () => void;
  open: boolean;
  readCurrentUserAuth?: UserAuthType;
}

export default function AppHeader({
  toggle,
  open,
  readCurrentUserAuth,
}: HeaderProps) {
  const router = useRouter();
  const client = useApolloClient();

  const [modal, setmodal] = useState(false);
  const [isImpersonatorMode, setImpersonatorMode] = useState(false);
  const [status, setStatus] = useState("");

  const [readAccountStatusFn, { loading: readAccountStatusLoading }] =
    useLazyQuery(READ_ACCOUNT_STATUS, {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        if (data) {
          setStatus(data?.readAccountStatus?.userAccount?.cardholderStatus);
          // return data?.readAccountStatus?.userAccount?.cardholderStatus;
        }
      },
      onError: (error: any) => {
        toast.error(`App Dashboard Data: ${error.message}`);
      },
      notifyOnNetworkStatusChange: true,
    });

  const toggleInfoModal = () => setmodal((o) => !o);

  useEffect(() => {
    if (readCurrentUserAuth && !readCurrentUserAuth.isParentCompanyUser) {
      readAccountStatusFn({});
    }
  }, [readCurrentUserAuth]);

  useEffect(() => {
    setImpersonatorMode(checkIfUserIsInImpersonatorMode());

    const handleTabClose = () => {
      if (getCookie("impersonatorToken")) {
        deleteCookie("impersonatorToken");
      }
    };

    const onBeforeUnloadFunc = (event: Event) => {
      if (getCookie("impersonatorToken")) {
        const e = event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = false; // Legacy method for cross browser support
        }
        return ""; // Legacy method for cross browser support
      }
      return true;
    };
    if (
      readCurrentUserAuth?.loggedInId &&
      status === ENUM_CARDHOLDER_STATUS.INACTIVE
    ) {
      const val = getCookie("firstLogin");
      if (val) {
        setmodal(false);
      } else {
        setCookie("firstLogin", "true", 1);
        setmodal(true);
      }
    } else {
      setmodal(false);
    }
    window.addEventListener("beforeunload", onBeforeUnloadFunc);
    window.addEventListener("unload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnloadFunc);
      window.removeEventListener("unload", handleTabClose);
    };
  }, [readCurrentUserAuth, status]);
  return (
    <>
      <div className="flex items-center justify-end">
        {isImpersonatorMode ? (
          <div className="relative px-2 py-1 my-3 mr-4 text-sm bg-red-200 rounded-md md:text-base sm:mr-6 sm:my-2 sm:text-sm">
            <div className="flex items-center gap-1">
              <ExclamationCircleIcon className="w-4 h-4 text-red-700 sm:w-5 sm:h-5" />
              <span className="text-red-600">Impersonator Mode</span>
            </div>
          </div>
        ) : null}
      </div>

      {(status || router.pathname === "/parent-company-user") && (
        <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-gray-600 md:mx-4 md:px-4 md:bg-orange-100 md:hidden">
          <div className="flex justify-between w-full md:flex-row-reverse">
            <img
              className="w-[120px] md:hidden block ml-4"
              src={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-full-logo.svg`}
              alt="RoadFlex"
            />
            <div className="flex items-center">
              {/* {isImpersonatorMode ? (
                <div className="relative px-2 py-1 my-3 mr-4 text-sm bg-red-200 rounded-md md:text-base sm:mr-6 sm:my-2 sm:text-sm">
                  <div className="flex items-center gap-1">
                    <ExclamationCircleIcon className="w-4 h-4 text-red-700 sm:w-5 sm:h-5" />
                    <span className="text-red-600">Impersonator Mode</span>
                  </div>
                </div>
              ) : null} */}
              {status && status === ENUM_CARDHOLDER_STATUS.ACTIVE && (
                <div className="relative px-2 py-1 my-3 mr-3 text-sm text-white rounded-md md:mr-0 sm:px-6 bg-brown-500 md:text-base sm:my-2 sm:text-sm">
                  <div className="flex items-center gap-1">Account Active</div>
                </div>
              )}
              {status && status === ENUM_CARDHOLDER_STATUS.INACTIVE && (
                <div className="relative px-2 py-1 my-3 mr-3 text-xs text-white rounded-md md:mr-0 sm:px-6 bg-brown-500 md:text-base sm:my-2 sm:text-sm">
                  <div className="flex items-center gap-1 text-white">
                    Account Locked
                  </div>
                </div>
              )}

              <button
                onClick={toggle}
                type="button"
                className="px-4 text-white border-l border-gray-200 focus:outline-none focus:ring-0 focus:ring-inset"
              >
                {/* <span className="sr-only">Open sidebar</span> */}
                <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      )}
      {modal && (
        <InfoModal
          setShow={toggleInfoModal}
          show={true}
          title="Account Inactive Notice"
          body={
            <ol className="text-xs list-decimal list-inside md:text-sm text-scarpa-flow-700">
              Your account is currently inactive. <br /> <br /> For new users,
              you need to have a valid bank account or debit card connection. Go
              to the payments page. <br />
              <br />
              For existing users, there are several possible reasons.
              <li>Recent automated payment failed.</li>
              <li>Your account has outstanding payments.</li>
              <li>Bank account/debit card connection error.</li>
              <br />
              To resolve the first two issues, make a manual payment through the
              payments page. We strongly encourage debit payment because it is
              processed immediately. To resolve the third issue, re-authenticate
              your bank account through the payments page.
            </ol>
          }
        />
      )}

      <SidebarOverlayed
        toggle={toggle}
        open={open}
        readCurrentUserAuth={readCurrentUserAuth}
      />
    </>
  );
}
