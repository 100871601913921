/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { CreditCardIcon, XIcon } from "@heroicons/react/outline";
import { CREATE_MANUAL_PAYMENT_INTENT } from "@roadflex/graphql";
import { capitalizeFirstLetter } from "@roadflex/utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import getConfig from "next/config";
import { OverlayPanel } from "primereact/overlaypanel";
import { Fragment, useEffect, useRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import { Button, ButtonType, ButtonVariant } from "../../buttons";
import { StripeCheckoutForm } from "./stripe-checkout-form-modal";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = publicRuntimeConfig;

const stripeLoadedResponse = loadStripe(
  `${NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`,
);
interface UserDetailsModalProps {
  userDetailsModal: boolean;
  setUserDetailsModal: (value: boolean) => void;
  selectedUser: any;
}
export const UserDetailsModal = (props: UserDetailsModalProps) => {
  const { userDetailsModal, setUserDetailsModal, selectedUser } = props;
  const op = useRef<OverlayPanel>(null);
  const [payAmount, setpayAmount] = useState("");
  const validateValue = (value: string | undefined): void => {
    const rawValue = value === undefined ? "undefined" : value;
    setpayAmount(rawValue || " ");
    if (Number.isNaN(Number(value)) && value !== undefined) {
      toast.error("Please enter a valid number");
    }
  };
  const [isPayNowClicked, setisPayNowClicked] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [getClientSecret] = useMutation(CREATE_MANUAL_PAYMENT_INTENT);

  const getSecret = async () => {
    try {
      const resp = await getClientSecret({
        variables: {
          data: {
            amount: Math.round(+payAmount * 100),
            details: "payment intent",
            email: selectedUser.email,
          },
        },
      });
      setClientSecret(resp?.data?.createManualPaymentIntent?.clientSecret);
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (+payAmount && isPayNowClicked) {
      getSecret();
    }
    // Create PaymentIntent as soon as the page loads
  }, [isPayNowClicked]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      {isPayNowClicked && clientSecret && (
        <Elements options={options as any} stripe={stripeLoadedResponse}>
          <StripeCheckoutForm
            {...{
              clientSecret: clientSecret,
              isPayNowClicked: isPayNowClicked,
              setisPayNowClicked: setisPayNowClicked,
              email: selectedUser?.email,
            }}
          />
        </Elements>
      )}
      <Transition.Root show={userDetailsModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-hidden"
          onClose={() => {
            //prevent closing when clicking outside the modal
          }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setUserDetailsModal(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col justify-between h-full p-8 overflow-y-auto bg-white">
                    <div className="flex flex-col flex-grow-1 h-100">
                      <div className="space-y-6">
                        <div className="flex items-start justify-between mt-4">
                          <div>
                            <h2 className="text-lg font-medium text-gray-900">
                              {capitalizeFirstLetter(selectedUser.businessName)}
                            </h2>
                            <p className="text-sm font-medium text-gray-500">
                              {selectedUser.email}
                              <br />
                              {selectedUser.formattedPhoneNumber}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          <span>Invoices</span>
                        </h2>
                        {selectedUser.invoices.length > 0 ? (
                          <ul role="list" className="divide-y divide-gray-200">
                            {selectedUser.invoices.map((invoice) => (
                              <li
                                key={invoice.invoiceId}
                                className="relative py-5 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-brown-600"
                              >
                                <div className="flex justify-between space-x-3">
                                  <div className="flex-1 min-w-0">
                                    <div className="block focus:outline-none">
                                      <span
                                        className="absolute inset-0"
                                        aria-hidden="true"
                                      />
                                      <p className="text-sm font-medium text-gray-900 truncate">
                                        {invoice.invoiceId}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate">
                                        {invoice.subject}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 text-sm text-gray-500 whitespace-nowrap">
                                    {invoice.daysOverDue} days Overdue
                                  </div>
                                </div>
                                <div className="mt-1">
                                  <p className="text-sm text-gray-600 line-clamp-2">
                                    {invoice.status}
                                  </p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div>No invoices due</div>
                        )}
                      </div>
                    </div>
                    <div className="pt-4">
                      <div className="relative w-full border rounded-md shadow-sm">
                        <CurrencyInput
                          placeholder="$"
                          className="block w-full h-full py-2 pl-3 pr-12 border-white rounded-md sm:text-sm focus:outline-none focus:ring-0 focus:border-white"
                          allowDecimals={true}
                          onValueChange={validateValue}
                          prefix={"$"}
                          step={10}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            USD
                          </span>
                        </div>
                      </div>
                      <div className="mt-3 rounded-md shadow sm:flex-shrink-0">
                        <Button
                          type={ButtonType.Button}
                          variant={ButtonVariant.Gray}
                          onClick={(e) => op.current.toggle(e)}
                          disabled={
                            +payAmount <= 1 ||
                            Number.isNaN(Number(payAmount)) ||
                            payAmount === undefined
                            // business?.business?.plaid?.length === 0
                          }
                          className="flex items-center justify-center w-full px-5 py-2.5 text-sm font-medium text-white rounded-md bg-scarpa-flow-100 hover:bg-scarpa-flow-200 focus:ring-2 focus:ring-scarpa-flow-100 focus:ring-opacity-50 sm:text-sm"
                        >
                          <CreditCardIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                          <span>Pay on behalf of customer</span>
                        </Button>
                        <OverlayPanel
                          ref={op}
                          style={{ width: "250px" }}
                          showCloseIcon
                          id="overlay_panel"
                        >
                          <p className="text-sm">
                            Are you sure you want to continue for{" "}
                            {selectedUser.email}?
                          </p>
                          <div className="mt-2">
                            <button
                              type="button"
                              value="INCREASE_LIMIT"
                              className="flex-1 px-4 py-2 text-sm font-medium text-white bg-red-600 border rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              onClick={(e) => {
                                op.current?.hide();
                              }}
                            >
                              No
                            </button>
                            <button
                              type="button"
                              value="INCREASE_LIMIT"
                              className="flex-1 px-4 py-2 ml-3 text-sm font-medium text-white rounded-md bg-scarpa-flow-100 hover:bg-scarpa-flow-200 focus:ring-2 focus:ring-scarpa-flow-100 focus:ring-opacity-50"
                              onClick={() => {
                                op.current?.hide();
                                setisPayNowClicked(true);
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </OverlayPanel>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
