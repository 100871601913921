/** @format */

import {
  ENUM_WALLET_ACTIVITY_TYPE,
  ENUM_WALLET_ITEMS_TYPE,
  ENUM_WALLET_TYPE,
} from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { UserAuthType, WalletItem } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { useState } from "react";
import { dateTemplate } from "../../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";

const walletAmountTemplate = (rowData: WalletItem) => {
  const walletAmountText = rowData.amount
    ? `${rowData.ItemType === "CREDIT" ? "+ " : "- "} ${dollarFormatter(
        rowData.amount / 100,
      )}`
    : `${" "}${" "}  -`;
  return <span className="whitespace-nowrap">{walletAmountText}</span>;
};

const fuelDiscountTemplate = (rowData: WalletItem) => {
  const fuelDiscountText = rowData.fuelDiscountAmount
    ? rowData.classification === ENUM_WALLET_ACTIVITY_TYPE.TRANSACTION
      ? `+ ${dollarFormatter(rowData.fuelDiscountAmount / 100)}`
      : "-"
    : "-";
  return <span className="whitespace-nowrap">{fuelDiscountText}</span>;
};

const classificationTemplate = (rowData: WalletItem) => {
  return (
    <span className="">
      {rowData.classification
        ? rowData.classification.replace(/_/g, " ")[0] +
          rowData.classification.replace(/_/g, " ").slice(1).toLowerCase()
        : "-"}
    </span>
  );
};

const descriptionTemplate = (rowData: WalletItem) => {
  return (
    <span className="whitespace-nowrap">
      {rowData.classification === "PAYMENTS"
        ? "Wallet topup"
        : rowData.classification === "ADMIN_RECHARGE"
        ? "RoadFlex credit"
        : rowData.classification === "MONTHLY_CARD_FEE"
        ? "Monthly card fee"
        : rowData.classification === "PREMIUM_CARD_ORDER"
        ? "Premium card order"
        : rowData.classification === "ADMIN_RECHARGE_REVERSAL"
        ? "Reversal of credit applied to account"
        : rowData.classification === "REFUND"
        ? "Merchant Refund"
        : rowData.classification === "TRANSACTION"
        ? "Transaction charge"
        : rowData.classification === "DISPUTE"
        ? "Removed disputed amount"
        : ""}
    </span>
  );
};
interface WalletItemList {
  type: ENUM_WALLET_TYPE;
  ItemType: ENUM_WALLET_ITEMS_TYPE;
  amount: number;
  classification: ENUM_WALLET_ACTIVITY_TYPE;
  fuelDiscountAmount: number;
  createdAt: Date;
}
export const AccountActivityUI = ({
  readCurrentUserAuth,
  walletItemsData,
  walletItemsLoading,
  walletItemsLazyParams,
  setWalletItemsLazyParams,
}: {
  readCurrentUserAuth: UserAuthType;
  walletItemsData: {
    walletItemsList: WalletItemList[];
    totalCount: number;
  };
  walletItemsLoading: boolean;
  walletItemsLazyParams: DataTablePFSEvent;
  setWalletItemsLazyParams: (value: DataTablePFSEvent) => void;
}) => {
  const { isMobile } = usePlatformDetect();

  const onPageAndSortWalletItems = (event: DataTablePFSEvent) => {
    setWalletItemsLazyParams({ ...walletItemsLazyParams, ...event });
  };
  const [expandedRows, setExpandedRows] = useState<WalletItemList[]>([]);

  const rowExpansionTemplate = (data: WalletItemList) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="mb-4 text-lg font-semibold text-gray-800">
          {descriptionTemplate(data)}
        </div>
        <div className="flex items-center justify-between text-sm text-gray-600">
          <div>
            {dateTemplate(
              data.createdAt.toString(),
              readCurrentUserAuth?.accountDisplaySetting?.timeZone,
            )}
          </div>
          <div>Discount: {fuelDiscountTemplate(data)}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full p-5 mt-4 mr-4 bg-white rounded-md shadow md:p-8">
      <div className="font-semibold text-brown-500 md:text-lg">
        Account Activity
      </div>
      <div className="flex flex-col h-full mt-4">
        <DataTable
          value={walletItemsData.walletItemsList || []}
          size="small"
          className="min-w-full w-auto !border-brown-500 custom-table"
          responsiveLayout={isMobile() ? "scroll" : "scroll"}
          style={{
            fontFamily: "inherit",
          }}
          paginatorClassName="!py-1"
          paginator
          loading={walletItemsLoading}
          paginatorTemplate={newPaginationTemplate}
          lazy
          rows={walletItemsLazyParams.rows}
          filters={walletItemsLazyParams.filters}
          sortField={walletItemsLazyParams.sortField}
          sortOrder={walletItemsLazyParams.sortOrder}
          sortMode={"single"}
          first={walletItemsLazyParams.first}
          pageLinkSize={isMobile() ? 2 : 3}
          totalRecords={walletItemsData.totalCount || 0}
          onPage={onPageAndSortWalletItems}
          onSort={onPageAndSortWalletItems}
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e: DataTableRowToggleParams) => setExpandedRows(e.data)}
        >
          {!isMobile() && (
            <Column
              field="createdAt"
              header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              className="text-sm 5xl:text-base"
              // expander
              bodyClassName={
                isMobile()
                  ? "text-brown-500 !py-1 text-sm 5xl:text-base"
                  : "text-brown-500 !py-1 text-sm 5xl:text-base"
              }
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={(r) =>
                dateTemplate(
                  r.createdAt,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                )
              }
            ></Column>
          )}
          {!isMobile() && (
            <Column
              field="type"
              header="Description"
              className="text-sm 5xl:text-base"
              bodyClassName={
                isMobile()
                  ? "text-brown-500 !py-1 text-sm 5xl:text-base"
                  : "text-brown-500 !py-1 text-sm 5xl:text-base"
              }
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={descriptionTemplate}
            ></Column>
          )}
          <Column
            field="classification"
            header="Activity"
            className="text-sm 5xl:text-base"
            bodyClassName={
              isMobile()
                ? "text-brown-500 !py-1 text-sm 5xl:text-base"
                : "text-brown-500 !py-1 text-sm 5xl:text-base"
            }
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
            body={classificationTemplate}
          ></Column>
          <Column
            field="amount"
            header="Amount"
            className="text-sm 5xl:text-base"
            bodyClassName={
              isMobile()
                ? "text-brown-500 !py-1 text-sm 5xl:text-base"
                : "text-brown-500 !py-1 text-sm 5xl:text-base"
            }
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
            body={walletAmountTemplate}
          ></Column>
          {!isMobile() && (
            <Column
              field="fuelDiscountAmount"
              header="Discount"
              className="text-sm 5xl:text-base"
              bodyClassName={
                isMobile()
                  ? "text-brown-500 !py-1 text-sm 5xl:text-base"
                  : "text-brown-500 !py-1 text-sm 5xl:text-base"
              }
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={fuelDiscountTemplate}
            ></Column>
          )}
          {isMobile() && (
            <Column
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              expander
              style={{ width: "3em" }}
            />
          )}
        </DataTable>
      </div>
    </div>
  );
};
