/** @format */
import { usePlatformDetect } from "@roadflex/react-hooks";
import { DriverAnalysisPerformanceType } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import { Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";

type DriverPerformanceUIProps = {
  loading: boolean;
  topN: DriverAnalysisPerformanceType[];
  bottomN: DriverAnalysisPerformanceType[];
  number: number;
  noSpend: DriverAnalysisPerformanceType[];
  decline: DriverAnalysisPerformanceType[];
  timePeriod: number;
  setTimePeriod: (timePeriod: number) => void;
};

const NoDataToShowMessage = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
      <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
        No Data to Show
      </div>
    </div>
  );
};

const DriverPerformanceUI = ({
  loading,
  topN,
  bottomN,
  number,
  decline,
  noSpend,
  timePeriod,
  setTimePeriod,
}: DriverPerformanceUIProps) => {
  const { isMobile } = usePlatformDetect();
  const data: {
    name: string;
    y: number;
  }[] = decline.map((key) => ({
    name: key.driverName,
    y: key.declineCount,
  }));

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    title: {
      text: `Declined Transactions By Driver (Past ${timePeriod} days, Max 20 Drivers)`,
      style: {
        textAlign: "left",
        color: "#893f2b",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "600",
      },
      align: "left",
    },
    tooltip: {
      formatter: function () {
        return `Driver Name: <b>${this.key}</b><br/><b>${this.y}</b>`;
      },
      shared: true,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    colors: [
      "#823920",
      "#ff5100",
      "#de8a26",
      "#6b9848",
      "#2670de",
      "#9f9f9f",
      "#893f2b",
      "#3a3939s",
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 300,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
              },
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Share",
        colorByPoint: true,
        data: data,
        type: "pie" as never,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
        innerSize: "60%",
        point: {
          events: {
            legendItemClick: function () {
              return false; // <== returning false will cancel the default action
            },
          },
        },
      },
    },
  };

  return (
    <div className="flex-col flex flex-1 my-4 mt-0 overflow-x-hidden md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="col-span-2 mb-4">
        <div className="flex flex-col items-center justify-start gap-10 md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row items-center">
              <label className="pr-2 font-semibold" htmlFor="timePeriod">
                Time Period
              </label>
              <Select
                id="timePeriod"
                value={timePeriod}
                options={[
                  { value: 30, label: "30 days" },
                  { value: 60, label: "60 days" },
                  { value: 90, label: "90 days" },
                ]}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  setTimePeriod(e.value);
                }}
                placeholder="Select days"
                className="w-28"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.value}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
              {loading && <InlineLoader />}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="inline-block min-w-full align-middle bg-white border border-gray-200 rounded-md shadow sm:py-6 sm:px-6">
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem"></Skeleton>
        </div>
      ) : (
        <div className="relative p-5 overflow-hidden bg-white border border-gray-200 rounded-md shadow sm:pt-6 sm:px-6">
          <HighchartsReact highcharts={Highcharts} options={options} />
          {decline.length > 0 ? null : <NoDataToShowMessage />}
        </div>
      )}
      <div className="mt-8 overflow-hidden bg-white rounded-md shadow">
        <div className="p-8 font-medium bg-white step-5">
          <div className="mb-4 font-semibold text-brown-500 md:text-lg">
            Top {number} drivers (spend past {timePeriod} days)
          </div>
          <div className="mx-auto">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle ">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={topN}
                loading={loading}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "inherit",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                sortMode={"single"}
                rows={10}
                sortField="totalSpend"
                sortOrder={-1}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10]}
              >
                <Column
                  field="driverName"
                  header="Driver Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  // filter
                  field="cardNumber"
                  header="Card Number"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.cardNumber.map(String).join(", ")}
                />
                <Column
                  // filter
                  field="vehicleName"
                  header="Vehicle Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.vehicleName.map(String).join(", ")}
                />
                <Column
                  field="totalSpend"
                  header="Total Spend"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.totalSpend / 100)}
                />
                <Column
                  field="discountAmount"
                  header="Discount Amount"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.discountAmount / 100)}
                />
                <Column
                  field="flagCount"
                  header="Flag Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  field="declineCount"
                  header="Decline Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  field="fuelQuantity"
                  header="Fuel (gal)"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => Math.round(r.fuelQuantity * 100) / 100}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 overflow-hidden bg-white rounded-md shadow">
        <div className="p-8 font-medium bg-white step-5">
          <div className="mb-4 font-semibold text-brown-500 md:text-lg">
            Bottom {number} drivers (spend past {timePeriod} days, excluding
            drivers with no spend)
          </div>
          <div className="mb-4 text-xs font-normal">
            <div className="font-semibold">Drivers with no spend:</div>
            {noSpend.map((x) => x.driverName.trim()).join(", ")}
          </div>
          <div className="mx-auto">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle ">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={bottomN}
                loading={loading}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "inherit",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                sortMode={"single"}
                rows={10}
                sortField="totalSpend"
                sortOrder={1}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10]}
              >
                <Column
                  field="driverName"
                  header="Driver Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  // filter
                  field="cardNumber"
                  header="Card Number"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.cardNumber.map(String).join(", ")}
                />
                <Column
                  // filter
                  field="vehicleName"
                  header="Vehicle Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => r.vehicleName.map(String).join(", ")}
                />
                <Column
                  field="totalSpend"
                  header="Total Spend"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.totalSpend / 100)}
                />
                <Column
                  field="discountAmount"
                  header="Discount Amount"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => dollarFormatter(r.discountAmount / 100)}
                />
                <Column
                  field="flagCount"
                  header="Flag Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  field="declineCount"
                  header="Decline Count"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  field="fuelQuantity"
                  header="Fuel (gal)"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => Math.round(r.fuelQuantity * 100) / 100}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverPerformanceUI;
