/** @format */
import {
  ENUM_CUSTOMER_TYPE,
  ENUM_NOTIFICATION_CHANNEL,
  ENUM_NOTIFICATION_NAME,
} from "@prisma/client";
import { useReadNotificationsPage } from "@roadflex/react-hooks";
import {
  ChildFeatureType,
  NotificationsType,
  NotificationsTypeExtended,
} from "@roadflex/types";
import { useEffect, useState } from "react";
import PersonalNotificationsUI from "./personal-notifications.ui";

interface PersonalNotificationsProps {
  customerType: ENUM_CUSTOMER_TYPE;
  childFeatures: ChildFeatureType[];
}

const PersonalNotifications = ({
  customerType,
  childFeatures,
}: PersonalNotificationsProps) => {
  const [notifications, setNotifications] = useState<
    NotificationsTypeExtended[]
  >([]);
  const [updating, setUpdating] = useState(false);

  const labels = [
    {
      label: "Blocked Merchant",
      value: "BLOCKED_MERCHANT",
      stringValue: "BLOCKED_MERCHANT",
    },
    {
      label: "Cardholder Inactive",
      value: "CARDHOLDER_INACTIVE",
      stringValue: "CARDHOLDER_INACTIVE",
    },
    {
      label: "Cardholder Verification Required",
      value: "CARDHOLDER_VERIFICATION_REQUIRED",
      stringValue: "CARDHOLDER_VERIFICATION_REQUIRED",
    },
    {
      label: "Card Inactive",
      value: "CARD_INACTIVE",
      stringValue: "CARD_INACTIVE",
    },
    {
      label: "Credit Limit Reached",
      value: "CREDIT_LIMIT_REACHED",
      stringValue: "CREDIT_LIMIT_REACHED",
    },
    {
      label: "Insufficient Funds",
      value: "INSUFFICIENT_FUNDS",
      stringValue: "INSUFFICIENT_FUNDS",
    },
    {
      label: "Not Allowed",
      value: "NOT_ALLOWED",
      stringValue: "NOT_ALLOWED",
    },
    {
      label: "Outside Operational Hours",
      value: "OUTSIDE_OPERATIONAL_HOURS",
      stringValue: "OUTSIDE_OPERATIONAL_HOURS",
    },
    {
      label: "Restricted Merchant",
      value: "RESTRICTED_MERCHANT",
      stringValue: "RESTRICTED_MERCHANT",
    },
    {
      label: "Spending Control",
      value: "SPENDING_CONTROLS",
      stringValue: "SPENDING_CONTROLS",
    },
    {
      label: "Suspected Fraud",
      value: "SUSPECTED_FRAUD",
      stringValue: "SUSPECTED_FRAUD",
    },
    {
      label: "Verification Failed",
      value: "VERIFICATION_FAILED",
      stringValue: "VERIFICATION_FAILED",
    },
    {
      label: "Telematics Security",
      value: "TELEMATICS_SECURITY",
      stringValue: "TELEMATICS_SECURITY",
    },
  ];

  const flaggedTransactionLabels = [
    {
      label: "Merchant Category",
      value: "FLAGGED_MERCHANT_CATEGORY",
      stringValue: "FLAGGED_MERCHANT_CATEGORY",
    },
    {
      label: "Fuel Type",
      value: "FUEL_TYPE",
      stringValue: "FUEL_TYPE",
    },
    {
      label: "Suspicious Fueling",
      value: "SUSPICIOUS_FUELING",
      stringValue: "SUSPICIOUS_FUELING",
    },
    {
      label: "Tank Capacity",
      value: "TANK_CAPACITY",
      stringValue: "TANK_CAPACITY",
    },
  ];
  const fuelTypesNotificationLabels = [
    {
      label: "Diesel",
      value: "FUEL_TYPE_DIESEL",
      stringValue: "FUEL_TYPE_DIESEL",
    },
    {
      label: "Unleaded Plus",
      value: "FUEL_TYPE_UNLEADED_PLUS",
      stringValue: "FUEL_TYPE_UNLEADED_PLUS",
    },
    {
      label: "Unleaded Regular",
      value: "FUEL_TYPE_UNLEADED_REGULAR",
      stringValue: "FUEL_TYPE_UNLEADED_REGULAR",
    },
    {
      label: "Unleaded Super",
      value: "FUEL_TYPE_UNLEADED_SUPER",
      stringValue: "FUEL_TYPE_UNLEADED_SUPER",
    },
    {
      label: "Other",
      value: "FUEL_TYPE_OTHER",
      stringValue: "FUEL_TYPE_OTHER",
    },
  ];
  const balanceLabels = [
    {
      label: "$30.00",
      value: "3000",
      stringValue: "3000",
    },
    {
      label: "$50.00",
      value: "5000",
      stringValue: "5000",
    },
    {
      label: "$100.00",
      value: "10000",
      stringValue: "10000",
    },
    {
      label: "$500.00",
      value: "50000",
      stringValue: "50000",
    },
    {
      label: "$2000.00",
      value: "200000",
      stringValue: "200000",
    },
  ];
  const transactionAboveLabels = [
    {
      label: "$30.00",
      value: "3000",
      stringValue: "3000",
    },
    {
      label: "$50.00",
      value: "5000",
      stringValue: "5000",
    },
    {
      label: "$100.00",
      value: "10000",
      stringValue: "10000",
    },
    {
      label: "$500.00",
      value: "50000",
      stringValue: "50000",
    },
  ];

  const [declineOptions, setdeclineOptions] = useState(new Map());
  const [transactionFlagOptions, setTransactionFlagOptions] = useState(
    new Map(),
  );
  const [
    selectedTransactionApprovedCategory,
    setSelectedTransactionApprovedCategory,
  ] = useState<string[]>([]);
  const [fuelTypeNotificationOptions, setFuelTypeNotificationOptions] =
    useState(new Map());
  const [balanceValue, setBalanceValue] = useState("");
  const [transactionAboveAmount, setTransactionAboveAmount] = useState("");
  const { handleSubmit, ...rest } = useReadNotificationsPage({
    onSubmit(res, err) {
      if (res?.code === "200") {
        const map = new Map<string, boolean>();
        const transactionFlagMap = new Map<string, boolean>();
        const fuelTypeNotificationMap = new Map<string, boolean>();
        let approvedTransactionsArray: string[] = [];
        const userNotifications: NotificationsType[] =
          res?.userNotifications || [];

        const userNotificationsExtended: NotificationsTypeExtended[] = [];
        userNotifications.forEach(
          (element: NotificationsType, index: number) => {
            const newObject: NotificationsTypeExtended = {
              ...element,
              email: false,
              sms: false,
            };
            if (
              element?.communicationMode === ENUM_NOTIFICATION_CHANNEL.EMAIL
            ) {
              newObject.email = true;
              newObject.sms = false;
            }
            if (
              element?.communicationMode ===
              ENUM_NOTIFICATION_CHANNEL.EMAIL_AND_SMS
            ) {
              newObject.email = true;
              newObject.sms = true;
            }
            if (element?.communicationMode === ENUM_NOTIFICATION_CHANNEL.SMS) {
              newObject.email = false;
              newObject.sms = true;
            }
            if (element?.communicationMode === ENUM_NOTIFICATION_CHANNEL.NONE) {
              newObject.email = false;
              newObject.sms = false;
            }
            userNotificationsExtended[index] = newObject;
            if (element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_DECLINED) {
              for (let i = 0; i < element.optionValues.length; i++) {
                map.set(element.optionValues[i], true);
              }
            }
            if (element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_FLAGGED) {
              for (let i = 0; i < element.optionValues.length; i++) {
                transactionFlagMap.set(element.optionValues[i], true);
              }
            }
            if (element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_FUEL_TYPE) {
              for (let i = 0; i < element.optionValues.length; i++) {
                fuelTypeNotificationMap.set(element.optionValues[i], true);
              }
            }
            if (element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED) {
              approvedTransactionsArray = element.optionValues;
            }
            if (element.name === ENUM_NOTIFICATION_NAME.LOW_BALANCE) {
              setBalanceValue(element?.variables || "3000");
            }
            if (
              element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_ABOVE_AMOUNT
            ) {
              setTransactionAboveAmount(element?.variables || "3000");
            }
          },
        );
        setNotifications(userNotificationsExtended);
        setdeclineOptions(map);
        setTransactionFlagOptions(transactionFlagMap);
        setFuelTypeNotificationOptions(fuelTypeNotificationMap);
        setSelectedTransactionApprovedCategory(approvedTransactionsArray);
      }
    },
  });

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <PersonalNotificationsUI
      customerType={customerType}
      notifications={notifications}
      updating={updating}
      setUpdating={setUpdating}
      setNotifications={setNotifications}
      declineOptions={declineOptions}
      setdeclineOptions={setdeclineOptions}
      transactionFlagOptions={transactionFlagOptions}
      setTransactionFlagOptions={setTransactionFlagOptions}
      fuelTypeNotificationOptions={fuelTypeNotificationOptions}
      setFuelTypeNotificationOptions={setFuelTypeNotificationOptions}
      labels={labels}
      balanceLabels={balanceLabels}
      transactionAboveLabels={transactionAboveLabels}
      balanceValue={balanceValue}
      flaggedTransactionLabels={flaggedTransactionLabels}
      fuelTypesNotificationLabels={fuelTypesNotificationLabels}
      setBalanceValue={setBalanceValue}
      transactionAboveAmount={transactionAboveAmount}
      setTransactionAboveAmount={setTransactionAboveAmount}
      selectedTransactionApprovedCategory={selectedTransactionApprovedCategory}
      setSelectedTransactionApprovedCategory={
        setSelectedTransactionApprovedCategory
      }
      childFeatures={childFeatures}
    />
  );
};
export default PersonalNotifications;
