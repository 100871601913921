/** @format */

import { useCreateParentCompanyUser } from "@roadflex/react-hooks";
import { ParentCompanyUserType } from "@roadflex/types";
import { toast } from "react-toastify";
import AddUserUI from "./addUser.ui";

type AddUserProps = {
  profilesAuthorized: ParentCompanyUserType[];
};
const AddUser = ({ profilesAuthorized }: AddUserProps) => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    ...rest
  } = useCreateParentCompanyUser({
    initialValues: {
      id: [],
      email: "",
      phoneNumber: "",
      password: "",
    },
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      resetForm();
    },
  });

  return (
    <AddUserUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        resetForm,
        ...rest,
        profilesAuthorized,
      }}
    />
  );
};
export default AddUser;
