/** @format */

import { useMutation, useQuery } from "@apollo/client";
import { ENUM_ELD_ORG_NAME } from "@prisma/client";
import {
  ELD_CONNECTED,
  FLEETIO_CONNECTED,
  IS_EJ_WARD_CONNECTED,
  REMOVE_EJ_WARD,
  REMOVE_ELD_CONNECTION,
  REMOVE_FLEETIO,
} from "@roadflex/graphql";
import { useGeoTabAuthenticate } from "@roadflex/react-hooks";
import { EldSystem, UserAuthType } from "@roadflex/types";
import getConfig from "next/config";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../loader";
import { ELDSystemsUI } from "./eld-systems.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

interface ELDSystemsProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

export const ELDSystems = ({
  readCurrentUserAuth,
  loading,
}: ELDSystemsProps) => {
  // const eldSystems =
  const [eldSystems, setEldSystems] = useState<EldSystem[]>([
    {
      name: "GeoTab",
      code: ENUM_ELD_ORG_NAME.GEOTAB,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/geotab.svg`,
    },
    {
      name: "Samsara",
      code: ENUM_ELD_ORG_NAME.SAMSARA,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/samsara.svg`,
    },
    {
      name: "Azuga",
      code: ENUM_ELD_ORG_NAME.AZUGA,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/azuga.svg`,
    },
    {
      name: "UTH",
      code: ENUM_ELD_ORG_NAME.UTH,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/utech_blue.svg`,
    },
    {
      name: "FLEETUP",
      code: ENUM_ELD_ORG_NAME.FLEETUP,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/fleetup-logo.svg`,
    },
    {
      name: "VERIZON",
      code: ENUM_ELD_ORG_NAME.VERIZON,
      imageLink: `${NEXT_PUBLIC_CDN_URL}/eld-logos/verizon.svg`,
    },
  ]);
  const [openAddELDSystemModal, setOpenAddELDSystemModal] = useState(false);
  const [openAddFleetioModal, setOpenAddFleetioModal] = useState(false);
  const [openAddEJWardModal, setOpenAddEJWardModal] = useState(false);

  const [selectedEldSystem, setSelectedEldSystem] = useState<EldSystem>(
    eldSystems[0],
  );

  const { data: fleetioConnected } = useQuery<{ isFleetioConnected: boolean }>(
    FLEETIO_CONNECTED,
    {
      fetchPolicy: "no-cache",
    },
  );

  const { data: ejWardConnected } = useQuery<{
    isEjWardConnected: { code: string; message: true };
  }>(IS_EJ_WARD_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  const { data: eldConnected } = useQuery<{
    isEldConnected: {
      isConnected: boolean;
      orgName: ENUM_ELD_ORG_NAME;
    };
  }>(ELD_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  const [disconnectFleetioFn] = useMutation<{
    removeFleetioConnection: {
      message: string;
    };
  }>(REMOVE_FLEETIO, {
    refetchQueries: [FLEETIO_CONNECTED],
  });

  const [disconnectEJWardFn] = useMutation<{
    removeEjWard: {
      code: string;
      message: string;
      type: string;
      success: string;
    };
  }>(REMOVE_EJ_WARD, {
    refetchQueries: [IS_EJ_WARD_CONNECTED],
  });

  const [disconnectEldFn] = useMutation<{
    EldRemove: {
      message: string;
    };
  }>(REMOVE_ELD_CONNECTION, {
    refetchQueries: [ELD_CONNECTED],
  });

  const { resetForm: geoTabAuthResetForm, ...geoTabAuthFormikValues } =
    useGeoTabAuthenticate({
      initialValues: {
        userName: "",
        password: "",
        database: "",
        orgName: selectedEldSystem.code,
        token: "",
        clientId: "",
        acctId: "",
        apiKey: "",
        secret: "",
      },

      onSubmit(res, err) {
        if (err) {
          toast.error(err.message);
          return;
        }
        // console.log(res, err);
        const sessionId = res?.eldAuthenticate?.sessionId || "";
        // console.log("sessionId => ", sessionId);
        if (sessionId) {
          setOpenAddELDSystemModal(false);
          toast.success(
            `${selectedEldSystem.name} account successfully authenticated.`,
          );
          geoTabAuthResetForm();
        } else {
          toast.error("Authentication failed! Please try again.");
        }
      },
    });

  const disconnectFleetio = async () => {
    try {
      await disconnectFleetioFn();
      toast.success("Fleetio disconnected successfully");
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const disconnectEJWard = async () => {
    try {
      const result = await disconnectEJWardFn();
      if (result?.data?.removeEjWard?.success) {
        toast.success(result.data.removeEjWard.message);
      } else {
        toast.error(result?.data?.removeEjWard?.message);
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const disconnectEld = async (orgName: ENUM_ELD_ORG_NAME) => {
    try {
      await disconnectEldFn({
        variables: {
          data: { orgName },
        },
      });
      toast.success(`ELD device disconnected successfully`);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ELDSystemsUI
      {...{
        openAddELDSystemModal,
        setOpenAddELDSystemModal,
        eldSystems,
        selectedEldSystem,
        setSelectedEldSystem,
        geoTabAuthForm: {
          resetForm: geoTabAuthResetForm,
          ...geoTabAuthFormikValues,
        },
        setOpenAddFleetioModal,
        openAddFleetioModal,
        openAddEJWardModal,
        setOpenAddEJWardModal,
        isFleetioConnected: fleetioConnected?.isFleetioConnected || false,
        isEJWardConnected:
          ejWardConnected?.isEjWardConnected?.code === "200" || false,
        disconnectFleetio,
        disconnectEJWard,
        disconnectEld,
        eldConnected: eldConnected?.isEldConnected || null,
        customerType: readCurrentUserAuth.fuelCard.customerType,
        loggedInId: readCurrentUserAuth.loggedInId,
      }}
    />
  );
};
