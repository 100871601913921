/** @format */

import {
  useReadFilterOptions,
  useReadTransactions,
} from "@roadflex/react-hooks";
import {
  CardsFilterOptions,
  DriversFilterOptions,
  TransactionType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { showErrorMessage } from "@roadflex/web-lib";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import TransactionsReceiptRequireUI from "./transactions-require-receipt.ui";

interface TransactionsReceiptRequireProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterConfigType = {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};

const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

const receiptRequiredTransactionInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
};

export const TransactionsReceiptRequire = ({
  readCurrentUserAuth,
  loading,
}: TransactionsReceiptRequireProps) => {
  const [receiptRequiredTransactionData, setReceiptRequiredTransactionData] =
    useState<{
      transactions: TransactionType[];
      totalCount: number;
    }>({
      totalCount: 0,
      transactions: [],
    });
  const [
    receiptRequiredTransactionsLazyParams,
    setReceiptRequiredTransactionsLazyParams,
  ] = useState<DataTablePFSEvent>({
    ...receiptRequiredTransactionInitialLazyParams,
  });

  const [tabOpen, setTabOpen] = useState(1);

  const {
    transactionData: transactions,
    transactionRefetch,
    transactionLoading,
  } = useReadTransactions(
    receiptRequiredTransactionsLazyParams,
    tabOpen === 1 ? false : true,
    true,
    false,
  );
  useEffect(() => {
    transactionRefetch();
  }, [tabOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const { filterOptions } = useReadFilterOptions();
  const tabs = [
    { id: 1, name: "Require Receipt", href: "", current: true },
    { id: 2, name: "Receipt Uploaded", href: "", current: false },
  ];

  const [transactionFilterOptions, setTransactionFilterOptions] = useState<{
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  }>({
    vehiclesFilterOptions: [],
    cardsFilterOptions: [],
    driversFilterOptions: [],
  });

  const onTabChange = (value: number) => {
    if (value === 1) {
      setReceiptRequiredTransactionsLazyParams({
        ...receiptRequiredTransactionInitialLazyParams,
        receiptUploaded: false,
      });
    } else {
      setReceiptRequiredTransactionsLazyParams({
        ...receiptRequiredTransactionInitialLazyParams,
        receiptUploaded: true,
      });
    }
    setTabOpen(value);
  };

  useEffect(() => {
    transactionRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptRequiredTransactionsLazyParams]);

  useEffect(() => {
    if (
      !transactionLoading &&
      transactions &&
      transactions?.readTransactionList?.code !== "200"
    ) {
      showErrorMessage(
        transactions?.readTransactionList?.message,
        transactions?.readTransactionList?.code,
      );
    }
    if (transactions && transactions?.readTransactionList.code === "200") {
      setReceiptRequiredTransactionData({
        totalCount: transactions?.readTransactionList.totalCount,
        transactions: transactions?.readTransactionList.transactions,
      });
    }
  }, [transactions, transactionLoading]);

  useEffect(() => {
    transactionRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptRequiredTransactionsLazyParams]);

  useEffect(() => {
    if (filterOptions?.transactionFilterOptions) {
      setTransactionFilterOptions({
        cardsFilterOptions: filterOptions.transactionFilterOptions.cards.map(
          (value) => {
            return {
              lastFour: value.lastFour,
            };
          },
        ),
        vehiclesFilterOptions:
          filterOptions.transactionFilterOptions.vehicles.map((value) => {
            return {
              vehicleName: value.fleetName,
            };
          }),
        driversFilterOptions:
          filterOptions.transactionFilterOptions.drivers.map((value) => {
            return {
              driverName: value.name,
            };
          }),
      });
    }
  }, [filterOptions?.transactionFilterOptions]);

  if (loading) {
    return null;
  }

  return (
    <TransactionsReceiptRequireUI
      {...{
        receiptRequiredTransactionData,
        transactionLoading,
        receiptRequiredTransactionsLazyParams,
        setReceiptRequiredTransactionsLazyParams,
        transactionFilterOptions,
        tabs,
        tabOpen,
        setTabOpen: onTabChange,
        readCurrentUserAuth,
      }}
    />
  );
};
