/* eslint-disable dot-notation */
/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { appAccountTagValidationSchema } from "@roadflex/constants";
import {
  APPLY_CARD_TAG,
  ASSIGN_ACCOUNT_TAG,
  CREATE_ACCOUNT_TAG,
  DELETE_ACCOUNT_TAG,
  READ_ACCOUNT_TAGS,
  UPDATE_ACCOUNT_TAG,
} from "@roadflex/graphql";
import { AccountTagInput, AccountTagType, ApplyCardTag } from "@roadflex/types";
import { useFormik } from "formik";

type UseCreateAccountTagProps = {
  initialValues: AccountTagType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type UseUpdateAccountTagProps = {
  initialValues: AccountTagType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type UseAssignAccountTagProps = {
  initialValues: {
    id: string;
    tagType: ENUM_ACCOUNT_TAG_TYPE;
    accountTagId: string;
    isUnassigned: boolean;
  };
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useApplyAccountTagesToCardsProps = {
  initialValues: ApplyCardTag;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type UseDeleteAccountTagProps = {
  initialValues: AccountTagInput;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type UseReadAccountTagProps = {
  tagType?: ENUM_ACCOUNT_TAG_TYPE;
};

export const useCreateAccountTag = ({
  onSubmit,
  initialValues,
}: UseCreateAccountTagProps) => {
  const [createAccountTag] = useMutation<{
    createAccountTag: {
      code: string;
      message: string;
    };
  }>(CREATE_ACCOUNT_TAG);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appAccountTagValidationSchema,
    onSubmit: async (value) => {
      const { ...rest } = value;
      const data = {
        ...rest,
      };
      try {
        const res = await createAccountTag({
          variables: { data },
        });
        if (res?.data?.createAccountTag) {
          onSubmit(res?.data?.createAccountTag || null, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useReadAccountTag = ({ tagType }: UseReadAccountTagProps) => {
  const { data, refetch, ...rest } = useQuery(READ_ACCOUNT_TAGS, {
    fetchPolicy: "no-cache",
    variables: { data: { tagType } },
  });
  return { data, refetch, ...rest };
};

export const useUpdateAccountTag = ({
  onSubmit,
  initialValues,
}: UseUpdateAccountTagProps) => {
  const [updateAccountTagFn] = useMutation<{
    updateAccountTag: {
      code: string;
      message: string;
    };
  }>(UPDATE_ACCOUNT_TAG);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appAccountTagValidationSchema,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await updateAccountTagFn({
          variables: { data },
        });
        if (res?.data?.updateAccountTag) {
          onSubmit(res?.data?.updateAccountTag || null, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useApplyCardTagsToCards = ({
  onSubmit,
  initialValues,
}: useApplyAccountTagesToCardsProps) => {
  const [applyCardTagFn] = useMutation<{
    applyCardTag: {
      message: string;
      code: string;
    };
  }>(APPLY_CARD_TAG, {});

  const { ...restFormikValues } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await applyCardTagFn({
          variables: {
            data: {
              ...value,
            },
          },
        });
        if (res?.data?.applyCardTag) {
          onSubmit(res?.data?.applyCardTag, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    ...restFormikValues,
  };
};

export const useDeleteAccountTag = ({
  onSubmit,
  initialValues,
}: UseDeleteAccountTagProps) => {
  const [deleteAccountTagFn] = useMutation<{
    deleteAccountTag: {
      code: string;
      message: string;
    };
  }>(DELETE_ACCOUNT_TAG, {
    refetchQueries: [READ_ACCOUNT_TAGS],
  });

  const {
    handleSubmit: archiveAccountTagHandleSubmit,
    values: archiveAccountTagValues,
    isSubmitting: archiveAccountTagSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data: AccountTagInput = {
        id: value.id,
      };
      try {
        const res = await deleteAccountTagFn({
          variables: { data },
        });
        if (res?.data?.deleteAccountTag) {
          onSubmit(res?.data?.deleteAccountTag, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    archiveAccountTagHandleSubmit,
    archiveAccountTagValues,
    archiveAccountTagSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useAssignAccountTag = ({
  onSubmit,
  initialValues,
}: UseAssignAccountTagProps) => {
  const [assignAccountTagFn] = useMutation<{
    assignAccountTag: {
      code: string;
      message: string;
    };
  }>(ASSIGN_ACCOUNT_TAG);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await assignAccountTagFn({
          variables: { data },
        });
        if (res?.data?.assignAccountTag) {
          onSubmit(res?.data?.assignAccountTag || null, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
