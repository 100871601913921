/** @format */

import { LockClosedIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { ENUM_CUSTOMER_TYPE, ENUM_JOB_TITLE } from "@prisma/client";
import {
  PersonalInformationType,
  PersonalInformationValues,
} from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import {
  dateOfBirthFormatter,
  phoneFormatter,
  ssnFormatter,
} from "@roadflex/web-lib";
import { FormikProps } from "formik";
import { Button, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";
import { Persona } from "../persona";

interface PersonalInformationUIProps
  extends FormikProps<PersonalInformationValues> {
  isVerifyEnabled: boolean;
  setVerifyEnabled: (isVerifyEnabled: boolean) => void;
  isSubmittingOnboardStatus: boolean;
  id: string;
  email: string;
  personaInquiryId: string;
  personaInquirySessionToken: string;
  handleStorePersonaInquiryId: (inquiryId: string) => void;
  customerType: string;
  personalInfo: PersonalInformationType;
}

const PersonalInformationUI = ({
  isVerifyEnabled,
  setVerifyEnabled,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  isSubmittingOnboardStatus,
  id,
  email,
  personaInquiryId,
  personaInquirySessionToken,
  handleStorePersonaInquiryId,
  customerType,
  personalInfo,
  dirty,
  ...rest
}: PersonalInformationUIProps) => (
  <form onSubmit={handleSubmit} className="flex flex-col w-full ">
    <div className="mb-1 text-xl font-semibold text-center text-white md:mb-4 md:text-4xl">
      Tell us about yourself (1 of 2)
    </div>
    <div className="mb-4 text-xs text-center text-white md:mb-8 md:text-sm">
      Due to regulatory guidelines, we are required to collect information on
      anyone who has significant ownership (&#62;25&#37;) of your business.
    </div>
    <div className="relative w-full">
      <TextInput
        label="Legal First (and Middle) Name"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.firstName && Boolean(errors.firstName)}
        errorMessage={errors.firstName}
        touched={touched.firstName}
        variant="responsive"
        required
        color={"text-white"}
      ></TextInput>
    </div>
    <div className="relative w-full mt-4">
      <TextInput
        label="Legal Last Name"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.lastName && Boolean(errors.lastName)}
        errorMessage={errors.lastName}
        touched={touched.lastName}
        variant="responsive"
        required
        color={"text-white"}
      ></TextInput>
    </div>
    <div className="relative w-full mt-4">
      <TextInput
        label="Email Address"
        name="email"
        value={email}
        variant="responsive"
        disabled
        required
        color={"text-white"}
      ></TextInput>
    </div>
    <div className="relative w-full mt-4">
      <TextInput
        label="Mobile"
        type="tel"
        name="phoneNumber"
        value={values.phoneNumber}
        onChange={(event) =>
          setFieldValue("phoneNumber", phoneFormatter(event?.target?.value))
        }
        onBlur={handleBlur}
        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
        errorMessage={errors.phoneNumber}
        touched={touched.phoneNumber}
        variant="responsive"
        required
        disabled
        color={"text-white"}
      ></TextInput>
    </div>
    <div className="relative w-full mt-4">
      <TextInput
        label={
          <div className="flex flex-row mb-1 text-white md:mb-2">
            <div className="flex items-center ml-2 text-center whitespace-nowrap">
              Date Of Birth*
            </div>
          </div>
        }
        name="dateOfBirth"
        value={values.dateOfBirth}
        type="tel"
        onChange={(event) =>
          setFieldValue(
            "dateOfBirth",
            dateOfBirthFormatter(event?.target?.value),
          )
        }
        onBlur={handleBlur}
        autoComplete="dateOfBirth"
        error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
        errorMessage={errors.dateOfBirth}
        touched={touched.dateOfBirth}
        variant="responsive"
        placeholder="MM/DD/YYYY"
        required
      ></TextInput>
    </div>
    <div className="relative w-full mt-4">
      <Select
        label="Executive Job Title"
        value={values.executiveJobTitle}
        name="executiveJobTitle"
        options={[
          {
            label: "-- Select an option --",
            value: "",
            disabled: true,
          },
        ].concat(
          Object.keys(ENUM_JOB_TITLE).map((key) => {
            return {
              label: humanizeEnum(key),
              value: key,
              disabled: false,
            };
          }),
        )}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => setFieldValue("executiveJobTitle", e.value)}
        onBlur={() => setFieldTouched("executiveJobTitle", true)}
        placeholder="-- Select an option --"
        touched={touched.executiveJobTitle}
        error={touched.executiveJobTitle && Boolean(errors.executiveJobTitle)}
        errorMessage={errors.executiveJobTitle}
        required
        color="text-white"
        variant="responsive"
      ></Select>
    </div>
    <div className="relative w-full mt-4">
      <TextInput
        label={
          <div className="mb-1 text-white md:mb-2">
            <div className="flex flex-row ">
              <div className="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.45053 14.1161H20.8048C21.1895 14.1161 21.5584 14.2689 21.8304 14.5409C22.1025 14.813 22.2553 15.1819 22.2553 15.5666V26.5317C22.2553 27.1189 22.1395 27.7005 21.9146 28.243C21.6897 28.7855 21.36 29.2784 20.9444 29.6934C20.5289 30.1084 20.0356 30.4374 19.4928 30.6616C18.95 30.8858 18.3683 31.0008 17.781 31H5.46834C4.88155 31 4.3005 30.8844 3.75838 30.6599C3.21626 30.4353 2.72367 30.1062 2.30875 29.6913C1.89382 29.2763 1.56469 28.7837 1.34013 28.2416C1.11558 27.6995 1 27.1184 1 26.5317V15.5666C1 15.1819 1.15282 14.813 1.42485 14.5409C1.69688 14.2689 2.06582 14.1161 2.45053 14.1161V14.1161Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M3.91504 13.8291V8.71768C3.91504 6.67082 4.72806 4.7078 6.1754 3.26046C7.62275 1.81311 9.58586 1 11.6327 1C13.6796 1 15.6426 1.81311 17.0899 3.26046C18.5373 4.7078 19.3504 6.67082 19.3504 8.71768V13.8351"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14.48 19.9973C14.4805 19.4846 14.3428 18.9813 14.0813 18.5403C13.8198 18.0993 13.4442 17.737 12.9941 17.4914C12.5441 17.2459 12.0361 17.1262 11.5238 17.1451C11.0114 17.1639 10.5136 17.3205 10.0828 17.5984C9.65196 17.8763 9.304 18.2652 9.07558 18.7242C8.84716 19.1832 8.74664 19.6953 8.78474 20.2066C8.82284 20.7179 8.99809 21.2094 9.29202 21.6295C9.58595 22.0496 9.98773 22.3827 10.455 22.5936V26.7948C10.4555 27.1059 10.5793 27.4041 10.7993 27.624C11.0192 27.844 11.3175 27.9678 11.6285 27.9683V27.9683C11.9398 27.9683 12.2382 27.8447 12.4582 27.6246C12.6783 27.4045 12.8019 27.1061 12.8019 26.7948V22.5936C13.302 22.3684 13.7263 22.0036 14.024 21.543C14.3217 21.0825 14.48 20.5457 14.48 19.9973V19.9973Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="flex items-center ml-2 text-center whitespace-nowrap">
                Executive's Social Security Number*
              </div>
            </div>
          </div>
        }
        name="ssn"
        value={values.ssn}
        type="tel"
        onChange={(event) =>
          setFieldValue("ssn", ssnFormatter(event?.target?.value))
        }
        onBlur={handleBlur}
        autoComplete="ssn"
        error={touched.ssn && Boolean(errors.ssn)}
        errorMessage={errors.ssn}
        touched={touched.ssn}
        variant="responsive"
        placeholder="###-##-####"
        required
      ></TextInput>
    </div>
    <div className="mt-2 text-sm text-white">
      To verify your identity, we'll need to know your full Social Security
      Number. We do not conduct credit checks. Your credit score won't be
      affected.
    </div>

    <div className="flex items-center justify-center px-4 py-1 mt-4 text-sm text-center max-w-[500px] w-full self-center bg-green-100 rounded-full">
      {" "}
      <CheckCircleIcon className="w-4 h-4 mr-1 text-green-900"></CheckCircleIcon>
      Applying won't affect your personal credit score
    </div>
    {customerType !== ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE &&
      customerType !== ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE && (
        <div className="mt-4 space-y-4" role="group">
          <div className="flex items-center">
            <input
              id="isVerifyEnabled"
              name="isVerifyEnabled"
              type="checkbox"
              disabled={Boolean(
                personalInfo?.fleetLicense && personalInfo?.fleetLicenseBack,
              )}
              checked={
                isVerifyEnabled ||
                Boolean(
                  personalInfo?.fleetLicense && personalInfo?.fleetLicenseBack,
                )
              }
              onChange={(e) => {
                setVerifyEnabled(e.target.checked);
              }}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
            <label
              htmlFor="isVerifyEnabled"
              className="block ml-3 text-xs text-white md:text-sm"
            >
              We will be collecting your business information. You confirm that
              you have significant control and management of your business.
            </label>
          </div>
        </div>
      )}

    <div className="mt-6">
      {personalInfo?.fleetLicense && personalInfo?.fleetLicenseBack ? (
        <Button
          variant={ButtonVariant.Orange}
          className="w-full"
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting || isSubmittingOnboardStatus}
          loading={isSubmitting || isSubmittingOnboardStatus}
        >
          <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
          {dirty ? `Save` : `Next`}
        </Button>
      ) : (
        <Persona
          {...{
            isVerifyEnabled,
            handleBlur,
            values,
            setFieldValue,
            isSubmitting,
            isSubmittingOnboardStatus,
            handleSubmit,
            errors,
            touched,
            handleChange,
            setFieldTouched,
            dirty,
            id,
            personaInquiryId,
            personaInquirySessionToken,
            handleStorePersonaInquiryId,
            customerType,
            ...rest,
          }}
        />
      )}
    </div>
  </form>
);

export default PersonalInformationUI;
