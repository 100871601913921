/** @format */
import { useMutation } from "@apollo/client";
import { onboardAddressSchema } from "@roadflex/constants";
import { CREATE_AND_UPDATE_HOME_ADDRESS } from "@roadflex/graphql";
import { AddressValues } from "@roadflex/types";
import { useFormik } from "formik";

type UseAddressProps = {
  onSubmit: (val: { success: boolean } | null, err: Error | null) => void;
  initialValues: AddressValues;
};

export const useAddressCreate = ({
  onSubmit,
  initialValues,
}: UseAddressProps) => {
  const [saveAddress] = useMutation(CREATE_AND_UPDATE_HOME_ADDRESS);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: onboardAddressSchema,
    onSubmit: async (value) => {
      const list = [{ ...value.homeAddress }];

      const data = {
        address: value.homeAddress,
        id: value.id,
      };
      try {
        const res = await saveAddress({
          variables: {
            data,
          },
        });
        onSubmit(res?.data?.createAndUpdateHomeAddress, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
