/** @format */

import { useMutation } from "@apollo/client";
import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { REMOVE_TRANSACTION_RECEIPT } from "@roadflex/graphql";
import {
  useReadAccountTag,
  useReadTransactionDetail,
  useReadUserDetails,
  useUpdateBlockedMerchants,
  useUpdateTransactionMemo,
} from "@roadflex/react-hooks";
import { AccountTagType, UserAuthType } from "@roadflex/types";
import { uploadTransactionReceipt } from "@roadflex/web-api";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../../loader";
import Redirect from "../../../redirect/redirect";
import { TransactionDetailsPageUI } from "./transaction-details.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;
interface Upload {
  file: File | null;
  progress: number;
  isLoading: boolean;
  hasError: boolean;
  url?: string;
  fromServer: boolean;
  createdAt: Date;
  fileId: string;
}
interface TransactionDetailsProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  transactionId: string;
  refetch: () => void;
}
export const TransactionDetailsPage = ({
  readCurrentUserAuth,
  loading,
  transactionId,
  refetch,
}: TransactionDetailsProps) => {
  const [editMemo, setEditMemo] = useState(false);
  const [blockMerchantsArray, setBlockMerchantsArray] = useState<string[]>([]);
  const [transactionTags, setTransactionTags] = useState<AccountTagType[]>([]);
  const [cardTags, setCardTags] = useState<AccountTagType[]>([]);
  const [driverTags, setDriverTags] = useState<AccountTagType[]>([]);
  const [vehicleTags, setVehicleTags] = useState<AccountTagType[]>([]);
  const router = useRouter();

  if (!transactionId) {
    router.push(`/transactions`);
  }

  const {
    transaction,
    loading: transactionDetailLoading,
    refetch: transactionDetailRefetch,
  } = useReadTransactionDetail(transactionId);
  const {
    blockedMerchantsValues,
    handleBlockMerchantsSubmit,
    isBlockMerchantsUpdating,
  } = useUpdateBlockedMerchants({
    initialValues: {
      blockedMerchants: "",
    },
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      toast.success(res?.data?.updateBlockedMerchants?.message);
    },
  });

  const { data: accountTagsData, loading: accountTagsDataLoading } =
    useReadAccountTag({});

  const {
    updateTransactionMemoValues,
    handleUpdateTransactionMemoSubmit,
    handleUpdateTransactionMemoChange,
    updateTransactionMemoSubmitting,
    setFieldValue,
  } = useUpdateTransactionMemo({
    initialValues: {
      memo: transaction?.memo,
      transactionId: transactionId,
      transactionTagId: transaction?.transactionTagId,
      cardTagId: transaction?.cardTagId,
      driverTagId: transaction?.driverTagId,
      vehicleTagId: transaction?.vehicleTagId,
      type: "Transaction",
    },
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      toast.success(res?.data?.updateTransactionMemo?.message);
      setEditMemo(false);
      transactionDetailRefetch();
    },
  });

  const [removeTransactionReceiptFn] = useMutation(REMOVE_TRANSACTION_RECEIPT);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const fetchedUploads: Upload[] = transaction.receipts.map(
          (receipt: {
            fileName: string;
            folderName: string;
            fileExtension: string;
            createdAt: Date;
          }) => ({
            file: null, // Assuming fileData.content is base64 or blob data
            progress: 100,
            isLoading: false,
            hasError: false,
            fromServer: true,
            createdAt: receipt.createdAt,
            fileId: receipt.fileName,
            url: `${NEXT_PUBLIC_API_URL}/api/files/${receipt.folderName}/${receipt.fileName}.${receipt.fileExtension}`, // Assuming the URL to download the file is provided in the response
          }),
        );

        setUploads(fetchedUploads);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    if (transaction?.receipts) {
      fetchFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(transaction?.receipts)]);
  const handleReceiptFiles = (files: FileList | null) => {
    if (!files) return;
    const currentUploadCount = uploads.length;
    const newFiles = Array.from(files);

    if (currentUploadCount + newFiles.length > 2) {
      toast.error("You can only upload up to 2 files.");
      return;
    }

    const newUploads: Upload[] = [];
    Array.from(files).forEach((file) => {
      newUploads.push({
        file,
        progress: 0,
        isLoading: true,
        hasError: false,
        fromServer: false,
        createdAt: new Date(),
        fileId: "",
      });
    });

    setUploads((prevUploads) => [...prevUploads, ...newUploads]);
  };

  useEffect(() => {
    const uploadFile = async (upload: Upload, index: number) => {
      const interval = setInterval(() => {
        setUploads((prevUploads) => {
          const newProgress = prevUploads.map((item, idx) => {
            if (idx === index && item.progress < 90) {
              return {
                ...item,
                progress: item.progress + Math.floor(Math.random() * 10) + 1,
              };
            }
            return item;
          });
          return newProgress;
        });
      }, 200);

      try {
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate file upload delay
        // Simulate file upload success
        await uploadTransactionReceipt(
          upload.file as File,
          "transaction_receipt",
          transactionId,
          "Transaction",
        );
        toast.success(`File ${upload.file?.name} uploaded successfully`);
        setUploads((prevUploads) =>
          prevUploads.map((item, idx) =>
            idx === index
              ? {
                  ...item,
                  progress: 100,
                  isLoading: false,
                  url: URL.createObjectURL(item.file as File),
                }
              : item,
          ),
        );
      } catch (error) {
        toast.error(`File ${upload.file?.name} upload failed`);
        setUploads((prevUploads) =>
          prevUploads.map((item, idx) =>
            idx === index
              ? { ...item, progress: 0, isLoading: false, hasError: true }
              : item,
          ),
        );
      }
      clearInterval(interval);
    };

    const processUploads = async () => {
      for (let i = 0; i < uploads.length; i++) {
        if (uploads[i].isLoading) {
          await uploadFile(uploads[i], i);
        }
      }
      transactionDetailRefetch();
      setIsUploading(false);
    };

    if (uploads.length > 0 && !isUploading) {
      setIsUploading(true);
      processUploads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads, isUploading]);

  const handleRemoveReceiptFile = async (fileId: string) => {
    // eslint-disable-next-line no-console
    console.log("here");
    await removeTransactionReceiptFn({
      variables: {
        data: {
          fileId: fileId,
        },
      },
    });
    transactionDetailRefetch();
  };

  const { readUserDetailsData } = useReadUserDetails("", "", "", "", [
    "CardholderMembership",
  ]);

  const setInitialMemoValues = () => {
    setFieldValue("memo", transaction?.memo || "");
    setFieldValue("transactionTagId", transaction?.transactionTagId || "");
    setFieldValue("cardTagId", transaction?.cardTagId || "");
    setFieldValue("driverTagId", transaction?.driverTagId || "");
    setFieldValue("vehicleTagId", transaction?.vehicleTagId || "");
  };

  useEffect(() => {
    if (
      readUserDetailsData?.readUserDetails?.details?.cardHoldersMembership
        ?.blockMerchant
    ) {
      setBlockMerchantsArray(
        readUserDetailsData?.readUserDetails?.details?.cardHoldersMembership?.blockMerchant.split(
          ",",
        ),
      );
    }
    setInitialMemoValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    readUserDetailsData?.readUserDetails?.details?.cardHoldersMembership
      ?.blockMerchant,
    transaction,
  ]);

  useEffect(() => {
    if (accountTagsData?.readAccountTags?.accountTagList) {
      const accountTagList =
        accountTagsData.readAccountTags.accountTagList || {};

      const setTags = (
        tagType: ENUM_ACCOUNT_TAG_TYPE,
        tags: AccountTagType[],
      ) => {
        tags.unshift({
          title: "-",
          description: "unassign",
          tagType,
          tagId: "",
        });
        return tags;
      };

      setTransactionTags(
        setTags(ENUM_ACCOUNT_TAG_TYPE.TRANSACTION, [
          ...accountTagList.TRANSACTION.active,
        ]),
      );
      setCardTags(
        setTags(ENUM_ACCOUNT_TAG_TYPE.CARD, [...accountTagList.CARD.active]),
      );
      setDriverTags(
        setTags(ENUM_ACCOUNT_TAG_TYPE.DRIVER, [
          ...accountTagList.DRIVER.active,
        ]),
      );
      setVehicleTags(
        setTags(ENUM_ACCOUNT_TAG_TYPE.VEHICLE, [
          ...accountTagList.VEHICLE.active,
        ]),
      );

      setInitialMemoValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTagsData]);

  if (transactionDetailLoading || accountTagsDataLoading) {
    return <Loader />;
  }

  let transactionDetailsObj = transaction;
  if (transactionDetailsObj) {
    if (transaction.transactionStatus === "CAPTURE") {
      const newTransactionObj = {
        ...transaction,
        date: new Date(transaction?.authorizationDate),
        driver: transaction?.driverName || "-",
        vehicle: transaction?.vehicleName || "-",
        vehicleVIN: transaction?.vehicleVIN || "",
        status: "Approved",
        lastFour: transaction?.card?.lastFour || "-",
      };
      transactionDetailsObj = newTransactionObj;
    }
  } else {
    toast.error("Transaction not found.");
    return <Redirect path="/transactions"></Redirect>;
  }

  const updateList = () => {
    blockedMerchantsValues.blockedMerchants = blockMerchantsArray.join(",");
    handleBlockMerchantsSubmit();
  };

  return (
    <TransactionDetailsPageUI
      {...{
        transactionDetailsObj,
        isBlockMerchantsUpdating,
        blockMerchantsArray,
        setBlockMerchantsArray,
        updateList,
        updateTransactionMemoValues,
        handleUpdateTransactionMemoSubmit,
        updateTransactionMemoSubmitting,
        handleUpdateTransactionMemoChange,
        editMemo,
        setEditMemo,
        setFieldValue,
        transactionTags,
        cardTags,
        driverTags,
        vehicleTags,
        accountTagsDataLoading,
        setInitialMemoValues,
        handleRemoveReceiptFile,
        handleReceiptFiles,
        uploads,
      }}
    />
  );
};
