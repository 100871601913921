/** @format */

import { ENUM_FLEET_STATUS, ENUM_ODOMETER } from "@prisma/client";
import { useReadVehicles, useUpdateVehicle } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { VehiclesEditUI } from "./vehicle.ui";

interface VehiclesProps {
  readCurrentUserAuth: UserAuthType;
  vehicleId: string;
}
export const VehiclesEdit = ({
  readCurrentUserAuth,
  vehicleId,
}: VehiclesProps) => {
  const tabs = [{ id: 1, name: "Vehicle", current: true }];
  const [update, setUpdate] = useState(false);
  const { getVehiclesData, getVehiclesRefetch, getVehiclesLoading } =
    useReadVehicles([vehicleId]);
  const [isLicensePlateSaved, setIsLicensePlateSaved] = useState(false);
  const [isArchived, setArchived] = useState(false);
  const router = useRouter();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    setValues,
    resetForm,
    ...rest
  } = useUpdateVehicle({
    initialValues: {
      id: getVehiclesData?.readVehicles?.vehicles[0]?.id || "",
      fleetName: getVehiclesData?.readVehicles?.vehicles[0]?.fleetName || "",
      vin: getVehiclesData?.readVehicles?.vehicles[0]?.vin || "",
      make: getVehiclesData?.readVehicles?.vehicles[0]?.make || "",
      model: getVehiclesData?.readVehicles?.vehicles[0]?.model || "",
      year: getVehiclesData?.readVehicles?.vehicles[0]?.year || "",
      licensePlate:
        getVehiclesData?.readVehicles?.vehicles[0]?.fleetLicensePlate || "",
      provisionState:
        getVehiclesData?.readVehicles?.vehicles[0]?.provisionState || "",
      fuelType:
        getVehiclesData?.readVehicles?.vehicles[0]?.fuelType || undefined,
      tankCapacity: getVehiclesData?.readVehicles?.vehicles[0]?.tankCapacity,
      odometer:
        getVehiclesData?.readVehicles?.vehicles[0]?.odometer ||
        ENUM_ODOMETER.MILES,
    },
    onSubmit(res, err) {
      setUpdate(false);
      resetForm();
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      router.push("/vehicles");
    },
  });

  useEffect(() => {
    if (getVehiclesData?.readVehicles?.vehicles[0]) {
      if (getVehiclesData?.readVehicles?.vehicles[0]?.fleetLicensePlate) {
        setIsLicensePlateSaved(true);
      }
      setValues({
        fleetName: getVehiclesData?.readVehicles?.vehicles[0]?.fleetName,
        vin: getVehiclesData?.readVehicles?.vehicles[0]?.vin,
        make: getVehiclesData?.readVehicles?.vehicles[0]?.make || "",
        model: getVehiclesData?.readVehicles?.vehicles[0]?.model || "",
        year: getVehiclesData?.readVehicles?.vehicles[0]?.year || "",
        licensePlate:
          getVehiclesData?.readVehicles?.vehicles[0]?.fleetLicensePlate,
        provisionState:
          getVehiclesData?.readVehicles?.vehicles[0]?.provisionState,
        odometer: getVehiclesData?.readVehicles?.vehicles[0]?.odometer,
        fuelType:
          getVehiclesData?.readVehicles?.vehicles[0]?.fuelType || undefined,
        tankCapacity: getVehiclesData?.readVehicles?.vehicles[0]?.tankCapacity,
        id: getVehiclesData?.readVehicles?.vehicles[0]?.id,
      });
      setArchived(
        getVehiclesData?.readVehicles?.vehicles[0].status ===
          ENUM_FLEET_STATUS.DELETED || false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVehiclesData?.readVehicles?.vehicles]);
  return (
    <VehiclesEditUI
      {...{
        tabs,
        vehicleId,
        isArchived,
        isLicensePlateSaved,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        isSubmitting,
        resetForm,
        errors,
        touched,
        setValues,
        update,
        setUpdate,
        ...rest,
      }}
    />
  );
};
