/** @format */

import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { TIME_FORMATS } from "@roadflex/constants";
import { AdminTablesLazyParams, UserDetailsForAdmin } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import classNames from "classnames";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import {
  ColumnFilterElementTemplateOptions,
  ColumnFilterModelOptions,
} from "primereact/column";
import { DataTablePFSEvent } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Select } from "../dropdowns";

export const emailBodyTemplate = (rowData: { email: string }) => {
  return (
    <>
      <span className="text-sm whitespace-nowrap">{rowData?.email}</span>
      <span className="ml-1 cursor-pointer">
        <CopyToClipboard
          text={rowData?.email}
          onCopy={() => {
            toast.success("Email Copied");
          }}
        >
          <i className="pi pi-copy" />
        </CopyToClipboard>
      </span>
    </>
  );
};

export const dateFilterTemplate = (options: {
  value: Date | Date[] | undefined;
  filterCallback: (value: Date | Date[] | undefined, index: number) => void;
  index: number;
}) => {
  return (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat={TIME_FORMATS.h}
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
      className="custom-calendar"
      panelClassName="custom-calendar-panel"
    />
  );
};

interface CustomDateFilterProps {
  options: {
    value: string | Date | null;
    filterModel: ColumnFilterModelOptions;
    filterCallback: (
      value: { date: Date | null; filterType: FilterMatchMode },
      index: number,
    ) => void;
    index: number;
  };
}

export const CustomDateFilter: React.FC<CustomDateFilterProps> = ({
  options,
}) => {
  const filterMode =
    // eslint-disable-next-line dot-notation
    (options.filterModel["matchMode"] as unknown as FilterMatchMode) ||
    FilterMatchMode.DATE_IS;
  // eslint-disable-next-line dot-notation
  const filterModeValue = options.filterModel["value"]
    ? // eslint-disable-next-line dot-notation
      new Date(options.filterModel["value"] as unknown as Date)
    : null;
  const [filterType, setFilterType] = useState<FilterMatchMode>(filterMode);
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    filterModeValue,
  );

  const filterOptions = [
    { label: "Date is", value: "dateIs" },
    // { label: "Date is not", value: "dateIsNot" },
    { label: "Date is before", value: "dateBefore" },
    { label: "Date is after", value: "dateAfter" },
  ];

  const handleFilterTypeChange = (e: { value: FilterMatchMode }) => {
    setFilterType(e.value);
    options.filterCallback(
      {
        date: selectedDate,
        filterType: e.value,
      },
      options.index,
    );
  };

  const handleDateChange = (e: CalendarChangeParams) => {
    const date = e.value ? new Date(e.value as Date) : null;
    setSelectedDate(date);
    options.filterCallback(
      {
        date,
        filterType,
      },
      options.index,
    );
  };
  const currentDate = new Date();
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 3,
    1,
  );
  return (
    <div className="flex flex-col gap-4">
      <Select
        value={filterType}
        options={filterOptions}
        onChange={handleFilterTypeChange}
        placeholder="Select a filter"
      ></Select>
      <Calendar
        value={selectedDate ?? undefined}
        onChange={handleDateChange}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
        className="custom-calendar custom-calendar-input"
        panelClassName="custom-calendar-panel"
        minDate={minDate}
      />
    </div>
  );
};

export const nameFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
) => {
  return (
    <input
      value={options.value}
      onChange={(e) => {
        options?.filterCallback(e.target.value);
      }}
      style={{
        fontFamily: "inherit",
      }}
      placeholder=""
      className="w-full border-[#893f2b] border-2 p-2 rounded-md shadow-none ring-0 outline-0"
    />
  );
};

export const dateTemplate = (rowData: {
  highRisk: string;
  lastActionDate: string;
}) => {
  return (
    <span
      className={classNames(
        rowData.highRisk ? "text-red-700" : "text-brown-500",
        "text-xs  whitespace-nowrap",
      )}
    >
      <time>{convertISOStringToDate(rowData.lastActionDate)}</time>
    </span>
  );
};

export const header = (refreshCallback: () => void) => {
  return (
    <div className="flex justify-end">
      <Button
        icon="pi pi-sync"
        className="p-button-outlined p-button-sm p-button-secondary"
        onClick={() => refreshCallback()}
      />
    </div>
  );
};

export const updateAndSetLazyParams = (
  event: DataTablePFSEvent,
  lazyParams: AdminTablesLazyParams,
  setLazyParams: (params: AdminTablesLazyParams) => void,
) => {
  const finalLazyParams: AdminTablesLazyParams = {
    ...lazyParams,
    ...event,
  };
  setLazyParams(finalLazyParams);
};

export const cardHolderStatusBodyTemplate = (rowData: UserDetailsForAdmin) => {
  return (
    <div className="flex items-center">
      {rowData?.cardHoldersMembershipStatusString === "ACTIVE" ? (
        <span className="inline-flex border border-solid border-green-800 items-center px-2.5 py-0.5 mr-2 rounded-full text-sm bg-green-100 text-green-800">
          ACTIVE
        </span>
      ) : rowData?.cardHoldersMembershipStatusString === "INACTIVE" ? (
        <span className="inline-flex border border-solid border-red-800 items-center px-2.5 py-0.5 mr-2 rounded-full text-sm bg-red-100 text-red-800">
          INACTIVE
        </span>
      ) : rowData?.cardHoldersMembershipStatusString === "BLOCKED" ? (
        <span className="inline-flex border border-solid border-gray-800 items-center px-2.5 py-0.5 rounded-full text-sm bg-gray-200 text-gray-800">
          BLOCKED
        </span>
      ) : (
        "-"
      )}
      {rowData.highRisk && (
        <>
          <Tooltip
            target=".high-risk-tooltip"
            position="top"
            className="red-tooltip"
            content="High Risk User"
          ></Tooltip>

          <div className="flex items-center ml-3 cursor-pointer">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-700 high-risk-tooltip"
              aria-hidden="true"
            />
          </div>
        </>
      )}
    </div>
  );
};

export const lastActionDateTemplate = (rowData: UserDetailsForAdmin) => {
  return (
    <span className="text-brown-500 !py-2 text-sm">
      <time>{convertISOStringToDate(rowData?.lastActionDate || "")}</time>
    </span>
  );
};
