/** @format */

import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

export const NoAccessFeatureUI = () => {
  return (
    <div className="flex items-center justify-center w-full h-full flex-col  min-h-[calc(100vh-128px)]">
      <div className="text-xl font-semibold text-yellow-600">
        NO ACCESS TO THIS FEATURE
      </div>
    </div>
  );
};
