/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import { CustomerPaymentMethods } from "@roadflex/types";
import { capitalizeFirstLetter } from "@roadflex/utils";
import { Fragment } from "react";
import { Button, ButtonVariant } from "../../buttons";

export interface CardConnectionModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  card: CustomerPaymentMethods;
  deletePaymentMethod: (id: string) => void;
  changePaymentMethod: (id: string) => void;
  deletingPaymentMethod: boolean;
  primaryPaymentMethodType: ENUM_PAYMENT_METHOD_TYPE;
}

export default function CardConnectionModal({
  open,
  setOpen,
  card,
  deletePaymentMethod,
  changePaymentMethod,
  deletingPaymentMethod,
  primaryPaymentMethodType,
}: CardConnectionModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-hidden"
        onClose={() => {
          //prevent closing when clicking outside the modal
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col h-full p-4 overflow-y-auto bg-white">
                  <div className="mb-8 text-lg font-bold">
                    Debit Card {capitalizeFirstLetter(card.brand)}{" "}
                  </div>
                  <div className="flex flex-col justify-between flex-grow h-full">
                    <div className="space-y-4">
                      <div className="text-sm">
                        <div className="mb-1">Expiration</div>
                        <div className="font-semibold">
                          {card.exp_month}/{card.exp_year}
                        </div>
                      </div>
                      <div className="text-sm">
                        <div className="mb-1">Card Ending in</div>
                        <div className="font-semibold">{card.last4}</div>
                      </div>
                      <div>
                        <Button
                          variant={ButtonVariant.Black}
                          onClick={() => {
                            changePaymentMethod(card.paymentMethodId);
                          }}
                          disabled={
                            card.default && primaryPaymentMethodType === "CARD"
                          }
                          className="flex flex-row items-center w-full sm:w-auto"
                        >
                          Make Primary Payment Method
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Button
                        variant={ButtonVariant.Red}
                        onClick={() => {
                          deletePaymentMethod(card.paymentMethodId);
                        }}
                        loading={deletingPaymentMethod}
                        className="flex flex-row items-center w-full sm:w-auto"
                      >
                        Remove Card
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
