/** @format */

import { useUpdateBusinessInformation } from "@roadflex/react-hooks";
import { UserDetailsType } from "@roadflex/types";
import { toast } from "react-toastify";
import UpdateBusinessUI from "./updateBusiness.ui";

type UpdateBusinessProps = {
  readUserDetailsData: UserDetailsType;
};

const UpdateBusiness = ({ readUserDetailsData }: UpdateBusinessProps) => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    handleReset,
    resetForm,
    setFieldValue,
    ...rest
  } = useUpdateBusinessInformation({
    initialValues: {
      title: readUserDetailsData?.businessDetails?.title || "",
      cardName: readUserDetailsData?.businessDetails?.cardName || "",
      description: readUserDetailsData?.businessDetails?.description || "",
      type: readUserDetailsData?.businessDetails?.type || "",
      dot: readUserDetailsData?.businessDetails?.dot || "",
      ein: readUserDetailsData?.businessDetails?.ein || "",
      mc: readUserDetailsData?.businessDetails?.mc || "",
    },
    onSubmit(res, error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res.message);
        // resetForm();
      }
    },
  });

  return (
    <UpdateBusinessUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        handleReset,
        errors,
        touched,
        resetForm,
        setFieldValue,
        ...rest,
      }}
    />
  );
};

export default UpdateBusiness;
