/** @format */

import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import {
  useBusinessUpdateAlt,
  useGetFleetInformation,
  useReadBusinessInformation,
  useReadUserDetails,
} from "@roadflex/react-hooks";
import { BusinessType } from "@roadflex/types";
import { useState } from "react";
import { toast } from "react-toastify";
import BusinessInformationUI from "./business-information.ui";

type Props = {
  nextStep: () => void;
  business: BusinessType;
  fleetInfo: {
    fleetNum: number;
    fuel: number;
  };
  isSubmittingOnboardStatus: boolean;
  customerType: string;
};

const BusinessInformation = ({
  nextStep,
  business,
  fleetInfo,
  customerType,
  isSubmittingOnboardStatus,
}: Props) => {
  const uploadedIRSLetter = business?.document?.irsLetter || "";
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    ...rest
  } = useBusinessUpdateAlt({
    initialValues: {
      title: business.title || "",
      // fuel: fleetInfo?.fuel
      //   ? fuelFormatter((fleetInfo?.fuel / 100).toString())
      //   : "",
      cardName: business.cardName || "",
      description: business.description || "",
      type: business.type || "",
      companyType: business.companyType || "",
      customerType: customerType || "PREPAID",
      yearsOfOperation: business?.yearsOfOperation?.toString() || "",
      ein: business.ein || "",
      irsLetter: "",
      irsLetterReupload: false,
      uploadedIRSLetter,
    },
    onSubmit(res, err) {
      if (!err) {
        nextStep();
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  const [
    showBusinessInformationConfirmationModal,
    setShowBusinessInformationConfirmationModal,
  ] = useState(false);

  return (
    <BusinessInformationUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isSubmittingOnboardStatus,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        ...rest,
        showBusinessInformationConfirmationModal,
        setShowBusinessInformationConfirmationModal,
        business: business,
      }}
    />
  );
};

// export default BusinessInformation;
function FleetInfo(props: {
  nextStep: () => void;
  business: BusinessType;
  isSubmittingOnboardStatus: boolean;
  customerType: string;
}) {
  const { fleetInfo, loading } = useGetFleetInformation({
    businessId: props.business.id,
  });

  if (loading) {
    return <div className="my-6 text-center text-white">Loading...</div>;
  }

  return <BusinessInformation {...{ ...props, fleetInfo }} />;
}

function Customer(props: {
  nextStep: () => void;
  isSubmittingOnboardStatus: boolean;
  business: BusinessType;
}) {
  const {
    readUserDetailsData,
    readUserDetailsLoading: loading,
    readUserDetailsRefetch,
  } = useReadUserDetails("", "", "", "", ["Setting"]);
  const data = readUserDetailsData?.readUserDetails?.details;

  if (loading) {
    return <div className="my-6 text-center text-white">Loading...</div>;
  }
  let customer = "PREPAID";
  if (
    data?.setting?.customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
    data?.setting?.customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS
  ) {
    customer = "CREDIT";
  } else if (data?.setting?.customerType?.split("_")[1] === "ENTERPRISE") {
    customer = data.setting.customerType;
  }

  return <FleetInfo {...{ ...props, customerType: customer }} />;
}

function Business(props: {
  nextStep: () => void;
  isSubmittingOnboardStatus: boolean;
}) {
  const { data, loading } = useReadBusinessInformation();
  if (loading) {
    return <div className="my-6 text-center text-white">Loading...</div>;
  }

  const { readBusinessInformation: business } = data;

  return <Customer {...{ ...props, business }} />;
}

export default Business;
