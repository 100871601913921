/** @format */
import { useMutation } from "@apollo/client";
import { ENUM_PRODUCT_NAMES } from "@prisma/client";
import {
  GET_PRODUCT_DETAILS,
  ONBOARD_STATUS_UPDATE,
  READ_CURRENT_USER_ONBOARD,
  READ_USER_DETAILS,
} from "@roadflex/graphql";
import { useFormik } from "formik";

type Values = {
  step: number;
  productName: ENUM_PRODUCT_NAMES;
};

type UseOnBoardStatusUpdateProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: Values;
};

export const useOnBoardStatusUpdate = ({
  onSubmit,
  // validationSchema,
  initialValues,
}: UseOnBoardStatusUpdateProps) => {
  const [onBoardStatusUpdateFn] = useMutation(ONBOARD_STATUS_UPDATE, {
    refetchQueries: [
      READ_USER_DETAILS,
      GET_PRODUCT_DETAILS,
      READ_CURRENT_USER_ONBOARD,
    ],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (value) => {
      try {
        const res = await onBoardStatusUpdateFn({
          variables: {
            data: { step: value.step + 1, productName: value.productName },
          },
        });
        onSubmit(res, null);
      } catch (error) {
        console.error(error);
        onSubmit(null, error);
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
