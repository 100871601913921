import { useMutation, useQuery } from "@apollo/client";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import {
  LIST_CUSTOMER_PAYMENT_METHODS,
  READ_BUSINESS_INFORMATION,
  STRIPE_CHANGE_PAYMENT_METHOD,
  STRIPE_DELETE_PAYMENT_METHOD,
} from "@roadflex/graphql";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StripePaymentMethodsUI } from "./StripePaymentMethods.ui";
import { StripePaymentMethodsUIAlt } from "./StripePaymentMethodsAlt.ui";

interface StripePaymentMethodsProps {
  ui: "box" | "list";
  primaryPaymentMethodType?: ENUM_PAYMENT_METHOD_TYPE;
  selectedPaymentMethod?: string;
  setSelectedPaymentMethod?: (value: string) => void;
}
export const StripePaymentMethods = ({
  ui,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  primaryPaymentMethodType,
}: StripePaymentMethodsProps) => {
  const [modal, setModal] = useState(false);
  const [changePaymentMethodFn, { loading: changingPaymentMethod }] =
    useMutation(STRIPE_CHANGE_PAYMENT_METHOD, {
      refetchQueries: [
        LIST_CUSTOMER_PAYMENT_METHODS,
        READ_BUSINESS_INFORMATION,
      ],
    });
  const [deletePaymentMethodFn, { loading: deletingPaymentMethod }] =
    useMutation(STRIPE_DELETE_PAYMENT_METHOD, {
      refetchQueries: [LIST_CUSTOMER_PAYMENT_METHODS],
    });
  const {
    data: customerPaymentMethods,
    loading: customerPaymentMethodsLoading,
  } = useQuery(LIST_CUSTOMER_PAYMENT_METHODS);

  const changePaymentMethod = async (paymentMethodId: string) => {
    try {
      await changePaymentMethodFn({
        variables: {
          data: {
            paymentMethodId,
          },
        },
      });
      toast.success("Default payment method changed to card");
      setModal(false);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  const deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      await deletePaymentMethodFn({
        variables: {
          data: {
            paymentMethodId,
          },
        },
      });
      toast.success("Card successfully deleted.");
      setModal(false);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  return ui === "box" && primaryPaymentMethodType ? (
    <StripePaymentMethodsUI
      changePaymentMethod={changePaymentMethod}
      deletePaymentMethod={deletePaymentMethod}
      deletingPaymentMethod={deletingPaymentMethod}
      changingPaymentMethod={changingPaymentMethod}
      customerPaymentMethodsLoading={customerPaymentMethodsLoading}
      customerPaymentMethods={
        customerPaymentMethods?.stripeListPaymentMethods?.paymentMethods || []
      }
      primaryPaymentMethodType={primaryPaymentMethodType}
      modal={modal}
      setModal={setModal}
    ></StripePaymentMethodsUI>
  ) : setSelectedPaymentMethod ? (
    <StripePaymentMethodsUIAlt
      customerPaymentMethodsLoading={customerPaymentMethodsLoading}
      customerPaymentMethods={
        customerPaymentMethods?.stripeListPaymentMethods?.paymentMethods || []
      }
      selectedPaymentMethod={selectedPaymentMethod || ""}
      setSelectedPaymentMethod={setSelectedPaymentMethod}
    ></StripePaymentMethodsUIAlt>
  ) : null;
};
