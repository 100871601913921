/** @format */

import { useLazyQuery } from "@apollo/client";
import { CARD_ANALYSIS_DATA } from "@roadflex/graphql";
import { useReadAppCardData } from "@roadflex/react-hooks";
import { TransactionType, UserAuthType } from "@roadflex/types";
import {
  appChartsGraphSorter,
  getEndOfWeek,
  getStartOfWeek,
  getTimeZoneOffset,
} from "@roadflex/utils";
import { showErrorMessage } from "@roadflex/web-lib";
import { addMilliseconds } from "date-fns";
import { CalendarChangeParams } from "primereact/calendar";
import { DropdownChangeParams } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../../loader";
import CardAnalysisUI from "./card-analysis.ui";

export const CardAnalysis = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }
  const [dates, setDates] = useState<Date[]>([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const displayModes = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
  ];
  const [selectedDisplayMode, setSelectedDisplayMode] = useState(
    displayModes[0].value,
  );

  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);
  const [startDate, setStartDate] = useState<Date>(minDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [cardId, setCardId] = useState<string>("");
  const [gmvGraphData, setGmvGraphData] = useState<Array<[string, string]>>([]);
  const [transactionCountGraphData, setTransactionCountGraphData] = useState<
    Array<[string, string]>
  >([]);
  const {
    readAppCardData,
    loading: readAppCardDataLoading,
    refetch: readAppCardDataRefetch,
  } = useReadAppCardData(true, true, true, "Shortened");
  const cardList = readAppCardData?.cards || [];
  const [transactionList, setTransactionList] = useState<TransactionType[]>([]);
  const [cardAnalysisDataFn, { loading: cardAnalysisDataLoading }] =
    useLazyQuery(CARD_ANALYSIS_DATA, {
      fetchPolicy: "no-cache",
      onCompleted: (data: {
        readCardAnalysisData: {
          code: string;
          transactions: TransactionType[];
          gmvGraphData: Array<[string, string]>;
          transactionCountGraphData: Array<[string, string]>;
          message: string;
        };
      }) => {
        if (data?.readCardAnalysisData?.code === "200") {
          setTransactionList(data?.readCardAnalysisData?.transactions || []);
          setGmvGraphData(data?.readCardAnalysisData?.gmvGraphData || []);
          setTransactionCountGraphData(
            data?.readCardAnalysisData?.transactionCountGraphData || [],
          );
        } else {
          showErrorMessage(
            data?.readCardAnalysisData?.message,
            data?.readCardAnalysisData?.code,
          );
          setTransactionList([]);
        }
      },
      onError: (error: Error) => {
        toast.error(`Error: ${error.message}`);
      },
      notifyOnNetworkStatusChange: true,
    });

  const fetchCardAnalysisData = (
    startDt: Date,
    endDt: Date,
    selectedCardId: string,
    displayMode: string,
  ) => {
    if (startDt && endDt && selectedCardId) {
      let finalStartDate = startDt;
      let finalEndDate = endDt;

      if (displayMode === "week") {
        finalStartDate = getStartOfWeek(startDt);
        finalEndDate = getEndOfWeek(endDt);
      }

      setDates([finalStartDate, finalEndDate]);
      cardAnalysisDataFn({
        variables: {
          data: {
            cardId: selectedCardId,
            dateFilter: {
              startDate: addMilliseconds(
                finalStartDate,
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
              endDate: addMilliseconds(
                finalEndDate,
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    const cards = readAppCardData?.cards || [];
    if (!readAppCardDataLoading && cards.length > 0) {
      const preselectedCardId = cards[0].id;
      setCardId(preselectedCardId);
      fetchCardAnalysisData(
        dates[0],
        dates[1],
        preselectedCardId,
        selectedDisplayMode,
      );
    }
  }, [readAppCardDataLoading, readAppCardData]);

  const onDisplayModeChange = (e: DropdownChangeParams) => {
    const displayMode = e.value;
    setSelectedDisplayMode(displayMode);
    if (displayMode === "week") {
      fetchCardAnalysisData(dates[0], dates[1], cardId, displayMode);
    }
  };

  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    if (dateArray.length === 2) {
      const [startDate1, endDate1] = dateArray;
      const oneMonthAfterStart = new Date(startDate1);
      oneMonthAfterStart.setMonth(oneMonthAfterStart.getMonth() + 1);
      if (endDate1 >= oneMonthAfterStart) {
        toast.error("Max Range: 1 month");
        return;
      }
    }
    setDates(dateArray);
    fetchCardAnalysisData(
      dateArray[0],
      dateArray[1],
      cardId,
      selectedDisplayMode,
    );
  };

  const onCardChange = (e: DropdownChangeParams) => {
    const cardIdVal = e.value;
    setCardId(cardIdVal);
    const dateArray = dates;
    fetchCardAnalysisData(
      dateArray[0],
      dateArray[1],
      cardIdVal,
      selectedDisplayMode,
    );
  };

  const transactionVolumeData: [number, number][] = [];
  const merchantByDollarSpentData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[] = [];

  const merchantByTransactionCountData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[] = [];

  const transactionDict: Record<string, number> = {};
  const merchantByDollarDict: Record<string, number> = {};
  const merchantByTransactionCountDict: Record<string, number> = {};

  let totalTransactionAmount = 0;
  let totalTransactionCount = 0;
  transactionList?.forEach((item) => {
    if (
      item.transactionStatus === "CAPTURE" &&
      item.merchantName !== "RoadFlex" &&
      new Date(item.authorizationDate) >= (dates as Date[])[0] &&
      new Date(item.authorizationDate) <= (dates as Date[])[1]
    ) {
      const transAmount = item.transactionAmount;
      const authDate = `${
        new Date(item.authorizationDate).toISOString().split("T")[0]
      }T00:00:00.000Z`;
      if (authDate in transactionDict) {
        transactionDict[authDate] += transAmount;
      } else {
        transactionDict[authDate] = transAmount;
      }
      const merchant = item.merchantName;
      totalTransactionAmount += transAmount;
      totalTransactionCount += 1;
      if (merchant in merchantByDollarDict) {
        merchantByDollarDict[merchant] += transAmount;
      } else {
        merchantByDollarDict[merchant] = transAmount;
      }
      if (merchant in merchantByTransactionCountDict) {
        merchantByTransactionCountDict[merchant] += 1;
      } else {
        merchantByTransactionCountDict[merchant] = 1;
      }
    }
  });
  for (const [key, value] of Object.entries(transactionDict)) {
    const date = new Date(key);
    transactionVolumeData.push([date.getTime(), value / 100]);
  }
  transactionVolumeData.sort(appChartsGraphSorter);
  const dailyTransactionVolumeData = [
    { name: "Daily Transaction Volume", data: transactionVolumeData },
  ];
  const otherPercentage = 0;
  for (const [key, value] of Object.entries(merchantByDollarDict)) {
    if (totalTransactionAmount !== 0) {
      const percentage =
        Math.round((10000 * value) / totalTransactionAmount) / 100;
      // if (percentage > 10) {
      merchantByDollarSpentData.push({
        name: key,
        y: percentage,
        sliced: true,
        selected: false,
      });
      // } else {
      //   otherPercentage += percentage;
      // }
    }
  }

  for (const [key, value] of Object.entries(merchantByTransactionCountDict)) {
    if (totalTransactionCount !== 0) {
      const percentage =
        Math.round((10000 * value) / totalTransactionCount) / 100;
      merchantByTransactionCountData.push({
        name: key,
        y: percentage,
        sliced: true,
        selected: false,
      });
    }
  }

  if (readAppCardDataLoading) {
    return <Loader />;
  }
  return (
    <CardAnalysisUI
      {...{
        cardAnalysisDataLoading,
        merchantByDollarSpentData,
        merchantByTransactionCountData,
        dates,
        setDates,
        onDateRangeChange,
        cardList,
        cardId,
        onCardChange,
        gmvGraphData,
        transactionCountGraphData,
        startDate,
        endDate,
        displayModes,
        selectedDisplayMode,
        onDisplayModeChange,
        readCurrentUserAuth,
      }}
    />
  );
};
