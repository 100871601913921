/* eslint-disable dot-notation */
/** @format */

import { useLazyQuery } from "@apollo/client";
import { pdf } from "@react-pdf/renderer";
import { GET_STATEMENT_OF_ACCOUNT_DATA } from "@roadflex/graphql";
import { StatementOfAccount } from "@roadflex/types";
import { differenceInDays, startOfMonth, subMonths } from "date-fns";
import { saveAs } from "file-saver";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { StatementOfAccountPdf } from "../../load-pdf";
import ModalLoader from "../../loader/modal-loader";

interface ExportTransactionsModalProps {
  open: boolean;
  onCloseExportModal: (value: boolean) => void;
  email?: string;
}

export const ExportStatementOfAccountModal = ({
  open,
  onCloseExportModal,
  email,
}: ExportTransactionsModalProps) => {
  const [dates, setDates] = useState<Date | Date[] | undefined>([
    startOfMonth(subMonths(new Date(), 1)),
    new Date(),
  ]);
  const calendarRef = useRef(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [soaInfoFn, { loading: soaInfoLoading }] = useLazyQuery<{
    getStatementOfAccount: {
      statementOfAccountData: StatementOfAccount[];
      businessName: string;
      city: string;
      firstName: string;
      lastName: string;
      street: string;
      streetDetail: string;
      zip: string;
    };
  }>(GET_STATEMENT_OF_ACCOUNT_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      setShowLoading(false);
      setShowComplete(true);
      if (data?.getStatementOfAccount) {
        const dateOnlyArray = (dates as Date[]).map((dateString) => {
          const date = new Date(dateString);
          date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
          const parts = date.toISOString().split("T")[0].split("-");
          return `${parts[1]}/${parts[2]}/${parts[0]}`;
        });
        const doc = (
          <StatementOfAccountPdf
            {...{
              company: {
                businessName: data?.getStatementOfAccount.businessName || "",
                city: data?.getStatementOfAccount.city || "",
                firstName: data?.getStatementOfAccount.firstName || "",
                lastName: data?.getStatementOfAccount.lastName || "",
                street: data?.getStatementOfAccount.street || "",
                streetDetail: data?.getStatementOfAccount.streetDetail || "",
                zip: data?.getStatementOfAccount.zip || "",
              },
              statementOfAccountData:
                data?.getStatementOfAccount?.statementOfAccountData || [],
              enterprise: true,
              statementDateRange: dateOnlyArray,
            }}
          />
        );
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(
          blob,
          `${
            data?.getStatementOfAccount.businessName || ""
          } - Statement of Account - ${dateOnlyArray[0]}-${
            dateOnlyArray[1]
          }.pdf`,
        );
      }
    },
    onError: (error) => {
      toast.error(`SOA error: ${error.message}`);
      setShowLoading(false);
      setShowComplete(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    if (dateArray[0] && dateArray[1]) {
      const daysDifference = differenceInDays(dateArray[1], dateArray[0]);
      if (daysDifference > 60) {
        toast.error("Date range cannot be more than 2 months");
        return;
      }
      (
        calendarRef?.current as unknown as { hideOverlay: () => void }
      )?.hideOverlay();
    }
    setDates([...dateArray]);
  };

  // const handleFocus = () => {
  //   console.log({ dates });
  //   if (!dates) {
  //     const currentDate = new Date();
  //     const previousMonthDate = startOfMonth(subMonths(currentDate, 1));
  //     console.log({ previousMonthDate });
  //     setDates([previousMonthDate, currentDate]);
  //   }
  // };

  const fetchSoaInfo = () => {
    setShowLoading(true);
    const dateOnlyArray = (dates as Date[]).map((dateString) => {
      const date = new Date(dateString);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return date.toISOString().split("T")[0];
    });
    soaInfoFn({
      variables: {
        data: {
          email: email,
          startDate: dateOnlyArray[0],
          endDate: dateOnlyArray[1],
        },
      },
    });
  };

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.AppSize}
        onClick={() => {
          onCloseExportModal(false);
        }}
      >
        Close
      </Button>
      {!showLoading && !showComplete && (
        <Button
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          disabled={
            dates
              ? (dates as Date[])[0] === null ||
                (dates as Date[])[1] === null ||
                showLoading
              : true
          }
          loading={showLoading}
          onClick={() => {
            fetchSoaInfo();
          }}
        >
          Export
        </Button>
      )}
    </div>
  );
  return (
    <Dialog
      header={
        <span className="text-base md:text-lg">
          Download Statement Of Account (PDF)
        </span>
      }
      visible={open}
      style={{ fontFamily: "inherit" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={() => onCloseExportModal(false)}
    >
      {showLoading || showComplete ? (
        <ModalLoader showLoading={showLoading} showComplete={showComplete} />
      ) : (
        <div className="space-y-3 text-sm md:text-base">
          <div className="flex flex-col">
            <label htmlFor="range">Date Range</label>
            <Calendar
              id="range"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              className="my-2 custom-calendar"
              panelClassName="custom-calendar-panel"
              placeholder="Select Date Range"
              maxDate={new Date()}
              // onFocus={handleFocus}
              numberOfMonths={2}
              ref={calendarRef}
              maxDateCount={60}
              inputClassName="!py-2"
              readOnlyInput
            />
          </div>
          <div className="italic font-normal">Max Range: 2 months</div>
        </div>
      )}
    </Dialog>
  );
};
