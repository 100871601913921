/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { GEOTAB_GET_LIVE_LOCATION } from "@roadflex/graphql";
import { EldDeviceLiveLocation } from "@roadflex/types";
import { useFormik } from "formik";

type Values = {
  database: string;
  orgName: string;
  deviceId: string;
};

type UseGeoTabGetLiveLocation = {
  initialValues: Values;
  onSubmit: (
    val: {
      eldDeviceLiveLocation: EldDeviceLiveLocation[];
    } | null,
    err: Error | null,
  ) => void;
};

export const useGeoTabGetLiveLocation = ({
  onSubmit,
  initialValues,
}: UseGeoTabGetLiveLocation) => {
  const [geoTabGetLiveLocation] = useMutation<{
    eldDeviceLiveLocation: EldDeviceLiveLocation[];
  }>(GEOTAB_GET_LIVE_LOCATION);

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await geoTabGetLiveLocation({
          variables: { data },
        });
        onSubmit(res?.data || null, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    geoTabGetLiveLocationValues: values,
    geoTabGetLiveLocationHandleChange: handleChange,
    geoTabGetLiveLocationHandleSubmit: handleSubmit,
    geoTabGetLiveLocationIsSubmitting: isSubmitting,
    geoTabGetLiveLocationSetFieldValue: setFieldValue,
    ...rest,
  };
};
