import { ENUM_CHILD_USER_TYPE } from "@prisma/client";
import {
  useDeleteAccountUser,
  useReadAccountUsers,
  useResendInvitation,
} from "@roadflex/react-hooks";
import { ChildUserType, UserAuthType } from "@roadflex/types";
import { phoneFormatter } from "@roadflex/web-lib";
import { toast } from "react-toastify";
import UsersUI from "./users.ui";
/*eslint no-empty-function: ["error", { "allow": ["functions"] }]*/

interface MultipleUsersProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

export const Users = ({ readCurrentUserAuth, loading }: MultipleUsersProps) => {
  const {
    deleteAccountUserSubmit,
    deleteAccountUserValues,
    deleteAccountUserSubmitting,
    setValues,
    ...rest
  } = useDeleteAccountUser({
    initialValues: {
      id: "",
    },
    onSubmit(res, err) {
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      if (err) {
        toast.error(err.message);
      }
    },
  });

  const {
    resendInvitationSubmit,
    resendInvitationValues,
    resendInvitationSubmitting,
    resendInvitationSetValues,
  } = useResendInvitation({
    initialValues: {
      id: "",
    },
    onSubmit(res, err) {
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      if (err) {
        toast.error(err.message);
      }
    },
  });

  const {
    data: accountUsers,
    refetch: accountUsersRefetch,
    loading: accountUsersLoading,
  } = useReadAccountUsers(null);

  const usersList = accountUsers?.readAccountUsers?.childUsers
    .map((child: ChildUserType) => {
      const newUserObj = {
        id: child.id || "",
        email: child.email || "",
        isEmailVerified: child.isEmailVerified || false,
        phoneNumber: phoneFormatter(child.phoneNumber || ""),
        firstName: child.firstName || "",
        lastName: child.lastName || "",
        roleName: child?.roles ? child?.roles[0]?.role.name : "",
        status: child?.otherUserDetail?.status || "",
        owner: false,
      };
      return newUserObj;
    })
    .concat({
      id: accountUsers?.readAccountUsers?.parent.id || "",
      email: accountUsers?.readAccountUsers?.parent.email || "",
      isEmailVerified: true,
      phoneNumber: phoneFormatter(
        accountUsers?.readAccountUsers?.parent.phoneNumber || "",
      ),
      firstName: accountUsers?.readAccountUsers?.parent.firstName || "",
      lastName: accountUsers?.readAccountUsers?.parent.lastName || "",
      roleName: ENUM_CHILD_USER_TYPE.OWNER,
      status: "PERMANENT",
      owner: true,
    });

  return (
    <UsersUI
      {...{
        handleSubmit: deleteAccountUserSubmit,
        values: deleteAccountUserValues,
        isSubmitting: deleteAccountUserSubmitting,
        setValues,
        ...rest,
        resendInvitationSubmit,
        resendInvitationSubmitting,
        resendInvitationSetValues,
        accountUsersLoading,
        usersList: usersList || [],
      }}
    />
  );
};
