/** @format */

import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  AuthorizationType,
  CardsFilterOptions,
  ChildFeatureType,
  DashboardDataType,
  DriversFilterOptions,
  TransactionDashboadDetailsType,
  TransactionType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { convertISOStringToDateNew } from "@roadflex/utils";
import {
  dollarFormatter,
  getCookie,
  setCookie,
  valueIsNullOrUndefined,
} from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { CustomDateFilter } from "../../datatable";
import {
  dateTemplate,
  driverFilterTemplate,
  vehicleFilterTemplate,
} from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import {
  authAmountTemplate,
  authorizationActionTemplate,
  authStatusTemplate,
  cardFilterTemplate,
  cardLastFourTemplate,
  driverTemplate,
  driverTemplateTransaction,
  fuelDiscountTemplate,
  merchantCategory,
  stateTemplate,
  transactionAmountTemplate,
  transactionReceiptStatusTemplate,
  transactionsActionMobileTemplate,
  transactionsActionTemplate,
  transactionStatusTemplate,
  vehicleTemplate,
  vehicleTemplateTransaction,
} from "../../datatable/transaction-table-templates/table-templates";
import { BlackListMerchant } from "../../modals/block-merchant-modal/block-merchant-modal";
import { ExportTransactionsModal } from "../../modals/export-transactions-modal/export-transactions-modal";
import { FuelCardComponentPermissionGuard } from "../../permission-guard";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";
type FilterConfigType = {
  [key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};

interface TransactionUIProps {
  dashboardDetails: TransactionDashboadDetailsType | null;
  childFeatures: ChildFeatureType[];
  authorizationLoading: boolean;
  settledTransactionData: {
    transactions: TransactionType[];
    totalCount: number;
  };
  transactionLoading: boolean;
  authorizationLazyParams: DataTablePFSEvent;
  transactionLazyParams: DataTablePFSEvent;
  setAuthorizationLazyParams: (value: DataTablePFSEvent) => void;
  setTransactionLazyParams: (value: DataTablePFSEvent) => void;
  initFilterConfig: FilterConfigType;
  mainDashboardData: DashboardDataType | null;
  appDashboardDataLoading: boolean;
  customerType?: ENUM_CUSTOMER_TYPE | undefined;
  updateList: () => void;
  isBlockMerchantsUpdating: boolean;
  blockMerchantsArray: string[];
  setBlockMerchantsArray: (val: string[]) => void;
  joyride: boolean;
  setJoyride: (value: boolean) => void;
  steps: Step[];
  pendingTransactionData: {
    authorizations: AuthorizationType[];
    totalCount: number;
  };
  transactionFilterOptions: {
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  };
  transactionRefetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<
    ApolloQueryResult<{
      readTransactionList: {
        code: string;
        message: string;
        transactions: TransactionType[];
        totalCount: number;
      };
    }>
  >;
  readCurrentUserAuth: UserAuthType;
}

const TransactionsUI = ({
  dashboardDetails,
  childFeatures,
  authorizationLoading,
  settledTransactionData,
  transactionLoading,
  authorizationLazyParams,
  transactionLazyParams,
  setAuthorizationLazyParams,
  setTransactionLazyParams,
  initFilterConfig,
  mainDashboardData,
  appDashboardDataLoading,
  customerType,
  updateList,
  isBlockMerchantsUpdating,
  blockMerchantsArray,
  setBlockMerchantsArray,
  joyride,
  setJoyride,
  steps,
  pendingTransactionData,
  transactionFilterOptions,
  transactionRefetch,
  readCurrentUserAuth,
}: TransactionUIProps) => {
  const transactionRef = useRef<DataTable>(null);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const { isMobile } = usePlatformDetect();
  const [expandedRows, setExpandedRows] = useState<AuthorizationType[]>([]);
  const router = useRouter();
  const goToFlaggedTransactions = () => {
    router.push({
      pathname: "/transactions/flagged",
    });
  };
  const goToDeclinedTransactions = () => {
    router.push("/transactions/declined");
  };

  const access = {
    BlockedMerchantsAccess:
      childFeatures.find(
        (item) => item.name === "TRANSACTION_PAGE_BLOCKED_MERCHANTS",
      ) !== undefined,
  };
  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setJoyride(true);
  };
  useEffect(() => {
    const transactionGuide = getCookie("transactionGuide");
    if (!transactionGuide) {
      setJoyride(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseExportModal = () => {
    setOpenExportModal(false);
  };
  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { status } = callbackData;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    }
    if (status === STATUS.READY) {
      setCookie("transactionGuide", "true", 30);
    }
  };

  const dateFilterApplied = (e: unknown, fromExport = false) => {
    const event = e as {
      field: string;
      constraints: {
        operator: FilterOperator;
        constraints: [
          {
            value: { date: Date | null; filterType: FilterMatchMode };
            matchMode: FilterMatchMode;
          },
        ];
      };
    };
    const constraints =
      event.constraints.constraints && event.constraints.constraints.length > 0
        ? event.constraints.constraints.map((value) => {
            return {
              matchMode: value?.value?.filterType,
              value: value?.value?.date,
            };
          })
        : initFilterConfig.date.constraints;
    setTransactionLazyParams({
      ...transactionLazyParams,
      isReport: fromExport ? true : false,
      filters: {
        ...initFilterConfig,
        date: {
          operator:
            event.constraints.operator || initFilterConfig.date.operator,
          constraints: constraints,
        },
      },
    });
  };

  const onPageAndSortAuthorizations = (event: DataTablePFSEvent) => {
    setAuthorizationLazyParams(event);
  };

  const onFilterAuthorizations = (event: DataTablePFSEvent) => {
    event.first = 0; // eslint-disable-line no-param-reassign
    setAuthorizationLazyParams(event);
  };

  const onPageAndSortTransactions = (event: DataTablePFSEvent) => {
    setTransactionLazyParams(event);
  };

  const onFilterTransactions = (event: DataTablePFSEvent) => {
    event.first = 0; // eslint-disable-line no-param-reassign
    setTransactionLazyParams(event);
  };

  let tz = "";
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }

  const rowExpansionAuthorizationTemplate = (data: AuthorizationType) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="mb-4 font-semibold text-gray-800">
          {data.merchantName} {/* Merchant Name */}
        </div>
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>
            Card: {cardLastFourTemplate(data)}
            {/* Last four digits of the card used */}
          </div>
          <div>
            Status: {authStatusTemplate(data)}
            {/* Last four digits of the card used */}
          </div>
          <div>
            Driver: {driverTemplate(data)} {/* Driver name */}
          </div>
          <div>
            Vehicle: {vehicleTemplate(data)} {/* Vehicle detail */}
          </div>
          <div>
            Location: {stateTemplate(data)} {/* Merchant location */}
          </div>
          <div className="flex items-center">
            Memo/Invoice: {authorizationActionTemplate(data)}{" "}
            {/* Additional details */}
          </div>
        </div>
      </div>
    );
  };

  const rowExpansionTransactionTemplate = (data: TransactionType) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="mb-4 font-semibold text-gray-800">
          {data.merchantName} {/* Merchant Name */}
        </div>
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>
            Card: {cardLastFourTemplate(data)}
            {/* Last four digits of the card used */}
          </div>
          <div>
            Status: {transactionStatusTemplate(data)}
            {/* Last four digits of the card used */}
          </div>
          <div>Discount: {fuelDiscountTemplate(data)}</div>
          <div>Merchant Category: {merchantCategory(data)}</div>
          <div>
            Driver: {driverTemplate(data)} {/* Driver name */}
          </div>
          <div>
            Vehicle: {vehicleTemplate(data)} {/* Vehicle detail */}
          </div>
          <div>
            Location: {stateTemplate(data)} {/* Merchant location */}
          </div>
          <div className="flex items-center gap-2">
            Transaction Receipt Reqire: {transactionReceiptStatusTemplate(data)}
          </div>
          <div className="flex justify-end mt-2">
            {transactionsActionMobileTemplate(data)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex justify-between text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        <div className="">{`Transactions`.toUpperCase()}</div>
        <div className="flex items-center justify-end rounded-md md:mt-0 step-6">
          <div>
            <Button
              onClick={handleClickStart}
              variant={ButtonVariant.Transparent}
              size={ButtonSize.Wrap}
            >
              <QuestionMarkCircleIcon
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
              />
              <span className="px-1 text-xs italic">Help Center</span>
            </Button>
          </div>
        </div>
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={joyride}
        scrollToFirstStep
        showProgress
        scrollOffset={76}
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />

      <div className="flex flex-col mt-5 xl:flex-row gap-y-4 md:gap-y-8 md:mt-0">
        <div className="flex flex-col p-4 bg-white rounded-md shadow md:p-8 xl:mr-4 xl:w-1/3 gap-y-2 md:gap-y-8 md:mt-0 step-2">
          {(customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
            customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
            customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
            <div className="block text-sm font-semibold lg:flex lg:flex-col lg:gap-2 xl:block xl:gap-0 4xl:flex 4xl:gap-2 md:text-base">
              <div className="whitespace-nowrap">Current Period: </div>
              <div className="font-normal">
                {appDashboardDataLoading
                  ? "Loading..."
                  : mainDashboardData?.currentCycle
                  ? `${convertISOStringToDateNew(
                      mainDashboardData?.currentCycle?.startDt.toString(),
                      false,
                      false,
                      true,
                      tz,
                    )} - ${convertISOStringToDateNew(
                      mainDashboardData?.currentCycle?.endDt.toString(),
                      false,
                      false,
                      true,
                      tz,
                    )} (${
                      readCurrentUserAuth?.accountDisplaySetting?.timeZone
                    })`
                  : "-"}
              </div>
            </div>
          )}
          <div className="flex flex-row justify-between w-full">
            <div className="text-sm md:text-base">
              {(customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
                <span>Credit Left</span>
              )}

              {(customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE) && (
                <span>Wallet Amount</span>
              )}
            </div>
            <div className="text-sm md:text-base">
              {(customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
                <div>
                  {appDashboardDataLoading
                    ? "Loading..."
                    : !valueIsNullOrUndefined(
                        mainDashboardData?.creditRemaining,
                      )
                    ? (dollarFormatter(
                        mainDashboardData?.creditRemaining
                          ? mainDashboardData?.creditRemaining / 100
                          : 0,
                      ) as string)
                    : "-"}
                </div>
              )}

              {(customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE) && (
                <div>
                  {appDashboardDataLoading
                    ? "Loading..."
                    : (dollarFormatter(
                        (+(mainDashboardData?.wallet?.signOnBalance || 0) +
                          +(mainDashboardData?.wallet?.referralBalance || 0) +
                          +(mainDashboardData?.wallet?.adminBalance || 0) +
                          +(mainDashboardData?.wallet?.cashBalance || 0)) /
                          100,
                      ) as string)}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between w-full text-sm md:text-base">
            <div className="">Lifetime Total Savings</div>
            <div className="">
              {dollarFormatter(
                (dashboardDetails?.totalDiscount || 0) / 100 || 0,
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between w-full text-sm md:text-base">
            <div className="">Lifetime Transaction Amount</div>
            <div className="">
              {dollarFormatter(
                (dashboardDetails?.totalTransactionAmount || 0) / 100 || 0,
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col p-4 bg-white rounded-md shadow md:p-8 xl:ml-4 xl:w-2/3 gap-y-2 md:gap-y-8 md:mt-0 step-3">
          <div className="py-2 font-semibold md:py-0 text-brown-500 md:text-lg">
            Risk Management
          </div>
          <div className="flex flex-col gap-2 md:gap-8 sm:flex-row lg:gap-12">
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div className="text-sm md:text-base">
                All Declined Transactions
              </div>
              <div className="">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={goToDeclinedTransactions}
                >
                  View
                </Button>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div className="text-sm md:text-base">Blocked Merchants</div>
              <div className="">
                <FuelCardComponentPermissionGuard
                  type="button"
                  featurePermission={access.BlockedMerchantsAccess}
                  messageType="premium"
                >
                  <Button
                    size={ButtonSize.AppSize}
                    variant={ButtonVariant.AppOrange}
                    onClick={() => setOpenBlockModal(true)}
                    disabled={transactionLoading}
                  >
                    Edit&nbsp;
                    <span className="font-normal text-yellow-600">
                      (Premium)
                    </span>
                  </Button>
                </FuelCardComponentPermissionGuard>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 md:gap-8 sm:flex-row lg:gap-12">
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div className="text-sm md:text-base">
                Flagged Transactions{" "}
                <span className="text-xs italic">(Past 90 days)</span>
              </div>
              <div className="">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={goToFlaggedTransactions}
                  disabled={transactionLoading}
                >
                  View
                </Button>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div className="text-sm md:text-base">
                Transaction Receipt Required{" "}
                <span className="text-xs italic">(Past 90 days)</span>
              </div>
              <div className="">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    router.push({
                      pathname: "/transactions/receipt-require",
                    });
                  }}
                  disabled={transactionLoading}
                >
                  View
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full p-4 mt-4 bg-white rounded-md shadow md:mt-8 md:p-8 step-4">
        <div className="font-semibold text-brown-500 md:text-lg">Pending</div>
        <div className="flex flex-col h-full mt-4">
          <DataTable
            size="small"
            stripedRows
            value={pendingTransactionData.authorizations}
            loading={authorizationLoading}
            paginator
            dataKey="id"
            style={{
              fontFamily: "inherit",
            }}
            paginatorTemplate={newPaginationTemplate}
            responsiveLayout={isMobile() ? "scroll" : "scroll"}
            emptyMessage="No available records"
            pageLinkSize={isMobile() ? 2 : 3}
            className="w-auto min-w-full custom-table"
            lazy
            rows={authorizationLazyParams.rows}
            filters={authorizationLazyParams.filters}
            sortField={authorizationLazyParams.sortField}
            sortOrder={authorizationLazyParams.sortOrder}
            sortMode={"single"}
            first={authorizationLazyParams.first}
            totalRecords={pendingTransactionData.totalCount}
            onPage={onPageAndSortAuthorizations}
            onSort={onPageAndSortAuthorizations}
            onFilter={onFilterAuthorizations}
            rowExpansionTemplate={rowExpansionAuthorizationTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e: DataTableRowToggleParams) =>
              setExpandedRows(e.data)
            }
          >
            <Column
              sortable
              dataType="date"
              filterField="date"
              field="date"
              header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={(r) =>
                dateTemplate(
                  r.authorizationDate,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                )
              }
            />
            {!isMobile() && (
              <Column
                sortable
                filterPlaceholder="Search by Name"
                field="merchantName"
                header="Merchant"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
            )}
            {!isMobile() && (
              <Column
                // filter
                sortable
                filterPlaceholder="Search by State"
                sortField="merchantState"
                header="Location"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={stateTemplate}
              />
            )}
            {!isMobile() && (
              <Column
                showFilterMatchModes={false}
                filterMenuStyle={{
                  width: "14rem",
                }}
                filter
                field="lastFour"
                sortable
                header="Card"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterMenuClassName="custom-filter"
                filterElement={(e) =>
                  cardFilterTemplate(
                    e,
                    transactionFilterOptions.cardsFilterOptions,
                  )
                }
                body={cardLastFourTemplate}
              />
            )}
            {!isMobile() && (
              <Column
                showFilterMatchModes={false}
                filterMenuStyle={{
                  width: "14rem",
                }}
                filter
                sortable
                body={driverTemplate}
                header="Driver"
                field="driver"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterElement={(e) =>
                  driverFilterTemplate(
                    e,
                    transactionFilterOptions.driversFilterOptions,
                  )
                }
                filterMenuClassName="custom-filter"
              />
            )}
            {!isMobile() && (
              <Column
                showFilterMatchModes={false}
                filterMenuStyle={{
                  width: "14rem",
                }}
                filter
                sortable
                body={vehicleTemplate}
                header="Vehicle"
                field="vehicle"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterElement={(e) =>
                  vehicleFilterTemplate(
                    e,
                    transactionFilterOptions.vehiclesFilterOptions,
                  )
                }
                filterMenuClassName="custom-filter"
              />
            )}
            <Column
              // filter
              sortable={!isMobile()}
              field="amount"
              header="Amount"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={authAmountTemplate}
            />
            <Column
              header="Status"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={(e, f) => authStatusTemplate(e, f, isMobile())}
            />
            {!isMobile() && (
              <Column
                // filter
                field="Memo/Invoice"
                header="Memo/Invoice"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={authorizationActionTemplate}
              />
            )}
            {isMobile() && (
              <Column
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                expander
                style={{ width: "3em" }}
              />
            )}
          </DataTable>
        </div>
      </div>
      <div className="flex flex-col w-full p-5 mt-4 bg-white rounded-md shadow md:mt-8 md:p-8 step-5">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="font-semibold text-brown-500 md:text-lg">
            Settled{" "}
            <span className="text-sm italic whitespace-nowrap">
              (Past 90 days)
            </span>
          </div>
          <div className="flex items-center">
            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => {
                setOpenExportModal(true);
              }}
              className="whitespace-nowrap"
              disabled={
                transactionLoading ||
                settledTransactionData.transactions?.length === 0
              }
            >
              Export Transactions
            </Button>
          </div>
        </div>
        <div className="flex flex-col h-full mt-4">
          <DataTable
            pageLinkSize={isMobile() ? 2 : 3}
            size="small"
            exportFilename={`Transaction-List-${new Date().toLocaleDateString(
              "en-US",
            )}`}
            ref={transactionRef}
            stripedRows
            value={settledTransactionData.transactions}
            loading={transactionLoading}
            paginator
            dataKey="id"
            paginatorTemplate={newPaginationTemplate}
            responsiveLayout={isMobile() ? "scroll" : "scroll"}
            emptyMessage="No available records"
            className="w-auto min-w-full custom-table"
            style={{
              fontFamily: "inherit",
            }}
            lazy
            rows={transactionLazyParams.rows}
            filters={transactionLazyParams.filters}
            sortField={transactionLazyParams.sortField}
            sortOrder={transactionLazyParams.sortOrder}
            sortMode={"single"}
            first={transactionLazyParams.first}
            totalRecords={settledTransactionData.totalCount}
            onPage={onPageAndSortTransactions}
            onSort={onPageAndSortTransactions}
            onFilter={onFilterTransactions}
            rowExpansionTemplate={rowExpansionTransactionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e: DataTableRowToggleParams) =>
              setExpandedRows(e.data)
            }
          >
            <Column
              sortable
              dataType="date"
              field="date"
              header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              body={(r) =>
                dateTemplate(
                  r.authorizationDate,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                )
              }
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              filter
              filterField="date"
              showFilterOperator={false}
              showFilterMatchModes={false}
              showAddButton={false}
              //showFilterMatchModes={false}
              filterMenuClassName="custom-filter"
              // filterElement={DateFilterTemplate}
              filterElement={(options) => (
                <CustomDateFilter options={options} />
              )}
              onFilterApplyClick={dateFilterApplied}
              onFilterClear={() => {
                setTransactionLazyParams({
                  ...transactionLazyParams,
                  filters: { ...initFilterConfig },
                });
              }}
            />
            {!isMobile() && (
              <Column
                // filter
                sortable
                filterPlaceholder="Search by Name"
                field="merchantName"
                header="Merchant"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
            )}
            {!isMobile() && (
              <Column
                filterPlaceholder="Search by State"
                sortField="merchantState"
                header="Merchant Category"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={merchantCategory}
              />
            )}
            {!isMobile() && (
              <Column
                // filter
                sortable
                filterPlaceholder="Search by State"
                sortField="merchantState"
                header="Location"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={stateTemplate}
              />
            )}

            <Column
              showFilterMatchModes={false}
              filterMenuStyle={{
                width: "14rem",
              }}
              filter
              sortable
              header="Card"
              field="lastFour"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              filterMenuClassName="custom-filter"
              filterElement={(e) =>
                cardFilterTemplate(
                  e,
                  transactionFilterOptions.cardsFilterOptions,
                )
              }
              body={cardLastFourTemplate}
            />
            <Column
              sortable={!isMobile()}
              field="transactionAmount"
              header="Amount"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={transactionAmountTemplate}
            />

            <Column
              sortable
              field="discount"
              header="Discount"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={fuelDiscountTemplate}
            />

            <Column
              showFilterMatchModes={false}
              filterMenuStyle={{
                width: "14rem",
              }}
              filter
              sortable
              body={(r) =>
                driverTemplateTransaction(r, isMobile() ? false : true)
              }
              header="Driver"
              field="driver"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              filterMenuClassName="custom-filter"
              filterElement={(e) =>
                driverFilterTemplate(
                  e,
                  transactionFilterOptions.driversFilterOptions,
                )
              }
            />

            {!isMobile() && (
              <Column
                showFilterMatchModes={false}
                filterMenuStyle={{
                  width: "14rem",
                }}
                filter
                sortable
                body={(r) => vehicleTemplateTransaction(r, true)}
                header="Vehicle"
                field="vehicle"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterMenuClassName="custom-filter"
                filterElement={(e) =>
                  vehicleFilterTemplate(
                    e,
                    transactionFilterOptions.vehiclesFilterOptions,
                  )
                }
              />
            )}

            {!isMobile() && (
              <Column
                sortable
                field="transactionReceiptRequired"
                header="Receipt Status"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={transactionReceiptStatusTemplate}
              />
            )}
            <Column
              header="Status"
              field="status"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={(e, f) => transactionStatusTemplate(e, f, isMobile())}
            />
            {!isMobile() && (
              <Column
                // filter
                field="details"
                header="Details"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={transactionsActionTemplate}
              />
            )}
            {isMobile() && (
              <Column
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                expander
                style={{ width: "3em" }}
              />
            )}
          </DataTable>
        </div>
      </div>

      {openExportModal && (
        <ExportTransactionsModal
          open={openExportModal}
          onCloseExportModal={onCloseExportModal}
        ></ExportTransactionsModal>
      )}

      {openBlockModal && (
        <BlackListMerchant
          open={openBlockModal}
          setOpen={setOpenBlockModal}
          updateList={updateList}
          isBlockMerchantsUpdating={isBlockMerchantsUpdating}
          blockMerchantsArray={blockMerchantsArray}
          setBlockMerchantsArray={setBlockMerchantsArray}
        ></BlackListMerchant>
      )}
    </div>
  );
};

export default TransactionsUI;
