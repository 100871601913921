/** @format */

import { ENUM_ODOMETER } from "@prisma/client";
import { useCreateFleet } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useState } from "react";
import { toast } from "react-toastify";
import { VehiclesEditUI } from "./vehicle.ui";

interface VehiclesNewProps {
  readCurrentUserAuth: UserAuthType;
}
export const VehiclesNew = ({ readCurrentUserAuth }: VehiclesNewProps) => {
  const tabs = [{ id: 1, name: "Vehicle", current: true }];
  const [update, setUpdate] = useState(false);
  const router = useRouter();

  const { resetForm: createVehicleResetForm, ...vehicleFormikValues } =
    useCreateFleet({
      initialValues: {
        fleetName: "",
        vin: "",
        make: "",
        model: "",
        year: "",
        licensePlate: "",
        provisionState: "",
        odometer: ENUM_ODOMETER.MILES,
        fuelType: undefined,
        tankCapacity: undefined,
      },
      onSubmit(res, err) {
        if (err) {
          toast.error(err.message);
        }
        if (res?.code === "200") {
          toast.success(res?.message);
        }
        createVehicleResetForm();
        router.push("/vehicles");
      },
    });
  return (
    <VehiclesEditUI
      {...{
        isArchived: false,
        tabs,
        update,
        setUpdate,
        resetForm: createVehicleResetForm,
        ...vehicleFormikValues,
      }}
    />
  );
};
