/** @format */
import { useReadTechnicalAnalysis } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useState } from "react";
import TechnicalAnalysisUI from "./technical-analysis.ui";

export const TechnicalAnalysis = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  const [queryTable, setQueryTable] = useState("CARDS");
  const cardsDefaultFilters: {
    operationalHours: string[];
    cardAssignments: string[];
    transactionControls: string[];
    spendingLimits: string[];
    merchantCategories: string[];
  } = {
    operationalHours: ["operationalHours"],
    cardAssignments: [
      // "driver", "vehicle"
    ],
    transactionControls: [
      // "smsSecurity",
      // "odometerSecurity",
      // "requireJobID",
      // "enableSmsReceiptUpload",
      // "fuelTypeSecurity",
      // "tankCapacitySecurity",
      // "suspiciousFuelingSecurity",
      // "flaggedMerchantCategories",
      // "telematicsSecurity",
    ],
    spendingLimits: [
      "perTransactionSpendingLimit",
      "dailySpendingLimit",
      "weeklySpendingLimit",
      "monthlySpendingLimit",
    ],
    merchantCategories: [
      // "fuelExpenses",
      // "vehicleExpenses",
      // "businessExpenses",
      // "additionalMerchants",
    ],
  };
  const [cardsFilters, setCardsFilters] = useState({ ...cardsDefaultFilters });

  const {
    technicalAnalysisData,
    technicalAnalysisDataRefetch,
    technicalAnalysisDataLoading,
  } = useReadTechnicalAnalysis(queryTable);

  const cards = technicalAnalysisData?.readTechnicalAnalysisData?.cards || [];
  const drivers =
    technicalAnalysisData?.readTechnicalAnalysisData?.drivers || [];
  const vehicles =
    technicalAnalysisData?.readTechnicalAnalysisData?.vehicles || [];

  const resetFilters = () => {
    setCardsFilters({ ...cardsDefaultFilters });
  };

  return (
    <TechnicalAnalysisUI
      {...{
        loading: technicalAnalysisDataLoading,
        queryTable,
        setQueryTable,
        cards,
        cardsFilters,
        setCardsFilters,
        drivers,
        vehicles,
        resetFilters,
      }}
    />
  );
};
