/** @format */

import { useMutation } from "@apollo/client";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { dollarFormatter } from "@roadflex/constants";
import {
  READ_CURRENT_USER_AUTH,
  STRIPE_CREATE_OFF_SESSION_PAYMENT,
} from "@roadflex/graphql";
import { Business } from "@roadflex/types";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import { StripeAddNewCardButton } from "../stripe-payment-methods/StripeAddNewCardButton";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;
interface DepositUIProps {
  depositAmount: number;
  business: Business | null;
  validateValue: (value?: string) => void;
  payAmount: string | number;
  customerType: ENUM_CUSTOMER_TYPE;
}

export const DepositUI = ({
  depositAmount,
  business,
  validateValue,
  payAmount,
  customerType,
}: DepositUIProps) => {
  const [createPaymentFn, { loading: processingPayment }] = useMutation<{
    stripeCreateOffSessionPayment: {
      code: string;
      message: string;
    };
  }>(STRIPE_CREATE_OFF_SESSION_PAYMENT, {
    fetchPolicy: "no-cache",
    refetchQueries: [READ_CURRENT_USER_AUTH],
  });

  const router = useRouter();

  const onSave = async () => {
    try {
      const res = await createPaymentFn();
      if (res?.data?.stripeCreateOffSessionPayment.code === "200") {
        toast.success(res?.data?.stripeCreateOffSessionPayment.message);
        router.push("/dashboard");
      } else {
        toast.error(
          res?.data?.stripeCreateOffSessionPayment.message ||
            "Something went wrong.",
        );
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <>
      <div id="panel-1" className="w-full">
        <div className="relative flex bg-brown-800">
          <div className="flex flex-row container 3xl:max-w-[1750px!important] md:items-center relative justify-center md:justify-start w-full  min-h-[50vh] md:min-h-[60vh]  md:text-left max-h-[1500px]">
            <div
              className="flex flex-col items-center justify-between w-full gap-8 py-8 md:h-full md:flex-row md:px-10"
              style={{ zIndex: "1" }}
            >
              <div className="flex flex-col items-center md:items-start text-black max-w-[550px] 3xl:max-w-[750px]">
                <div className="my-2 lg:!leading-snug text-2xl text-center md:text-left md:text-3xl lg:text-4xl 3xl:text-6xl">
                  <div className="font-bold text-white">
                    You&apos;ve been approved!
                  </div>
                </div>
                <div className="my-2 lg:!leading-snug text-2xl text-center md:text-left md:text-3xl lg:text-4xl 3xl:text-6xl">
                  <div className="font-bold text-white">
                    Welcome to RoadFlex!
                  </div>
                </div>
                <div className="flex flex-col mt-6 space-y-4 text-sm md:text-base">
                  <div className="flex items-center">
                    <Image
                      layout="fixed"
                      width={"20"}
                      height={"20"}
                      src={`${NEXT_PUBLIC_CDN_URL}/contact-us/svg/percent-white.svg`}
                      alt="cards"
                    ></Image>
                    <span className="ml-4 font-medium text-white">
                      Save on average up to 43&#162; per gallon
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Image
                      layout="fixed"
                      width={"20"}
                      height={"20"}
                      src={`${NEXT_PUBLIC_CDN_URL}/contact-us/svg/card-error-white.svg`}
                      alt="cards"
                    ></Image>
                    <span className="ml-4 font-medium text-white">
                      Prevent and eliminate fuel card fraud
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Image
                      layout="fixed"
                      width={"20"}
                      height={"20"}
                      src={`${NEXT_PUBLIC_CDN_URL}/contact-us/svg/world-white.svg`}
                      alt="cards"
                    ></Image>
                    <span className="ml-4 font-medium text-white">
                      Make vehicle related purchases everywhere Visa is accepted
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Image
                      layout="fixed"
                      width={"20"}
                      height={"20"}
                      src={`${NEXT_PUBLIC_CDN_URL}/contact-us/svg/card-dollar-white.svg`}
                      alt="cards"
                    ></Image>
                    <span className="ml-4 font-medium text-white">
                      Direct drivers to the best stations to fuel at
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Image
                      layout="fixed"
                      width={"20"}
                      height={"20"}
                      src={`${NEXT_PUBLIC_CDN_URL}/contact-us/svg/headphone-white.svg`}
                      alt="cards"
                    ></Image>
                    <span className="ml-4 font-medium text-white">
                      Around the clock premier support
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Image
                      layout="fixed"
                      width={"20"}
                      height={"20"}
                      src={`${NEXT_PUBLIC_CDN_URL}/contact-us/svg/clipboard-white.svg`}
                      alt="cards"
                    ></Image>
                    <span className="ml-4 font-medium text-white">
                      Solve driver lock-outs with the RoadFlex Driver App
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-[50%] h-auto max-h-[850px] 3xl:max-w-[900px]">
                <div className="relative">
                  <Image
                    layout="responsive"
                    width={"1000"}
                    height={"630"}
                    className="flter"
                    src={`${NEXT_PUBLIC_CDN_URL}/common/footer/images/footer-card.png`}
                    alt="Contact RoadFlex background image"
                  ></Image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 px-4 m-4 md:px-8 md:my-12">
        <div className="grid grid-cols-7 gap-6 py-4">
          <div className="col-span-7 md:col-span-3">
            <div className="w-full">
              <div className="flex items-center gap-4 mb-4 text-lg font-semibold md:font-bold md:text-xl">
                {(customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
                  customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
                  customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
                  <div>Make a deposit with your debit card</div>
                )}
                {(customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
                  customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
                  customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE) && (
                  <div>Make payment for set-up fee</div>
                )}
              </div>
              <div className="flex items-stretch w-full">
                <div className="w-full md:pr-6">
                  <div className="sm:flex">
                    <div className="relative w-full border rounded-xl">
                      <CurrencyInput
                        placeholder="$"
                        className="block w-full h-full p-3 pr-12 bg-white border rounded-xl placeholder-brown-700 placeholder-opacity-80 sm:max-w-full focus:outline-none sm:text-base border-brown-700"
                        allowDecimals={true}
                        onValueChange={validateValue}
                        prefix={"$"}
                        step={10}
                        value={depositAmount}
                        disabled={true}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="price-currency"
                        >
                          USD
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 rounded-xl sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <StripeAddNewCardButton
                        onDeposit={onSave}
                        loading={processingPayment}
                        disabled={
                          +payAmount <= 0 ||
                          Number.isNaN(Number(payAmount)) ||
                          payAmount === undefined
                        }
                      ></StripeAddNewCardButton>
                    </div>
                  </div>
                  <div className="mt-3 text-sm text-gray-500 md:text-base">
                    Amount: {dollarFormatter(depositAmount)}
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="col-span-7 border-brown-500 items md:pl-12 md:border-l-2 md:col-span-4">
            <div className="pb-4 text-xl font-bold text-black border-b-2 border-brown-500">
              How it Works
            </div>
            <div className="py-4">
              {(customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
                <div className="w-full text-gray-700">
                  <p className="text-sm font-medium leading-6 md:text-base">
                    Deposit {dollarFormatter(depositAmount)} into your account
                    using only a debit card. Once we successfully receive the
                    funds, you will gain access to the platform.
                  </p>
                  <p className="mt-4 text-sm font-medium leading-6 md:text-base">
                    We do this to better serve all our customers and ensure we
                    can continue to grow in a sustainable way.
                  </p>
                  <p className="mt-4 text-sm font-medium leading-6 md:text-base">
                    This deposit is{" "}
                    <span className="font-bold text-black">refundable</span> if
                    you transact at least $1,000.00 using our cards and have no
                    outstanding balance.
                  </p>
                </div>
              )}
              {(customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE) && (
                <div className="w-full text-gray-700">
                  <p className="text-sm font-medium leading-6 md:text-base">
                    Make payment for a $25.00 set-up fee. Once we successfully
                    receive the funds, you will gain access to the platform.
                  </p>
                  <p className="mt-4 text-sm font-medium leading-6 md:text-base">
                    We do this to better serve all our customers and ensure we
                    can continue to grow in a sustainable way.
                  </p>
                  <p className="mt-4 text-sm font-medium leading-6 md:text-base">
                    This set-up fee is{" "}
                    <span className="font-bold text-black">not refundable</span>
                    .
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="mt-auto">
        <AppFooter />
      </div> */}
      </div>
    </>
  );
};
