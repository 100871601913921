/** @format */

import {
  useApplyPolicyToCards,
  useCardListSubscription,
  useDeleteCardPolicies,
  useReadAppCardData,
  useReadCardPolicy,
} from "@roadflex/react-hooks";
import { CardRule, CardShortened, UserAuthType } from "@roadflex/types";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../loader";
import { PoliciesUI } from "./policy.ui";

type CardsProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  refetch: () => void;
};

export const Policies = ({
  readCurrentUserAuth,
  loading,
  refetch,
}: CardsProps) => {
  const [openApplyCardRule, setOpenApplyCardRule] = useState(false);
  const {
    readAppCardData,
    loading: readAppCardDataLoading,
    refetch: readAppCardDataRefetch,
  } = useReadAppCardData(true, true, true, "Shortened");
  const { data: readCardPolicy, loading: readCardPolicyLoading } =
    useReadCardPolicy(null);
  const { data: cardsListSubscribed } = useCardListSubscription(
    readCurrentUserAuth.primaryOwnerId,
  );
  const { handleSubmit, values, isSubmitting, resetForm, setFieldValue } =
    useApplyPolicyToCards({
      initialValues: {
        cardRuleId: "",
        cardIdList: [""],
      },
      onSubmit(res, err) {
        resetForm();
        if (err) {
          toast.error(err.message);
          return;
        }
        if (res?.code === "200") {
          toast.success(res?.message);
        } else {
          toast.error(res?.message, { autoClose: false });
        }
      },
    });

  const {
    handleSubmitDelete,
    valuesDelete,
    isSubmittingDelete,
    resetFormDelete,
    setFieldValueDelete,
    ...rest
  } = useDeleteCardPolicies({
    initialValues: {
      cardRuleIds: [],
    },
    onSubmit(res, err) {
      resetFormDelete();
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
    },
  });
  if (loading) {
    return <Loader />;
  }

  // DataTable templates
  const deliveredCards: CardShortened[] = [];

  const cardRules: CardRule[] = [];

  const currentVehiclesSet = new Set<string>();
  const currentDriversSet = new Set<string>();

  if (cardsListSubscribed?.cards?.length) {
    cardsListSubscribed?.cards?.forEach((card: CardShortened) => {
      const newCardObj: CardShortened = {
        ...card,
      };
      if (newCardObj.fleet) {
        currentVehiclesSet.add(newCardObj?.fleet[0]?.fleetName);
      }
      if (newCardObj.fleetDriver) {
        currentDriversSet.add(newCardObj?.fleetDriver[0]?.name);
      }
      deliveredCards.push(newCardObj);
    });
  } else {
    readAppCardData?.cards?.forEach((card: CardShortened) => {
      const newCardObj: CardShortened = {
        ...card,
      };
      if (newCardObj.fleet) {
        currentVehiclesSet.add(newCardObj?.fleet[0]?.fleetName);
      }
      if (newCardObj.fleetDriver) {
        currentDriversSet.add(newCardObj?.fleetDriver[0]?.name);
      }
      deliveredCards.push(newCardObj);
    });
  }

  readCardPolicy?.readCardPolicy?.policy?.forEach((cardRule: CardRule) => {
    const newCardRuleObj: CardRule = {
      ...cardRule,
      daily:
        (cardRule.cardSpending.find((data) => data.interval === "daily")
          ?.amount || 0) / 100 || 0,
      weekly:
        (cardRule.cardSpending.find((data) => data.interval === "weekly")
          ?.amount || 0) / 100 || 0,
      monthly:
        (cardRule.cardSpending.find((data) => data.interval === "monthly")
          ?.amount || 0) / 100 || 0,
      transaction:
        (cardRule.cardSpending.find(
          (data) => data.interval === "per_authorization",
        )?.amount || 0) / 100 || 0,
    };
    cardRules.push(newCardRuleObj);
  });

  return (
    <PoliciesUI
      {...{
        readCardPolicyLoading,
        deliveredCards,
        handleSubmitDelete,
        isSubmittingDelete,
        setFieldValueDelete,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        cardRules,
        openApplyCardRule,
        setOpenApplyCardRule,
      }}
    />
  );
};
