/** @format */
import { usePlatformDetect } from "@roadflex/react-hooks";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import MerchantCategoryBreakdownGraph from "../../graphs/merchant-category-breakdown-graph.ui";
import WeeklySpendGraph from "../../graphs/weekly-spend-graph.ui";

type FleetPerformanceUIProps = {
  loading: boolean;
  weeklySpendsData: Array<[number, number]>;
  categoryBreakdownData: { [key: string]: string };
  blockedTransactionsList: {
    declineReason: string;
    numberOfOccurrences: number;
    numberOfUniqueDrivers: number;
  }[];
};

const FleetPerformanceUI = ({
  loading,
  weeklySpendsData,
  categoryBreakdownData,
  blockedTransactionsList,
}: FleetPerformanceUIProps) => {
  const { isMobile } = usePlatformDetect();

  return (
    <div className="text-sm md:mt-0 md:text-base h-full md:min-h-[calc(100vh-96px)]">
      <div className="grid grid-cols-1 gap-4 mt-2 xl:grid-cols-2 ">
        <div className="">
          <WeeklySpendGraph
            weeklySpendsData={weeklySpendsData}
            dashboardLoading={loading}
          ></WeeklySpendGraph>
        </div>
        <div className="">
          <MerchantCategoryBreakdownGraph
            categoryBreakdown={categoryBreakdownData}
            dashboardLoading={loading}
          ></MerchantCategoryBreakdownGraph>
        </div>
      </div>
      <div className="mt-8 overflow-hidden bg-white rounded-md shadow">
        <div className="p-8 font-medium bg-white step-5">
          <div className="mb-4 font-semibold text-brown-500 md:text-lg">
            Fraud Prevention: blocked transactions
          </div>
          <div className="mx-auto">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle ">
              <DataTable
                size="small"
                stripedRows
                value={blockedTransactionsList}
                loading={loading}
                dataKey="declineReason"
                style={{
                  fontFamily: "inherit",
                }}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                sortMode={"single"}
                sortField="declineReason"
                sortOrder={1}
              >
                <Column
                  field="declineReason"
                  header="Reason the transaction was declined"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  // filter
                  field="numberOfOccurrences"
                  header="Total number of occurrences"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  // filter
                  field="numberOfUniqueDrivers"
                  header="Number of unique drivers involved"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetPerformanceUI;
