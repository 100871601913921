/* eslint-disable max-lines */
/** @format */

import { useMutation } from "@apollo/client";
import {
  ArrowRightIcon,
  ChatIcon,
  ChevronRightIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import { ENUM_CLASSIFICATION, ENUM_TELEMATICS_SECURITY } from "@prisma/client";
import {
  appPolicyValidationSchema,
  CATEGORIES_MASTER,
  CATEGORY_LABELS,
  DAYS_OF_WEEK,
  OPERATIONAL_HOURS_TIMES,
} from "@roadflex/constants";
import { CARD_RULE_CREATE_UPDATE, READ_CARD_POLICY } from "@roadflex/graphql";
import {
  CardMerchants,
  CardRule,
  CardRuleDetails,
  ChildFeatureType,
  GlobalSettingType,
  OperationalHours,
  PolicyRestrictionFormikValues,
  TabsType,
} from "@roadflex/types";
import { humanize, humanizeEnum } from "@roadflex/utils";
import { showErrorMessage } from "@roadflex/web-lib";
import classNames from "classnames";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { CustomMultiSelect, Select } from "../../../dropdowns";
import { CheckboxInput, TextInput } from "../../../inputs";
import { TooltipModal } from "../../../modals";
import { FuelCardComponentPermissionGuard } from "../../../permission-guard";
import PolicyMerchantDisclosureUI from "./PolicyMerchantDisclosure.ui";
interface PolicyUIProps {
  tabs: TabsType[];
  cardRuleData: CardRule | null;
  adminCardSettingData: GlobalSettingType;
  submittingRequest?: boolean;
  setsubmittingRequest: (val: boolean) => void;
  editLimits: boolean;
  setEditLimits: (value: boolean) => void;
  editCardRules: boolean;
  setEditCardRules: (value: boolean) => void;
  merchantOriginal?: CardMerchants[];
  childFeatures: ChildFeatureType[];
}

// eslint-disable-next-line max-lines-per-function
export const PolicyUI = ({
  tabs,
  cardRuleData,
  adminCardSettingData,
  submittingRequest,
  editLimits,
  setEditLimits,
  editCardRules,
  setEditCardRules,
  setsubmittingRequest,
  merchantOriginal,
  childFeatures,
}: PolicyUIProps) => {
  const [footer, setfooter] = useState(<span>RoadFlex</span>);
  const router = useRouter();
  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);

  const [tabOpen, setTabOpen] = useState(1);
  const [createUpdateCardRuleRequest] = useMutation<{
    createUpdateCardRule: {
      message: string;
      code: string;
    };
  }>(CARD_RULE_CREATE_UPDATE, {
    refetchQueries: [READ_CARD_POLICY],
  });
  const operationalHours = cardRuleData?.operationalHours || [];
  const [fuelExpensesCategories, setFuelExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [businessExpensesCategories, setBusinessExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [vehicleExpensesCategories, setVehicleExpensesCategories] = useState<
    CardMerchants[]
  >([]);
  const [otherCategories, setOtherCategories] = useState<CardMerchants[]>([]);
  const [otherMerchantCategories, setOtherMerchantCategories] = useState<
    CardMerchants[]
  >([]);
  const [createMode, setCreateMode] = useState<boolean>(false);

  const initializeEditedOperationalHours = (
    opHours: OperationalHours[],
  ): OperationalHours[] => {
    return DAYS_OF_WEEK.map((day) => {
      const existingOpHours = opHours.find((oh) => oh.dayOfWeek === day);
      if (existingOpHours) {
        existingOpHours.isEnabled = true;
        return existingOpHours;
      }
      return {
        isEnabled: false,
        dayOfWeek: day,
        startTime: "",
        endTime: "",
      };
    });
  };

  const [tooltip, settooltip] = useState(false);
  const [editedOperationalHours, setEditedOperationalHours] = useState<
    OperationalHours[]
  >(initializeEditedOperationalHours(operationalHours));

  const access = {
    CardRulesAccess:
      childFeatures.find(
        (item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES",
      ) !== undefined,
    OperationalHoursAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_CARD_RULES_OPERATIONAL_HOURS",
        ) !== undefined,
    TransactionControlsAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_CARD_RULES")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_CARD_RULES_TRANSACTION_CONTROLS",
        ) !== undefined,
    SpendLimitsAccess:
      childFeatures.find(
        (item) => item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS",
      ) !== undefined,
    LimitsAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS")
        ?.grandchildren?.find(
          (item) =>
            item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS_SPEND_LIMIT",
        ) !== undefined,
    CategoryRestrictionsAccess:
      childFeatures
        .find((item) => item.name === "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS")
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "SPEND_POLICY_PAGE_DETAILS_SPEND_LIMITS_CATEGORY_RESTRICTIONS",
        ) !== undefined,
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    setFieldValue,
    errors,
    resetForm,
    ...rest
  } = useFormik<PolicyRestrictionFormikValues>({
    enableReinitialize: true,
    initialValues: {
      id: cardRuleData?.id || "",
      name: cardRuleData?.name || "",
      description: cardRuleData?.description || "",
      smsSecurity: cardRuleData?.smsSecurity || false,
      dailyLimit:
        (cardRuleData?.cardSpending.find((data) => data.interval === "daily")
          ?.amount || 0) / 100 ||
        adminCardSettingData?.dailyLimit / 100 ||
        "",
      weeklyLimit:
        (cardRuleData?.cardSpending.find((data) => data.interval === "weekly")
          ?.amount || 0) / 100 || "",
      monthlyLimit:
        (cardRuleData?.cardSpending.find((data) => data.interval === "monthly")
          ?.amount || 0) / 100 || "",
      transactionLimit:
        (cardRuleData?.cardSpending.find(
          (data) => data.interval === "per_authorization",
        )?.amount || 0) / 100 ||
        adminCardSettingData?.transactionLimit / 100 ||
        "",
      enableOperationalHours: cardRuleData?.enableOperationalHours || false,
      merchants: merchantOriginal,
      enableSmsReceiptUpload: cardRuleData?.enableSmsReceiptUpload || false,
      operationalHours: cardRuleData?.operationalHours,
      fuelTypeSecurity: cardRuleData?.fuelTypeSecurity || false,
      tankCapacitySecurity: cardRuleData?.tankCapacitySecurity || false,
      telematicsSecurity:
        cardRuleData?.telematicsSecurity || ENUM_TELEMATICS_SECURITY.OFF,
      odometerSecurity: cardRuleData?.odometerSecurity || false,
      suspiciousFuelingSecurity:
        cardRuleData?.suspiciousFuelingSecurity || false,
      requireJobID: cardRuleData?.requireJobID || false,
      flaggedMerchantCategories: cardRuleData?.flaggedMerchantCategories || [],
    },
    validationSchema: appPolicyValidationSchema(adminCardSettingData),
    onSubmit: async (value) => {
      const operationalHoursList = editedOperationalHours
        .filter((oh) => {
          return oh.isEnabled && oh.startTime && oh.endTime;
        })
        .map((filteredOh) => {
          return {
            dayOfWeek: filteredOh.dayOfWeek,
            startTime: filteredOh.startTime,
            endTime: filteredOh.endTime,
          };
        });

      const enableOpHours: boolean = !operationalHoursList.length
        ? false
        : value.enableOperationalHours;

      const data: CardRuleDetails = {
        id: value.id,
        name: value.name,
        description: value.description,
        smsSecurity: value.smsSecurity,
        fuelTypeSecurity: value.fuelTypeSecurity,
        tankCapacitySecurity: value.tankCapacitySecurity,
        telematicsSecurity: value.telematicsSecurity,
        odometerSecurity: value.odometerSecurity,
        requireJobID: value.requireJobID,
        suspiciousFuelingSecurity: value.suspiciousFuelingSecurity,
        monthly: value.monthlyLimit ? Number(value.monthlyLimit) * 100 : 0,
        weekly: value.weeklyLimit ? Number(value.weeklyLimit) * 100 : 0,
        daily: value.dailyLimit ? Number(value.dailyLimit) * 100 : 0,
        transaction: value.transactionLimit
          ? Number(value.transactionLimit) * 100
          : 0,
        operationalHours: operationalHoursList,
        enableOperationalHours: enableOpHours,
        merchants: value.merchants,
        enableSmsReceiptUpload: value.enableSmsReceiptUpload,
        flaggedMerchantCategories: value.flaggedMerchantCategories,
      };
      // eslint-disable-next-line no-use-before-define
      if (handleCardRuleCreateUpdate) {
        // eslint-disable-next-line no-use-before-define
        handleCardRuleCreateUpdate(data);
      }
    },
  });

  useEffect(() => {
    if (!cardRuleData) {
      setCreateMode(true);
      setEditLimits(true);
      setEditCardRules(true);
      setFieldValue("merchants", merchantOriginal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOriginal?.length]);

  useEffect(() => {
    const fuelExpenses: CardMerchants[] = [];
    const businessExpenses: CardMerchants[] = [];
    const vehicleExpenses: CardMerchants[] = [];
    const other: CardMerchants[] = [];
    const otherOption: CardMerchants[] = [];

    if (cardRuleData?.merchants) {
      cardRuleData.merchants.forEach((merchant) => {
        if (
          merchant.classification === ENUM_CLASSIFICATION.FUEL &&
          merchant.authorized
        ) {
          fuelExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.BUSINESS &&
          merchant.authorized
        ) {
          businessExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.VEHICLE &&
          merchant.authorized
        ) {
          if (merchant.name !== "auto_service_shops") {
            vehicleExpenses.push(merchant);
          }
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name !== "others"
        ) {
          other.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name === "others"
        ) {
          otherOption.push(merchant);
        }
      });
    } else {
      (merchantOriginal || []).forEach((merchant) => {
        if (
          merchant.classification === ENUM_CLASSIFICATION.FUEL &&
          merchant.authorized
        ) {
          fuelExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.BUSINESS &&
          merchant.authorized
        ) {
          businessExpenses.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.VEHICLE &&
          merchant.authorized
        ) {
          if (merchant.name !== "auto_service_shops") {
            vehicleExpenses.push(merchant);
          }
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name !== "others"
        ) {
          other.push(merchant);
        }
        if (
          merchant.classification === ENUM_CLASSIFICATION.OTHER &&
          merchant.authorized &&
          merchant.name === "others"
        ) {
          otherOption.push(merchant);
        }
      });
    }
    fuelExpenses.sort((a, b) => a.name.localeCompare(b.name));
    businessExpenses.sort((a, b) => a.name.localeCompare(b.name));
    vehicleExpenses.sort((a, b) => a.name.localeCompare(b.name));
    other.sort((a, b) => a.name.localeCompare(b.name));
    setFuelExpensesCategories(fuelExpenses);
    setBusinessExpensesCategories(businessExpenses);
    setVehicleExpensesCategories(vehicleExpenses);
    setOtherCategories(other);
    setOtherMerchantCategories(otherOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(values.merchants || []).length]);

  const backToPolicyPanel = () => {
    router.push("/policies");
  };

  const handleCardRuleCreateUpdate = (cardRuleDetails: CardRuleDetails) => {
    setsubmittingRequest(true);
    const {
      id,
      name,
      description,
      operationalHours: selectedHours,
      merchants,
      monthly,
      weekly,
      daily,
      transaction,
      enableOperationalHours,
      smsSecurity,
      fuelTypeSecurity,
      tankCapacitySecurity,
      telematicsSecurity,
      odometerSecurity,
      requireJobID,
      suspiciousFuelingSecurity,
      enableSmsReceiptUpload,
      flaggedMerchantCategories,
    } = cardRuleDetails;
    const newMerchants: CardMerchants[] = [];
    if (merchants) {
      for (let i = 0; i < merchants.length; i++) {
        const newObj = merchants[i];
        Object.keys(newObj).forEach((key) => {
          if (
            newObj[key as keyof typeof newObj] === null ||
            key === "__typename"
          ) {
            delete newObj[key as keyof typeof newObj];
          }
        });
        newMerchants.push(newObj);
      }
    }

    createUpdateCardRuleRequest({
      variables: {
        data: {
          id: id,
          name: name,
          description: description,
          operationalHours: selectedHours,
          enableOperationalHours: enableOperationalHours,
          merchants: newMerchants,
          monthly: monthly,
          weekly: weekly,
          daily: daily,
          transaction: transaction,
          smsSecurity: smsSecurity,
          fuelTypeSecurity,
          tankCapacitySecurity,
          telematicsSecurity,
          odometerSecurity,
          requireJobID,
          suspiciousFuelingSecurity,
          enableSmsReceiptUpload,
          flaggedMerchantCategories,
        },
      },
    })
      .then(({ data }) => {
        if (data?.createUpdateCardRule?.code === "200") {
          toast.success(data?.createUpdateCardRule?.message);
          if (createMode) {
            backToPolicyPanel();
          }
        } else {
          showErrorMessage(
            data?.createUpdateCardRule?.message || "Something went wrong.",
            data?.createUpdateCardRule?.code || "",
          );
        }
        setsubmittingRequest(false);
        setEditCardRules(false);
        setEditLimits(false);
      })
      .catch((err) => {
        console.error(err);
        setsubmittingRequest(false);
        toast.error(
          err?.message ||
            "Something went wrong with creating/updating the rule!",
        );
      });
  };

  const times = OPERATIONAL_HOURS_TIMES;

  const handleCheckboxChange = (dayOfWeek: string, checked: boolean) => {
    setEditedOperationalHours((prev) =>
      prev.map((oh) =>
        oh.dayOfWeek === dayOfWeek
          ? {
              ...oh,
              startTime: checked ? oh.startTime : "",
              endTime: checked ? oh.endTime : "",
              isEnabled: checked,
            }
          : oh,
      ),
    );
  };

  const handleMerchantChange = <K extends keyof CardMerchants>(
    merchantType: CardMerchants,
    value: CardMerchants[K],
    updateField: K,
  ) => {
    const newObject = values.merchants;

    if (newObject) {
      let index = newObject?.findIndex((val) => val.name === merchantType.name);
      newObject[index][updateField] = value;

      if (merchantType.name === "auto_body_repair_shops") {
        index = newObject?.findIndex(
          (val) => val.name === "auto_service_shops",
        );
        newObject[index][updateField] = value;
      }

      setFieldValue("merchants", newObject);
    }
  };

  const handleCancel = () => {
    setEditedOperationalHours(
      initializeEditedOperationalHours(operationalHours),
    );
    resetForm();
    setFieldValue(
      "merchants",
      JSON.parse(JSON.stringify(cardRuleData?.merchants)),
    );
    setEditCardRules(false);
    setEditLimits(false);
  };
  return (
    <div className="flex-col flex flex-1 pb-28 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)] md:pb-4 md:px-14">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToPolicyPanel}
                  className="!pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg">
                {cardRuleData?.name
                  ? `Spend Policy - ${values.name}`
                  : "New Spend Policy"}
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="w-full mt-5 md:mt-0">
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          {access.CardRulesAccess && (
            <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
              <div className="py-8 mt-8 text-sm bg-white rounded-md shadow md:text-base">
                <div className="flex flex-col gap-8 overflow-hidden">
                  <div className="px-4 font-medium border-gray-300 ">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Policy Details
                    </div>
                  </div>
                  <div className="h-px border-0 bg-brown-500"></div>
                  <div className="flex flex-row items-center w-full px-4">
                    <div className="w-full">
                      <div className="flex flex-row items-center justify-between border-gray-300">
                        <div className="">Name</div>
                        <div className="w-1/4">
                          <TextInput
                            name="name"
                            id="name"
                            value={values.name}
                            disabled={!createMode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Policy Name"
                            className=""
                            touched={touched.name}
                            error={touched.name && Boolean(errors.name)}
                            errorMessage={errors.name}
                          ></TextInput>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-8 mt-8 text-sm bg-white rounded-md shadow md:text-base">
                <div className="flex flex-col gap-8 overflow-hidden">
                  <div className="px-4 font-medium border-gray-300 ">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Operational Hours{" "}
                      <span className="font-normal text-yellow-600">
                        (Premium)
                      </span>
                    </div>
                  </div>
                  <div className="h-px border-0 bg-brown-500"></div>
                  <div className="flex flex-row items-center w-full px-4 ">
                    <div className="w-full">
                      <div className="flex flex-row items-center justify-between w-full mb-4">
                        <div className="text-left">
                          Limit spending by days and times in PST?{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="ml-4 text-left">
                          <FuelCardComponentPermissionGuard
                            type="inputswitch"
                            featurePermission={access.OperationalHoursAccess}
                          >
                            <InputSwitch
                              name="enableOperationalHours"
                              checked={values.enableOperationalHours}
                              onChange={handleChange}
                              disabled={!editCardRules}
                              className="border-0 input-switch"
                            />
                          </FuelCardComponentPermissionGuard>
                        </div>
                      </div>
                      {/* <div className="mt-2 text-xs italic text-left text-gray-500">
                                              Transactions outside the operational
                                              hours will always be declined
                                            </div> */}
                      <div className="flex flex-col divide-y divide-gray-300">
                        {editedOperationalHours.map((dayOperationalHours) => {
                          const disableEdit = !(
                            dayOperationalHours.isEnabled &&
                            values.enableOperationalHours &&
                            editCardRules
                          );
                          return (
                            <div
                              key={dayOperationalHours.dayOfWeek}
                              className={classNames(
                                dayOperationalHours.isEnabled
                                  ? "text-black"
                                  : "text-gray-500",
                                "flex flex-col gap-3 sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between py-4 first:pt-0 last:pb-0 border-gray-300",
                              )}
                            >
                              <div className="flex flex-row items-center">
                                <CheckboxInput
                                  label={dayOperationalHours.dayOfWeek.toLowerCase()}
                                  labelClass="capitalize"
                                  id={`isEnabled-${dayOperationalHours.dayOfWeek}`}
                                  type="checkbox"
                                  name={`isEnabled-${dayOperationalHours.dayOfWeek}`}
                                  checked={dayOperationalHours.isEnabled}
                                  disabled={
                                    !values.enableOperationalHours ||
                                    !editCardRules
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      dayOperationalHours.dayOfWeek,
                                      e.target.checked,
                                    )
                                  }
                                ></CheckboxInput>
                              </div>
                              <div className="flex flex-row items-center">
                                <Select
                                  id="start-time"
                                  value={dayOperationalHours.startTime}
                                  disabled={disableEdit}
                                  onChange={(e) =>
                                    setEditedOperationalHours((prev) =>
                                      prev.map((oh) =>
                                        oh.dayOfWeek ===
                                        dayOperationalHours.dayOfWeek
                                          ? {
                                              ...oh,
                                              startTime: e.target.value,
                                            }
                                          : oh,
                                      ),
                                    )
                                  }
                                  placeholder="-- Select --"
                                  className="w-[120px]"
                                  optionLabel="label"
                                  optionValue="value"
                                  options={[
                                    {
                                      label: "-- Select --",
                                      value: "",
                                    },
                                  ].concat(
                                    times.map((time) => {
                                      return { label: time, value: time };
                                    }),
                                  )}
                                ></Select>
                                <label className="m-4">to</label>
                                <Select
                                  id="end-time"
                                  value={dayOperationalHours.endTime}
                                  disabled={disableEdit}
                                  onChange={(e) =>
                                    setEditedOperationalHours((prev) =>
                                      prev.map((oh) =>
                                        oh.dayOfWeek ===
                                        dayOperationalHours.dayOfWeek
                                          ? {
                                              ...oh,
                                              endTime: e.target.value,
                                            }
                                          : oh,
                                      ),
                                    )
                                  }
                                  placeholder="-- Select --"
                                  className="w-[120px]"
                                  optionLabel="label"
                                  optionValue="value"
                                  options={[
                                    {
                                      label: "-- Select --",
                                      value: "",
                                    },
                                  ].concat(
                                    times
                                      .slice(
                                        times.findIndex(
                                          (time) =>
                                            time ===
                                            dayOperationalHours.startTime,
                                        ),
                                      )
                                      .map((time) => {
                                        return { label: time, value: time };
                                      }),
                                  )}
                                ></Select>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-8 mt-8 text-sm bg-white rounded-md shadow md:text-base">
                <div className="flex flex-col gap-8 overflow-hidden">
                  <div className="px-4 font-medium border-gray-300">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Transaction Controls{" "}
                      <span className="font-normal text-yellow-600">
                        (Premium)
                      </span>
                    </div>
                  </div>
                  <div className="h-px border-0 bg-brown-500"></div>
                  <div className="flex flex-col px-4 divide-y divide-gray-300">
                    <div className="flex flex-row justify-between pb-4">
                      <div className="flex flex-col">
                        <div>
                          Card unlock through SMS or driver App{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Ensure card can only be used by a verified driver
                        </div>
                        <div className="mt-2 text-sm">
                          <Button
                            onClick={() => {
                              settooltip(true);
                            }}
                            variant={ButtonVariant.Transparent}
                            size={ButtonSize.Wrap}
                            className="text-orange-500"
                          >
                            {/* <ExclamationCircleIcon
                            className="w-5 h-5 text-yellow-600"
                            aria-hidden="true"
                          ></ExclamationCircleIcon> */}
                            How it works?
                          </Button>
                          {tooltip && (
                            <TooltipModal
                              title={"Card Unlock Security"}
                              body={
                                <div className="text-sm md:text-base">
                                  <div className="mb-3">
                                    <div className="mb-5">
                                      By enabling, only verified users will be
                                      able to use this card, so make sure they
                                      are added on the driver&apos;s page.
                                      Before using the card, drivers must verify
                                      their identity via the following method:
                                    </div>
                                  </div>
                                  <div className="flex flex-row">
                                    <div className="p-2 mr-2 bg-gray-200 rounded-md h-fit">
                                      <ChatIcon
                                        className="w-10 h-10"
                                        aria-hidden="true"
                                      ></ChatIcon>
                                    </div>
                                    <div>
                                      <div className="font-medium">
                                        Unlock via SMS
                                      </div>
                                      <div className="">
                                        Text the last 4 digits of your card to
                                        (315) 888-5469. The card will be
                                        unlocked for 30 minutes.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              setShow={settooltip}
                              show={tooltip}
                              footer={footer}
                              setFooter={setfooter}
                            ></TooltipModal>
                          )}
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.smsSecurity}
                            name="smsSecurity"
                            id="smsSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                        </FuelCardComponentPermissionGuard>
                        {values.smsSecurity && (
                          <div className="ml-2">Active</div>
                        )}
                        {!values.smsSecurity && (
                          <div className="ml-2">Inactive</div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Require an odometer reading before every fuel
                          transaction{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Odometer reading needs to be submitted to unlock the
                          card
                        </div>
                        {errors.odometerSecurity && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.odometerSecurity}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.odometerSecurity}
                            name="odometerSecurity"
                            id="odometerSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.odometerSecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.odometerSecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Require a transaction tag ID{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Drivers will be prompted for a transaction tag ID in
                          the driver app after the card is swiped
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.requireJobID}
                            name="requireJobID"
                            id="requireJobID"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.requireJobID && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.requireJobID && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Enable Receipt Upload via SMS{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Drivers will receive a text message after the card is
                          swiped
                        </div>
                        {errors.enableSmsReceiptUpload && (
                          <div className="mt-2 text-sm text-red-500">
                            {errors.enableSmsReceiptUpload}
                          </div>
                        )}
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.enableSmsReceiptUpload}
                            name="enableSmsReceiptUpload"
                            id="enableSmsReceiptUpload"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.enableSmsReceiptUpload && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.enableSmsReceiptUpload && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag for wrong fuel type{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Transactions will be flagged if the purchased fuel
                          type doesn't match the fuel type in vehicle settings
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.fuelTypeSecurity}
                            name="fuelTypeSecurity"
                            id="fuelTypeSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.fuelTypeSecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.fuelTypeSecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag when fuel purchase exceeds tank capacity{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          {" "}
                          Transactions will be flagged if the fuel purchased is
                          greater than the vehicle tank capacity
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.tankCapacitySecurity}
                            name="tankCapacitySecurity"
                            id="tankCapacitySecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.tankCapacitySecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.tankCapacitySecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag when fuel level anomaly is detected/fuel theft
                          detected{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Transactions will be flagged if the fuel level within
                          the vehicle drops beyond an acceptable limit between 2
                          consecutive vehicle fueling
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="inputswitch"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <InputSwitch
                            checked={values.suspiciousFuelingSecurity}
                            name="suspiciousFuelingSecurity"
                            id="suspiciousFuelingSecurity"
                            disabled={!editCardRules}
                            onChange={handleChange}
                            className="ml-4 border-0 input-switch disabled:opacity-50"
                          />
                          {values.suspiciousFuelingSecurity && (
                            <div className="ml-2">Active</div>
                          )}
                          {!values.suspiciousFuelingSecurity && (
                            <div className="ml-2">Inactive</div>
                          )}
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between py-4">
                      <div className="flex flex-col">
                        <div className="">
                          Flag transactions from specific merchant categories{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="dropdown"
                          featurePermission={access.TransactionControlsAccess}
                          messageType="premium"
                        >
                          <CustomMultiSelect
                            value={values.flaggedMerchantCategories}
                            options={[
                              {
                                label: "-- Select multiple options --",
                                value: "",
                                disabled: true,
                              },
                              {
                                label: "auto_service_shops",
                                value: "auto_service_shops",
                                disabled: false,
                              },
                            ].concat(
                              CATEGORIES_MASTER.split(",").map((categories) => {
                                return {
                                  label: categories,
                                  value: categories,
                                  disabled: false,
                                };
                              }),
                            )}
                            onChange={(e) =>
                              setFieldValue(
                                "flaggedMerchantCategories",
                                e.value,
                              )
                            }
                            placeholder="-- Select multiple options --"
                            optionLabel="label"
                            optionValue="value"
                            maxSelectedLabels={1}
                            disabled={!editCardRules}
                          ></CustomMultiSelect>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between pt-4">
                      <div className="flex flex-col">
                        <div className="">
                          Alert/Decline transaction when vehicle is far away
                          from the merchant{" "}
                          <span className="font-normal text-yellow-600">
                            (Premium)
                          </span>
                        </div>
                        <div className="mt-2 text-sm">
                          Connect a telematics provider to get started
                        </div>
                      </div>
                      <div className="text-base">
                        <FuelCardComponentPermissionGuard
                          type="dropdown"
                          featurePermission={access.TransactionControlsAccess}
                        >
                          <Select
                            value={values.telematicsSecurity}
                            disabled={!editCardRules}
                            onChange={(e) =>
                              setFieldValue("telematicsSecurity", e.value)
                            }
                            onBlur={handleBlur}
                            name="telematicsSecurity"
                            id="telematicsSecurity"
                            className="min-w-[150px]"
                            options={[
                              {
                                label: "--- Select ---",
                                value: "",
                                disabled: true,
                              },
                            ].concat(
                              Object.keys(ENUM_TELEMATICS_SECURITY)?.map(
                                (telemetics) => {
                                  return {
                                    label: humanizeEnum(telemetics),
                                    value: telemetics,
                                    disabled: false,
                                  };
                                },
                              ),
                            )}
                          ></Select>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {createMode && (
                  <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-2">
                    <Button
                      variant={ButtonVariant.Green}
                      size={ButtonSize.Wrap}
                      className="flex items-center justify-center w-12 h-12 rounded-full"
                      disabled={submittingRequest}
                      onClick={() => {
                        setTabOpen(2);
                      }}
                    >
                      <ArrowRightIcon className="w-6 h-6" />
                    </Button>
                  </div>
                )}

                {!createMode && !editCardRules && (
                  <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      size={ButtonSize.AppSize}
                      variant={ButtonVariant.Green}
                      disabled={submittingRequest}
                      onClick={() => {
                        setEditCardRules(true);
                      }}
                    >
                      <PencilIcon className="w-5 h-5" />
                      <span className="pl-2 text-base">Edit</span>
                    </Button>
                  </div>
                )}
                {!createMode && editCardRules && (
                  <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Red}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Black}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      loading={submittingRequest}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          {access.SpendLimitsAccess && (
            <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-6`}>
              <div className="py-8 mt-8 text-sm bg-white rounded-md shadow md:text-base">
                <div className="flex flex-col gap-8 overflow-hidden">
                  <div className="px-4 font-medium border-gray-300">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Spend Limits
                    </div>
                  </div>
                  <div className="h-px border-0 bg-brown-500"></div>
                  <div className="flex flex-col px-4 divide-y divide-gray-300">
                    <div className="flex flex-row items-center justify-between pb-4 border-gray-300">
                      <div className="">Per Transaction Limit</div>
                      <div className="text-right">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="transactionLimit"
                            id="transactionLimit"
                            value={values.transactionLimit}
                            onChange={handleChange}
                            disabled={!editLimits}
                            onBlur={handleBlur}
                            placeholder="1000"
                            touched={touched.transactionLimit}
                            error={
                              touched.transactionLimit &&
                              Boolean(errors.transactionLimit)
                            }
                            errorMessage={errors.transactionLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between py-4 border-gray-300">
                      <div className="">Daily Limit</div>
                      <div className="text-right">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="dailyLimit"
                            id="dailyLimit"
                            value={values.dailyLimit}
                            onChange={handleChange}
                            disabled={!editLimits}
                            onBlur={handleBlur}
                            placeholder="100"
                            touched={touched.dailyLimit}
                            error={
                              touched.dailyLimit && Boolean(errors.dailyLimit)
                            }
                            errorMessage={errors.dailyLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between py-4 border-gray-300">
                      <div className="">Weekly Limit</div>
                      <div className="text-right">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="weeklyLimit"
                            id="weeklyLimit"
                            value={values.weeklyLimit}
                            onChange={handleChange}
                            disabled={!editLimits}
                            onBlur={handleBlur}
                            placeholder="1000"
                            touched={touched.weeklyLimit}
                            error={
                              touched.weeklyLimit && Boolean(errors.weeklyLimit)
                            }
                            errorMessage={errors.weeklyLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between pt-4 border-gray-300">
                      <div className="">Monthly Limit</div>
                      <div className="capitalize">
                        <FuelCardComponentPermissionGuard
                          type="input"
                          featurePermission={access.LimitsAccess}
                        >
                          <TextInput
                            type="number"
                            className="max-w-[205px]"
                            min={0}
                            name="monthlyLimit"
                            id="monthlyLimit"
                            value={values.monthlyLimit}
                            onChange={handleChange}
                            disabled={!editLimits}
                            onBlur={handleBlur}
                            placeholder="5000"
                            touched={touched.monthlyLimit}
                            error={
                              touched.monthlyLimit &&
                              Boolean(errors.monthlyLimit)
                            }
                            errorMessage={errors.monthlyLimit}
                          ></TextInput>
                        </FuelCardComponentPermissionGuard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-8 mt-8 text-sm bg-white rounded-md shadow md:text-base">
                <div className="flex flex-col overflow-hidden">
                  <div className="px-4 mb-8 border-gray-300">
                    <div className="font-semibold text-brown-500 md:text-lg">
                      Category Restrictions{" "}
                      <span className="font-normal text-yellow-600">
                        {" "}
                        (Premium)
                      </span>
                    </div>
                    <div className="mt-2 text-sm">
                      Block or restrict spending on this card to merchant
                      categories
                    </div>
                  </div>
                  <div className="h-px border-0 bg-brown-500"></div>
                  <div className="px-4">
                    <fieldset className="space-y-0">
                      {fuelExpensesCategories?.length > 0 && (
                        <>
                          <div className="pt-8 text-left">Fuel Expenses</div>
                          {fuelExpensesCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <PolicyMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleSubmit,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  ...rest,
                                }}
                              ></PolicyMerchantDisclosureUI>
                            </div>
                          ))}
                        </>
                      )}

                      {vehicleExpensesCategories?.length > 0 && (
                        <>
                          <div className="pt-8 text-left">Vehicle Expenses</div>
                          {vehicleExpensesCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <PolicyMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleSubmit,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  ...rest,
                                }}
                              ></PolicyMerchantDisclosureUI>
                            </div>
                          ))}
                        </>
                      )}
                      {businessExpensesCategories.length > 0 && (
                        <>
                          <div className="pt-8 text-left">
                            Business Expenses
                          </div>
                          {businessExpensesCategories.map((merchantType) => (
                            <div key={merchantType.name}>
                              <PolicyMerchantDisclosureUI
                                {...{
                                  access: access.CategoryRestrictionsAccess,
                                  editLimits,
                                  merchantType,
                                  handleChange,
                                  resetForm,
                                  handleSubmit,
                                  handleBlur,
                                  values,
                                  touched,
                                  errors,
                                  setFieldValue,
                                  ...rest,
                                }}
                              ></PolicyMerchantDisclosureUI>
                            </div>
                          ))}
                        </>
                      )}
                      {otherCategories?.length > 0 && (
                        <>
                          <div className="w-full">
                            <div className="pt-8 font-medium text-left">
                              Additional Merchants Enabled
                            </div>
                            <div className="mt-2 text-xs italic text-left text-gray-500">
                              Additional merchant types that are opened up for
                              your account your account
                            </div>
                          </div>
                          {otherCategories.map((merchantType) => (
                            <PolicyMerchantDisclosureUI
                              {...{
                                access: access.CategoryRestrictionsAccess,
                                editLimits,
                                merchantType,
                                handleChange,
                                resetForm,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                                ...rest,
                              }}
                            ></PolicyMerchantDisclosureUI>
                          ))}
                          {otherMerchantCategories.map((merchantType) => (
                            <div
                              className="relative flex items-center justify-between py-4 border-b divide-gray-300"
                              key={merchantType.name}
                            >
                              <div className="pl-4 ml-4 mr-3 text-sm text-left">
                                <label htmlFor={merchantType.name} className="">
                                  {(CATEGORY_LABELS[merchantType.name] &&
                                    CATEGORY_LABELS[merchantType.name]
                                      ?.label) ||
                                    humanize(merchantType.name)}
                                </label>
                              </div>
                              <div className="flex items-center h-6 pr-4">
                                <InputSwitch
                                  id={merchantType.name}
                                  name={merchantType.name}
                                  checked={
                                    values?.merchants?.filter(
                                      (value) =>
                                        value.name === merchantType.name,
                                    )[0].toggleOn
                                  }
                                  onChange={(e) =>
                                    handleMerchantChange(
                                      merchantType,
                                      e.target.value,
                                      "toggleOn",
                                    )
                                  }
                                  disabled={!editLimits}
                                  className="border-0 input-switch"
                                />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>
              <div>
                {!editLimits && (
                  <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Green}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      onClick={() => {
                        setEditLimits(true);
                      }}
                    >
                      <PencilIcon className="w-5 h-5" />
                      <span className="pl-2 text-base">Edit</span>
                    </Button>
                  </div>
                )}
                {editLimits && (
                  <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
                    {!createMode && (
                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Red}
                        size={ButtonSize.AppSize}
                        disabled={submittingRequest}
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Black}
                      size={ButtonSize.AppSize}
                      disabled={submittingRequest}
                      loading={submittingRequest}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
