/** @format */
import { DownloadIcon } from "@heroicons/react/outline";
import { AccountDocumentType, UserAuthType } from "@roadflex/types";
import getConfig from "next/config";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { dateTemplate } from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

interface DocumentsUIProps {
  accountDocuments: AccountDocumentType[];
  readCurrentUserAuth: UserAuthType;
  isMobile: () => boolean;
}

export const DocumentsUI = ({
  accountDocuments,
  readCurrentUserAuth,
  isMobile,
}: DocumentsUIProps) => {
  const nameTemplate = (rowData: AccountDocumentType) => {
    const JSONObject = JSON.parse(JSON.stringify(rowData?.metaData));
    return <div className="flex items-center">{JSONObject?.name || "-"}</div>;
  };
  const filterTemplate = (rowData: AccountDocumentType) => {
    return <div className="flex items-center">{rowData?.fileType}</div>;
  };
  const downloadTemplate = (rowData: AccountDocumentType) => {
    const filePath =
      `${rowData?.folderName}/${rowData?.fileName}.${rowData?.fileExtension}` ||
      "";
    return (
      <a
        href={`${NEXT_PUBLIC_API_URL}/api/files/${filePath}`}
        rel="noreferrer"
        target="_blank"
      >
        <DownloadIcon className="w-5 h-5 mr-2"></DownloadIcon>
      </a>
    );
  };
  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Account Documents`.toUpperCase()}
      </div>
      <div className="p-8 mt-8 overflow-hidden bg-white rounded-md shadow step-4">
        <div className="font-medium">
          <div className="min-w-full mt-4 overflow-hidden overflow-x-auto align-middle ">
            {/* <TableAlt data={authorizationList} rowsPerPage={10} /> */}
            <DataTable
              size="small"
              stripedRows
              loading={false}
              value={accountDocuments}
              paginator
              rows={10}
              dataKey="id"
              sortField="createdAt"
              sortOrder={-1}
              rowsPerPageOptions={[10, 20, 50]}
              paginatorClassName="!py-1"
              paginatorTemplate={newPaginationTemplate}
              emptyMessage="No available records"
              style={{ fontFamily: "inherit" }}
              responsiveLayout={isMobile() ? "stack" : "scroll"}
              className="custom-table"
            >
              <Column
                sortable
                dataType="date"
                filterField="date"
                field="date"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
                body={(r) =>
                  dateTemplate(
                    r.createdAt,
                    readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                  )
                }
              />
              <Column
                sortable
                filterPlaceholder="Search by Name"
                field="Document Name"
                header="Document Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={nameTemplate}
              />
              <Column
                sortable
                filterPlaceholder="Search by Type"
                field="Document Type"
                header="Document Type"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={filterTemplate}
                filterMenuClassName="custom-filter"
              />
              <Column
                field="Download"
                header="Download"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={downloadTemplate}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};
