import { useMutation } from "@apollo/client";
import {
  LIST_CUSTOMER_PAYMENT_METHODS,
  STRIPE_ADD_NEW_PAYMENT_METHOD,
} from "@roadflex/graphql";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StripeAddNewCardButtonUI } from "./StripeAddNewCardButton.ui";

interface StripeAddNewCardButtonProps {
  onRepaymentActivate?: () => void;
  onDeposit?: () => void;
  buttonName?: "Connect Debit Card" | "Add a debit card";
  disabled?: boolean;
  loading?: boolean;
}
export const StripeAddNewCardButton = ({
  onRepaymentActivate,
  onDeposit,
  disabled,
  loading,
  buttonName,
}: StripeAddNewCardButtonProps) => {
  const [addPaymentMethodFn, { loading: addingPaymentMethod }] = useMutation(
    STRIPE_ADD_NEW_PAYMENT_METHOD,
    {
      refetchQueries: onDeposit ? [] : [LIST_CUSTOMER_PAYMENT_METHODS],
    },
  );

  const [clientSecret, setClientSecret] = useState("");
  const [showSetupIntentForm, setShowSetupIntentForm] = useState(false);

  const addPaymentMethod = async () => {
    try {
      const resp = await addPaymentMethodFn();
      setClientSecret(resp?.data?.stripeAddNewPaymentMethod?.clientSecret);
      setShowSetupIntentForm(true);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <StripeAddNewCardButtonUI
      addPaymentMethod={addPaymentMethod}
      clientSecret={clientSecret}
      addingPaymentMethod={addingPaymentMethod}
      showSetupIntentForm={showSetupIntentForm}
      setShowSetupIntentForm={setShowSetupIntentForm}
      onRepaymentActivate={onRepaymentActivate}
      onDeposit={onDeposit}
      buttonName={buttonName}
      disabled={disabled}
      loading={loading}
    ></StripeAddNewCardButtonUI>
  );
};
