/** @format */
import { useQuery } from "@apollo/client";
import { ADDRESSES } from "@roadflex/graphql";
import { AddressType } from "@roadflex/types";

type useReadAddressesProps = {
  businessId: string | null | undefined;
};

export const useReadAddresses = ({ businessId }: useReadAddressesProps) => {
  const { data, ...rest } = useQuery<{
    addresses: {
      addresses: AddressType[];
      isSameAddress: boolean;
    };
  }>(ADDRESSES, {
    variables: {
      where: {
        businessId,
      },
    },
    fetchPolicy: "no-cache",
    // skip: !!businessId !== true,
  });

  return { data: data?.addresses, ...rest };
};
