/** @format */
import { useMutation } from "@apollo/client";
import { ENUM_ADDRESS_TYPE } from "@prisma/client";
import { onboardAdditionalBusinessInfoSchema } from "@roadflex/constants";
import { UPDATE_ADDITIONAL_BUSINESS_INFORMATION } from "@roadflex/graphql";
import { AdditionalBusinessInfoValues } from "@roadflex/types";
import { useFormik } from "formik";

type UseAdditionaBusinessInfoUpdateProps = {
  onSubmit: (val: { success: boolean } | null, err: Error | null) => void;
  initialValues: AdditionalBusinessInfoValues;
};

export const useAdditionaBusinessInfoUpdate = ({
  onSubmit,
  initialValues,
}: UseAdditionaBusinessInfoUpdateProps) => {
  const [saveAdditionalBusinessInfo] = useMutation(
    UPDATE_ADDITIONAL_BUSINESS_INFORMATION,
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: onboardAdditionalBusinessInfoSchema,
    onSubmit: async (value) => {
      const list = value.differentDeliveryAddress
        ? [{ ...value.businessAddress }, { ...value.deliveryAddress }]
        : [
            {
              ...value.businessAddress,
              type: ENUM_ADDRESS_TYPE.PERMANENT,
            },
            {
              ...value.businessAddress,
              type: ENUM_ADDRESS_TYPE.DELIVERY,
            },
          ];
      const data = {
        deliveryType: value.deliveryType,
        addressList: list,
        weeklyfuelSpends:
          parseFloat((value.fuel as string).replace("$", "")) * 100,
        fleetNum: parseInt(value.fleetNum as string, 10),
        fleetType: value.fleetType,
        annualBusinessRevenue:
          parseFloat((value.annualBusinessRevenue as string).replace("$", "")) *
          100,
        isSameAddress: !value.differentDeliveryAddress,
      };

      try {
        const res = await saveAdditionalBusinessInfo({
          variables: {
            data,
          },
        });
        onSubmit(res?.data?.updateBusinessAddress, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
