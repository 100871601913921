/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_ALERTS,
  READ_ALERTS,
  READ_USER_NOTIFICATIONS,
  UPDATE_ALERTS,
} from "@roadflex/graphql";
import { AlertsInput, AlertType, NotificationsType } from "@roadflex/types";
import { useFormik } from "formik";
import { DataTablePFSEvent } from "primereact/datatable";

type useUpdateAlertProps = {
  initialValues: AlertsInput;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useDeleteAlertProps = {
  initialValues: AlertsInput;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useReadAlert = ({
  alertsLazyParams,
  readCountsOnly,
}: {
  alertsLazyParams: DataTablePFSEvent;
  readCountsOnly: boolean;
}) => {
  const { data, loading, refetch, ...rest } = useQuery<{
    readAlerts: {
      code: string;
      message: string;
      totalCount: number;
      totalUnreadCount: number;
      alerts: AlertType[];
      latestAlerts: AlertType[];
    };
  }>(READ_ALERTS, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...alertsLazyParams,
        readCountsOnly,
      },
    },
  });
  return {
    data,
    loadingAlerts: loading,
    refetchAlerts: refetch,
    ...rest,
  };
};

export const useUpdateAlert = ({
  onSubmit,
  initialValues,
}: useUpdateAlertProps) => {
  const [updateAlert] = useMutation<{
    updateAlerts: {
      code: string;
      message: string;
    };
  }>(UPDATE_ALERTS, {
    refetchQueries: [READ_ALERTS],
  });

  const {
    handleChange: updateAlerthandleChange,
    handleSubmit: updateAlerthandleSubmit,
    values: updateAlerthandleValues,
    isSubmitting: updateAlertSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data: AlertsInput = {
        id: value.id,
        markAsRead: value.markAsRead,
      };

      try {
        const res = await updateAlert({
          variables: { data },
        });
        if (res?.data?.updateAlerts) {
          onSubmit(res.data.updateAlerts, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    updateAlerthandleValues,
    updateAlerthandleChange,
    updateAlerthandleSubmit,
    updateAlertSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useDeleteAlert = ({
  onSubmit,
  initialValues,
}: useDeleteAlertProps) => {
  const [deleteAlert] = useMutation<{
    deleteAlerts: {
      code: string;
      message: string;
    };
  }>(DELETE_ALERTS, {
    refetchQueries: [READ_ALERTS],
  });

  const {
    handleSubmit: archiveAlerthandleSubmit,
    values: archiveAlerthandleValues,
    isSubmitting: archiveAlertSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data: AlertsInput = {
        id: value.id,
      };

      try {
        const res = await deleteAlert({
          variables: { data },
        });
        if (res?.data?.deleteAlerts) {
          onSubmit(res.data.deleteAlerts, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    archiveAlerthandleValues,
    archiveAlerthandleSubmit,
    archiveAlertSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

type useReadNotifications = {
  onSubmit: (
    val: {
      code: string;
      message: string;
      userNotifications: NotificationsType[];
    } | null,
    err: Error | null,
  ) => void;
};

export const useReadNotificationsPage = ({
  onSubmit,
}: useReadNotifications) => {
  const [readUserNotificationsFn] = useMutation<{
    readUserNotifications: {
      userNotifications: NotificationsType[];
      code: string;
      message: string;
    };
  }>(READ_USER_NOTIFICATIONS);
  const { handleSubmit, ...rest } = useFormik({
    initialValues: {},
    onSubmit: async (value) => {
      try {
        const res = await readUserNotificationsFn();
        if (res?.data?.readUserNotifications) {
          onSubmit(res.data.readUserNotifications, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    handleSubmit,
    ...rest,
  };
};
