/** @format */

import { PasswordFormValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { TextInput } from "../inputs";

export interface SetPasswordUIProps extends FormikProps<PasswordFormValues> {
  isSubmitting: boolean;
  isSubmittingOnboardStatus: boolean;
}

const SetPasswordUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  isSubmittingOnboardStatus,
  handleBlur,
  touched,
  errors,
  ...rest
}: SetPasswordUIProps) => (
  <form className="flex flex-col w-full" onSubmit={handleSubmit}>
    <div className="mb-6 text-xl font-bold text-center text-white">
      Create Your Password
    </div>
    <div className="relative w-full">
      <TextInput
        label="Password"
        type="password"
        required
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.password && Boolean(errors.password)}
        errorMessage={errors.password}
        touched={touched.password}
        variant="responsive"
        color={"text-white"}
      ></TextInput>
    </div>
    <div className="relative w-full mt-4">
      <TextInput
        label="Confirm Password"
        type="password"
        required
        name="retypePassword"
        value={values.retypePassword}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.retypePassword && Boolean(errors.retypePassword)}
        errorMessage={errors.retypePassword}
        touched={touched.retypePassword}
        variant="responsive"
        color={"text-white"}
      ></TextInput>
    </div>
    <div className="mt-6 text-right">
      <Button
        type={ButtonType.Submit}
        variant={ButtonVariant.Orange}
        size={ButtonSize.Normal}
        className="w-full"
        disabled={isSubmitting || isSubmittingOnboardStatus}
        loading={isSubmitting || isSubmittingOnboardStatus}
      >
        Submit
      </Button>
    </div>
    <div className="mt-12 text-xs italic text-center text-white">
      You can always come back and resume by logging in with your email.
    </div>
  </form>
);

export default SetPasswordUI;
