/** @format */

import {
  useApplyCardTagsToCards,
  useCardListSubscription,
  useDeleteAccountTag,
  useReadAccountTag,
  useReadAppCardData,
} from "@roadflex/react-hooks";
import { AccountTagType, CardShortened, UserAuthType } from "@roadflex/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../../loader";
import { AccountTagsUI } from "./account-tags.ui";

const tagDescriptionTemplate = (rowData: AccountTagType) => (
  <span className="whitespace-nowrap">{rowData?.description}</span>
);

const tagIdTemplate = (rowData: AccountTagType) => {
  return <span className="whitespace-nowrap">{rowData?.tagId}</span>;
};

const tagTitleTemplate = (rowData: AccountTagType) => {
  return <span className="whitespace-nowrap">{rowData?.title}</span>;
};

const tabs = [
  { id: 1, name: "Transaction Tags", href: "", current: true },
  { id: 2, name: "Card Tags", href: "", current: false },
  { id: 3, name: "Driver Tags", href: "", current: false },
  { id: 4, name: "Vehicle Tags", href: "", current: false },
];

type AccountTagsProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};

export const AccountTags = ({
  readCurrentUserAuth,
  loading,
}: AccountTagsProps) => {
  const [currentTags, setCurrentTags] = useState([]);
  const [currentArchivedTags, setCurrentArchivedTags] = useState([]);
  const [openApplyCardTag, setOpenApplyCardTag] = useState(false);
  const [tabOpen, setTabOpen] = useState(1);

  const { data, loading: readAccountTagLoading } = useReadAccountTag({});

  const { data: cardsListSubscribed } = useCardListSubscription(
    readCurrentUserAuth.primaryOwnerId,
  );

  const { readAppCardData } = useReadAppCardData(true, true, true, "Shortened");

  const {
    handleSubmit: handleSubmitApplyCardTags,
    values: valuesApplyCardTags,
    isSubmitting: isSubmittingApplyCardTags,
    resetForm,
    setFieldValue: setFieldValueApplyCardTags,
  } = useApplyCardTagsToCards({
    initialValues: {
      cardTagId: "",
      cardIdList: [""],
    },
    onSubmit(res, err) {
      resetForm();
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
    },
  });

  const customerType = readCurrentUserAuth?.fuelCard?.customerType;
  const deliveredCards: CardShortened[] = [];

  if (cardsListSubscribed?.cards?.length) {
    cardsListSubscribed?.cards?.forEach((card: CardShortened) => {
      const newCardObj: CardShortened = {
        ...card,
      };
      deliveredCards.push(newCardObj);
    });
  } else {
    readAppCardData?.cards?.forEach((card: CardShortened) => {
      const newCardObj: CardShortened = {
        ...card,
      };
      deliveredCards.push(newCardObj);
    });
  }

  const {
    archiveAccountTagValues,
    archiveAccountTagHandleSubmit,
    archiveAccountTagSubmitting,
    setValues,
    ...rest
  } = useDeleteAccountTag({
    initialValues: {
      id: "",
    },
    onSubmit(res, err) {
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      if (err) {
        toast.error(err.message);
      }
    },
  });

  const onTabChange = (value: number) => {
    const accountTagList = data?.readAccountTags?.accountTagList || {};

    let activeTags;
    let archivedTags;

    switch (value) {
      case 1:
        activeTags = accountTagList?.TRANSACTION?.active || [];
        archivedTags = accountTagList?.TRANSACTION?.deleted || [];
        break;
      case 2:
        activeTags = accountTagList?.CARD?.active || [];
        archivedTags = accountTagList?.CARD?.deleted || [];
        break;
      case 3:
        activeTags = accountTagList?.DRIVER?.active || [];
        archivedTags = accountTagList?.DRIVER?.deleted || [];
        break;
      case 4:
        activeTags = accountTagList?.VEHICLE?.active || [];
        archivedTags = accountTagList?.VEHICLE?.deleted || [];
        break;
      default:
        activeTags = [];
        archivedTags = [];
        break;
    }

    setCurrentTags(activeTags);
    setCurrentArchivedTags(archivedTags);
    setTabOpen(value);
  };

  useEffect(() => {
    if (data && data.readAccountTags && data.readAccountTags.accountTagList) {
      onTabChange(tabOpen);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AccountTagsUI
      {...{
        currentTags,
        currentArchivedTags,
        openApplyCardTag,
        setOpenApplyCardTag,
        deliveredCards,
        loading: readAccountTagLoading,
        tagIdTemplate,
        tagTitleTemplate,
        tagDescriptionTemplate,
        values: archiveAccountTagValues,
        setValues,
        handleSubmit: archiveAccountTagHandleSubmit,
        isSubmitting: archiveAccountTagSubmitting,
        tabs,
        tabOpen,
        setTabOpen: onTabChange,
        customerType,
        readAccountDisplaySetting: readCurrentUserAuth?.accountDisplaySetting,
        ...rest,
        handleSubmitApplyCardTags,
        setFieldValueApplyCardTags,
        isSubmittingApplyCardTags,
      }}
    ></AccountTagsUI>
  );
};
