/** @format */

import { useCreditToPrepaid } from "@roadflex/react-hooks";
import getConfig from "next/config";
import { toast } from "react-toastify";
import { Button, ButtonLink, ButtonSize, ButtonVariant } from "../../buttons";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

interface NoAccessUIProps {
  isPrepaidEligible?: boolean;
}

export const NoAccessUI = ({ isPrepaidEligible }: NoAccessUIProps) => {
  const { onCreditToPrepaidFn, loading, error } = useCreditToPrepaid();

  return (
    <div className="w-full h-full min-h-[calc(100vh-128px)] flex flex-col justify-center">
      <div className="container flex flex-col items-center justify-center h-full my-8 text-center">
        <div>
          <div
            className="p-4 sm:px-20 sm:py-16 border-2 rounded-xl  min-h-[456px] w-full md:w-[650px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            {!isPrepaidEligible && (
              <div className="text-white">
                <div className="mb-12 text-xl font-semibold">
                  Application Status
                </div>
                <div className="mb-6">
                  If you recently submitted your application, you will hear back
                  from us in 3-5 business days. Prepaid applications are often
                  approved in 1 day. Check your email for updates.
                </div>
                <div className="mb-12">
                  If you received an email and recently enrolled in the prepaid
                  card program, refresh this page after 15 minutes.
                </div>
                <div className="mb-12">
                  <div className="mb-2 text-white">Any questions? </div>
                  <div className="mb-4 text-white">
                    Call (877) 870-7440 or email hello@roadflex.com
                  </div>
                  <ButtonLink
                    href={NEXT_PUBLIC_WWW_URL}
                    variant={ButtonVariant.Orange}
                    className="w-full"
                  >
                    Go to roadflex.com
                  </ButtonLink>
                </div>
              </div>
            )}
            {isPrepaidEligible && (
              <div className="text-white">
                <div className="mb-12 text-xl font-bold text-center">
                  Your application has been reviewed.
                </div>
                <div className="mb-4">
                  You are eligible to be part of the RoadFlex Prepaid Card
                  Program. Click on this button to confirm.
                </div>
                <div className="flex justify-center mb-5">
                  <Button
                    variant={ButtonVariant.SecondaryOutline}
                    size={ButtonSize.Normal}
                    disabled={loading}
                    onClick={async () => {
                      try {
                        await onCreditToPrepaidFn({
                          variables: {},
                        });
                        toast.success(
                          "You are now enrolled in the prepaid program. Refresh the page in 15 minutes.",
                        );
                      } catch (err: unknown) {
                        if (err instanceof Error) {
                          toast.error(err.message);
                        } else {
                          toast.error(
                            "An error has occurred enrolling you into the prepaid program",
                          );
                        }
                      }
                    }}
                  >
                    Confirm Prepaid Enrollment
                  </Button>
                </div>

                <div className="mt-12">
                  <div className="mb-2 text-white">Any questions? </div>
                  <div className="mb-4 text-white">
                    Call (877) 870-7440 or email hello@roadflex.com
                  </div>
                  <ButtonLink
                    href={NEXT_PUBLIC_WWW_URL}
                    variant={ButtonVariant.Orange}
                    className="w-full"
                  >
                    Go to roadflex.com
                  </ButtonLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
