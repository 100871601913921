/** @format */

import { ChevronRightIcon } from "@heroicons/react/outline";
import { ENUM_FUEL_TYPE, ENUM_ODOMETER } from "@prisma/client";
import { STATE_CODES } from "@roadflex/constants";
import { TabsType, VehicleType } from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { TextInput } from "../../../inputs";

interface VehiclesEditUIProps extends FormikProps<VehicleType> {
  isArchived: boolean;
  tabs: TabsType[];
  vehicleId?: string;
  isLicensePlateSaved?: boolean;
  update: boolean;
  setUpdate: (value: boolean) => void;
}

export const VehiclesEditUI = ({
  isArchived,
  tabs,
  handleChange,
  isSubmitting,
  values,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  resetForm,
  setFieldValue,
  setFieldTouched,
  vehicleId,
  isLicensePlateSaved = false,
  update,
  setUpdate,
}: VehiclesEditUIProps) => {
  const router = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const backToDriversVehiclesPanel = () => {
    router.push("/vehicles");
  };

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToDriversVehiclesPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg ">
                {vehicleId
                  ? `Vehicle - ${values.fleetName}`
                  : "Add New Vehicle"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-5 md:mt-0">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
            <form
              className="flex flex-col h-full p-8 overflow-y-auto bg-white rounded-md shadow"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col flex-grow h-full space-y-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Vehicle Name"}
                      required
                      value={values.fleetName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="fleetName"
                      id="fleetName"
                      touched={touched.fleetName}
                      error={touched.fleetName && Boolean(errors.fleetName)}
                      errorMessage={errors.fleetName}
                      disabled={vehicleId !== undefined}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"VIN"}
                      value={values.vin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="vin"
                      id="vin"
                      touched={touched.vin}
                      error={touched.vin && Boolean(errors.vin)}
                      errorMessage={errors.vin}
                      disabled={vehicleId !== undefined && !update}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Make"}
                      value={values.make}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="make"
                      id="make"
                      touched={touched.make}
                      error={touched.make && Boolean(errors.make)}
                      errorMessage={errors.make}
                      disabled={vehicleId !== undefined && !update}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Model"}
                      value={values.model}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="model"
                      id="model"
                      touched={touched.model}
                      error={touched.model && Boolean(errors.model)}
                      errorMessage={errors.model}
                      disabled={vehicleId !== undefined && !update}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Year"}
                      value={values.year}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="year"
                      id="year"
                      touched={touched.year}
                      error={touched.year && Boolean(errors.year)}
                      errorMessage={errors.year}
                      disabled={vehicleId !== undefined && !update}
                    ></TextInput>
                  </div>

                  <div className="relative col-span-12 sm:col-span-12">
                    <Select
                      label="State"
                      value={values?.provisionState}
                      options={[
                        {
                          label: "-- Select a State--",
                          value: "",
                          disabled: true,
                        },
                      ].concat(
                        STATE_CODES.map((m) => {
                          return {
                            label: `${m.label} (${m.value})`,
                            value: m.value,
                            disabled: false,
                          };
                        }),
                      )}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) => setFieldValue("provisionState", e.value)}
                      onBlur={() => setFieldTouched("provisionState", true)}
                      placeholder="Select"
                      touched={touched?.provisionState}
                      error={
                        touched?.provisionState &&
                        Boolean(errors?.provisionState)
                      }
                      errorMessage={errors?.provisionState}
                      disabled={vehicleId !== undefined && !update}
                    ></Select>
                  </div>

                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"License Plate*"}
                      value={values.licensePlate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="licensePlate"
                      id="licensePlate"
                      touched={touched.licensePlate}
                      error={
                        touched.licensePlate && Boolean(errors.licensePlate)
                      }
                      errorMessage={errors.licensePlate}
                      disabled={
                        (vehicleId !== undefined && !update) ||
                        isLicensePlateSaved
                      }
                    ></TextInput>
                  </div>

                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      type="number"
                      label={"Fuel Tank Capacity (gallons)"}
                      value={values.tankCapacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="tankCapacity"
                      id="tankCapacity"
                      touched={touched.tankCapacity}
                      error={
                        touched.tankCapacity && Boolean(errors.tankCapacity)
                      }
                      errorMessage={errors.tankCapacity}
                      disabled={vehicleId !== undefined && !update}
                    ></TextInput>
                  </div>

                  <div className="relative col-span-12 sm:col-span-12">
                    <Select
                      label="Fuel Type"
                      value={values?.fuelType}
                      options={[
                        {
                          label: "-- Select Fuel Type --",
                          value: "" as unknown as string,
                          disabled: true,
                        },
                      ].concat(
                        Object.keys(ENUM_FUEL_TYPE).map((m) => {
                          return {
                            label: humanizeEnum(m),
                            value: m,
                            disabled: false,
                          };
                        }),
                      )}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) => setFieldValue("fuelType", e.value)}
                      onBlur={() => setFieldTouched("fuelType", true)}
                      placeholder="-- Select Fuel Type --"
                      touched={touched?.fuelType}
                      error={touched?.fuelType && Boolean(errors?.fuelType)}
                      errorMessage={errors?.fuelType}
                      disabled={vehicleId !== undefined && !update}
                    ></Select>
                  </div>

                  <div className="flex w-full col-span-12 sm:col-span-12">
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("odometer", ENUM_ODOMETER.KMS);
                      }}
                      disabled={vehicleId !== undefined && !update}
                      className={`${
                        values.odometer === ENUM_ODOMETER.KMS
                          ? "bg-brown-500 text-white"
                          : "border-brown-500 bg-white text-gray-700"
                      } relative inline-flex items-center px-4 justify-center w-full py-2 rounded-l-md border text-xs lg:text-sm font-medium  hover:bg-brown-500 hover:text-white disabled:opacity-50`}
                    >
                      KMS
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("odometer", ENUM_ODOMETER.HOUR);
                      }}
                      disabled={vehicleId !== undefined && !update}
                      className={`${
                        values.odometer === ENUM_ODOMETER.HOUR
                          ? "bg-brown-500 text-white"
                          : "border-brown-500 bg-white text-gray-700"
                      } -ml-px relative inline-flex justify-center items-center w-full px-4 py-2 border text-xs lg:text-sm font-medium hover:bg-brown-500 hover:text-white disabled:opacity-50`}
                    >
                      HOUR
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("odometer", ENUM_ODOMETER.MILES);
                      }}
                      disabled={vehicleId !== undefined && !update}
                      className={`${
                        values.odometer === ENUM_ODOMETER.MILES
                          ? "bg-brown-500 text-white"
                          : "border-brown-500 bg-white text-gray-700"
                      } -ml-px relative inline-flex justify-center items-center w-full px-4 py-2 rounded-r-md border text-xs lg:text-sm font-medium   hover:bg-brown-500 hover:text-white disabled:opacity-50`}
                    >
                      MILES
                    </button>
                  </div>
                </div>
              </div>
              {!isArchived ? (
                <div className="flex flex-row justify-end">
                  {vehicleId ? (
                    <>
                      {!update && (
                        <div className="flex flex-row justify-end w-full mt-8">
                          <Button
                            variant={ButtonVariant.Primary}
                            size={ButtonSize.AppSize}
                            className=""
                            onClick={() => {
                              setUpdate(true);
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      )}
                      {update && (
                        <div className="flex flex-row justify-end w-full mt-8">
                          <Button
                            variant={ButtonVariant.GrayOutline}
                            size={ButtonSize.AppSize}
                            className=""
                            onClick={() => {
                              setUpdate(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant={ButtonVariant.Primary}
                            size={ButtonSize.AppSize}
                            className="ml-4"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type={ButtonType.Submit}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <Button
                      variant={ButtonVariant.AppOrange}
                      size={ButtonSize.AppSize}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      type={ButtonType.Submit}
                      className="flex flex-row items-center w-full sm:w-auto"
                    >
                      Add New Vehicle
                    </Button>
                  )}
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
