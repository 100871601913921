import { ChevronRightIcon, CreditCardIcon } from "@heroicons/react/outline";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import { CustomerPaymentMethods } from "@roadflex/types";
import { capitalizeFirstLetter } from "@roadflex/utils";
import classNames from "classnames";
import React, { useState } from "react";
import CardConnectionModal from "../../modals/card-connection-modal/card-connection-modal";
import { StripeAddNewCardButton } from "./StripeAddNewCardButton";

interface StripePaymentMethodsUIProps {
  changePaymentMethod: (paymentMethodId: string) => void;
  deletePaymentMethod: (paymentMethodId: string) => void;
  changingPaymentMethod: boolean;
  deletingPaymentMethod: boolean;
  customerPaymentMethods: CustomerPaymentMethods[];
  customerPaymentMethodsLoading: boolean;
  primaryPaymentMethodType: ENUM_PAYMENT_METHOD_TYPE;
  modal: boolean;
  setModal: (value: boolean) => void;
}
export const StripePaymentMethodsUI = (props: StripePaymentMethodsUIProps) => {
  const {
    deletingPaymentMethod,
    changePaymentMethod,
    deletePaymentMethod,
    customerPaymentMethods,
    customerPaymentMethodsLoading,
    primaryPaymentMethodType,
    modal,
    setModal,
  } = props;
  const [selectedCard, setSelectedCard] = useState<CustomerPaymentMethods>();
  return (
    <div className="w-full p-3 flex-grow-1">
      <div className="flex flex-col justify-between w-full">
        <div className="flex flex-col items-end justify-center w-full">
          <div className="flex flex-col items-center mb-2">
            <StripeAddNewCardButton
              disabled={customerPaymentMethods.length === 5}
            ></StripeAddNewCardButton>
            <span className="mt-2 text-sm italic">(Max 5 connections)</span>
          </div>
        </div>
        {customerPaymentMethodsLoading && (
          <div className="w-full mb-4 text-center">Updating...</div>
        )}
        <div className="flex flex-row flex-wrap w-full gap-2">
          {customerPaymentMethods.length > 0 &&
            customerPaymentMethods.map(
              (card: CustomerPaymentMethods, idx: number) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      setSelectedCard(card);
                      setModal(true);
                    }}
                    className={classNames(
                      "p-2  flex-grow bg-white border-brown-500 text-brown-500 min-h-[95px] sm:min-h-[125px] sm:min-w-[220px] sm:max-w-[250px] border rounded-md shadow flex flex-col justify-between hover:shadow-lg hover:cursor-pointer",
                    )}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <div className={classNames("text-sm mr-3 bg-white")}>
                        Debit Card {capitalizeFirstLetter(card.brand)}
                      </div>
                      <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>
                    </div>

                    <div className="flex items-end justify-between">
                      <div className="flex flex-col mr-3 text-sm font-bold text-gray-900">
                        {/* <CreditCardIcon className="w-6 h-6 mr-2"></CreditCardIcon> */}
                        <div>&#xB7;&#xB7;&#xB7;&#xB7; {card?.last4}</div>
                        <div className="text-sm text-gray-400 text-ellipsis">
                          Expiration: {card.exp_month}/{card.exp_year}
                        </div>
                      </div>
                      {card.default &&
                        primaryPaymentMethodType ===
                          ENUM_PAYMENT_METHOD_TYPE.CARD && (
                          <span className="text-sm">Primary</span>
                        )}
                    </div>
                  </div>
                );
              },
            )}
        </div>
        {customerPaymentMethods.length === 0 && !customerPaymentMethodsLoading && (
          <div className="h-[125px] flex justify-center items-center flex-col">
            <div>
              <CreditCardIcon className="w-20 h-12"></CreditCardIcon>
            </div>
            <div className="max-w-[200px] text-center md:text-xl">
              No cards connected
            </div>
          </div>
        )}
      </div>

      {modal && selectedCard && (
        <CardConnectionModal
          open={modal}
          setOpen={setModal}
          card={selectedCard}
          changePaymentMethod={changePaymentMethod}
          deletePaymentMethod={deletePaymentMethod}
          deletingPaymentMethod={deletingPaymentMethod}
          primaryPaymentMethodType={primaryPaymentMethodType}
        ></CardConnectionModal>
      )}
    </div>
  );
};
