/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";

type Props = {
  title: string;
  body: string | JSX.Element;
  show: boolean;
  setShow: (value: boolean) => void;
  confirm: () => void;
  submitting: boolean;
};
export function ConfirmModal({
  title,
  body,
  show,
  submitting,
  setShow,
  confirm,
}: Props) {
  // const [show, setShow] = useState(show);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 text-center md:items-end md:pb-20 sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="flex items-center justify-center mx-auto ">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <div className="mt-2">
                    <p className="text-gray-500 ">{body}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <div className="flex flex-row col-span-12">
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.SecondaryOutline}
                    size={ButtonSize.AppSize}
                    disabled={submitting}
                    className="w-full"
                    onClick={() => setShow(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    disabled={submitting}
                    loading={submitting}
                    className="w-full ml-3"
                    onClick={() => confirm()}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
