/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@apollo/client";
import { SET_SALES_ADMIN } from "@roadflex/graphql";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";

interface AddEditSalesAgentProps {
  showSalesAdminModal: boolean;
  setShowSalesAdminModal: (val: boolean) => void;
  salesAdmins: any;
  currentSelectedAgent: string;
  userEmail: string;
  refetchUserDetails: () => void;
  getUsers?: () => void;
}

export const AddEditSalesAgentModal = ({
  showSalesAdminModal,
  setShowSalesAdminModal,
  salesAdmins,
  currentSelectedAgent,
  userEmail,
  refetchUserDetails,
  getUsers,
}: AddEditSalesAgentProps) => {
  const cancelButtonRef = useRef(null);
  const [setSalesAdmin] = useMutation(SET_SALES_ADMIN);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
  } = useFormik({
    initialValues: { salesAgent: currentSelectedAgent || "" },
    onSubmit: async (value) => {
      try {
        await setSalesAdmin({
          variables: {
            data: {
              adminId: value.salesAgent,
              userEmail: userEmail,
            },
          },
        });
        toast.success("Sales agent assigned successfully.");
        refetchUserDetails();
        if (getUsers) {
          getUsers();
        }
        setShowSalesAdminModal(false);
      } catch (err: any) {
        toast.error((err as Error).message);
      }
    },
  });

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        disabled={isSubmitting}
        variant={ButtonVariant.Gray}
        size={ButtonSize.Normal}
        onClick={() => setShowSalesAdminModal(false)}
        ref={cancelButtonRef}
      >
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.Gray}
        disabled={isSubmitting}
        loading={isSubmitting}
        size={ButtonSize.Normal}
        className="!mr-0"
        onClick={() => {
          handleSubmit();
        }}
      >
        Submit
      </Button>
    </div>
  );
  return (
    <Dialog
      header={`Add/Update Sales Agent`}
      visible={showSalesAdminModal}
      style={{ width: "35vw" }}
      footer={footer}
      onHide={() => setShowSalesAdminModal(false)}
    >
      <form className="w-full">
        <div className="relative w-full">
          <select
            onChange={handleChange}
            onBlur={handleBlur}
            name={`salesAgent`}
            value={values.salesAgent}
            className={classNames(
              touched?.salesAgent && errors?.salesAgent
                ? "border-red-500 focus:border-red-500 border-2"
                : "",
              "block w-full pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
            )}
          >
            <option disabled value={""}>
              -- Select an option --
            </option>
            {salesAdmins?.map(
              (admin: { id: number; firstName: string; lastName: string }) => (
                <option value={admin.id} key={admin.id}>
                  {admin.firstName} {admin.lastName}
                </option>
              ),
            )}
          </select>
        </div>
      </form>
    </Dialog>
  );
};
