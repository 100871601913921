/** @format */

import { CogIcon } from "@heroicons/react/outline";
import { ELD_SYSTEMS } from "@roadflex/constants";
import {
  useGeoTabGetTripDetails,
  useGeoTabGetTripHistory,
} from "@roadflex/react-hooks";
import {
  EldDeviceTripDetails,
  GeoTabTripHistory,
  TripDetails,
} from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Splitter, SplitterPanel } from "primereact/splitter";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { TripHistoryMap } from "./TripHistoryMap";

export interface GeoTabDeviceTripsModalProps {
  openGeoTabDeviceTripsModal: boolean;
  setOpenGeoTabDeviceTripsModal: (value: boolean) => void;
  orgName: string;
  database: string;
  tripDetailValues: TripDetails;
}

const ZERO_FROM_VERSION = "0000000000000000";

export default function GeoTabDeviceTripsModal({
  openGeoTabDeviceTripsModal,
  setOpenGeoTabDeviceTripsModal,
  orgName,
  database,
  tripDetailValues,
}: GeoTabDeviceTripsModalProps) {
  const [geoTabTrips, setGeoTabTrips] = useState<EldDeviceTripDetails[]>([]);
  const [geoTabTripHistory, setGeoTabTripHistory] = useState<
    GeoTabTripHistory[]
  >([]);
  const [fromVersionForTripDetails, setFromVersionForTripDetails] =
    useState(ZERO_FROM_VERSION);
  const [fromVersionForTripHistory, setFromVersionForTripHistory] =
    useState(ZERO_FROM_VERSION);
  const [showTripHistory, setShowTripHistory] = useState(false);
  const [isTripHistoryLoading, setTripHistoryLoading] = useState(true);
  const [tripCoordinatesArray, setTripCoordinatesArray] = useState<
    {
      lat: number;
      lng: number;
    }[]
  >([]);
  const [markersArray, setMarkersArray] = useState<
    {
      position: {
        lat: number;
        lng: number;
      };
      speed: string;
      time: string;
      showInfo: boolean;
      key: number;
    }[]
  >([]);
  const [startDate, setStartDate] = useState<Date | Date[] | undefined>(
    undefined,
  );
  const [endDate, setEndDate] = useState<Date | Date[] | undefined>(undefined);

  const initialTripHistoryObj = {
    startDate: "",
    endDate: "",
    fromVersion: fromVersionForTripHistory,
    resultsLimit: 5000,
    deviceId: "",
  };
  const [tripHistoryValues, setTripHistoryValues] = useState({
    ...initialTripHistoryObj,
  });

  const {
    geoTabGetTripDetailsHandleSubmit,
    geoTabGetTripDetailsIsSubmitting,
    geoTabGetTripDetailsSetFieldValue,
  } = useGeoTabGetTripDetails({
    initialValues: {
      orgName,
      database,
      startDate: "",
      endDate: "",
      fromVersion: fromVersionForTripDetails,
      resultsLimit: "",
      deviceId: "",
    },

    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res) {
        // console.log(res, err);
        setFromVersionForTripDetails(
          res?.eldDeviceTripDetails?.fromVersion || "",
        );
        setGeoTabTrips(res?.eldDeviceTripDetails?.eldDeviceTripDetails || []);
        // toast.success("GeoTab device trips successfully retrieved.");
      }
    },
  });

  const {
    geoTabGetTripHistoryHandleSubmit,
    geoTabGetTripHistoryIsSubmitting,
    geoTabGetTripHistorySetFieldValue,
  } = useGeoTabGetTripHistory({
    initialValues: {
      orgName,
      database,
      startDate: "",
      endDate: "",
      fromVersion: "",
      resultsLimit: "",
      deviceId: "",
    },

    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      // console.log(res, err);
      const geoTabTripHistoryList =
        res?.eldDeviceTripHistory?.eldDeviceTripHistory || [];
      setFromVersionForTripHistory(
        res?.eldDeviceTripHistory?.fromVersion || "",
      );
      // console.log("geoTabTripHistoryList => ", geoTabTripHistoryList);
      setGeoTabTripHistory([...geoTabTripHistory, ...geoTabTripHistoryList]);
      // if (
      //   geoTabTripHistoryList.length != 0
      //   // && geoTabTripHistory.length < 5000
      // ) {
      //   geoTabGetTripHistorySetFieldValue(
      //     "fromVersion",
      //     fromVersionForTripHistory,
      //   );
      //   geoTabGetTripHistoryHandleSubmit();
      // } else {
      const tripCoordinatesList = geoTabTripHistoryList?.map((coordinate) => {
        return {
          lat: Number(coordinate.latitude),
          lng: Number(coordinate.longitude),
        };
      });
      const markersList = geoTabTripHistoryList?.map((coordinate, index) => {
        return {
          position: {
            lat: Number(coordinate.latitude),
            lng: Number(coordinate.longitude),
          },
          speed: coordinate.speed,
          time: coordinate.dateTime,
          showInfo: false,
          key: index,
        };
      });
      setTripCoordinatesArray(tripCoordinatesList);
      setMarkersArray(markersList);
      setTripHistoryLoading(false);
      // toast.success("GeoTab trip history successfully retrieved.");
      // }
    },
  });

  const renderFooter = () => {
    return (
      <div>
        {showTripHistory ? (
          <button
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {
              setShowTripHistory(false);
              setGeoTabTripHistory([]);
              setFromVersionForTripHistory(ZERO_FROM_VERSION);
              setTripHistoryLoading(true);
            }}
          >
            Back to trip details
          </button>
        ) : (
          <button
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => setOpenGeoTabDeviceTripsModal(false)}
          >
            Close
          </button>
        )}
      </div>
    );
  };

  const tripDistanceTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-xs text-gray-500 whitespace-nowrap">
        <div>{rowData.distance}</div>
      </span>
    );
  };

  const tripStartTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-xs text-gray-500 whitespace-nowrap">
        <div>{convertISOStringToDate(rowData.start)}</div>
      </span>
    );
  };

  const tripStopTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-xs text-gray-500 whitespace-nowrap">
        <div>{convertISOStringToDate(rowData.stop)}</div>
      </span>
    );
  };

  const nextTripStartTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-xs text-gray-500 whitespace-nowrap">
        <div>{convertISOStringToDate(rowData.nextTripStart)}</div>
      </span>
    );
  };

  const tripHistoryDateTimeTemplate = (rowData: GeoTabTripHistory) => {
    return (
      <span className="text-xs text-gray-500 whitespace-nowrap">
        <div>{convertISOStringToDate(rowData.dateTime)}</div>
      </span>
    );
  };

  const getTripHistoryTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <Button
        variant={ButtonVariant.Green}
        size={ButtonSize.Small}
        className="text-xs"
        onClick={(e) => {
          setTripHistoryValues({
            startDate: rowData.start,
            endDate: rowData.stop,
            fromVersion: "",
            resultsLimit: 5000,
            deviceId: tripDetailValues.deviceId,
          });
          geoTabGetTripHistorySetFieldValue("orgName", orgName);
          if (orgName === ELD_SYSTEMS.GEOTAB) {
            geoTabGetTripHistorySetFieldValue(
              "fromVersion",
              fromVersionForTripHistory,
            );
          }
          geoTabGetTripHistorySetFieldValue("startDate", rowData.start);
          geoTabGetTripHistorySetFieldValue("endDate", rowData.stop);
          geoTabGetTripHistorySetFieldValue(
            "resultsLimit",
            tripHistoryValues.resultsLimit,
          );
          geoTabGetTripHistorySetFieldValue(
            "deviceId",
            tripDetailValues.deviceId,
          );
          geoTabGetTripHistoryHandleSubmit();
          setShowTripHistory(true);
        }}
      >
        Show trip history
      </Button>
    );
  };

  useEffect(() => {
    setFromVersionForTripDetails(ZERO_FROM_VERSION);
    setFromVersionForTripHistory(ZERO_FROM_VERSION);
    setShowTripHistory(false);
    setTripHistoryLoading(true);
    setGeoTabTripHistory([]);
    setGeoTabTrips([]);
    if (openGeoTabDeviceTripsModal) {
      let start = startDate;
      let end = endDate;
      if (!start) {
        start = new Date();
        start.setDate(start.getDate() - 1);
        setStartDate(start);
      }
      if (!end) {
        end = new Date();
        setEndDate(end);
      }
      geoTabGetTripDetailsSetFieldValue("orgName", orgName);
      if (orgName === ELD_SYSTEMS.GEOTAB) {
        geoTabGetTripDetailsSetFieldValue("fromVersion", ZERO_FROM_VERSION);
        geoTabGetTripHistorySetFieldValue("fromVersion", ZERO_FROM_VERSION);
      } else {
        geoTabGetTripDetailsSetFieldValue("fromVersion", "");
        geoTabGetTripHistorySetFieldValue("fromVersion", "");
      }
      geoTabGetTripDetailsSetFieldValue("startDate", start);
      geoTabGetTripDetailsSetFieldValue("endDate", end);
      geoTabGetTripDetailsSetFieldValue(
        "resultsLimit",
        tripDetailValues.resultsLimit,
      );
      geoTabGetTripDetailsSetFieldValue("deviceId", tripDetailValues.deviceId);
      geoTabGetTripDetailsHandleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openGeoTabDeviceTripsModal]);

  return (
    <Dialog
      header={
        <div className="flex flex-col flex-wrap justify-start w-full col-span-1 gap-5 sm:w-auto sm:flex-row">
          <div className="flex items-center">{`Trip details for ${tripDetailValues.deviceName}`}</div>
        </div>
      }
      visible={openGeoTabDeviceTripsModal}
      className="w-[95%] sm:w-[80vw]"
      footer={renderFooter()}
      onHide={() => setOpenGeoTabDeviceTripsModal(false)}
    >
      {!showTripHistory ? (
        <div className="flex items-center justify-start col-span-1 gap-4 mb-4 text-base ">
          <div className="flex items-center justify-start col-span-1 gap-2">
            <label htmlFor="from">From</label>
            <Calendar
              id="from"
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              inputClassName="!py-2"
            />
          </div>
          <div className="flex items-center justify-start col-span-1 gap-2">
            <label htmlFor="to">To</label>
            <Calendar
              id="to"
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              inputClassName="!py-2"
            />
          </div>
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.Normal}
            onClick={(e) => {
              geoTabGetTripDetailsSetFieldValue("startDate", startDate);
              geoTabGetTripDetailsSetFieldValue("endDate", endDate);
              geoTabGetTripDetailsHandleSubmit();
              setShowTripHistory(false);
            }}
          >
            Apply
          </Button>
        </div>
      ) : null}
      <div className="border-b border-gray-200 shadow sm:rounded-lg ">
        {showTripHistory ? (
          <Splitter style={{ height: "650px" }}>
            <SplitterPanel size={20}>
              <DataTable
                size="small"
                stripedRows
                value={geoTabTripHistory}
                // paginator
                rows={10}
                dataKey="id"
                sortField="dateTime"
                sortOrder={1}
                paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
                paginator
                paginatorClassName="justify-content-end"
                responsiveLayout="scroll"
                emptyMessage="No available records"
                loading={geoTabGetTripHistoryIsSubmitting}
                style={{ fontFamily: "inherit" }}
              >
                <Column
                  field="latitude"
                  header="Latitude"
                  className="text-xs"
                  bodyClassName="text-xs text-gray-500 whitespace-nowrap"
                />
                <Column
                  field="longitude"
                  header="Longitude"
                  className="text-xs"
                  bodyClassName="text-xs text-gray-500 whitespace-nowrap"
                />
                <Column
                  sortable
                  field="speed"
                  header="Speed"
                  className="text-xs"
                  bodyClassName="text-xs text-gray-500 whitespace-nowrap"
                />
                <Column
                  sortable
                  dataType="date"
                  field="dateTime"
                  header="Time"
                  className="text-xs"
                  body={tripHistoryDateTimeTemplate}
                />
              </DataTable>
            </SplitterPanel>
            <SplitterPanel size={80}>
              {isTripHistoryLoading ? (
                <div className="flex justify-center h-full align-middle">
                  <span className="inline-flex items-center">
                    <CogIcon
                      className="w-6 h-6 mr-2 -ml-1 animate-spin"
                      aria-hidden="true"
                    />
                    {"Downloading Coordinates..."}
                  </span>
                </div>
              ) : (
                <TripHistoryMap {...{ tripCoordinatesArray, markersArray }} />
              )}
            </SplitterPanel>
          </Splitter>
        ) : (
          <DataTable
            size="small"
            stripedRows
            value={geoTabTrips}
            paginator
            rows={10}
            dataKey="id"
            sortField="start"
            sortOrder={1}
            paginatorTemplate={newPaginationTemplate}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rowsPerPageOptions={[10, 20, 50]}
            responsiveLayout="scroll"
            className="custom-table"
            emptyMessage="No available records"
            loading={geoTabGetTripDetailsIsSubmitting}
            style={{ fontFamily: "inherit" }}
          >
            <Column
              sortable
              field="distance"
              header="Distance"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={tripDistanceTemplate}
            />
            <Column
              sortable
              field="drivingDuration"
              header="Driving Duration"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              sortable
              dataType="date"
              field="start"
              header="Start"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={tripStartTemplate}
            />
            <Column
              sortable
              dataType="date"
              field="stop"
              header="Stop"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={tripStopTemplate}
            />
            <Column
              sortable
              dataType="date"
              field="nextTripStart"
              header="Next trip start"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={nextTripStartTemplate}
            />
            <Column
              // filter
              header="Trip Details"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={getTripHistoryTemplate}
            />
          </DataTable>
        )}
      </div>
    </Dialog>
  );
}
