/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import getConfig from "next/config";
import { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonType, ButtonVariant } from "../../buttons";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_ADMIN_URL } = publicRuntimeConfig;

export const StripeCheckoutForm = ({
  clientSecret,
  isPayNowClicked,
  setisPayNowClicked,
  email,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const cancelButtonRef = useRef(null);

  const [message, setMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!clientSecret) {
      return;
    }
    try {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then((result: any) => {
          switch (result?.paymentIntent?.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Requires to go through the payment method.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            err?.message ||
              "Something went wrong with generating payment amount",
          );
        });
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.message || "Something went wrong with generating payment amount",
      );
      setisPayNowClicked(false);
    }
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${NEXT_PUBLIC_ADMIN_URL}/customer-dashboard?email=${email}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error?.message);
      setIsLoading(false);
      return;
    }
    if (error.type) {
      setIsLoading(false);
      setMessage("An unexpected error occured.");
      return;
    }

    setIsLoading(false);
    setisPayNowClicked(false);
    toast.success("Payment successful");
  };

  return (
    <Transition.Root show={isPayNowClicked} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          // prevent closing outside the dialog
        }}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 z-[90]">
                <button
                  type="button"
                  className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setisPayNowClicked(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="w-6 h-6 text-black" aria-hidden="true" />
                </button>
              </div>
              <div className="flex items-center justify-center mx-auto ">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Make Payment
                </Dialog.Title>
              </div>
              <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" />
                <div className="p-4 text-center">
                  <Button
                    variant={ButtonVariant.Green}
                    loading={isLoading}
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                    type={ButtonType.Submit}
                  >
                    Pay Now
                  </Button>
                </div>
                {/* Show any error or success messages */}
                {message && (
                  <div className="text-orange-600" id="payment-message">
                    **{message}
                  </div>
                )}
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
