/** @format */

import { Dialog } from "primereact/dialog";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";

type Props = {
  title: string;
  body: JSX.Element;
  show: boolean;
  setShow: (value: boolean) => void;
};

export function InfoModal({ title, body, show, setShow }: Props) {
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        <Button
          size={ButtonSize.AppSize}
          variant={ButtonVariant.SecondaryOutline}
          onClick={() => setShow(false)}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      header={title}
      style={{ minWidth: "30vw" }}
      visible={show}
      onHide={() => setShow(false)}
      closeOnEscape={true}
      closable={false}
      footer={renderFooter}
    >
      <div className="mt-2">{body}</div>
    </Dialog>
  );
}

export default InfoModal;
