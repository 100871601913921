/** @format */

import { useSubscription } from "@apollo/client";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { TRANSACTION_SUBSCRIPTION } from "@roadflex/graphql";
import { useBillingCycleList, useTransactionList } from "@roadflex/react-hooks";
import { ExtendedTransactionType, UserAuthType } from "@roadflex/types";
import { showErrorMessage } from "@roadflex/web-lib";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useEffect, useState } from "react";
import AccountingUI from "./Accounting.ui";

type FilterProps = "date" | "transactionAmount";

const initFilterConfig: {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
};

interface AccountingProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

export const Accounting = ({
  readCurrentUserAuth,
  loading,
}: AccountingProps) => {
  const transactionList: ExtendedTransactionType[] = [];
  const currentCardsSet = new Set<string>();
  const currentDriversSet = new Set<string>();

  const { transactionData, transactionRefetch, transactionLoading } =
    useTransactionList();
  const {
    data: billingCycleList,
    loading: billingCycleListLoading,
    refetch,
  } = useBillingCycleList();

  useEffect(() => {
    if (
      !billingCycleListLoading &&
      billingCycleList &&
      billingCycleList.billingCycleList.code !== "200"
    ) {
      showErrorMessage(
        billingCycleList.billingCycleList.message,
        billingCycleList.billingCycleList.code,
      );
    }
  }, [billingCycleList, billingCycleListLoading]);

  const customerType = readCurrentUserAuth.fuelCard.customerType;
  const isPrepaidCustomer =
    customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
    customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
    customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE;

  transactionData?.readTransactions?.transactions?.forEach((item) => {
    if (item.transactionStatus === "CAPTURE") {
      const newTransactionObj = {
        ...item,
        date: new Date(item?.authorizationDate),
        driver: item?.driverName || "-",
        vehicle: item?.vehicleName || "-",
        status: "Approved",
        lastFour: item?.card?.lastFour || "-",
      };
      currentDriversSet.add(newTransactionObj.driver);
      currentCardsSet.add(newTransactionObj.lastFour);
      transactionList.push(newTransactionObj);
    }
  });
  const [transactionsFilterConfig, setTransactionFilterConfig] = useState<{
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  }>(initFilterConfig);

  const { data: transactionUpdated } = useSubscription(
    TRANSACTION_SUBSCRIPTION,
    {
      fetchPolicy: "no-cache",
      variables: {
        data: {
          userId: readCurrentUserAuth.primaryOwnerId,
        },
      },
    },
  );
  useEffect(() => {
    transactionRefetch();
  }, [transactionRefetch, transactionUpdated]);
  useEffect(() => {
    if (
      !transactionLoading &&
      transactionData &&
      transactionData?.readTransactions?.code !== "200"
    ) {
      showErrorMessage(
        transactionData.readTransactions.message,
        transactionData.readTransactions.code,
      );
    }
  }, [transactionData, transactionLoading]);
  const billingList =
    billingCycleList?.billingCycleList?.billingCycleList &&
    billingCycleList?.billingCycleList?.billingCycleList?.length > 0
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        billingCycleList?.billingCycleList?.billingCycleList
      : [];
  return (
    <AccountingUI
      {...{
        isPrepaidCustomer,
        transactionList,
        billingList,
        transactionLoading,
        transactionsFilterConfig,
        setTransactionFilterConfig,
        initFilterConfig,
      }}
    />
  );
};
