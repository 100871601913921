/** @format */

import {
  useReadAuthorizations,
  useReadFilterOptions,
} from "@roadflex/react-hooks";
import {
  AuthorizationType,
  CardsFilterOptions,
  DriversFilterOptions,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { showErrorMessage } from "@roadflex/web-lib";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import TransactionsDeclinedUI from "./transactions-declined.ui";

interface TransactionsDeclinedProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterConfigType = {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};

const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

const authorizationInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
};

export const TransactionsDeclined = ({
  readCurrentUserAuth,
  loading,
}: TransactionsDeclinedProps) => {
  const [authorizationLazyParams, setAuthorizationLazyParams] =
    useState<DataTablePFSEvent>({
      ...authorizationInitialLazyParams,
    });
  const { authorizationData, authorizationRefetch, authorizationLoading } =
    useReadAuthorizations(authorizationLazyParams, false, "");

  const { filterOptions } = useReadFilterOptions();

  const [declinedTransactionData, setDeclinedTransactionData] = useState<{
    authorizations: AuthorizationType[];
    totalCount: number;
  }>({
    authorizations: [],
    totalCount: 0,
  });
  useEffect(() => {
    if (
      !authorizationLoading &&
      authorizationData &&
      authorizationData?.readAuthorizationList?.code !== "200"
    ) {
      showErrorMessage(
        authorizationData?.readAuthorizationList?.message,
        authorizationData?.readAuthorizationList?.code,
      );
    }
    if (
      authorizationData &&
      authorizationData.readAuthorizationList.code === "200"
    ) {
      setDeclinedTransactionData({
        totalCount: authorizationData.readAuthorizationList.totalCount,
        authorizations: authorizationData.readAuthorizationList.authorizations,
      });
    }
  }, [authorizationData, authorizationLoading]);

  useEffect(() => {
    authorizationRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationLazyParams]);

  const [transactionFilterOptions, setTransactionFilterOptions] = useState<{
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  }>({
    vehiclesFilterOptions: [],
    cardsFilterOptions: [],
    driversFilterOptions: [],
  });

  useEffect(() => {
    if (filterOptions?.transactionFilterOptions) {
      setTransactionFilterOptions({
        cardsFilterOptions: filterOptions.transactionFilterOptions.cards.map(
          (value) => {
            return {
              lastFour: value.lastFour,
            };
          },
        ),
        vehiclesFilterOptions:
          filterOptions.transactionFilterOptions.vehicles.map((value) => {
            return {
              vehicleName: value.fleetName,
            };
          }),
        driversFilterOptions:
          filterOptions.transactionFilterOptions.drivers.map((value) => {
            return {
              driverName: value.name,
            };
          }),
      });
    }
  }, [filterOptions?.transactionFilterOptions]);

  if (loading) {
    return null;
  }

  return (
    <TransactionsDeclinedUI
      {...{
        declinedTransactionData,
        transactionFilterOptions,
        authorizationLazyParams,
        setAuthorizationLazyParams,
        authorizationLoading,
        readCurrentUserAuth,
      }}
    />
  );
};
