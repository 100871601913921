/** @format */

import { onboardTermsOfServiceSchema } from "@roadflex/constants";
import {
  useReadAddresses,
  useReadBusinessInformation,
  useTermsOfServiceUpdate,
} from "@roadflex/react-hooks";
import { AddressType, BusinessType, UserOnboardType } from "@roadflex/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TermsOfServiceUI from "./terms-of-service.ui";

type Props = {
  onJumpToStep: (step: number) => void;
  isSubmittingApplication: boolean;
  onSubmitApplication: () => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: () => void;
  isSubmittingOnboardStatus: boolean;
  business: BusinessType;
  addresses: AddressType[];
};

const TermsOfService = ({
  onJumpToStep,
  isSubmittingApplication,
  onSubmitApplication,
  readCurrentUserOnboard,
  business,
  addresses,
}: Props) => {
  const [
    showRegistrationConfirmationModal,
    setShowRegistrationConfirmationModal,
  ] = useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    setTouched,
    ...rest
  } = useTermsOfServiceUpdate({
    initialValues: {
      ownsMoreThan25Percent: false,
      significantManagementResponsibility: false,
      authorizedRepresentative: false,
      agreeToRoadFlexTerms: false,
    },
    validationSchema: onboardTermsOfServiceSchema,
    onSubmit(res, err) {
      if (!err) {
        onSubmitApplication();
      } else {
        toast.error("Something Went Wrong.");
      }
    },
  });
  useEffect(() => {
    setTouched({
      authorizedRepresentative: true,
      agreeToRoadFlexTerms: true,
    });
  }, []);
  return (
    <TermsOfServiceUI
      readCurrentUserOnboard={readCurrentUserOnboard}
      onJumpToStep={onJumpToStep}
      onConfirm={onSubmitApplication}
      confirming={isSubmittingApplication}
      showRegistrationConfirmationModal={showRegistrationConfirmationModal}
      setShowRegistrationConfirmationModal={
        setShowRegistrationConfirmationModal
      }
      business={business}
      addresses={addresses}
      isSubmittingApplication={isSubmittingApplication}
      {...{
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        setTouched,
        ...rest,
      }}
    />
  );
};
function BusinessAndAddress(props: {
  onJumpToStep: (step: number) => void;
  isSubmittingApplication: boolean;
  onSubmitApplication: () => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: () => void;
  isSubmittingOnboardStatus: boolean;
}) {
  const { data, loading } = useReadBusinessInformation();
  const { data: addressData, loading: addressLoading } = useReadAddresses({
    businessId: undefined,
  });

  if (loading || addressLoading) {
    return <div className="mt-6 text-center text-white">Loading...</div>;
  }

  const addresses = addressData?.addresses || [];
  const { readBusinessInformation: business } = data;
  return <TermsOfService {...{ ...props, business, addresses: addresses }} />;
}

export default BusinessAndAddress;
