/** @format */

import { ENUM_BILLING_CYCLE_STATUS } from "@prisma/client";
import { BillingCycleType, ExtendedTransactionType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { dollarFormatter } from "@roadflex/web-lib";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import ModalLoader from "../../loader/modal-loader";

type FilterProps = "date" | "transactionAmount";

interface ExportAccountingModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  dateFilterApplied: (e: unknown) => void;
  transactionsFilterConfig: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: {
        value: null | string | Date;
        matchMode: FilterMatchMode;
      }[];
    };
  };
  transactionList: ExtendedTransactionType[];
  billingList: BillingCycleType[];
  isPrepaidCustomer: boolean;
}

export const ExportAccountingModal = ({
  open,
  setOpen,
  dateFilterApplied,
  transactionsFilterConfig,
  isPrepaidCustomer,
  transactionList,
  billingList,
}: ExportAccountingModalProps) => {
  const [dates, setDates] = useState<Date | Date[] | undefined>([
    new Date(),
    new Date(),
  ]);
  const [type, setType] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);

  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);
  const csvLinkRef1 = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    setDates(dateArray);
    if (dateArray[0] !== null && dateArray[1] !== null) {
      dateFilterApplied({
        field: "date",
        constraints: {
          operator: "and",
          constraints: [
            {
              value: new Date(dateArray[0]),
              matchMode: "dateAfter",
            },
            {
              value: new Date(dateArray[1]),
              matchMode: "dateBefore",
            },
          ],
        },
      });
    }
  };
  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setType(e.target.value);
    }
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const startOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDay(),
      0,
      0,
      0,
    );
    let dateArray: Date[] = [startOfDay, date];
    if (e.target.value === "Current Month") {
      dateArray = [firstDay, date];
    }
    setDates(dateArray);
    if (dateArray[0] !== null && dateArray[1] !== null) {
      dateFilterApplied({
        field: "date",
        constraints: {
          operator: "and",
          constraints: [
            {
              value: new Date(dateArray[0]),
              matchMode: "dateAfter",
            },
            {
              value: new Date(dateArray[1]),
              matchMode: "dateBefore",
            },
          ],
        },
      });
    }
  };

  const onExportCSV = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
      setShowComplete(true);
      csvLinkRef1?.current?.link.click();
    }, 2000);
  };

  const dateFilteredTransaction = transactionList
    .filter((transaction) =>
      transactionsFilterConfig.date.constraints.length === 2
        ? new Date(transaction.authorizationDate).getTime() >=
            new Date(
              transactionsFilterConfig.date.constraints[0].value || Date.now(),
            ).getTime() &&
          new Date(transaction.authorizationDate).getTime() <=
            new Date(
              transactionsFilterConfig.date.constraints[1].value || Date.now(),
            ).getTime()
        : true,
    )
    .sort(function (a, b) {
      if (a.authorizationDate < b.authorizationDate) return 1;
      if (a.authorizationDate > b.authorizationDate) return -1;
      return 0;
    });

  const filteredBillingCycle = billingList
    .filter((billingCycle) =>
      transactionsFilterConfig.date.constraints.length === 2
        ? new Date(billingCycle.updatedAt).getTime() >=
            new Date(
              transactionsFilterConfig.date.constraints[0].value || Date.now(),
            ).getTime() &&
          new Date(billingCycle.updatedAt).getTime() <=
            new Date(
              transactionsFilterConfig.date.constraints[1].value || Date.now(),
            ).getTime()
        : true,
    )
    .sort(function (a, b) {
      if (a.updatedAt < b.updatedAt) return 1;
      if (a.updatedAt > b.updatedAt) return -1;
      return 0;
    });

  const exportTransactionListPrepaid = dateFilteredTransaction
    .map(function (transaction, index) {
      if (index === 0) {
        return {
          originalDate: transaction?.authorizationDate,
          authorizationDate: convertISOStringToDate(
            transaction?.authorizationDate?.toString(),
            false,
            false,
            true,
          ).substring(0, 10),
          description: transaction.merchantName,
          debits: dollarFormatter(
            Number(
              transaction.transactionAmount - transaction?.fuelDiscountAmount,
            ) / 100,
          ),
          credits: dollarFormatter(0),
          start: dollarFormatter(0),
          end: dollarFormatter(0),
        };
      }
      return {
        originalDate: transaction?.authorizationDate,
        authorizationDate: convertISOStringToDate(
          transaction?.authorizationDate?.toString(),
          false,
          false,
          true,
        ).substring(0, 10),
        description: transaction.merchantName,
        debits: dollarFormatter(
          Number(
            transaction.transactionAmount - transaction.fuelDiscountAmount,
          ) / 100,
        ),
        credits: dollarFormatter(0),
      };
    })
    .concat(
      dateFilteredTransaction.map(function (transaction) {
        return {
          //this is a (terrible) hack until I figure out the specifications
          originalDate: transaction?.authorizationDate,
          authorizationDate: convertISOStringToDate(
            transaction?.authorizationDate?.toString(),
            false,
            false,
            true,
          ).substring(0, 10),
          description: `WALLET: ${transaction.merchantName}`,
          debits: dollarFormatter(0),
          credits: dollarFormatter(
            Number(
              transaction.transactionAmount - transaction.fuelDiscountAmount,
            ) / 100,
          ),
        };
      }),
    )
    .sort(function (a, b) {
      if (a.originalDate < b.originalDate) return 1;
      if (a.originalDate > b.originalDate) return -1;
      return 0;
    });

  function calculateStartingBalance() {
    let start = 0;

    const beforeBilling = billingList.filter(
      (billingCycle) =>
        new Date(billingCycle.updatedAt).getTime() <
        new Date(
          transactionsFilterConfig.date.constraints[0].value || Date.now(),
        ).getTime(),
    );

    beforeBilling.forEach((bc) => {
      if (bc.status === ENUM_BILLING_CYCLE_STATUS.SUCCEEDED) {
        start -= Number(bc.spendings / 100);
      }
    });

    const beforeTransaction = transactionList.filter(
      (transaction) =>
        new Date(transaction.authorizationDate).getTime() <
        new Date(
          transactionsFilterConfig.date.constraints[0].value || Date.now(),
        ).getTime(),
    );

    beforeTransaction.forEach((transaction) => {
      start +=
        Number(transaction.transactionAmount - transaction.fuelDiscountAmount) /
        100;
    });
    return start;
  }

  function calculateEndingBalance(starting: number) {
    let ending = starting;
    filteredBillingCycle.forEach((bc) => {
      if (bc.status === ENUM_BILLING_CYCLE_STATUS.SUCCEEDED) {
        ending -= Number(bc.spendings / 100);
      }
    });

    dateFilteredTransaction.forEach((transaction) => {
      ending +=
        Number(transaction.transactionAmount - transaction.fuelDiscountAmount) /
        100;
    });
    return ending;
  }

  let exportTransactionListCredit = dateFilteredTransaction.map(function (
    transaction,
    index,
  ) {
    return {
      originalDate: transaction?.authorizationDate,
      authorizationDate: convertISOStringToDate(
        transaction?.authorizationDate?.toString(),
        false,
        false,
        true,
      ).substring(0, 10),
      description: transaction.merchantName,
      debits: dollarFormatter(
        Number(transaction.transactionAmount - transaction.fuelDiscountAmount) /
          100,
      ),
      credits: dollarFormatter(0),
      start: "",
      end: "",
    };
  });

  filteredBillingCycle.forEach((bc) => {
    if (bc.status === ENUM_BILLING_CYCLE_STATUS.SUCCEEDED) {
      exportTransactionListCredit.push({
        originalDate: bc?.updatedAt,
        authorizationDate: convertISOStringToDate(
          bc?.updatedAt?.toString(),
          false,
          false,
          true,
        ).substring(0, 10),
        description: `Payment for invoice: ${bc.invoiceId}`,
        debits: dollarFormatter(0),
        credits: dollarFormatter(bc.spendings / 100),
        start: "",
        end: "",
      });
    }
  });

  exportTransactionListCredit.sort(function (a, b) {
    if (a.originalDate < b.originalDate) return 1;
    if (a.originalDate > b.originalDate) return -1;
    return 0;
  });

  exportTransactionListCredit = exportTransactionListCredit.map(function (
    element,
    index,
  ) {
    const elementCopy = { ...element };
    if (index === 0) {
      const startBalance = calculateStartingBalance();
      elementCopy.start = dollarFormatter(startBalance);
      elementCopy.end = dollarFormatter(calculateEndingBalance(startBalance));
    }
    return elementCopy;
  });

  const startMonth = (dates as Date[])[0].getMonth() + 1;
  const endMonth = (dates as Date[])[1]?.getMonth() + 1;
  const startDay = (dates as Date[])[0].getDate();
  const endDay = (dates as Date[])[1]?.getDate();
  const startYear = (dates as Date[])[0].getFullYear();
  const endYear = (dates as Date[])[1]?.getFullYear();
  const accountingHeader = [
    { label: "Date", key: "authorizationDate" },
    { label: "Description", key: "description" },
    { label: "Credits", key: "credits" },
    { label: "Debits", key: "debits" },
    {
      label: `Starting Balance on ${startMonth}/${startDay}/${startYear}`,
      key: "start",
    },
    {
      label: `Ending Balance on ${endMonth}/${endDay}/${endYear}`,
      key: "end",
    },
  ];

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.AppSize}
        onClick={() => {
          setOpen(false);
        }}
      >
        Close
      </Button>
      {isPrepaidCustomer && (
        <CSVLink
          filename="accounting-report.csv"
          ref={csvLinkRef1}
          data={exportTransactionListPrepaid}
          headers={accountingHeader}
          target="_blank"
        ></CSVLink>
      )}
      {!isPrepaidCustomer && (
        <CSVLink
          filename="accounting-report.csv"
          ref={csvLinkRef1}
          data={exportTransactionListCredit}
          headers={accountingHeader}
          target="_blank"
        ></CSVLink>
      )}
      {!showLoading && !showComplete && (
        <Button
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          disabled={
            dates
              ? (dates as Date[])[0] === null || (dates as Date[])[1] === null
              : true
          }
          onClick={() => onExportCSV()}
        >
          Export CSV
        </Button>
      )}
    </div>
  );
  return (
    <Dialog
      header={<span className="text-base md:text-lg">Export Accounting</span>}
      visible={open}
      style={{ fontFamily: "inherit" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={() => setOpen(false)}
    >
      <div className="space-y-3">
        {showLoading || showComplete ? (
          <ModalLoader showLoading={showLoading} showComplete={showComplete} />
        ) : (
          <div>
            <div className="relative flex items-start my-1">
              <div className="flex items-center h-5">
                <input
                  id={"cb"}
                  type="checkbox"
                  value={"Today"}
                  name={"cb"}
                  checked={type === "Today"}
                  onChange={onTypeChange}
                  className="w-4 h-4 text-orange-600 border-gray-300 rounded cursor-pointer focus:ring-opacity-0 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm md:text-base">
                <label htmlFor={"cb"} className="font-medium text-gray-700">
                  Today
                </label>
              </div>
            </div>
            <div className="relative flex items-start my-1">
              <div className="flex items-center h-5">
                <input
                  id={"cb2"}
                  type="checkbox"
                  name={"cb2"}
                  value={"Current Month"}
                  checked={type === "Current Month"}
                  onChange={onTypeChange}
                  className="w-4 h-4 text-orange-600 border-gray-300 rounded cursor-pointer focus:ring-opacity-0 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm md:text-base">
                <label htmlFor={"cb2"} className="font-medium text-gray-700">
                  Current Month
                </label>
              </div>
            </div>
            <div className="relative flex items-start my-1">
              <div className="flex items-center h-5">
                <input
                  id={"cb3"}
                  type="checkbox"
                  name={"cb3"}
                  value={"Custom"}
                  checked={type === "Custom"}
                  onChange={onTypeChange}
                  className="w-4 h-4 text-orange-600 border-gray-300 rounded cursor-pointer focus:ring-opacity-0 focus:ring-orange-500"
                />
              </div>
              <div className="ml-3 text-sm md:text-base">
                <label htmlFor={"cb2"} className="font-medium text-gray-700">
                  Custom
                </label>
              </div>
            </div>
            {type === "Custom" && (
              <div className="flex flex-col">
                <label htmlFor="range">Date Range (America/ Los Angeles)</label>
                <Calendar
                  id="range"
                  value={dates}
                  className="my-2 custom-calender"
                  onChange={onDateRangeChange}
                  selectionMode="range"
                  panelClassName="custom-calendar-panel"
                  placeholder="Select Date Range"
                  maxDate={new Date()}
                  minDate={minDate}
                  inputClassName="!py-2"
                  readOnlyInput
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};
