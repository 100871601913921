/** @format */

/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  return (
    <footer className="footer h-16 bg-gradient-to-r from-[#57291A] to-[#240F09] relative overflow-y-hidden">
      <div className="absolute right-0 top-0">
        <svg
          width="194"
          height="70"
          viewBox="0 0 194 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.7">
            <mask
              id="mask0_215_647"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="-73"
              width="194"
              height="195"
            >
              <path
                d="M44.7364 -72.2374H0.405273V-57.3073L66.7372 24.8445L0.405273 106.984V121.914H44.7364L123.109 24.8445L44.7364 -72.2374Z"
                fill="#A5794E"
              />
              <path
                d="M103.861 65.8901L149.088 121.914H193.406V106.984L132.041 30.9802L103.861 65.8901Z"
                fill="#A5794E"
              />
              <path
                d="M149.088 -72.2374L103.861 -16.2131L132.041 18.6968L193.406 -57.3073V-72.2374H149.088Z"
                fill="#A5794E"
              />
            </mask>
            <g mask="url(#mask0_215_647)">
              <path
                d="M-26.252 -30.9581H180.5V77.5634H-26.252V-30.9581Z"
                fill="#512617"
              />
            </g>
          </g>
        </svg>
      </div>
    </footer>
  );
}

export default Footer;
