/** @format */
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import {
  useReadCardOrderingFeesSettings,
  useUpdateToPremium,
} from "@roadflex/react-hooks";
import { UserAuthType, UserDetailsType } from "@roadflex/types";
import { useState } from "react";
import { toast } from "react-toastify";
import DetailsUI from "./details.ui";

interface DetailsProps {
  readCurrentUserAuth: UserAuthType;
  readUserDetailsData: UserDetailsType;
}

const Details = ({
  readCurrentUserAuth,
  readUserDetailsData,
}: DetailsProps) => {
  const [upgradeConfirmModal, setUpgradeConfirmModal] = useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    ...rest
  } = useUpdateToPremium({
    initialValues: {
      currentCustomerType:
        readUserDetailsData?.setting?.customerType ||
        ENUM_CUSTOMER_TYPE.PREPAID,
    },
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      resetForm();
      setUpgradeConfirmModal(false);
    },
  });

  const { readUserCustomizedSettings } = useReadCardOrderingFeesSettings(
    readUserDetailsData?.email,
  );

  return (
    <DetailsUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        resetForm,
        ...rest,
        readCurrentUserAuth,
        upgradeConfirmModal,
        setUpgradeConfirmModal,
        readUserDetailsData,
        readUserCustomizedSettings:
          readUserCustomizedSettings?.readUserCustomizedSettings,
      }}
    />
  );
};
export default Details;
