/** @format */
import { DownloadIcon } from "@heroicons/react/outline";
import { BillingCycleType, ExtendedTransactionType } from "@roadflex/types";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { ExportStatementOfAccountModal } from "../../modals";
import { ExportAccountingModal } from "../../modals/export-accounting-modal/export-accounting-modal";

type FilterProps = "date" | "transactionAmount";

interface AccountingUIProps {
  transactionList: ExtendedTransactionType[];
  billingList: BillingCycleType[];
  transactionLoading: boolean;
  isPrepaidCustomer: boolean;
  transactionsFilterConfig: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: {
        value: null | string | Date;
        matchMode: FilterMatchMode;
      }[];
    };
  };
  setTransactionFilterConfig: (data: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  }) => void;
  initFilterConfig: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  };
}

const AccountingUI = ({
  transactionList,
  billingList,
  transactionLoading,
  setTransactionFilterConfig,
  transactionsFilterConfig,
  isPrepaidCustomer,
  initFilterConfig,
}: AccountingUIProps) => {
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openSOAExportModal, setOpenSOAExportModal] = useState(false);
  const dateFilterApplied = (e: unknown) => {
    const event = e as {
      field: string;
      constraints: {
        operator: FilterOperator;
        constraints: [
          { value: null | string | Date; matchMode: FilterMatchMode },
        ];
      };
    };
    setTransactionFilterConfig({
      ...initFilterConfig,
      date: {
        operator: event.constraints.operator || initFilterConfig.date.operator,
        constraints:
          event.constraints.constraints || initFilterConfig.date.constraints,
      },
    });
  };
  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full  md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Accounting`.toUpperCase()}
      </div>
      <div className="flex flex-col h-auto mt-5 md:mt-0">
        <div className="flex flex-col h-full bg-white rounded-md shadow">
          <div className="flex flex-row items-center justify-between gap-4 p-8 border-b-2 border-brown-500 ">
            <div>
              <div className="font-semibold md:text-lg text-brown-500">
                Export accounting transactions
              </div>
            </div>

            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => setOpenExportModal(true)}
              disabled={transactionLoading}
              className="whitespace-nowrap"
            >
              <DownloadIcon className="w-5 h-5 mr-2"></DownloadIcon>
              Export
            </Button>
          </div>
          <div className="px-8 py-4 border-b-2 border-brown-500">
            {" "}
            Everything you need to balance your books efficiently. Works with
            your favorite accounting softwares like Quickbooks.
          </div>
          <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                1
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">Click "Export".</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                2
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">
                  Choose "Today", "Current Month" or customized date range.
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                3
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">
                  Export the transactions and payments made within the chosen
                  period.
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                4
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">
                  {" "}
                  Transactions exclude fuel discounts and late fees.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-auto mt-5">
        <div className="flex flex-col h-full bg-white rounded-md shadow">
          <div className="flex flex-row items-center justify-between gap-4 p-8 border-b-2 border-brown-500 ">
            <div>
              <div className="font-semibold md:text-lg text-brown-500">
                Export Statement of Account
              </div>
            </div>

            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => setOpenSOAExportModal(true)}
              disabled={transactionLoading}
              className="whitespace-nowrap"
            >
              <DownloadIcon className="w-5 h-5 mr-2"></DownloadIcon>
              Export
            </Button>
          </div>
          <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                1
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">Click "Export".</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                2
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">Choose a customized date range.</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full md:w-12 md:h-12 bg-brown-500 md:text-lg">
                3
              </div>
              <div className="flex flex-col md:w-5/6">
                <div className="">
                  Export the SOA made within the chosen period.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openExportModal && (
        <ExportAccountingModal
          open={openExportModal}
          setOpen={setOpenExportModal}
          dateFilterApplied={dateFilterApplied}
          transactionsFilterConfig={transactionsFilterConfig}
          transactionList={transactionList}
          billingList={billingList}
          isPrepaidCustomer={isPrepaidCustomer}
        ></ExportAccountingModal>
      )}

      {openSOAExportModal && (
        <ExportStatementOfAccountModal
          open={openSOAExportModal}
          onCloseExportModal={setOpenSOAExportModal}
        ></ExportStatementOfAccountModal>
      )}
    </div>
  );
};

export default AccountingUI;
