/** @format */

import {
  ChevronRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { ENUM_CHILD_USER_TYPE } from "@prisma/client";
import {
  ChildUserType,
  NotificationsTypeExtended,
  TabsType,
} from "@roadflex/types";
import { phoneFormatter } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { RadioInput, TextInput } from "../../../inputs";

interface UserUIProps extends FormikProps<ChildUserType> {
  tabs: TabsType[];
  notifications: NotificationsTypeExtended[];
  setNotifications: (value: NotificationsTypeExtended[]) => void;
  userId?: string;
  update: boolean;
  setUpdate: (value: boolean) => void;
}

export const UserUI = ({
  tabs,
  notifications,
  setNotifications,
  handleChange,
  values,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  userId,
  isSubmitting,
  setValues,
  update,
  setUpdate,
}: UserUIProps) => {
  const router = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const backToMultipleUsersPanel = () => {
    router.push("/multiple-users");
  };

  const revertValues = () => {
    setValues((prevValues) => ({
      ...prevValues,
      userNotification: notifications,
    }));
  };

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToMultipleUsersPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg ">
                {userId
                  ? `User - ${values.firstName} ${values.lastName}`
                  : "Add New User"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-5 md:mt-0">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
            <form
              className="flex flex-col h-full p-8 overflow-y-auto bg-white rounded-md shadow"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col flex-grow h-full py-8 space-y-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"First Name"}
                      required
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userId !== undefined && !update}
                      name="firstName"
                      id="firstName"
                      touched={touched.firstName}
                      error={touched.firstName && Boolean(errors.firstName)}
                      errorMessage={errors.firstName}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Last Name"}
                      required
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userId !== undefined && !update}
                      name="lastName"
                      id="lastName"
                      touched={touched.lastName}
                      error={touched.lastName && Boolean(errors.lastName)}
                      errorMessage={errors.lastName}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label={"Email"}
                      required
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={userId !== ""}
                      name="email"
                      id="email"
                      touched={touched.email}
                      error={touched.email && Boolean(errors.email)}
                      errorMessage={errors.email}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      type="tel"
                      label={"Phone Number"}
                      value={values.phoneNumber}
                      onChange={(event) => {
                        setFieldValue(
                          "phoneNumber",
                          phoneFormatter(event?.target?.value),
                        );
                      }}
                      onBlur={handleBlur}
                      disabled={userId !== undefined && !update}
                      name="phoneNumber"
                      id="phoneNumber"
                      touched={touched.phoneNumber}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      errorMessage={errors.phoneNumber}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 mt-8 sm:col-span-12">
                    <div className="block mb-1">Role</div>
                    <div className="">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER}
                        checked={
                          values.roleName ===
                          ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Account Manager"
                        className="!rounded"
                      ></RadioInput>

                      <div className="text-sm italic">
                        <span>
                          Account managers can manage all users, cards, vehicles
                          and policies (similar to the account owner). They can
                          manage billing and subscriptions.
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.CARD_ADMIN}
                        checked={
                          values.roleName === ENUM_CHILD_USER_TYPE.CARD_ADMIN
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Card Admin"
                        className="!rounded"
                      ></RadioInput>

                      <div className="text-sm italic">
                        <span>
                          Card admins add other users, order cards, set spend
                          limits and manage policies.
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.CARD_MANAGER}
                        checked={
                          values.roleName === ENUM_CHILD_USER_TYPE.CARD_MANAGER
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Card Manager"
                        className="!rounded"
                      ></RadioInput>

                      <div className="text-sm italic">
                        <span>
                          Card managers can manage drivers, vehicles and order
                          cards.
                        </span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <RadioInput
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={ENUM_CHILD_USER_TYPE.BOOKKEEPER}
                        checked={
                          values.roleName === ENUM_CHILD_USER_TYPE.BOOKKEEPER
                        }
                        name="roleName"
                        disabled={userId !== undefined && !update}
                        label="Book Keeper"
                        className="!rounded"
                      ></RadioInput>

                      <div className="text-sm italic">
                        <span>
                          Book keepers can manage billing and subscriptions.
                        </span>
                      </div>
                    </div>
                    {touched.roleName && errors?.roleName && (
                      <>
                        <Tooltip
                          target=".roleName-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.roleName}
                        ></Tooltip>

                        <div className="absolute h-[34px] bottom-0 right-0 pr-2 flex items-center">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 roleName-error"
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </div>

                  {userId !== "" && userId !== null && (
                    <div className="relative col-span-12 mt-8 sm:col-span-12">
                      <div className="w-full text-sm font-medium text-left md:text-base">
                        Select which notifications to receive
                      </div>

                      {values?.userNotification.map(
                        (entry: NotificationsTypeExtended, idx) => (
                          <div className="w-full mt-4" key={entry?.name}>
                            <div className="relative flex items-center justify-between">
                              <div className="mr-3 text-xs text-left">
                                <label
                                  htmlFor={`${entry?.name}`}
                                  className="block text-sm text-left md:text-base text-blue-gray-900"
                                >
                                  {entry?.description}
                                </label>
                              </div>
                              <div className="flex items-center ml-4 text-left">
                                <span className="mr-2">Email</span>
                                <InputSwitch
                                  name={`userNotification[${idx}].email`}
                                  id="email"
                                  checked={entry?.email}
                                  onChange={handleChange}
                                  className="border-0 input-switch"
                                  disabled={entry?.isEmailDisabled || !update}
                                />
                                <span className="mx-3">SMS</span>
                                <InputSwitch
                                  name={`userNotification[${idx}].sms`}
                                  id="sms"
                                  checked={entry?.sms}
                                  onChange={handleChange}
                                  className="border-0 input-switch"
                                  disabled={entry?.isSmsDisabled || !update}
                                />
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-row justify-end py-4">
                {userId ? (
                  <>
                    {!update && (
                      <div className="flex flex-row justify-end w-full mt-8">
                        <Button
                          variant={ButtonVariant.AppOrange}
                          size={ButtonSize.AppSize}
                          className=""
                          onClick={() => {
                            setUpdate(true);
                          }}
                          disabled={false}
                        >
                          Update
                        </Button>
                      </div>
                    )}
                    {update && (
                      <div className="flex flex-row justify-end w-full mt-8">
                        <Button
                          variant={ButtonVariant.Red}
                          size={ButtonSize.AppSize}
                          className=""
                          onClick={() => {
                            setUpdate(false);
                            revertValues();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant={ButtonVariant.Primary}
                          size={ButtonSize.AppSize}
                          className="ml-4"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type={ButtonType.Submit}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type={ButtonType.Submit}
                    className="flex flex-row items-center w-full sm:w-auto"
                  >
                    Add New User
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
