/** @format */
import { useQuery } from "@apollo/client";
import { GET_FLEET_INFORMATION } from "@roadflex/graphql";

interface UseGetFleetInformationProps {
  businessId: string;
}

export const useGetFleetInformation = ({
  businessId,
}: UseGetFleetInformationProps) => {
  const { data, refetch, ...rest } = useQuery(GET_FLEET_INFORMATION, {
    variables: {
      where: {
        businessId,
      },
    },
    fetchPolicy: "no-cache",
  });
  const fleetInfo = data?.readFleetInfo;
  return { fleetInfo, refetch, ...rest };
};
