/** @format */

import { formatJson } from "@roadflex/utils";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";

export interface JsonDataViewerModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: JSON;
  header: string;
  description?: string;
}

export const JsonDataViewerModal = ({
  open,
  data,
  setOpen,
  header,
  description,
}: JsonDataViewerModalProps) => {
  const renderFooter = () => {
    return (
      <div>
        {/* <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button> */}
      </div>
    );
  };
  return (
    <Dialog
      header={header}
      visible={open}
      style={{ width: "70vw", fontFamily: "inherit" }}
      footer={renderFooter()}
      onHide={() => setOpen(false)}
    >
      {!data && <div>No Logs</div>}
      {data && (
        <>
          {description && <div className="mb-2 text-sm">{description}</div>}
          <Fieldset className="!border-1 !border-brown-500">
            <pre className="leading-3">
              <code className="text-xs">
                {JSON.stringify(formatJson(data), null, 4).replace(
                  /"([^"]+)":/g,
                  "$1:",
                )}
              </code>
            </pre>
          </Fieldset>
        </>
      )}
    </Dialog>
  );
};
