/** @format */

import { useReadUserDetails } from "@roadflex/react-hooks";
import { FeatureType, UserAuthType, UserDetailsType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import { AccountSettingsUI } from "./account-settings.ui";

interface AccountSettingsProps {
  readCurrentUserAuth: UserAuthType;
  childFeatures: FeatureType[];
  loading: boolean;
  refetch: () => void;
}

export const AccountSettings = ({
  readCurrentUserAuth,
  childFeatures,
  loading,
  refetch,
}: AccountSettingsProps) => {
  const { query } = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const {
    readUserDetailsData,
    readUserDetailsLoading,
    readUserDetailsRefetch,
  } = useReadUserDetails("", "", "", "", ["BusinessDetails", "Setting"]);

  const [userDetails, setUserDetails] = useState<UserDetailsType | null>(null);

  useEffect(() => {
    const { tab } = query;
    let element = null;

    if (tab === "1") {
      setTabOpen(1);
      return;
    }
    if (tab === "2") {
      setTabOpen(2);
      return;
    }
    if (tab) {
      element = document.querySelector(`#${tab}`);
    }
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 80,
        left: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readUserDetailsData?.readUserDetails?.details && !loading) {
      setUserDetails(readUserDetailsData?.readUserDetails?.details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readUserDetailsLoading]);

  if (loading || !userDetails) {
    return <Loader />;
  }
  return (
    <AccountSettingsUI
      {...{
        readCurrentUserAuth,
        readUserDetailsData: userDetails,
        childFeatures,
        tabOpen,
        setTabOpen,
      }}
    />
  );
};
