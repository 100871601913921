/** @format */
import { Dialog } from "primereact/dialog";

type tooltipProps = {
  title: string;
  body: JSX.Element;
  show: boolean;
  setShow: (value: boolean) => void;
  footer: JSX.Element;
  setFooter?: (value: JSX.Element) => void;
};

export function TooltipModal({
  title,
  body,
  show,
  setShow,
  footer,
  setFooter,
}: tooltipProps) {
  return (
    <Dialog
      header={<span className="text-base md:text-lg">{title}</span>}
      visible={show}
      style={{ fontFamily: "inherit" }}
      className="w-[95%] sm:w-3/4 xl:lg:max-w-[1200px] lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      onHide={() => {
        setShow(false);
        if (setFooter) {
          setFooter(<span>RoadFlex</span>);
        }
      }}
    >
      {body}
    </Dialog>
  );
}
