/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CardShortened } from "@roadflex/types";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fragment, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";

type ApplyCardRuleModalProps = {
  mode: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  deliveredCards: CardShortened[];
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isSubmitting: boolean;
  setFieldValue: (field: string, value: string[] | string) => void;
};

export const ApplyCardRuleModal = ({
  mode,
  open,
  setOpen,
  deliveredCards,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}: ApplyCardRuleModalProps) => {
  const [selectedCards, setSelectedCards] = useState<CardShortened[]>([]);
  const cardsItemTemplate = (option: CardShortened) => {
    return (
      <div className="text-xs">
        <div>{`Card: ${option.lastFour} `}</div>
        <div>{`Driver: ${option.fleetDriver[0]?.name} `}</div>
        <div>{`Vehicle: ${option.fleet[0]?.fleetName}`}</div>
      </div>
    );
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-hidden"
        onClose={() => {
          //prevent closing when clicking outside the modal
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full px-8 py-4 overflow-y-auto bg-white">
                  <div className="relative pb-10 space-y-6">
                    <h2 className="text-lg font-medium text-left">
                      {mode === "ApplyCardRule" ? (
                        <span>Apply Policy</span>
                      ) : mode === "ApplyCardTag" ? (
                        <span>Apply Card Tag</span>
                      ) : (
                        ""
                      )}
                    </h2>
                    <div className="mb-8">
                      <div className="bg-gray-200 rounded-md">
                        {mode === "ApplyCardRule" && (
                          <p className="p-4 text-xs font-medium md:text-sm">
                            Policies can be applied to multiple cards. Once
                            applied, existing settings will be overwritten.
                          </p>
                        )}
                        {mode === "ApplyCardTag" && (
                          <p className="p-4 text-xs font-medium md:text-sm">
                            Card tags can be applied to multiple cards. Once
                            applied, existing settings will be overwritten.
                          </p>
                        )}
                      </div>
                      <div className="flex items-start justify-between mt-4">
                        <div className="w-full">
                          <div className="flex items-end justify-center px-4 pt-4 text-center sm:block sm:p-0">
                            <div className="relative inline-block w-full">
                              <div className="h-full text-center">
                                <div className="mt-2 space-y-6">
                                  <div className="overflow-hidden border-gray-200 shadow">
                                    <DataTable
                                      size="small"
                                      stripedRows
                                      value={deliveredCards}
                                      paginator
                                      rows={deliveredCards?.length || 1000}
                                      dataKey="id"
                                      style={{
                                        fontFamily: "inherit",
                                      }}
                                      sortField="lastFour"
                                      sortOrder={-1}
                                      responsiveLayout="stack"
                                      emptyMessage="No available records"
                                      selectionMode="checkbox"
                                      selection={selectedCards}
                                      onSelectionChange={(e) => {
                                        setSelectedCards(e.value);
                                      }}
                                      className="!bg-white custom-table !text-brown-500 !border-brown-500 datatable-no-footer"
                                    >
                                      <Column
                                        selectionMode="multiple"
                                        headerStyle={{ width: "3em" }}
                                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                                      ></Column>
                                      <Column
                                        sortable
                                        field="lastFour"
                                        header="Card Details"
                                        className="text-xs"
                                        bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                                        headerClassName="!bg-white !text-brown-500 !border-brown-500"
                                        body={cardsItemTemplate}
                                      />
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="fixed z-10 right-12 bottom-4"> */}
                  <div className="flex flex-row-reverse w-full pt-8 border-t-[1px] border-gray-300 mb-6">
                    <Button
                      variant={ButtonVariant.Black}
                      size={ButtonSize.AppSize}
                      disabled={isSubmitting || selectedCards.length === 0}
                      loading={isSubmitting}
                      className="ml-4"
                      onClick={() => {
                        const cardIdList = selectedCards.map((card) => {
                          return card.id;
                        });
                        setFieldValue("cardIdList", cardIdList);
                        handleSubmit();
                        setOpen(false);
                      }}
                    >
                      {mode === "ApplyCardRule" ? (
                        <span>Apply Policy</span>
                      ) : mode === "ApplyCardTag" ? (
                        <span>Apply Card Tag</span>
                      ) : (
                        ""
                      )}
                    </Button>
                    <Button
                      variant={ButtonVariant.Red}
                      size={ButtonSize.AppSize}
                      disabled={isSubmitting}
                      className=""
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
