/** @format */

import { useUpdatePassword } from "@roadflex/react-hooks";
import { toast } from "react-toastify";
import UpdatePasswordUI from "./updatePassword.ui";

const UpdatePassword = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    ...rest
  } = useUpdatePassword({
    initialValues: {
      currentPassword: "",
      password: "",
      retypePassword: "",
    },
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
      }
      resetForm();
    },
  });

  return (
    <UpdatePasswordUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        resetForm,
        ...rest,
      }}
    />
  );
};
export default UpdatePassword;
