/** @format */

import { ApolloError } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import { PLAID_REAUTHORIZE_REQUIRED } from "@roadflex/constants";
import {
  useBankAccountMakePrimary,
  useDeleteBankAccount,
  usePlaidAccountBalance,
} from "@roadflex/react-hooks";
import { PlaidType, UserAuthType } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import PlaidLinkUpdate from "../../fuelcard-components/plaid/PlaidLinkUpdate";
import { RadioInput } from "../../inputs";

export interface BankConnectionModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedData: PlaidType;
  info?: {
    plaidId: string;
    accountType?: string;
    businessId?: string;
    accountId?: string;
    inputChanged: boolean;
  };
  setInfo: (info: {
    plaidId: string;
    accountType?: string;
    businessId?: string;
    accountId?: string;
    inputChanged: boolean;
  }) => void;
  businessId: string;
  primaryPaymentMethodType: ENUM_PAYMENT_METHOD_TYPE;
  readCurrentUserAuth: UserAuthType;
}

export default function BankConnectionModal({
  open,
  setOpen,
  selectedData: plaid,
  info,
  setInfo,
  businessId,
  primaryPaymentMethodType,
  readCurrentUserAuth,
}: BankConnectionModalProps) {
  const { deleteBankAccount, loading: deletingAccount } =
    useDeleteBankAccount();
  const { makeAccountPrimary, loading: makingAccountPrimary } =
    useBankAccountMakePrimary();
  const { checkPlaidBalanceFn, loading: loadingBalance } =
    usePlaidAccountBalance();
  const [bankBalance, setBankBalance] = useState("");
  const onBankAccountDelete = async (accountId: string) => {
    try {
      await deleteBankAccount({
        variables: {
          input: {
            accountId: accountId,
            businessId: businessId,
          },
        },
      });
      toast.success("Deleted account successfully");
      setOpen(false);
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const onMakeAccountPrimary = async (plaidId: string) => {
    try {
      await makeAccountPrimary({
        variables: {
          input: {
            plaidId: plaidId,
            businessId: businessId,
            accountType: info?.accountType?.toUpperCase(),
            accountId: info?.accountId,
          },
        },
      });
      toast.success("Primary account changed successfully");
      setOpen(false);
    } catch (err) {
      if (err instanceof ApolloError) {
        toast.error(err.message || "Something went wrong");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const onCheckBalance = async () => {
    try {
      const res = await checkPlaidBalanceFn({
        variables: {
          input: {
            plaidId: plaid.id,
            accountId: plaid.accountInfo.account_id,
          },
        },
      });
      setBankBalance(
        dollarFormatter(res.data?.checkAccountBalance?.bankBalance || 0),
      );
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-hidden"
        onClose={() => {
          //prevent closing when clicking outside the modal
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col h-full p-4 overflow-y-auto bg-white">
                  <div className="mb-8 text-lg font-bold">Bank Connection</div>
                  <div className="flex flex-col justify-between flex-grow h-full">
                    <div className="space-y-4">
                      {plaid?.plaidError &&
                        (!PLAID_REAUTHORIZE_REQUIRED.includes(
                          plaid?.plaidError,
                        ) ? (
                          <div className="relative flex text-red-500">
                            Bank connection error. Remove connection and
                            reconnect.
                          </div>
                        ) : (
                          <div className="">
                            <PlaidLinkUpdate
                              businessId={businessId}
                              plaidId={plaid.id}
                              plaidError={plaid.plaidError}
                              setModal={setOpen}
                            />
                          </div>
                        ))}
                      <div className="text-sm">
                        <div className="mb-1">Bank Name</div>
                        <div className="font-semibold">{plaid.institution}</div>
                      </div>
                      <div>
                        <div className="mb-1">Accounts</div>
                        {plaid?.authInfo?.accounts?.length &&
                          plaid.authInfo.accounts
                            .filter(
                              (f) =>
                                f.subtype &&
                                (f.subtype.toUpperCase() === "CHECKING" ||
                                  f.subtype.toUpperCase() === "SAVINGS"),
                            )
                            ?.map((account) => (
                              <div
                                key={account?.account_id}
                                className="flex gap-2 mb-2 sm:items-center"
                              >
                                <RadioInput
                                  name={`accountType-${account?.account_id}`}
                                  id={`accountType-${account?.account_id}`}
                                  onChange={(e) => {
                                    setInfo({
                                      plaidId: plaid?.id,
                                      accountType: account?.subtype,
                                      businessId,
                                      accountId: account?.account_id,
                                      inputChanged: true,
                                    });
                                    setBankBalance("");
                                  }}
                                  type="radio"
                                  checked={
                                    info?.accountType?.toUpperCase() ===
                                      account?.subtype?.toUpperCase() &&
                                    info?.accountId === account.account_id
                                  }
                                  label={
                                    <div className="flex flex-wrap m-0 text-sm text-ellipsis">
                                      Account ending with
                                      <span className="mx-2 font-bold">
                                        {" "}
                                        {account?.mask}
                                      </span>
                                      <span className="">
                                        ({account?.subtype?.toUpperCase()})
                                      </span>
                                    </div>
                                  }
                                />
                              </div>
                            ))}
                      </div>
                      {!plaid?.plaidError && plaid?.newAccountsAvailable && (
                        <div className="">
                          <div className="flex justify-end text-sm italic">
                            New accounts available
                          </div>
                          <div className="flex justify-end">
                            <PlaidLinkUpdate
                              businessId={businessId}
                              plaidId={plaid.id}
                              plaidError={""}
                            />
                          </div>
                        </div>
                      )}

                      <div className="">
                        <div className="flex ">
                          <Button
                            variant={ButtonVariant.GrayOutline}
                            size={ButtonSize.Small}
                            loading={loadingBalance}
                            disabled={bankBalance.length > 0}
                            onClick={() => onCheckBalance()}
                            className="flex flex-row items-center w-auto"
                          >
                            Check Balance
                          </Button>
                        </div>
                        {bankBalance.length > 0 && (
                          <div className="flex text-sm">
                            Balance: {bankBalance}
                          </div>
                        )}
                      </div>

                      <div className="">
                        <Button
                          variant={ButtonVariant.Primary}
                          onClick={() => {
                            onMakeAccountPrimary(plaid.id);
                          }}
                          loading={makingAccountPrimary}
                          disabled={
                            plaid.plaidError !== null ||
                            (plaid.isPrimary &&
                              info?.accountId ===
                                plaid.accountInfo.account_id &&
                              primaryPaymentMethodType === "ACH") ||
                            makingAccountPrimary
                          }
                          className="flex flex-row items-center w-full sm:w-auto"
                        >
                          Set as Primary Payment Method
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Button
                        variant={ButtonVariant.Red}
                        onClick={() => {
                          onBankAccountDelete(plaid.id);
                        }}
                        //disable this button if primary connection for credit users. (we are not giving option to remove last connection to credit users)
                        //disable this button for prepaid only if isPrimary &&  primaryPaymentMethodType === "ACH"
                        //by default atleast 1 entry remain primary on the backend.
                        disabled={
                          (plaid.isPrimary &&
                            primaryPaymentMethodType === "ACH") ||
                          deletingAccount
                        }
                        loading={deletingAccount}
                        className="flex flex-row items-center w-full sm:w-auto"
                      >
                        Remove Connection
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
