/** @format */

import { CreditCardIcon } from "@heroicons/react/outline";
import { dollarFormatter } from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";

type AccountBalanceUIProps = {
  currentData: {
    creditBalance: number;
    bonusAmount: number;
    totalFuelQuantity: number;
    bonusType: string;
    referralBalance: number;
    signOnBalance: number;
    targetFuelQuantity: number;
    amountCorrection: number;
  };
};

export const AccountBalanceUI = ({ currentData }: AccountBalanceUIProps) => {
  const router = useRouter();
  return (
    <div className="flex flex-col w-full p-4 mt-4 mr-4 bg-white rounded-md shadow gap-y-2">
      <div className="py-2 font-semibold text-brown-500 md:text-lg">
        Account Balance:{" "}
        {dollarFormatter(
          ((+currentData?.signOnBalance || 0) +
            (+currentData?.referralBalance || 0) +
            (+currentData?.creditBalance || 0)) /
            100,
        )}
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <div className="flex flex-row">
            <div className="flex items-center mr-2">Account Credit</div>
            <div className="px-2 py-1 bg-orange-100">
              <Button
                variant={ButtonVariant.Transparent}
                size={ButtonSize.AppSize}
                className="!p-0"
                onClick={() => {
                  router.push("/payments");
                }}
              >
                <div className="flex text-orange-500">
                  <CreditCardIcon className="w-5 h-5 mr-1"></CreditCardIcon>
                  <div className="text-sm font-semibold whitespace-nowrap">
                    Add Funds
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {dollarFormatter((+currentData?.creditBalance || 0) / 100)}
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <div className="flex items-center">Referral Bonus</div>
        </div>
        <div className="flex items-center">
          {dollarFormatter((+currentData?.referralBalance || 0) / 100)}
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <div className="flex items-center">Sign-on Bonus</div>
        </div>
        <div className="flex items-center">
          {dollarFormatter((+currentData?.signOnBalance || 0) / 100)}
        </div>
      </div>
    </div>
  );
};
