/** @format */
import { useQuery } from "@apollo/client";
import { READ_HOME_ADDRESS } from "@roadflex/graphql";
import { AddressType } from "@roadflex/types";

type useReadHomeAddressProps = {
  id: string | null | undefined;
};

export const useReadHomeAddress = ({ id }: useReadHomeAddressProps) => {
  const { data, ...rest } = useQuery<{
    readHomeAddress: {
      address: AddressType;
      id: string;
    };
  }>(READ_HOME_ADDRESS, {
    variables: {
      data: {
        id,
      },
    },
    fetchPolicy: "no-cache",
    // skip: !!businessId !== true,
  });

  return { data: data?.readHomeAddress, ...rest };
};
