/** @format */
import { useQuery } from "@apollo/client";
import { BILLING_CYCLE_LIST } from "@roadflex/graphql";
import { BillingCycleType } from "@roadflex/types";

export const useBillingCycleList = () => {
  const { data, ...rest } = useQuery<{
    billingCycleList: {
      code: string;
      message: string;
      billingCycleList: BillingCycleType[];
    };
  }>(BILLING_CYCLE_LIST, {
    fetchPolicy: "no-cache",
  });
  return { data, ...rest };
};
