/** @format */

import { dollarFormatter } from "@roadflex/constants";
import { useReadReferrals, useShareReferral } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import getConfig from "next/config";
import React, { useState } from "react";
import { Step } from "react-joyride";
import { toast } from "react-toastify";
import { Loader } from "../../loader";
import { ReferralUI } from "./referral.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

interface ReferralProps {
  readCurrentUserAuth: UserAuthType;
  loading?: boolean;
}

export const Referral = ({ readCurrentUserAuth, loading }: ReferralProps) => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { data, loading: loadingAllReferrals } = useReadReferrals();
  const { handleReset, ...rest } = useShareReferral({
    initialValues: {
      email: "",
      phoneNumber: "",
    },
    onSubmit(res) {
      if (res?.code === "200") {
        handleReset({});
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    },
  });

  const referralEarnings = data?.readReferrals.referralEarnings || 0;
  const referralSetting = data?.readReferrals.referralSetting || null;
  const referralCode = data?.readReferrals?.referralCode || "";
  const referralLink = `${NEXT_PUBLIC_WWW_URL}/signup?referral=${referralCode}`;

  const steps: Step[] = [
    {
      title: "Welcome",
      content: "Welcome to the Refer & Earn page.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      placement: "center",
      target: "body",
    },
    {
      title: "Referral Code",
      content: "This is your referral code.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-2",
    },
    {
      title: "How to share",
      content: "Invite via text, email or other platforms.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-3",
    },
    {
      target: ".step-4",
      title: "Referral Earnings",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      content: `Once the referred fleet account purchases ${
        referralSetting?.fuelQuantity
      } gallons of fuel using RoadFlex cards, ${dollarFormatter(
        (referralSetting?.bonusAmount || 0) / 100,
      )} credit will be applied to your account. Keep track of your earnings here.`,
    },
    {
      target: ".step-5",
      title: "Help Center",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      content:
        'If you need help or would like to go through the navigation again, click "Help Center".',
    },
  ];

  const [joyride, setJoyride] = useState(false);

  if (loading || loadingAllReferrals) {
    return <Loader />;
  }

  return (
    <ReferralUI
      {...{
        readCurrentUserAuth,
        referralCode,
        referralLink,
        isLinkCopied,
        setLinkCopied,
        handleReset,
        joyride,
        setJoyride,
        steps,
        allReferrals: data?.readReferrals?.referred || [],
        loadingAllReferrals: loadingAllReferrals,
        referralEarnings,
        referralSetting,
        ...rest,
      }}
    ></ReferralUI>
  );
};
