/** @format */

import {
  usePlatformDetect,
  useReadAccountDisplaySetting,
} from "@roadflex/react-hooks";
import { PaymentHistoryType } from "@roadflex/types";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { dateTemplate } from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";

interface PaymentsHistoryUIProps {
  paymentHistoryList: PaymentHistoryType[];
  loadingPaymentHistory: boolean;
  amountTemplate: (rowData: PaymentHistoryType) => void;
  statusTemplate: (rowData: PaymentHistoryType) => void;
}

export const PaymentsHistoryUI = ({
  paymentHistoryList,
  loadingPaymentHistory,
  amountTemplate,
  statusTemplate,
}: PaymentsHistoryUIProps) => {
  const { isMobile } = usePlatformDetect();
  const {
    readAccountDisplaySetting,
    refetch: readAccountDisplaySettingRefetch,
  } = useReadAccountDisplaySetting();

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Payments History`.toUpperCase()}
      </div>
      <div className="p-4 mt-5 overflow-hidden bg-white rounded-md shadow md:p-8 md:m-0">
        <div className="font-medium">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
            <DataTable
              pageLinkSize={isMobile() ? 2 : 3}
              size="small"
              stripedRows
              value={paymentHistoryList}
              loading={loadingPaymentHistory}
              paginator
              rows={10}
              dataKey="id"
              style={{
                fontFamily: "inherit",
              }}
              sortField="createdAt"
              sortOrder={-1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              className="custom-table"
            >
              <Column
                sortable
                field="createdAt"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                header={`Date (${readAccountDisplaySetting?.timeZone})`}
                body={(r) =>
                  dateTemplate(r.createdAt, readAccountDisplaySetting?.timeZone)
                }
              />
              {!isMobile() && (
                <Column
                  // filter
                  // sortable
                  field="type"
                  header="Payment Type"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) => (
                    <span>
                      {/* 
                    FOR PAYMENTHISTORY TABLE TYPE IS COMING AS ACH
                      IF PAYID EXISTS AND TYPE IS ACH 
                      THEN TYPE = ACH 
                      ELSE TYPE = ROADFLEX WALLET
                    FOR MANUAL PAYMENT TABLE
                      TYPE WILL BE 'MANUAL' ONLY
                    */}
                      {/* {r?.payId === "Wallet" ? "Roadflex Wallet" : r.type} */}
                      {r.type}
                    </span>
                  )}
                />
              )}
              <Column
                sortable={!isMobile()}
                field="amount"
                header="Amount"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) => amountTemplate(r?.amount)}
              />
              <Column
                field="status"
                header="Status"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={statusTemplate}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};
