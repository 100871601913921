/** @format */

import classNames from "classnames";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import React, { forwardRef, useEffect, useState } from "react";

export const CustomMultiSelect = forwardRef<
  MultiSelect,
  MultiSelectProps & {
    variant?: "small" | "medium" | "large" | "responsive";
    error?: boolean;
    errorMessage?: string;
    label?: string;
    required?: boolean;
  }
>(
  (
    {
      value,
      options,
      onChange,
      placeholder = "Select options",
      className,
      panelClassName,
      optionLabel = "label",
      optionValue = "value",
      itemTemplate,
      disabled = false,
      style,
      variant = "medium",
      error = false,
      errorMessage,
      label,
      required,
      ...props
    },
    ref,
  ) => {
    const [variantValue, setVariantValue] = useState(variant);

    useEffect(() => {
      const updateVariant = () => {
        if (window.innerWidth >= 768) {
          setVariantValue("large");
        } else {
          setVariantValue("medium");
        }
      };
      if (variant === "responsive") {
        // Initial check
        updateVariant();

        // Add event listener
        window.addEventListener("resize", updateVariant);

        // Cleanup event listener on component unmount
      }
      return () => window.removeEventListener("resize", updateVariant);
    }, [variant]);

    const selectClass = classNames(
      "custom-multiselect w-full relative",
      className,
      {
        "border-red-500": error,
        small: variantValue === "small",
        medium: variantValue === "medium",
        large: variantValue === "large",
      },
    );
    const labelClass = classNames("block", {
      "text-sm mb-1": variantValue === "small",
      "text-base mb-1": variantValue === "medium",
      "text-base mb-2": variantValue === "large",
    });

    const panelClass = classNames("custom-multiselect-panel", panelClassName, {
      small: variantValue === "small",
      medium: variantValue === "medium",
      large: variantValue === "large",
    });

    return (
      <div>
        {label && (
          <div className={labelClass}>
            {label}
            {required && "*"}
          </div>
        )}
        <MultiSelect
          value={value}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          className={selectClass}
          panelClassName={panelClass}
          optionLabel={optionLabel}
          optionValue={optionValue}
          itemTemplate={itemTemplate}
          filter={(options || []).length > 7}
          disabled={disabled}
          style={style}
          {...props}
        />
      </div>
    );
  },
);

export default CustomMultiSelect;
