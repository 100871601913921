/** @format */

import {
  ArrowCircleRightIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { Business, UserAuthType } from "@roadflex/types";
import { getCookie, setCookie } from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { ACHCreditWireModal } from "../../modals/ach-credit-wire-modal/ach-credit-wire-modal";
import { MakePaymentModal } from "../../modals/make-payment-modal/make-payment-modal";
import { PlaidPaymentMethods } from "../plaid/PlaidPaymentMethods";
import { StripePaymentMethods } from "../stripe-payment-methods/StripePaymentMethods";

interface PaymentsUIProps {
  readCurrentUserAuth: UserAuthType;
  business: Business | null;
  userDepositCode: string | undefined;
  showRepaymentButton: boolean;
  customerType: ENUM_CUSTOMER_TYPE;
  allowACHPaymentMethod: boolean;
  allowDebitCardPaymentMethod: boolean;
}
interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

export const PaymentsUI = ({
  readCurrentUserAuth,
  business,
  userDepositCode,
  showRepaymentButton,
  customerType,
  allowACHPaymentMethod,
  allowDebitCardPaymentMethod,
}: PaymentsUIProps) => {
  const [isPayNowClicked, setIsPayNowClicked] = useState(false);
  const [isOtherPaymentClicked, setIsOtherPaymentClicked] = useState(false);
  const router = useRouter();
  const [{ run, stepIndex, steps }, setJoyride] = useState<JoyrideState>({
    run: false,
    stepIndex: 0,
    steps: [],
  });

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setJoyride({ run: true, stepIndex: 0, steps });
  };

  useEffect(() => {
    const paymentsGuide = getCookie("paymentsGuide");
    const creditsteps: Step[] = [
      {
        title: "Welcome",
        content: "Welcome to the Payments page.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        placement: "center",
        target: "body",
      },
      {
        title: "",
        content:
          "To connect a debit card, click here. Note that we only allow a maximum of 5 debit card connections.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target: ".step-2",
      },
      {
        title: "",
        content:
          "To connect a bank account, click here. Note that we only allow a maximum of 5 ACH connections.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target: ".step-3",
      },
      {
        title: "",
        content:
          "Once your card or bank account is connected, you can make payment by clicking this button. Note: Prepaid Accounts only have a card option for payments and auto-loads",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        target: ".step-4",
      },
      {
        title: "",
        content:
          "Pay by ACH Credit or wire is an additional payment option given to you to fund your account, but this is a last resort option.",
        target: ".step-5",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
      },
      {
        target: ".step-7",
        title: "Help Center",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        content:
          'If you need help or would like to go through the navigation again, click "Help Center".',
      },
    ];
    const prepaidSteps: Step[] = [
      {
        title: "Welcome",
        content: "Welcome to the Payments page.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        placement: "center",
        target: "body",
      },
      {
        title: "",
        content:
          "To connect a debit card, click here. Note that we only allow a maximum of 5 debit card connections.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target: ".step-2",
      },
      {
        title: "",
        content:
          "Once your card or ACH is connected, you can make payment by clicking this button.",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        target: ".step-4",
      },
      {
        title: "",
        content:
          "Pay by ACH Credit or wire is an additional payment option given to you to fund your account, but this is a last resort option.",
        target: ".step-5",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
      },
      {
        title: "",
        content:
          "Auto-reload allows you to fund your account when your account falls below a specific balance. Alternatively, you can choose to fund your account on a weekly or monthly basis.",
        target: ".step-6",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
      },
      {
        target: ".step-7",
        title: "Help Center",
        locale: {
          skip: (
            <span aria-label="skip" style={{ fontSize: "16px" }}>
              End
            </span>
          ),
        },
        spotlightPadding: 10,
        content:
          'If you need help or would like to go through the navigation again, click "Help Center".',
      },
    ];
    if (!paymentsGuide) {
      setJoyride({
        run: true,
        stepIndex,
        steps:
          customerType === "CREDIT" ||
          customerType === "CREDIT_PLUS" ||
          customerType === "CREDIT_ENTERPRISE"
            ? creditsteps
            : prepaidSteps,
      });
    } else {
      setJoyride({
        run: false,
        stepIndex,
        steps:
          customerType === "CREDIT" ||
          customerType === "CREDIT_PLUS" ||
          customerType === "CREDIT_ENTERPRISE"
            ? creditsteps
            : prepaidSteps,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { action, index, status, type } = callbackData;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setCookie("paymentsGuide", "true", 30);
      setJoyride({ run: false, stepIndex, steps });
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyride({
        stepIndex: nextStepIndex,
        run,
        steps,
      });
    }
  };
  return (
    <div className="flex flex-col flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4">
      <div className="flex justify-between text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        <div>{`Payments`.toUpperCase()}</div>
        <div className="flex items-center justify-end rounded-md md:mt-0 step-6">
          <Button
            onClick={handleClickStart}
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Wrap}
          >
            <QuestionMarkCircleIcon
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
            />
            <span className="px-1 text-xs italic">Help Center</span>
          </Button>
        </div>
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        stepIndex={stepIndex}
        run={run}
        scrollToFirstStep
        showProgress
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        scrollOffset={76}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />
      <div className="p-4 mt-5 bg-white rounded-md shadow md:p-8 md:mt-0">
        <div className="flex justify-end flex-grow w-full mb-4 md:mb-5">
          {showRepaymentButton &&
            (customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
              customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
              customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
              <Button
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                className="mr-3"
                onClick={() => router.push("/payments/repayment-plan")}
              >
                Repayment Plan
              </Button>
            )}
          {(customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
            customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
            customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE ||
            customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
            <Button
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              className="mr-3 step-6"
              onClick={() => router.push("/payments/auto-reload")}
              disabled={!allowDebitCardPaymentMethod}
            >
              {customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE && (
                <span>Auto Pay</span>
              )}
              {customerType !== ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE && (
                <span>Auto Reload</span>
              )}
            </Button>
          )}

          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.AppSize}
            onClick={() => setIsPayNowClicked(true)}
            className="step-4"
            disabled={!allowACHPaymentMethod && !allowDebitCardPaymentMethod}
          >
            <span>Make Payment</span>
          </Button>
        </div>
        <div className="flex justify-end flex-grow w-full mb-4 md:mb-5">
          <Button
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Wrap}
            onClick={() => setIsOtherPaymentClicked(true)}
            className="step-5"
          >
            <span>Pay by ACH Credit or Wire </span>
            <ArrowCircleRightIcon className="w-5 h-5 ml-2" aria-hidden="true" />
          </Button>
        </div>
        {business?.id && allowACHPaymentMethod && (
          <div>
            <div className="flex flex-col w-full mt-4 md:mt-5">
              <div className="flex flex-row justify-between py-2 text-sm font-semibold border-b-2 text-brown-500 md:text-base border-brown-500">
                BANK ACCOUNTS
              </div>
            </div>
            <div className="mt-5 mb-5 overflow-hidden md:mt-0">
              <div className="p-2 font-medium sm:p-3 ">
                <PlaidPaymentMethods
                  plaidData={business?.plaid}
                  businessId={business?.id}
                  primaryPaymentMethodType={business.paymentMethodType}
                  readCurrentUserAuth={readCurrentUserAuth}
                  ui="box"
                ></PlaidPaymentMethods>
              </div>
            </div>
          </div>
        )}
        {business?.id && allowDebitCardPaymentMethod && (
          <div>
            <div className="flex flex-col w-full mt-4 md:mt-8">
              <div className="flex flex-row justify-between py-2 text-sm font-semibold border-b-2 text-brown-500 md:text-base border-brown-500">
                DEBIT CARDS
              </div>
            </div>
            <div className="mb-5 overflow-hidden ">
              <div className="font-medium">
                <StripePaymentMethods
                  ui="box"
                  primaryPaymentMethodType={business?.paymentMethodType}
                ></StripePaymentMethods>
              </div>
            </div>
          </div>
        )}
      </div>
      {isOtherPaymentClicked && business?.id && (
        <ACHCreditWireModal
          open={isOtherPaymentClicked}
          setOpen={setIsOtherPaymentClicked}
          userDepositCode={userDepositCode}
        ></ACHCreditWireModal>
      )}

      {isPayNowClicked && business?.id && (
        <MakePaymentModal
          plaidData={business?.plaid}
          businessId={business?.id}
          open={isPayNowClicked}
          setOpen={setIsPayNowClicked}
          customerType={readCurrentUserAuth.fuelCard.customerType}
          allowACHPaymentMethod={allowACHPaymentMethod}
          allowDebitCardPaymentMethod={allowDebitCardPaymentMethod}
          readCurrentUserAuth={readCurrentUserAuth}
        ></MakePaymentModal>
      )}
    </div>
  );
};
