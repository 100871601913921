/** @format */

import { useApolloClient } from "@apollo/client";
import { appAccountSettingsChangeEmailSchema } from "@roadflex/constants";
import {
  useSendVerificationEmailOrSms,
  useUpdateEmailOrPhoneNumber,
} from "@roadflex/react-hooks";
import { UserDetailsType } from "@roadflex/types";
import { deleteCookie, getCookie, phoneFormatter } from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { useState } from "react";
import { toast } from "react-toastify";
import UpdateBasicUI from "./updateBasic.ui";

interface UpdateBasicProps {
  readUserDetailsData: UserDetailsType;
}

const UpdateBasic = ({ readUserDetailsData }: UpdateBasicProps) => {
  const [showOtpModal, setShowOtpModal] = useState(false);

  const [updatedEmail, setUpdatedEmail] = useState(false);
  const [updatedPhone, setUpdatedPhone] = useState(false);

  const client = useApolloClient();
  const router = useRouter();

  const {
    values: SendVerificationValues,
    handleChange: SendVerificationHandleChange,
    handleSubmit: SendVerificationHandleSubmit,
    isSubmitting: SendVerificationIsSubmitting,
    handleBlur: SendVerificationHandleBlur,
    errors: SendVerificationErrors,
    touched: SendVerificationTouched,
    setFieldValue: SendVerificationSetFieldValue,
    handleReset: SendVerificationHandleReset,
    resetForm: SendVerificationResetForm,
  } = useSendVerificationEmailOrSms({
    initialValues: {
      newEmail: readUserDetailsData?.email,
      currentEmail: readUserDetailsData?.email,
      newPhoneNumber: phoneFormatter(readUserDetailsData?.phoneNumber),
      currentPhoneNumber: phoneFormatter(readUserDetailsData?.phoneNumber),
    },
    validationSchema: appAccountSettingsChangeEmailSchema,
    onSubmit(res, err) {
      if (err) {
        toast.error(err.message);
        return;
      }
      if (res?.code === "200") {
        if (
          SendVerificationValues.newEmail !==
          SendVerificationValues.currentEmail
        ) {
          setShowOtpModal(true);
        }
        if (
          SendVerificationValues.newPhoneNumber
            ?.trim()
            ?.match(/\d+/g)
            ?.join("") !== SendVerificationValues.currentPhoneNumber
        ) {
          setShowOtpModal(true);
        }
      }
    },
  });

  const handleLogout = async () => {
    if (getCookie("impersonatorToken")) {
      deleteCookie("impersonatorToken");
    } else {
      deleteCookie("token");
      deleteCookie("firstLogin");
    }
    await client.clearStore();
    router.push("/");
  };

  const { setFieldValue, handleSubmit, values, isSubmitting, ...rest } =
    useUpdateEmailOrPhoneNumber({
      initialValues: {
        otp: "",
        email: "",
        phoneNumber: "",
      },
      onSubmit(res, err) {
        if (err) {
          toast.error(err.message);
          return;
        }
        if (res?.code === "200") {
          setShowOtpModal(false);
          if (values.email) {
            setUpdatedEmail(false);
            toast.success(res?.message);
          }
          if (values.phoneNumber) {
            setUpdatedPhone(false);
            toast.success(res?.message);
          }
          handleLogout();
        }
      },
    });

  return (
    <UpdateBasicUI
      {...{
        readUserDetailsData,
        showOtpModal,
        setShowOtpModal,
        updatedEmail,
        updatedPhone,
        setUpdatedEmail,
        setUpdatedPhone,
        SendVerificationValues,
        SendVerificationHandleChange,
        SendVerificationHandleSubmit,
        SendVerificationResetForm,
        SendVerificationIsSubmitting,
        SendVerificationHandleBlur,
        SendVerificationErrors,
        SendVerificationTouched,
        SendVerificationSetFieldValue,
        setFieldValue,
        handleSubmit,
        values,
        isSubmitting,
        ...rest,
      }}
    />
  );
};
export default UpdateBasic;
