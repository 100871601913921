/** @format */
import { Dialog } from "primereact/dialog";

interface ACHCreditWireModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  userDepositCode?: string;
}

export const ACHCreditWireModal = ({
  open,
  setOpen,
  userDepositCode,
}: ACHCreditWireModalProps) => {
  return (
    <Dialog
      header={`Payment Instructions`}
      visible={open}
      style={{ fontFamily: "inherit" }}
      className="w-[95%] sm:w-3/4 xl:lg:max-w-[1200px] lg:max-w-[750px] md:max-w-[500px]"
      onHide={() => setOpen(false)}
    >
      <div className="min-h-[300px] text-sm md:text-base">
        <div className="mb-4">
          <div className="pb-5 font-semibold">Your RoadFlex Account ID</div>
          <div className="pb-5 font-semibold">{userDepositCode}</div>
          <div>
            Reference this ID if you send us a payment through your bank. If an
            ID is unavailable, reference the email linked to your account.
          </div>
        </div>
        <div className="border-t-2 border-gray-300"></div>

        <div className="my-4">
          <div className="pb-5 font-semibold">
            Send us an ACH Payment or Wire through your bank
          </div>
          <div className="pb-5">
            ACH Payments can be made directly to RoadFlex&apos;s account below:
          </div>
          <div>Evolve Bank & Trust </div>
          <div>6070 Poplar Ave, Suite 200, Memphis, TN 38119</div>
          <div>Routing Number: 084106768</div>
          <div>Account Number: 9802941880</div>
        </div>
        <div className="border-t-2 border-gray-300"></div>
        <div className="pt-5">Note</div>
        <div className="">
          <span>
            Please put your RoadFlex Account ID in the Transaction Memo or
            Reference Field. To avoid late fees, make sure to schedule payments
            such that RoadFlex receives your payment by 9AM PST on your due
            date, or by 9AM PST on the prior business day if your due date falls
            on a non-business day.
          </span>
        </div>
      </div>
    </Dialog>
  );
};
