import { CreditCardIcon } from "@heroicons/react/solid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import getConfig from "next/config";
import React from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import StripeSetupIntentForm from "./StripeSetupIntentForm";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = publicRuntimeConfig;

const stripeResponse = loadStripe(`${NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

interface StripeAddNewCardButtonUIProps {
  addPaymentMethod: () => void;
  clientSecret: string;
  addingPaymentMethod: boolean;
  setShowSetupIntentForm: (value: boolean) => void;
  showSetupIntentForm: boolean;
  onRepaymentActivate?: () => void;
  onDeposit?: () => void;
  disabled?: boolean;
  loading?: boolean;
  buttonName?: "Connect Debit Card" | "Add a debit card";
}
export const StripeAddNewCardButtonUI = (
  props: StripeAddNewCardButtonUIProps,
) => {
  const {
    clientSecret,
    addingPaymentMethod,
    addPaymentMethod,
    setShowSetupIntentForm,
    showSetupIntentForm,
    onRepaymentActivate,
    onDeposit,
    disabled,
    loading,
    buttonName = "Connect Debit Card",
  } = props;

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        focusBoxShadow: "none",
        colorPrimary: "#893f2b",
      },
    },
  };

  return (
    <div className="">
      {onRepaymentActivate ? (
        <Button
          variant={ButtonVariant.Gray}
          loading={addingPaymentMethod}
          onClick={() => addPaymentMethod()}
          size={ButtonSize.Small}
          className="flex flex-row items-center w-full sm:w-auto"
        >
          Activate
        </Button>
      ) : onDeposit ? (
        <Button
          size={ButtonSize.Large}
          variant={ButtonVariant.Brown}
          loading={addingPaymentMethod || loading}
          onClick={() => addPaymentMethod()}
          disabled={disabled}
          className="flex flex-row items-center w-full !rounded-xl sm:!py-3 sm:w-auto"
        >
          <CreditCardIcon className="w-5 h-5 mr-2" aria-hidden="true" />
          <span>Pay Now</span>
        </Button>
      ) : (
        <Button
          variant={
            buttonName === "Connect Debit Card"
              ? ButtonVariant.AppOrange
              : ButtonVariant.Gray
          }
          size={ButtonSize.AppSize}
          loading={addingPaymentMethod}
          onClick={() => addPaymentMethod()}
          className="flex flex-row items-center w-full step-2 sm:w-auto"
        >
          {buttonName === "Add a debit card" && "+ "}
          {buttonName}
        </Button>
      )}

      {clientSecret && showSetupIntentForm && (
        <Elements options={options} stripe={stripeResponse}>
          <StripeSetupIntentForm
            onRepaymentActivate={onRepaymentActivate}
            onDeposit={onDeposit}
            showSetupIntentForm={showSetupIntentForm}
            setShowSetupIntentForm={setShowSetupIntentForm}
          ></StripeSetupIntentForm>
        </Elements>
      )}
    </div>
  );
};
