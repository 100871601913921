/** @format */

import { useSetPassword } from "@roadflex/react-hooks";
import { UserOnboardType } from "@roadflex/types";
import { useEffect } from "react";
import { toast } from "react-toastify";
import SetPasswordUI from "./set-password.ui";

export interface SetPasswordProps {
  nextStep: () => void;
  readCurrentUserOnboard: UserOnboardType;
  isSubmittingOnboardStatus: boolean;
}

const SetPassword = ({
  nextStep,
  readCurrentUserOnboard,
  isSubmittingOnboardStatus,
}: SetPasswordProps) => {
  useEffect(() => {
    const { isPasswordSet } = readCurrentUserOnboard;

    if (isPasswordSet) {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readCurrentUserOnboard]);

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    ...rest
  } = useSetPassword({
    initialValues: {
      password: "",
      retypePassword: "",
    },
    onSubmit(val, error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      const { success } = val as { success: boolean };

      if (!success) {
        toast.error("Something went wrong, please try again.");
        return;
      }

      nextStep();
    },
  });

  return (
    <SetPasswordUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isSubmittingOnboardStatus,
        handleBlur,
        errors,
        touched,
        ...rest,
      }}
    />
  );
};

export default SetPassword;
