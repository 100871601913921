/** @format */
import { useMutation } from "@apollo/client";
import { genericPasswordSchema } from "@roadflex/constants";
import { PASSWORD_SET } from "@roadflex/graphql";
import { PasswordFormValues } from "@roadflex/types";
import { useFormik } from "formik";

export type useSetPasswordProps = {
  onSubmit: (val: { success: boolean } | null, error: Error | null) => void;
  initialValues: PasswordFormValues;
};

export const useSetPassword = ({
  onSubmit,
  initialValues,
}: useSetPasswordProps) => {
  const [mutationFn] = useMutation(PASSWORD_SET);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPasswordSchema,
    onSubmit: async (value) => {
      try {
        const res = await mutationFn({
          variables: { data: value },
        });
        onSubmit(res?.data?.passwordSet, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, { message: "Something Went Wrong", name: "" });
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
