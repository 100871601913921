/** @format */

import {
  ChevronRightIcon,
  DownloadIcon,
  PhotographIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import {
  AccountTagType,
  ExtendedTransactionType,
  MapType,
  MerchantDetails,
  PurchaseDetails,
} from "@roadflex/types";
import { convertISOStringToDate, humanize } from "@roadflex/utils";
import { calculateDistance, dollarFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { TextInput } from "../../../inputs";
import { SinglePointLocationMap } from "../../eld-systems";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY, NEXT_PUBLIC_API_URL } = publicRuntimeConfig;
interface Upload {
  file: File | null;
  progress: number;
  isLoading: boolean;
  hasError: boolean;
  url?: string;
  fromServer: boolean;
  createdAt: Date;
  fileId: string;
}
interface TransactionDetailsUIProps {
  transactionDetailsObj: ExtendedTransactionType;
  isBlockMerchantsUpdating: boolean;
  blockMerchantsArray: string[];
  setBlockMerchantsArray: (val: string[]) => void;
  updateList: () => void;
  updateTransactionMemoSubmitting: boolean;
  updateTransactionMemoValues: {
    memo: string;
    transactionId: string;
    type: string;
    transactionTagId?: string;
    cardTagId?: string;
    driverTagId?: string;
    vehicleTagId?: string;
  };
  handleUpdateTransactionMemoSubmit: () => void;
  handleUpdateTransactionMemoChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  editMemo: boolean;
  setEditMemo: (val: boolean) => void;
  setFieldValue: (field: string, value: string) => void;
  transactionTags: AccountTagType[];
  cardTags: AccountTagType[];
  driverTags: AccountTagType[];
  vehicleTags: AccountTagType[];
  accountTagsDataLoading: boolean;
  setInitialMemoValues: () => void;
  handleRemoveReceiptFile: (value: string) => void;
  handleReceiptFiles: (value: FileList) => void;
  uploads: Upload[];
}

export const TransactionDetailsPageUI = ({
  transactionDetailsObj,
  isBlockMerchantsUpdating,
  blockMerchantsArray,
  setBlockMerchantsArray,
  updateList,
  updateTransactionMemoValues,
  handleUpdateTransactionMemoSubmit,
  updateTransactionMemoSubmitting,
  handleUpdateTransactionMemoChange,
  editMemo,
  setEditMemo,
  setFieldValue,
  transactionTags,
  cardTags,
  driverTags,
  vehicleTags,
  accountTagsDataLoading,
  setInitialMemoValues,
  handleReceiptFiles,
  handleRemoveReceiptFile,
  uploads,
}: TransactionDetailsUIProps) => {
  const [mapValues, setMapValues] = useState<MapType>({
    latitude: "0",
    longitude: "0",
    message: "",
  });
  const [showMap, setShowMap] = useState(true);
  const [accuracy, setAccuracy] = useState("");
  const [vehicleAddress, setVehicleAddress] = useState("-");
  const [loading, setLoading] = useState(false);
  const [distanceFromMerchant, setDistanceFromMerchant] = useState<
    number | null
  >(null);

  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const accountTagItemTemplate = (accountTagItem: AccountTagType) => {
    const index = Number(accountTagItem?.colorCode) || 0;
    const length = ACCOUNT_TAGS_COLOR.length;
    const colorCode = ACCOUNT_TAGS_COLOR[index % length];
    return (
      <div>
        <span
          className={classNames(colorCode, "rounded-full text-sm px-2 mr-3")}
        ></span>
        <span>
          {accountTagItem.title}{" "}
          {accountTagItem.tagId ? <span>({accountTagItem.tagId})</span> : null}
        </span>
      </div>
    );
  };

  const router = useRouter();
  const backToTransactionsPanel = () => {
    router.push("/transactions");
  };

  const discardChanges = () => {
    setEditMemo(false);
    setInitialMemoValues();
  };

  const toggle = () => {
    if (editMemo) {
      setEditMemo(false);
    } else {
      setEditMemo(true);
    }
  };
  useEffect(() => {
    const vehicleLocationInfo =
      transactionDetailsObj?.authorization?.extraInfo?.liveLocation &&
      transactionDetailsObj?.authorization?.extraInfo?.liveLocation[0];

    const vehicleLat = vehicleLocationInfo?.latitude;
    const vehicleLng = vehicleLocationInfo?.longitude;
    const merchantDetails: MerchantDetails | null = transactionDetailsObj
      ?.stripeResponse?.merchant_data
      ? transactionDetailsObj?.stripeResponse?.merchant_data
      : transactionDetailsObj?.highnoteResponse?.merchantDetails
      ? {
          name:
            transactionDetailsObj?.highnoteResponse?.merchantDetails?.name ||
            "",
          category:
            transactionDetailsObj?.highnoteResponse?.merchantDetails
              ?.category || "",
          category_code:
            transactionDetailsObj?.highnoteResponse?.merchantDetails
              ?.categoryCode || "",
          city:
            transactionDetailsObj?.highnoteResponse?.merchantDetails?.address
              ?.locality || "",
          country:
            transactionDetailsObj?.highnoteResponse?.merchantDetails
              ?.countryCodeAlpha3 || "",
          postal_code:
            transactionDetailsObj?.highnoteResponse?.merchantDetails?.address
              ?.postalCode || "",
          state:
            transactionDetailsObj?.highnoteResponse?.merchantDetails?.address
              ?.region || "",
        }
      : null;
    if (merchantDetails) {
      const name = merchantDetails.name;
      const city = merchantDetails.city;
      const state = merchantDetails.state;
      const postalCode = merchantDetails.postal_code;
      const country = merchantDetails.country;
      const fullAddress = `${name}, ${city}, ${state} ${postalCode}, ${country}`;
      // console.log("fullAddress => ", fullAddress);
      Geocode.setApiKey(NEXT_PUBLIC_GOOGLE_MAP_KEY);
      Geocode.setLanguage("en");

      Geocode.fromAddress(fullAddress).then(
        (response) => {
          // console.log("final response => ", response);
          if (response.results && response.results.length > 0) {
            const { lat, lng } = response.results[0].geometry.location;
            const { location_type: locationType } =
              response.results[0].geometry;
            const accuracyMessage =
              locationType && locationType === "ROOFTOP"
                ? "Accurate"
                : "Approximate";
            setAccuracy(accuracyMessage);
            // setMerchantLocation({ lat, lng });
            setMapValues({
              latitude: lat,
              longitude: lng,
              message: `${accuracyMessage} Location`,
            });

            if (vehicleLat && vehicleLng) {
              Geocode.fromLatLng(vehicleLat, vehicleLng).then(
                (res) => {
                  // console.log("final res => ", res);
                  if (res.results && res.results.length > 0) {
                    const address = res.results[0].formatted_address;
                    setVehicleAddress(address);
                    const distance = calculateDistance(
                      Number(lat),
                      Number(lng),
                      Number(vehicleLat),
                      Number(vehicleLng),
                    );
                    setDistanceFromMerchant(Number(distance));
                  }
                },
                (error) => {
                  console.error(error);
                },
              );
            }
          } else {
            setShowMap(false);
          }
        },
        (error) => {
          setShowMap(false);
          console.error(error);
        },
      );
    }
  }, [transactionDetailsObj]);

  const purchaseDetails: PurchaseDetails = {
    ...transactionDetailsObj?.purchaseDetails,
    ...transactionDetailsObj?.highnotePurchaseDetails,
  };
  const fuelInfo = transactionDetailsObj?.extraInfo?.fuelLevel;

  const grossAmt = (transactionDetailsObj?.transactionAmount || 0) / 100;
  const discount = (transactionDetailsObj?.fuelDiscountAmount || 0) / 100;
  const transactionAmt = grossAmt - discount;

  const stripePurchasedItems = transactionDetailsObj?.purchaseDetails?.receipt;
  const highnotePurchasedItems =
    transactionDetailsObj?.highnotePurchaseDetails?.invoice?.lineItems;
  const fleetDetails =
    transactionDetailsObj?.card?.fleet && transactionDetailsObj?.card?.fleet[0];

  const getFuelBeforeTransactionVal = (
    fuelLevelBeforeTransaction: number,
    tankCapacity: number,
  ) => {
    return Number(
      ((fuelLevelBeforeTransaction / 100) * tankCapacity).toFixed(2),
    );
  };

  const getFuelAfterTransactionVal = (
    fuelLevelAfterTransaction: number,
    tankCapacity: number,
  ) => {
    return Number(
      ((fuelLevelAfterTransaction / 100) * tankCapacity).toFixed(2),
    );
  };

  const tankCapacity = fuelInfo?.fuelCapacity || 0;
  const fuelLevelBeforeTransaction = fuelInfo?.minFuelPercent || 1000;
  const fuelLevelAfterTransaction = fuelInfo?.maxFuelPercent || 0;

  const showFuelData = !(
    fuelLevelBeforeTransaction > 100 ||
    fuelLevelAfterTransaction <= 0 ||
    fuelLevelBeforeTransaction >= fuelLevelAfterTransaction
  );

  let fuelBeforeTransactionInGallons = "";
  let fuelAfterTransactionInGallons = "";
  let excessGallons = "";
  let excessTransactionAmount = "";

  if (tankCapacity && showFuelData) {
    const fuelBeforeTransactionVal = getFuelBeforeTransactionVal(
      fuelLevelBeforeTransaction,
      tankCapacity,
    );
    const fuelAfterTransactionVal = getFuelAfterTransactionVal(
      fuelLevelAfterTransaction,
      tankCapacity,
    );

    fuelBeforeTransactionInGallons = `(${fuelBeforeTransactionVal} gallons)`;
    fuelAfterTransactionInGallons = `(${fuelAfterTransactionVal} gallons)`;

    if (
      (purchaseDetails?.fuel?.volume_decimal ||
        purchaseDetails?.fuel?.quantity_decimal ||
        purchaseDetails?.visaFuelQuantity) &&
      (purchaseDetails?.fuel?.unit_cost_decimal ||
        purchaseDetails?.visaFuelUnitCost)
    ) {
      const fuelInGallons =
        Number(purchaseDetails?.fuel?.volume_decimal) ||
        Number(purchaseDetails?.fuel?.quantity_decimal) ||
        Number(purchaseDetails?.visaFuelQuantity) / 10000;
      const pricePerGallon = purchaseDetails?.fuel?.unit_cost_decimal
        ? Number(purchaseDetails.fuel.unit_cost_decimal) / 100
        : purchaseDetails?.visaFuelUnitCost
        ? purchaseDetails?.visaFuelUnitCost
        : 0;

      excessGallons = (
        fuelAfterTransactionVal -
        fuelBeforeTransactionVal -
        fuelInGallons
      ).toFixed(2);

      excessTransactionAmount = dollarFormatter(
        Number(excessGallons) * pricePerGallon,
      );
    }
  }
  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.GrayOutline}
                onClick={backToTransactionsPanel}
                className="pl-0 ml-[-6px] border-0"
              >
                <span className="flex items-center h-5">
                  <ChevronRightIcon
                    className={
                      "-rotate-180 h-5 w-5 transform  ease-in duration-300"
                    }
                    aria-hidden="true"
                  />
                </span>
                Back
              </Button>
              <div className="ml-4 text-lg font-semibold">
                Transaction Status{" "}
                {transactionDetailsObj.transactionStatus &&
                transactionDetailsObj.transactionStatus === "CAPTURE" ? (
                  <span className="px-2 py-1 ml-4 text-sm text-green-700 bg-green-100 rounded md:text-base">
                    Approved
                  </span>
                ) : (
                  <span className="px-2 py-1 ml-4 text-sm text-green-700 bg-green-100 rounded md:text-base">
                    Refunded
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-5 xl:flex-row gap-y-4 md:gap-y-8 md:mt-0">
          <div className="flex flex-col p-4 bg-white rounded-md shadow md:p-8 xl:mr-4 xl:w-1/2 gap-y-8 md:mt-0">
            <div className="flex flex-row justify-between w-full gap-3">
              <div className="text-sm md:text-base">Date</div>
              <div className="text-sm text-right md:text-base">
                <span>
                  {
                    convertISOStringToDate(
                      transactionDetailsObj?.date?.toString(),
                      false,
                      false,
                      true,
                    ).split(",")[0]
                  }
                </span>
                <span>
                  {
                    convertISOStringToDate(
                      transactionDetailsObj?.date?.toString(),
                      false,
                      false,
                      true,
                    ).split(",")[1]
                  }
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full gap-3">
              <div className="text-sm md:text-base">Merchant</div>
              <div className="text-sm text-right md:text-base">
                <span>
                  {transactionDetailsObj.merchantName
                    ? transactionDetailsObj.merchantName
                    : "-"}
                </span>
                {" ("}
                <span>
                  {transactionDetailsObj?.stripeResponse?.merchant_data?.city
                    ? `${transactionDetailsObj?.stripeResponse?.merchant_data?.city}, `
                    : ""}
                  {transactionDetailsObj.merchantState
                    ? transactionDetailsObj.merchantState
                    : "-"}{" "}
                  {transactionDetailsObj.merchantPostCd
                    ? transactionDetailsObj.merchantPostCd
                    : "-"}
                </span>
                {")"}
                <div className="flex justify-end">
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    disabled={
                      loading ||
                      isBlockMerchantsUpdating ||
                      blockMerchantsArray.length >= 20 ||
                      blockMerchantsArray.indexOf(
                        transactionDetailsObj.merchantName
                          .replace(/^\s+|\s+$/g, "")
                          .toUpperCase(),
                      ) >= 0
                    }
                    className=""
                    onClick={() => {
                      const array = blockMerchantsArray;
                      const newElement = transactionDetailsObj.merchantName
                        .replace(/^\s+|\s+$/g, "")
                        .toUpperCase();
                      if (array.indexOf(newElement) < 0) {
                        array.push(newElement);
                        setBlockMerchantsArray(array);
                        updateList();
                      }
                    }}
                  >
                    {blockMerchantsArray.indexOf(
                      transactionDetailsObj.merchantName
                        .replace(/^\s+|\s+$/g, "")
                        .toUpperCase(),
                    ) >= 0 && <span>Merchant Blocked</span>}
                    {blockMerchantsArray.indexOf(
                      transactionDetailsObj.merchantName
                        .replace(/^\s+|\s+$/g, "")
                        .toUpperCase(),
                    ) < 0 && <span>Block this merchant</span>}
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full gap-3">
              <div className="text-sm md:text-base">Amount</div>
              <div className="text-sm text-right md:text-base">
                {transactionDetailsObj.transactionAmount
                  ? dollarFormatter(
                      transactionDetailsObj.transactionAmount / 100,
                    )
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row justify-between w-full mt-4">
              <div className="flex flex-col justify-between w-full space-y-4">
                <div className="text-sm md:text-base">
                  Transaction Receipt (Max: 2)
                </div>
                <div>
                  <div className="text-xs">
                    Click the area below to upload files from your device
                  </div>
                  <label
                    htmlFor="receipts"
                    className="relative flex items-center justify-center h-32 text-black bg-white border-2 border-gray-200 border-dashed rounded-lg hover:cursor-pointer "
                  >
                    <input
                      className="hidden"
                      type="file"
                      id="receipts"
                      name="receipts"
                      onChange={(event) => {
                        handleReceiptFiles(event?.target?.files as FileList);
                        // setStatus({ error: null });
                      }}
                      // onBlur={handleBlur}
                      multiple
                      accept=".png, .jpeg, .jpg"
                    />
                    <div className="flex flex-row">
                      <UploadIcon
                        className="w-10 h-5 text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm tracking-wider group-hover:text-gray-600">
                        Upload Receipt
                      </span>
                    </div>
                  </label>
                </div>
                {uploads.length > 0 && (
                  <div className="">
                    <h3 className="font-medium text-gray-900 ">
                      Uploaded Files
                    </h3>
                    <ul className="mt-4 space-y-4">
                      {uploads.map((upload, index) => (
                        <li
                          key={index}
                          className="relative flex items-center gap-2 p-4 bg-white border rounded-lg shadow-sm md:gap-4"
                        >
                          <PhotographIcon className="w-8 h-8"></PhotographIcon>
                          <div className="flex flex-wrap items-center justify-between w-full gap-2 md:gap-4">
                            <div className="flex-1">
                              <div className="flex flex-row gap-3">
                                <p className="text-sm font-medium text-gray-900">
                                  {/* {upload.file?.name} */}
                                  receipt_image_{index + 1}
                                </p>
                                {upload.fromServer && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveReceiptFile(upload.fileId)
                                    }
                                  >
                                    <TrashIcon className="w-4 h-4"></TrashIcon>
                                  </button>
                                )}
                              </div>
                              <p className="text-sm text-gray-500">
                                upload Date:{" "}
                                {
                                  convertISOStringToDate(
                                    upload.createdAt.toString(),
                                    false,
                                    false,
                                    true,
                                  ).split(",")[0]
                                }
                              </p>
                            </div>
                            <div className="flex items-center space-x-2">
                              {upload.progress !== 100 && (
                                <div className="relative w-48">
                                  <div className="w-full h-2 bg-gray-200 rounded-full">
                                    <div
                                      className={`h-full rounded-full ${
                                        upload.hasError
                                          ? "bg-red-500"
                                          : "bg-brown-500"
                                      }`}
                                      style={{ width: `${upload.progress}%` }}
                                    ></div>
                                  </div>
                                  <div className="inset-y-0 right-0 flex items-center pr-2 text-sm text-gray-500 ">
                                    {upload.hasError
                                      ? "Error uploading"
                                      : `${upload.progress}%`}
                                  </div>
                                </div>
                              )}
                              {upload.isLoading ? (
                                <></>
                              ) : upload.hasError ? (
                                <svg
                                  className="w-5 h-5 text-red-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              ) : (
                                <a
                                  href={upload.url}
                                  rel="noreferrer"
                                  target="_blank"
                                  download={upload.file?.name}
                                  className=""
                                >
                                  <DownloadIcon className="w-4 h-4"></DownloadIcon>
                                </a>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4 bg-white rounded-md shadow md:p-8 xl:ml-4 xl:w-1/2 gap-y-2 md:mt-0">
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/4 text-sm md:w-1/2 md:text-base">Memo</div>
              <div className="w-3/4 ml-2 text-sm md:w-1/2 md:text-base">
                <TextInput
                  disabled={!editMemo}
                  type="text"
                  maxLength={250}
                  name="memo"
                  value={updateTransactionMemoValues?.memo || ""}
                  onChange={handleUpdateTransactionMemoChange}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/4 text-sm md:w-1/2 md:text-base">
                Transaction Tag
              </div>
              <div className="w-3/4 ml-2 text-sm md:w-1/2">
                <Select
                  id="transaction-tag"
                  value={updateTransactionMemoValues.transactionTagId}
                  options={transactionTags}
                  onChange={(e) => {
                    setFieldValue("transactionTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/4 text-sm md:w-1/2 md:text-base">
                Card Tag
              </div>
              <div className="w-3/4 ml-2 text-sm md:w-1/2">
                <Select
                  id="card-tag"
                  value={updateTransactionMemoValues.cardTagId}
                  options={cardTags}
                  onChange={(e) => {
                    setFieldValue("cardTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/4 text-sm md:w-1/2 md:text-base">
                Driver Tag
              </div>
              <div className="w-3/4 ml-2 text-sm md:w-1/2">
                <Select
                  id="driver-tag"
                  value={updateTransactionMemoValues.driverTagId}
                  options={driverTags}
                  onChange={(e) => {
                    setFieldValue("driverTagId", e.value);
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div className="w-1/4 text-sm md:w-1/2 md:text-base">
                Vehicle Tag
              </div>
              <div className="w-3/4 ml-2 text-sm md:w-1/2">
                <Select
                  id="vehicle-tag"
                  value={updateTransactionMemoValues.vehicleTagId}
                  options={vehicleTags}
                  onChange={(e) => {
                    setFieldValue("vehicleTagId", e.value);
                  }}
                  style={{
                    fontFamily: "inherit",
                    fontSize: "0.75rem",
                  }}
                  placeholder="No Tag Selected"
                  optionLabel="title"
                  optionValue="id"
                  itemTemplate={accountTagItemTemplate}
                  disabled={accountTagsDataLoading || !editMemo}
                ></Select>
              </div>
            </div>
            <div className="flex justify-end">
              {!editMemo && (
                <Button
                  onClick={toggle}
                  variant={ButtonVariant.AppOrange}
                  size={ButtonSize.AppSize}
                  disabled={updateTransactionMemoSubmitting || loading}
                >
                  Add/Update
                </Button>
              )}
              {editMemo && (
                <div className="flex gap-x-2">
                  <Button
                    onClick={discardChanges}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={updateTransactionMemoSubmitting || loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    loading={updateTransactionMemoSubmitting}
                    disabled={updateTransactionMemoSubmitting || loading}
                    onClick={handleUpdateTransactionMemoSubmit}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="my-4 text-sm bg-white rounded-md shadow md:mt-8 md:text-base">
          {showMap || mapValues.message !== "" ? (
            <SinglePointLocationMap {...{ mapValues }} />
          ) : (
            <div className="flex flex-row items-center justify-between">
              <div className="px-4 py-4 text-gray-500">
                Unable to load location using the merchant address
              </div>
            </div>
          )}
        </div>
        <div className="text-sm bg-white rounded-md shadow md:mt-8 md:text-base">
          <div className="flex flex-col gap-4 p-4 overflow-hidden md:p-8">
            <div className="border-gray-300 ">
              <div className="font-semibold md:mb-2 text-brown-500 md:text-lg">
                Transaction Details
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Posted Date</div>
              <div className="text-right">
                {transactionDetailsObj?.transactionDate
                  ? convertISOStringToDate(
                      transactionDetailsObj?.transactionDate.toString(),
                      false,
                      false,
                      true,
                    )
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Gross Amount</div>
              <div className="text-right">
                {grossAmt ? dollarFormatter(grossAmt) : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between border-gray-300">
              <div className="text-gray-500">Discount</div>
              <div className="text-right">{dollarFormatter(discount)}</div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-xs italic text-gray-500">
                Fuel discount will apply only if merchants send the data and if
                the fuel quantity is in gallons
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Net Amount</div>
              <div className="text-right">
                {transactionAmt ? dollarFormatter(transactionAmt) : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Fuel Type</div>
              <div className="capitalize">
                {humanize(purchaseDetails?.fuel?.type) ||
                  humanize(purchaseDetails?.visaFuelType || "") ||
                  "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Gallons</div>
              <div className="text-right">
                {purchaseDetails?.fuel?.volume_decimal
                  ? Number(purchaseDetails.fuel.volume_decimal).toFixed(2)
                  : purchaseDetails?.fuel?.quantity_decimal
                  ? Number(purchaseDetails.fuel.quantity_decimal).toFixed(2)
                  : purchaseDetails?.visaFuelQuantity
                  ? purchaseDetails?.visaFuelQuantity / 10000
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Price Per Gallon</div>
              <div className="text-right">
                {purchaseDetails?.fuel?.unit_cost_decimal
                  ? dollarFormatter(
                      Number(purchaseDetails.fuel.unit_cost_decimal) / 100,
                    )
                  : purchaseDetails?.visaFuelUnitCost
                  ? dollarFormatter(
                      Number(purchaseDetails.visaFuelUnitCost) / 100,
                    )
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2">
              <div className="text-gray-500">Purchase Type</div>
              <div className="text-right">
                {purchaseDetails?.fuel || purchaseDetails?.visaFuelType ? (
                  <span className="text-green-700">Fuel Detected</span>
                ) : (
                  <span className="text-red-600">Non Fuel Only</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 mt-4 text-sm bg-white rounded-md shadow md:p-8 md:mt-8 md:text-base">
          <div className="flex flex-col gap-4 overflow-hidden">
            <div className="font-semibold md:mb-2 text-brown-500 md:text-lg">
              Purchased Items
            </div>

            {stripePurchasedItems && stripePurchasedItems.length > 0 ? (
              stripePurchasedItems.map((item, index) => {
                return (
                  <div
                    className="flex flex-row items-center justify-between pb-2 border-b border-gray-300"
                    key={index}
                  >
                    <div className="text-gray-500">
                      {item?.description || "-"}
                    </div>
                    <div className="text-right">
                      {item?.total ? dollarFormatter(+item.total / 100) : "-"}
                    </div>
                  </div>
                );
              })
            ) : highnotePurchasedItems && highnotePurchasedItems.length > 0 ? (
              highnotePurchasedItems.map((item, index) => {
                return (
                  <div
                    className="flex flex-row items-center justify-between pb-2 border-b border-gray-300"
                    key={index}
                  >
                    <div className="text-gray-500">
                      {item?.descriptor || "-"}
                    </div>
                    <div className="text-right">
                      {item?.lineItemTotal?.value
                        ? dollarFormatter(+item?.lineItemTotal?.value / 100)
                        : "-"}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex flex-row items-center justify-between pb-2 border-gray-300">
                <div className="text-gray-500">No items found</div>
              </div>
            )}
          </div>
        </div>

        <div className="p-4 mt-4 text-sm bg-white rounded-md shadow md:p-8 md:mt-8 md:text-base">
          <div className="flex flex-col gap-4 overflow-hidden">
            <div className="mb-2 font-semibold text-brown-500 md:text-lg">
              Driver
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-gray-300">
              <div className="text-gray-500">Assigned</div>
              <div className="text-right">
                {transactionDetailsObj.driverName
                  ? transactionDetailsObj.driverName
                  : "Card not assigned to a driver"}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 mt-4 text-sm bg-white rounded-md shadow md:p-8 md:mt-8 md:text-base">
          <div className="flex flex-col gap-4 overflow-hidden">
            <div className="mb-2 font-semibold text-brown-500 md:text-lg">
              Vehicle
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Assigned</div>
              <div className="text-right">
                {fleetDetails?.fleetName ? fleetDetails.fleetName : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Vehicle Make</div>
              <div className="text-right">
                {fleetDetails?.make ? fleetDetails.make : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Vehicle Model</div>
              <div className="text-right">
                {fleetDetails?.model ? fleetDetails.model : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-gray-300">
              <div className="text-gray-500">Vehicle Year</div>
              <div className="text-right">
                {fleetDetails?.year ? fleetDetails.year : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 mt-4 text-sm bg-white rounded-md shadow md:p-8 md:mt-8 md:text-base">
          <div className="flex flex-col gap-4 overflow-hidden">
            <div className="mb-2 font-semibold text-brown-500 md:text-lg">
              Vehicle Location
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Telematics Data</div>
              <div className="text-right">
                {transactionDetailsObj?.authorization?.extraInfo
                  ?.liveLocation &&
                transactionDetailsObj?.authorization?.extraInfo
                  ?.liveLocation[0] ? (
                  <span className="text-green-700">Available</span>
                ) : (
                  <span className="text-red-600">Not Available</span>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="mr-4 text-gray-500">Location</div>
              <div className="text-right">
                {vehicleAddress ? vehicleAddress : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Timestamp</div>
              <div className="text-right">
                {convertISOStringToDate(
                  transactionDetailsObj?.authorization?.extraInfo
                    ?.liveLocation?.[0]?.dateTime || "",
                )}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-gray-300">
              <div className="text-gray-500">Distance From Merchant</div>
              <div className="text-right">
                {distanceFromMerchant ? `${distanceFromMerchant} miles` : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 mt-4 text-sm bg-white rounded-md shadow md:p-8 md:mt-8 md:text-base">
          <div className="flex flex-col gap-4 overflow-hidden">
            <div className="mb-2 font-semibold text-brown-500 md:text-lg">
              Fuel Level
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Tank Capacity</div>
              <div className="text-right">
                {tankCapacity ? `${tankCapacity} gallons` : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Before Transaction</div>
              <div className="text-right">
                {showFuelData
                  ? `${fuelLevelBeforeTransaction}% ${fuelBeforeTransactionInGallons}`
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">After Transaction</div>
              <div className="text-right">
                {showFuelData
                  ? `${fuelLevelAfterTransaction}%  ${fuelAfterTransactionInGallons}`
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-b border-gray-300">
              <div className="text-gray-500">Excess Gallons</div>
              <div className="text-right">
                {excessGallons ? excessGallons : "-"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between pb-2 border-gray-300">
              <div className="text-gray-500">Excess Transaction Amount</div>
              <div className="text-right">
                {excessTransactionAmount ? excessTransactionAmount : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
