/** @format */
import { ENUM_CLASSIFICATION } from "@prisma/client";
import { CATEGORY_LABELS, dollarFormatter } from "@roadflex/constants";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { Card, TechnicalAnalysisDataDriverVehicleType } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import { CustomMultiSelect, Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";

type TechnicalAnalysisUIProps = {
  loading: boolean;
  queryTable: string;
  setQueryTable: (queryTable: string) => void;
  cards: Card[];
  cardsFilters: {
    operationalHours: string[];
    cardAssignments: string[];
    transactionControls: string[];
    spendingLimits: string[];
    merchantCategories: string[];
  };
  setCardsFilters: (cardsFilters: {
    operationalHours: string[];
    cardAssignments: string[];
    transactionControls: string[];
    spendingLimits: string[];
    merchantCategories: string[];
  }) => void;
  drivers: TechnicalAnalysisDataDriverVehicleType[];
  vehicles: TechnicalAnalysisDataDriverVehicleType[];
  resetFilters: () => void;
};

const TechnicalAnalysisUI = ({
  loading,
  queryTable,
  setQueryTable,
  cards,
  cardsFilters,
  setCardsFilters,
  drivers,
  vehicles,
  resetFilters,
}: TechnicalAnalysisUIProps) => {
  const { isMobile } = usePlatformDetect();

  const statusElement = (status: boolean) => {
    return (
      <span
        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm 5xl:text-base font-medium capitalize
      ${
        status ? "bg-green-100 text-green-800" : "bg-orange-100 text-orange-800"
      }
      `}
      >
        {status ? "Active" : "Inactive"}
      </span>
    );
  };
  return (
    <div className="flex-col flex flex-1 my-4 mt-0 overflow-x-hidden md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="col-span-2 mb-4">
        <div className="flex flex-col items-center justify-start gap-10 md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row items-center">
              <label className="pr-2 font-semibold" htmlFor="queryTable">
                Query Type
              </label>
              <Select
                id="queryTable"
                value={queryTable}
                options={[
                  { value: "CARDS", label: "Cards" },
                  { value: "DRIVERS", label: "Drivers", disabled: true },
                  { value: "VEHICLES", label: "Vehicles", disabled: true },
                ]}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  setQueryTable(e.value);
                }}
                placeholder="Select Query Type"
                className="w-52"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.value}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
              {loading && <InlineLoader />}
            </div>
          </div>
        </div>
      </div>
      {queryTable === "CARDS" ? (
        <div className="mt-2 overflow-hidden bg-white rounded-md shadow">
          <div className="px-8 pb-8 font-medium bg-white step-5">
            <div className="flex flex-wrap items-end w-full gap-4 my-4">
              <div className="mb-2">
                <div className="mb-2 text-base font-semibold">
                  Columns To Show
                </div>
              </div>
              <div className="mb-2 min-w-[250px]">
                <div className="mb-1 text-sm">Operational Hours</div>
                <CustomMultiSelect
                  value={cardsFilters.operationalHours}
                  options={[
                    {
                      label: "Status & Hours",
                      value: "operationalHours",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      operationalHours: e.value,
                    })
                  }
                />
              </div>
              <div className="mb-2 min-w-[250px]">
                <div className="mb-1 text-sm">Transaction Controls</div>
                <CustomMultiSelect
                  value={cardsFilters.transactionControls}
                  options={[
                    {
                      label: "Card unlock through SMS or driver app",
                      value: "smsSecurity",
                    },
                    {
                      label:
                        "Require an odometer reading before every fuel transaction",
                      value: "odometerSecurity",
                    },
                    {
                      label: "Require a transaction tag ID",
                      value: "requireJobID",
                    },
                    {
                      label: "Enable Receipt Upload via SMS",
                      value: "enableSmsReceiptUpload",
                    },
                    {
                      label: "Flag for wrong fuel type",
                      value: "fuelTypeSecurity",
                    },
                    {
                      label: "Flag when fuel purchase exceeds tank capacity",
                      value: "tankCapacitySecurity",
                    },
                    {
                      label:
                        "Flag when fuel level anomaly is detected/fuel theft detected",
                      value: "suspiciousFuelingSecurity",
                    },
                    {
                      label:
                        "Flag transactions from specific merchant categories",
                      value: "flaggedMerchantCategories",
                    },
                    {
                      label:
                        "Alert/Decline transaction when vehicle is far away from the merchant",
                      value: "telematicsSecurity",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      transactionControls: e.value,
                    })
                  }
                />
              </div>
              <div className="mb-2 min-w-[250px]">
                <div className="mb-1 text-sm">Spend Limits</div>
                <CustomMultiSelect
                  value={cardsFilters.spendingLimits}
                  options={[
                    {
                      label: "Per Transaction Limit",
                      value: "perTransactionSpendingLimit",
                    },
                    { label: "Daily Limit", value: "dailySpendingLimit" },
                    { label: "Weekly Limit", value: "weeklySpendingLimit" },
                    { label: "Monthly Limit", value: "monthlySpendingLimit" },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      spendingLimits: e.value,
                    })
                  }
                />
              </div>
              <div className="mb-2 min-w-[250px]">
                <div className="mb-1 text-sm">Category Restrictions</div>
                <CustomMultiSelect
                  value={cardsFilters.merchantCategories}
                  options={[
                    {
                      label: "Fuel Expenses",
                      value: "fuelExpenses",
                    },
                    { label: "Vehicle Expenses", value: "vehicleExpenses" },
                    { label: "Business Expenses", value: "businessExpenses" },
                    {
                      label: "Additional Merchants",
                      value: "additionalMerchants",
                    },
                  ]}
                  placeholder="Select Options"
                  maxSelectedLabels={2}
                  className="w-full !text-sm h-10 flex items-center"
                  onChange={(e) =>
                    setCardsFilters({
                      ...cardsFilters,
                      merchantCategories: e.value,
                    })
                  }
                />
              </div>
              <div className="mb-2">
                <Button
                  size={ButtonSize.Normal}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className="mx-auto">
              <div className="min-w-full overflow-hidden overflow-x-auto align-middle ">
                <DataTable
                  pageLinkSize={3}
                  size="small"
                  stripedRows
                  value={cards}
                  loading={loading}
                  paginator
                  dataKey="id"
                  style={{
                    fontFamily: "inherit",
                  }}
                  paginatorTemplate={newPaginationTemplate}
                  responsiveLayout={isMobile() ? "stack" : "scroll"}
                  emptyMessage="No available records"
                  className="!bg-white custom-table !text-brown-500 !border-brown-500"
                  sortMode={"single"}
                  rows={10}
                  sortField="lastFour"
                  sortOrder={1}
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                >
                  <Column
                    field="lastFour"
                    header="Last Four & Status"
                    className="text-sm 5xl:text-base"
                    bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    body={(r: Card) => {
                      return (
                        <div className="">
                          <div className="m-1 capitalize whitespace-nowrap text-ellipsis">
                            <span className="font-semibold">{r.lastFour} </span>
                            ({r.cardType.toLowerCase()})
                          </div>
                          <div className="my-1">
                            {statusElement(r?.status === "active")}
                          </div>
                        </div>
                      );
                    }}
                  />
                  {cardsFilters?.operationalHours?.includes(
                    "operationalHours",
                  ) && (
                    <Column
                      header="Operational Hours"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <>
                            <div className="my-1">
                              {statusElement(
                                r?.enableOperationalHours || false,
                              )}
                            </div>
                            {r?.operationalHours?.map((op) => {
                              return (
                                <div className="m-1 whitespace-nowrap text-ellipsis">
                                  <span className="font-semibold capitalize">
                                    {op.dayOfWeek.toLowerCase()}{" "}
                                  </span>
                                  ({op.startTime} - {op.endTime})
                                </div>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "smsSecurity",
                  ) && (
                    <Column
                      header="Card unlock through SMS or driver app"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(r?.smsSecurity || false)}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "odometerSecurity",
                  ) && (
                    <Column
                      header="Require an odometer reading before every fuel transaction"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(r?.odometerSecurity || false)}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "requireJobID",
                  ) && (
                    <Column
                      header="Require a transaction tag ID"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(r?.requireJobID || false)}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "enableSmsReceiptUpload",
                  ) && (
                    <Column
                      header="Enable Receipt Upload via SMS"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(r?.enableSmsReceiptUpload || false)}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "fuelTypeSecurity",
                  ) && (
                    <Column
                      header="Flag for wrong fuel type"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(r?.fuelTypeSecurity || false)}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "tankCapacitySecurity",
                  ) && (
                    <Column
                      header="Flag when fuel purchase exceeds tank capacity"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(r?.tankCapacitySecurity || false)}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "suspiciousFuelingSecurity",
                  ) && (
                    <Column
                      header="Flag when fuel level anomaly is detected/fuel theft detected"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1">
                            {statusElement(
                              r?.suspiciousFuelingSecurity || false,
                            )}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "flaggedMerchantCategories",
                  ) && (
                    <Column
                      header="Flag transactions from specific merchant categories"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return r.flaggedMerchantCategories?.map((merchant) => {
                          return (
                            <div className="m-1 whitespace-nowrap text-ellipsis">
                              {(CATEGORY_LABELS[merchant] &&
                                CATEGORY_LABELS[merchant]?.label) ||
                                humanize(merchant)}
                            </div>
                          );
                        });
                      }}
                    />
                  )}
                  {cardsFilters?.transactionControls?.includes(
                    "telematicsSecurity",
                  ) && (
                    <Column
                      header="Alert/Decline transaction when vehicle is far away from the merchant"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return (
                          <div className="m-1 capitalize">
                            {r?.telematicsSecurity?.toLowerCase()}
                          </div>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.spendingLimits?.includes(
                    "perTransactionSpendingLimit",
                  ) && (
                    <Column
                      header="Per Transaction Limit"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        const result = r.cardSpending.find(
                          (data) => data.interval === "per_authorization",
                        );
                        return (
                          <>
                            {result?.amount
                              ? dollarFormatter(result.amount / 100)
                              : "-"}
                          </>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.spendingLimits?.includes(
                    "dailySpendingLimit",
                  ) && (
                    <Column
                      header="Daily Transaction Limit"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        const result = r.cardSpending.find(
                          (data) => data.interval === "daily",
                        );
                        return (
                          <>
                            {result?.amount
                              ? dollarFormatter(result.amount / 100)
                              : "-"}
                          </>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.spendingLimits?.includes(
                    "weeklySpendingLimit",
                  ) && (
                    <Column
                      header="Weekly Transaction Limit"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        const result = r.cardSpending.find(
                          (data) => data.interval === "weekly",
                        );
                        return (
                          <>
                            {result?.amount
                              ? dollarFormatter(result.amount / 100)
                              : "-"}
                          </>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.spendingLimits?.includes(
                    "monthlySpendingLimit",
                  ) && (
                    <Column
                      header="Monthly Transaction Limit"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        const result = r.cardSpending.find(
                          (data) => data.interval === "monthly",
                        );
                        return (
                          <>
                            {result?.amount
                              ? dollarFormatter(result.amount / 100)
                              : "-"}
                          </>
                        );
                      }}
                    />
                  )}
                  {cardsFilters?.merchantCategories?.includes(
                    "fuelExpenses",
                  ) && (
                    <Column
                      header="Fuel Expenses"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return r.merchants
                          ?.filter(
                            (merchant) =>
                              merchant.toggleOn &&
                              merchant.classification ===
                                ENUM_CLASSIFICATION.FUEL,
                          )
                          .map((merchant) => {
                            return (
                              <div className="m-1 whitespace-nowrap text-ellipsis">
                                {(CATEGORY_LABELS[merchant.name] &&
                                  CATEGORY_LABELS[merchant.name]?.label) ||
                                  humanize(merchant.name)}
                              </div>
                            );
                          });
                      }}
                    />
                  )}
                  {cardsFilters?.merchantCategories?.includes(
                    "vehicleExpenses",
                  ) && (
                    <Column
                      header="Vehicle Expenses"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return r.merchants
                          ?.filter(
                            (merchant) =>
                              merchant.toggleOn &&
                              merchant.classification ===
                                ENUM_CLASSIFICATION.VEHICLE,
                          )
                          .map((merchant) => {
                            return (
                              <div className="m-1 whitespace-nowrap text-ellipsis">
                                {(CATEGORY_LABELS[merchant.name] &&
                                  CATEGORY_LABELS[merchant.name]?.label) ||
                                  humanize(merchant.name)}
                              </div>
                            );
                          });
                      }}
                    />
                  )}
                  {cardsFilters?.merchantCategories?.includes(
                    "businessExpenses",
                  ) && (
                    <Column
                      header="Business Expenses"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return r.merchants
                          ?.filter(
                            (merchant) =>
                              merchant.toggleOn &&
                              merchant.classification ===
                                ENUM_CLASSIFICATION.BUSINESS,
                          )
                          .map((merchant) => {
                            return (
                              <div className="m-1 whitespace-nowrap text-ellipsis">
                                {(CATEGORY_LABELS[merchant.name] &&
                                  CATEGORY_LABELS[merchant.name]?.label) ||
                                  humanize(merchant.name)}
                              </div>
                            );
                          });
                      }}
                    />
                  )}
                  {cardsFilters?.merchantCategories?.includes(
                    "additionalMerchants",
                  ) && (
                    <Column
                      header="Additional Merchants"
                      className="text-sm 5xl:text-base"
                      bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                      headerClassName="!bg-white !text-brown-500 !border-brown-500"
                      body={(r: Card) => {
                        return r.merchants
                          ?.filter(
                            (merchant) =>
                              merchant.toggleOn &&
                              merchant.classification ===
                                ENUM_CLASSIFICATION.OTHER,
                          )
                          .map((merchant) => {
                            return (
                              <div className="m-1 whitespace-nowrap text-ellipsis">
                                {(CATEGORY_LABELS[merchant.name] &&
                                  CATEGORY_LABELS[merchant.name]?.label) ||
                                  humanize(merchant.name)}
                              </div>
                            );
                          });
                      }}
                    />
                  )}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TechnicalAnalysisUI;
