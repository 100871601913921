/** @format */

import { DotsVerticalIcon } from "@heroicons/react/outline";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { CardRule, CardShortened } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import { useRouter } from "next/router";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import React, { createRef, useRef } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { ApplyCardRuleModal } from "../../modals";

interface cardsUIProps {
  readCardPolicyLoading: boolean;
  deliveredCards: CardShortened[];
  handleSubmitDelete: (
    e?: React.FormEvent<HTMLFormElement> | undefined,
  ) => void;
  isSubmittingDelete: boolean;
  setFieldValueDelete: (field: string, value: string[]) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isSubmitting: boolean;
  setFieldValue: (field: string, value: string[] | string) => void;
  cardRules: CardRule[];
  openApplyCardRule: boolean;
  setOpenApplyCardRule: (value: boolean) => void;
}

export const PoliciesUI = ({
  readCardPolicyLoading,
  deliveredCards,
  handleSubmitDelete,
  isSubmittingDelete,
  setFieldValueDelete,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  cardRules,
  openApplyCardRule,
  setOpenApplyCardRule,
}: cardsUIProps) => {
  const platform = usePlatformDetect();
  const router = useRouter();
  const spendPolicyRefs = useRef<(React.RefObject<Menu> | null)[]>([]);
  spendPolicyRefs.current = cardRules.map(
    (_, i) => spendPolicyRefs.current[i] ?? createRef(),
  );
  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Spend Policies`.toUpperCase()}
      </div>

      <div className="p-8 mt-5 overflow-hidden text-sm font-medium bg-white rounded-md shadow md:mt-0 md:text-base">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="font-semibold text-brown-500 md:text-lg">
            Policies
          </div>
          <Button
            size={ButtonSize.AppSize}
            variant={ButtonVariant.AppOrange}
            onClick={() => {
              // openCreateEditRuleModal(null);
              // setCreateEditRule(true);
              router.push("/policies/new");
            }}
          >
            Create Policy
          </Button>
        </div>

        <div className="flex items-center py-4">
          <div className="w-full overflow-hidden">
            <DataTable
              size="small"
              loading={
                readCardPolicyLoading || isSubmittingDelete || isSubmitting
              }
              stripedRows
              value={cardRules}
              paginator
              rows={10}
              pageLinkSize={3}
              dataKey="id"
              style={{
                fontFamily: "inherit",
              }}
              sortField="name"
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={platform.isMobile() ? "stack" : "scroll"}
              emptyMessage="No available records"
              className="custom-table"
            >
              <Column
                sortable
                field="name"
                header="Spend Policy Name"
                className="text-sm md:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              <Column
                sortable
                field="transaction"
                header="Per Transaction Limit"
                className="text-sm md:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) => {
                  return r.transaction ? dollarFormatter(r.transaction) : "-";
                }}
              />
              <Column
                sortable
                field="daily"
                header="Daily Spend Limit"
                className="text-sm md:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) => {
                  return r.daily ? dollarFormatter(r.daily) : "-";
                }}
              />
              <Column
                sortable
                field="weekly"
                header="Weekly Spend Limit"
                className="text-sm md:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) => {
                  return r.weekly ? dollarFormatter(r.weekly) : "-";
                }}
              />
              <Column
                sortable
                field="monthly"
                header="Monthly Spend Limit"
                className="text-sm md:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) => {
                  return r.monthly ? dollarFormatter(r.monthly) : "-";
                }}
              />
              <Column
                headerStyle={{ width: "3em" }}
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(e, props) => (
                  <div className="relative flex">
                    <Menu
                      model={[
                        {
                          label: "Apply",
                          icon: "pi pi-fw pi-check",
                          command: () => {
                            setOpenApplyCardRule(true);
                            setFieldValue("cardRuleId", e.id);
                          },
                        },
                        {
                          label: "Edit",
                          icon: "pi pi-fw pi-pencil",
                          command: () => {
                            router.push(`/policies/id/${e.id}`);
                          },
                        },
                        {
                          label: "Delete",
                          icon: "pi pi-fw pi-trash",
                          command: () => {
                            setFieldValueDelete("cardRuleIds", [e.id]);
                            handleSubmitDelete();
                          },
                        },
                      ]}
                      popup
                      ref={spendPolicyRefs.current[props.rowIndex]}
                    />
                    <Button
                      variant={ButtonVariant.Transparent}
                      className="!p-0"
                      onClick={(event) =>
                        spendPolicyRefs.current[
                          props.rowIndex
                        ]?.current?.toggle(event)
                      }
                    >
                      <DotsVerticalIcon className="w-5"></DotsVerticalIcon>
                    </Button>
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      {openApplyCardRule && (
        <ApplyCardRuleModal
          mode="ApplyCardRule"
          open={openApplyCardRule}
          setOpen={setOpenApplyCardRule}
          deliveredCards={deliveredCards}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
        ></ApplyCardRuleModal>
      )}
    </div>
  );
};
