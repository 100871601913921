/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

export interface BlackListMerchantProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  // eslint-disable-next-line
  updateList: () => void;
  isBlockMerchantsUpdating: boolean;
  blockMerchantsArray: string[];
  setBlockMerchantsArray: (val: string[]) => void;
  isAdmin?: boolean;
}

export const BlackListMerchant = ({
  open,
  setOpen,
  isAdmin = false,
  updateList,
  isBlockMerchantsUpdating,
  blockMerchantsArray,
  setBlockMerchantsArray,
}: BlackListMerchantProps) => {
  const [addedBlockMerchant, setAddedBlockMerchant] = useState("");
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-hidden"
        style={{ fontFamily: "inherit" }}
        onClose={() => {
          //prevent closing when clicking outside the modal
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col h-full p-4 overflow-y-auto bg-white">
                  <h2 className="text-lg font-bold">Blocked Merchants</h2>
                  <div className="bg-yellow-400 rounded-md">
                    <p className="p-4 text-xs font-medium md:text-sm">
                      Block specific merchants across all of your cards by
                      adding it to the block list. A maximum of 20 merchants can
                      be on your block list.
                    </p>
                  </div>
                  <div className="flex flex-col justify-between flex-grow h-full">
                    <div className="space-y-4">
                      <div className="text-sm md:text-base">
                        {blockMerchantsArray.map((e) => {
                          return (
                            <span
                              key={e}
                              className="flex flex-wrap items-center justify-between px-2 py-1 m-1 text-xs leading-loose bg-gray-200 rounded sm:text-sm hover:bg-gray-300"
                            >
                              {e}
                              <Button
                                variant={ButtonVariant.Transparent}
                                size={ButtonSize.Small}
                                disabled={isBlockMerchantsUpdating}
                                onClick={(event) => {
                                  if (e && e.trim() !== "") {
                                    const array = blockMerchantsArray;
                                    if (array.indexOf(e.trim()) >= 0) {
                                      array.splice(array.indexOf(e.trim()), 1);
                                    }
                                    setBlockMerchantsArray(array);
                                    updateList();
                                  }
                                }}
                              >
                                <XIcon className="w-4 h-4"></XIcon>
                              </Button>
                            </span>
                          );
                        })}
                        {blockMerchantsArray.length === 0 && (
                          <div className="pt-4 text-sm text-left md:text-base">
                            {" "}
                            No merchants on blocked list.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full pt-8 border-t-[1px] border-gray-300 mb-6">
                    <div>
                      <TextInput
                        type="text"
                        value={addedBlockMerchant}
                        placeholder="Merchant Name"
                        name="blockedMerchants"
                        id="blockedMerchants"
                        onChange={(e) => setAddedBlockMerchant(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-row-reverse mt-2">
                      <Button
                        variant={ButtonVariant.Primary}
                        size={ButtonSize.Normal}
                        disabled={
                          isBlockMerchantsUpdating ||
                          blockMerchantsArray.length >= 20
                        }
                        className=""
                        onClick={() => {
                          const array = blockMerchantsArray;
                          const newElement = addedBlockMerchant
                            .replace(/^\s+|\s+$/g, "")
                            .toUpperCase();
                          if (array.indexOf(newElement) < 0) {
                            array.push(newElement);
                            setBlockMerchantsArray(array);
                            updateList();
                          }
                        }}
                      >
                        Block this merchant
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
