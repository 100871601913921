import { useLazyQuery } from "@apollo/client";
import type { Libraries } from "@googlemaps/js-api-loader";
import { useLoadScript } from "@react-google-maps/api";
import { READ_FUEL_STATIONS } from "@roadflex/graphql";
import { GoogleMapsMarkersType, UserAuthType } from "@roadflex/types";
import { showErrorMessage } from "@roadflex/web-lib";
import getConfig from "next/config";
import { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import FuelStationFinderAutoCompleteUI from "./fuel-station-finder-autocomplete.ui";
import FuelStationFinderMapUI from "./fuel-station-finder-map.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

type FuelStationFinderProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};
export const FuelStationFinder = ({
  readCurrentUserAuth,
  loading: readCurrentUserAuthLoading,
}: FuelStationFinderProps) => {
  const [showInfo, setShowInfo] = useState<number | null>(null);
  const [markers, setMarkers] = useState<GoogleMapsMarkersType[]>([]);
  const [parameters, setParameters] = useState<{
    latitude: number;
    longitude: number;
    address: string;
  }>({ latitude: 37.7749, longitude: -122.4194, address: "" });
  const [loading, setLoading] = useState<boolean>(false);

  const libraries = useRef<Libraries>(["places"]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY || "",
    libraries: libraries.current,
  });

  const [readFuelStationsFn, { loading: readFuelStationsLoading }] =
    useLazyQuery(READ_FUEL_STATIONS, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.readFuelStations?.code === "200") {
          setMarkers(data?.readFuelStations?.markers);
        } else {
          showErrorMessage(
            data.readFuelStations.message,
            data.readFuelStations.code,
          );
        }
        setLoading(false);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        setLoading(false);
      },
      notifyOnNetworkStatusChange: true,
    });

  const handleSearch = () => {
    setLoading(true);
    readFuelStationsFn({
      variables: {
        data: {
          latitude: parameters.latitude,
          longitude: parameters.longitude,
          latitudeDelta: 0.04864195044303443,
          longitudeDelta: 0.040142817690068,
        },
      },
    });
  };

  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
      scrollwheel: false,
    }),
    [],
  );

  const handleMouseOver = (info: number) => {
    setShowInfo(info);
  };
  const handleMouseExit = () => {
    setShowInfo(null);
  };

  if (!isLoaded) {
    return <></>;
  }

  return (
    <div className="m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Fuel Station Finder`.toUpperCase()}
      </div>
      <FuelStationFinderAutoCompleteUI
        handleSearch={handleSearch}
        loading={loading}
        parameters={parameters}
        setParameters={setParameters}
      />
      <FuelStationFinderMapUI
        mapOptions={mapOptions}
        markers={markers}
        handleMouseOver={handleMouseOver}
        handleMouseExit={handleMouseExit}
        showInfo={showInfo}
        parameters={parameters}
      />
    </div>
  );
};
