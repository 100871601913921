/** @format */
import { ENUM_ADDRESS_TYPE, ENUM_DELIVERY_TYPE } from "@prisma/client";
import {
  useAdditionaBusinessInfoUpdate,
  useReadAdditionalBusinessInformation,
} from "@roadflex/react-hooks";
import { AdditionalBusinessInfoType } from "@roadflex/types";
import { fuelFormatter } from "@roadflex/web-lib";
import getConfig from "next/config";
import { useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { toast } from "react-toastify";
import AdditionalBusinessInfoUI from "./additional-business-information.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;
interface AdditionalBusinessInfoProps {
  nextStep: () => void;
  additionalBusinessInfo: AdditionalBusinessInfoType;
  isSubmittingOnboardStatus: boolean;
}

const AdditionalBusinessInfo = ({
  nextStep,
  additionalBusinessInfo,
  isSubmittingOnboardStatus,
}: AdditionalBusinessInfoProps) => {
  const isSameAddress = additionalBusinessInfo?.isSameAddress;
  const businessAddress = additionalBusinessInfo?.addressList.find((value) =>
    value.type.includes("PERMANENT"),
  );
  const deliveryAddress = additionalBusinessInfo?.addressList.find((value) =>
    value.type.includes("DELIVERY"),
  );
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    ...rest
  } = useAdditionaBusinessInfoUpdate({
    initialValues: {
      businessId: additionalBusinessInfo?.id,
      businessAddress: {
        id: businessAddress?.id || "",
        streetAddress: businessAddress?.streetAddress || "",
        streetDetails: businessAddress?.streetDetails || "",
        state: businessAddress?.state || "",
        city: businessAddress?.city || "",
        zip: businessAddress?.zip || "",
        type: ENUM_ADDRESS_TYPE.PERMANENT,
      },
      deliveryAddress: {
        id: (!isSameAddress && deliveryAddress?.id) || "",
        streetAddress: (!isSameAddress && deliveryAddress?.streetAddress) || "",
        streetDetails: (!isSameAddress && deliveryAddress?.streetDetails) || "",
        state: (!isSameAddress && deliveryAddress?.state) || "",
        city: (!isSameAddress && deliveryAddress?.city) || "",
        zip: (!isSameAddress && deliveryAddress?.zip) || "",
        type: ENUM_ADDRESS_TYPE.DELIVERY,
      },
      // deliveryType: business?.deliveryType || ENUM_DELIVERY_TYPE.DAYS_2, // Hardcoding deliveryType to DAYS_5_7
      deliveryType: ENUM_DELIVERY_TYPE.DAYS_5_7,
      differentDeliveryAddress: !isSameAddress,
      fuel: additionalBusinessInfo?.weeklyfuelSpends
        ? fuelFormatter(
            (Number(additionalBusinessInfo?.weeklyfuelSpends) / 100).toString(),
          )
        : "",
      annualBusinessRevenue: additionalBusinessInfo?.annualBusinessRevenue
        ? fuelFormatter(
            (
              Number(additionalBusinessInfo?.annualBusinessRevenue) / 100
            ).toString(),
          )
        : "",
      fleetType: additionalBusinessInfo?.fleetType || "",
      fleetNum: additionalBusinessInfo?.fleetNum || "",
    },
    onSubmit(res, err) {
      if (!err) {
        nextStep();
      } else {
        toast.error("Something Went Wrong.");
      }
    },
  });

  const { ref: businessAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      if (place.address_components) {
        let streetAddress = "";
        let zip = "";
        let state = "";
        let city = "";
        for (const component of place.address_components) {
          const componentType = component.types[0];
          if (componentType === "street_number") {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType === "route") {
            streetAddress += component.long_name;
          }
          if (componentType === "postal_code") {
            zip = component.long_name;
          }
          if (componentType === "administrative_area_level_1") {
            state = component.short_name;
          }
          if (componentType === "locality") {
            city = component.long_name;
          }
        }
        setFieldValue("businessAddress.zip", zip);
        setFieldValue("businessAddress.state", state);
        setFieldValue("businessAddress.city", city);
        setFieldValue("businessAddress.streetAddress", streetAddress);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  const { ref: shippingAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      if (place.address_components) {
        let streetAddress = "";
        let zip = "";
        let state = "";
        let city = "";
        for (const component of place.address_components) {
          const componentType = component.types[0];
          if (componentType === "street_number") {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType === "route") {
            streetAddress += component.long_name;
          }
          if (componentType === "postal_code") {
            zip = component.long_name;
          }
          if (componentType === "administrative_area_level_1") {
            state = component.short_name;
          }
          if (componentType === "locality") {
            city = component.long_name;
          }
        }
        setFieldValue("deliveryAddress.zip", zip);
        setFieldValue("deliveryAddress.state", state);
        setFieldValue("deliveryAddress.city", city);
        setFieldValue("deliveryAddress.streetAddress", streetAddress);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });
  const autoCompleteRef = useRef({
    businessAutoCompleteRef,
    shippingAutoCompleteRef,
  });

  return (
    <AdditionalBusinessInfoUI
      {...{
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        isSubmittingOnboardStatus,
        touched,
        errors,
        setFieldValue,
        ...rest,
        additionalBusinessInfo,
      }}
      ref={autoCompleteRef}
    />
  );
};

function Business(props: {
  nextStep: () => void;
  isSubmittingOnboardStatus: boolean;
}) {
  const { data, loading } = useReadAdditionalBusinessInformation();

  if (loading) {
    return <div className="mt-6 text-center text-white">Loading...</div>;
  }

  return (
    <AdditionalBusinessInfo
      {...{
        ...props,
        additionalBusinessInfo: data.readAdditionalBusinessInformation,
      }}
    />
  );
}

export default Business;
