/** @format */

import {
  DotsVerticalIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import { ENUM_ACCOUNT_TAG_TYPE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  AccountTagType,
  ChildFeatureType,
  FleetDriverExtended,
  IdListInputType,
  TabsType,
} from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import { getCookie, setCookie } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikErrors } from "formik";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable, DataTableRowToggleParams } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { createRef, FormEvent, useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { nameFilterTemplate } from "../../datatable";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { AssignAccountTagModal } from "../../modals";
import { FuelCardComponentPermissionGuard } from "../../permission-guard";
import UploadDriversFile from "./upload-drivers-file-modal";

interface DriversUIProps {
  driversList: FleetDriverExtended[];
  archivedDriversList: FleetDriverExtended[];
  getDriversLoading: boolean;
  getDriversRefetch: () => void;
  deleteDriverHandleSubmit: (
    e?: FormEvent<HTMLFormElement> | undefined,
  ) => void;
  deleteDriverSetValues: (
    fields: React.SetStateAction<IdListInputType>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<unknown>>;
  joyride: boolean;
  setJoyride: (value: boolean) => void;
  steps: Step[];
  tabs: TabsType[];
  tabOpen: number;
  setTabOpen: (value: number) => void;
  maxDrivers: number;
  childFeatures: ChildFeatureType[];
}
export const DriversUI = ({
  driversList,
  archivedDriversList,
  getDriversLoading,
  getDriversRefetch,
  deleteDriverHandleSubmit,
  deleteDriverSetValues,
  steps,
  joyride,
  setJoyride,
  tabs,
  tabOpen,
  setTabOpen,
  maxDrivers,
  childFeatures,
}: DriversUIProps) => {
  const [driverFilters, setDriverFilters] = useState({
    name: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });
  const [expandedRows, setExpandedRows] = useState<FleetDriverExtended[]>([]);
  const [uploadDriversFilePopup, setUploadDriversFilePopup] = useState(false);
  const [openAssignAccountTagModal, setOpenAssignAccountTagModal] =
    useState(false);
  const [selectedAccountTag, setSelectedAccountTag] = useState<
    undefined | AccountTagType
  >();
  const [itemName, setItemName] = useState<string>("");
  const [itemId, setItemId] = useState<string>("");

  const { isMobile } = usePlatformDetect();
  const router = useRouter();
  const driverMenuRefs = useRef<(React.RefObject<Menu> | null)[]>([]);
  const archivedDriverMenuRefs = useRef<(React.RefObject<Menu> | null)[]>([]);
  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const access = {
    ImportDriverAccess:
      childFeatures.find(
        (item) => item.name === "DRIVER_PAGE_IMPORT_DRIVERS",
      ) !== undefined,
  };

  driverMenuRefs.current = driversList.map(
    (_, i) => driverMenuRefs.current[i] ?? createRef(),
  );
  archivedDriverMenuRefs.current = driversList.map(
    (_, i) => archivedDriverMenuRefs.current[i] ?? createRef(),
  );
  const driverStatusTemplate = (rowData: FleetDriverExtended) => {
    return (
      <div className="flex items-center">
        <span className="whitespace-nowrap">
          <div>{rowData.status ? humanize(rowData.status) : "-"}</div>
        </span>
      </div>
    );
  };

  const accountTagTemplate = (rowData: FleetDriverExtended) => {
    let colorCode = "bg-white";
    if (rowData.accountTag) {
      const index = Number(rowData.accountTag?.colorCode) || 0;
      const length = ACCOUNT_TAGS_COLOR.length;
      colorCode = ACCOUNT_TAGS_COLOR[index % length];
    }
    return (
      <div className="flex gap-2 w-fit">
        <span
          className={classNames(
            colorCode,
            "rounded-full px-2 whitespace-nowrap text-ellipsis",
          )}
        >
          {rowData?.accountTag?.title || "-"}
        </span>
        <Button
          variant={ButtonVariant.Transparent}
          size={ButtonSize.Wrap}
          className="flex items-center w-4 h-4"
          onClick={() => {
            setSelectedAccountTag(rowData?.accountTag);
            setItemId(rowData.id);
            setItemName(rowData.name);
            setOpenAssignAccountTagModal(true);
          }}
        >
          <PencilIcon className="w-4 h-4" />
        </Button>
      </div>
    );
  };

  const rowExpansionTemplate = (data: FleetDriverExtended) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>Driver Name: {data?.name || "-"} </div>
          <div>Email: {data?.email || "-"} </div>
          <div>Phone Number: {data?.formattedPhoneNumber || "-"} </div>
          <div>Notes: {data?.notes || "-"} </div>
          {data?.status !== "DELETED" ? (
            <>
              <div className="flex items-center gap-1">
                Status: {driverStatusTemplate(data)}
              </div>
              <div className="flex items-center gap-1">
                Account Tag: {accountTagTemplate(data)}
              </div>
              <div className="flex flex-row justify-between gap-4 mt-2">
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.Red}
                  onClick={() => {
                    deleteDriverSetValues({
                      idList: [data.id],
                    });
                    deleteDriverHandleSubmit();
                  }}
                >
                  Archive
                </Button>
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    router.push(`/vehicles/${data.id}`);
                  }}
                >
                  Edit
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setJoyride(true);
  };
  useEffect(() => {
    const driversGuide = getCookie("driversGuide");
    if (!driversGuide) {
      setJoyride(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { status, type } = callbackData;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    }
    if (status === STATUS.READY) {
      setCookie("driversGuide", "true", 30);
    }
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex justify-between text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        <div className="">{`Drivers`.toUpperCase()}</div>
        <div className="flex items-center justify-end rounded-md md:mt-0 step-4">
          <div>
            <Button
              onClick={handleClickStart}
              variant={ButtonVariant.Transparent}
              size={ButtonSize.Wrap}
            >
              <QuestionMarkCircleIcon
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
              />
              <span className="px-1 text-xs italic">Help Center</span>
            </Button>
          </div>
        </div>
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={joyride}
        scrollToFirstStep
        showProgress
        scrollOffset={76}
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />

      <div className="p-8 mt-5 overflow-hidden bg-white rounded-md shadow md:mt-0">
        <div className="flex flex-row justify-end w-full">
          <div className="flex items-center gap-4">
            {!isMobile() && (
              <FuelCardComponentPermissionGuard
                type="button"
                featurePermission={access.ImportDriverAccess}
                messageType="enterprise"
              >
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    setUploadDriversFilePopup(true);
                  }}
                  className="flex flex-row items-center gap-2 p-2 text-sm step-2"
                >
                  Import Drivers{" "}
                  <span className="font-normal text-yellow-600">
                    (Enterprise)
                  </span>
                </Button>
              </FuelCardComponentPermissionGuard>
            )}
            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => {
                if (driversList.length >= maxDrivers) {
                  toast.error(
                    `Your account has a limit of ${maxDrivers} drivers`,
                  );
                } else {
                  router.push("/drivers/new");
                }
              }}
              className="flex flex-row items-center gap-2 p-2 text-sm step-2"
            >
              Add Driver
            </Button>
          </div>
        </div>
        <div className="border-b border-brown-500">
          <nav className="flex -mb-px space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tabOpen === tab.id
                    ? "border-black text-black"
                    : "border-transparent hover:border-black",
                  "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(tab.id);
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
        <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
            {/* <Table data={transactionList} rowsPerPage={10} /> */}
            <DataTable
              size="small"
              stripedRows
              pageLinkSize={isMobile() ? 2 : 3}
              paginator
              rows={10}
              dataKey="id"
              sortField="name"
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              style={{
                fontFamily: "inherit",
              }}
              loading={getDriversLoading}
              value={driversList}
              rowsPerPageOptions={[10, 20, 50]}
              className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
              filters={driverFilters}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data)
              }
            >
              <Column
                filter
                sortable
                filterMenuStyle={{
                  width: "14rem",
                }}
                filterPlaceholder="Search by driver name"
                field="name"
                header="Driver Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                showFilterMenuOptions={false}
                showFilterMatchModes={false}
                filterMenuClassName="custom-filter"
                filterElement={nameFilterTemplate}
              />
              {!isMobile() && (
                <Column
                  sortable
                  field="email"
                  header="Email"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  sortable
                  sortField="phoneNumber"
                  field="formattedPhoneNumber"
                  header="Phone Number"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  field="notes"
                  header="Notes"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              <Column
                sortable
                field="status"
                header="Status"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={driverStatusTemplate}
              />
              {!isMobile() && (
                <Column
                  sortable
                  field="accountTag.title"
                  header="Account Tag"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={accountTagTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  headerStyle={{ width: "3em" }}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base step-3"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e, props) => (
                    <div className="relative flex">
                      <Menu
                        model={[
                          {
                            label: "Edit",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                              router.push(`/drivers/${e.id}`);
                            },
                          },
                          {
                            label: "Archive",
                            icon: "pi pi-fw pi-trash",
                            command: () => {
                              deleteDriverSetValues({ idList: [e.id || []] });
                              deleteDriverHandleSubmit();
                            },
                          },
                        ]}
                        popup
                        ref={driverMenuRefs.current[props.rowIndex]}
                      />
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          driverMenuRefs.current[
                            props.rowIndex
                          ]?.current?.toggle(event)
                        }
                      >
                        <DotsVerticalIcon className="w-5"></DotsVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              )}
              {isMobile() && (
                <Column
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  expander
                  style={{ width: "3em" }}
                />
              )}
            </DataTable>
          </div>
        </div>
        <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-6`}>
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
            {/* <Table data={transactionList} rowsPerPage={10} /> */}
            <DataTable
              size="small"
              stripedRows
              paginator
              rows={10}
              dataKey="id"
              sortField="name"
              pageLinkSize={isMobile() ? 2 : 3}
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
              style={{
                fontFamily: "inherit",
              }}
              loading={getDriversLoading}
              value={archivedDriversList}
              rowsPerPageOptions={[10, 20, 50]}
              rowExpansionTemplate={rowExpansionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data)
              }
            >
              <Column
                sortable
                filterPlaceholder="Search by driver name"
                field="name"
                header="Driver Name"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              <Column
                sortable
                field="email"
                header="Email"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              {!isMobile() && (
                <Column
                  sortable
                  sortField="phoneNumber"
                  field="formattedPhoneNumber"
                  header="Phone Number"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  field="notes"
                  header="Notes"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  headerStyle={{ width: "3em" }}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base step-3"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e, props) => (
                    <div className="relative flex">
                      <Menu
                        model={[
                          {
                            label: "View",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                              router.push(`/drivers/${e.id}`);
                            },
                          },
                        ]}
                        popup
                        ref={archivedDriverMenuRefs.current[props.rowIndex]}
                      />
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          archivedDriverMenuRefs.current[
                            props.rowIndex
                          ]?.current?.toggle(event)
                        }
                      >
                        <DotsVerticalIcon className="w-5"></DotsVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              )}
              {isMobile() && (
                <Column
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  expander
                  style={{ width: "3em" }}
                />
              )}
            </DataTable>
          </div>
        </div>
      </div>

      {openAssignAccountTagModal && (
        <AssignAccountTagModal
          setOpen={setOpenAssignAccountTagModal}
          open={openAssignAccountTagModal}
          accountTagType={ENUM_ACCOUNT_TAG_TYPE.DRIVER}
          accountTag={selectedAccountTag}
          itemName={itemName}
          itemId={itemId}
          refetchData={getDriversRefetch}
        />
      )}

      {uploadDriversFilePopup && (
        <UploadDriversFile
          uploadDriversFilePopup={uploadDriversFilePopup}
          setUploadDriversFilePopup={setUploadDriversFilePopup}
          refetchDrivers={getDriversRefetch}
        ></UploadDriversFile>
      )}
    </div>
  );
};
